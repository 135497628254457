export const convertFileToBase64 = (file: File): Promise<string> => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve((reader.result as string).replace('data:application/octet-stream;base64,', ''))
        }
        reader.onerror = (error) => {
            reject(error)
        }
        reader.readAsDataURL(file)
    })
}