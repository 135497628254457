export const declarationTypeImportOptions = [
    { name: "H1", description: "H1: release for free circulation and special procedure: specific use (end use)" },
    { name: "H2", description: "H2: storage (customs warehousing)" },
    { name: "H3", description: "H3: specific use (temporary admission)" },
    { name: "H4", description: "H4: processing (inward processing)" },
    { name: "H5", description: "H5: introduction of goods (trade with Special Fiscal Territories or Territories with which the EU has formed a Customs Union)" },
    { name: "H6", description: "H6: N/A" },
    { name: "H7", description: "H7: release for free circulation in respect of a consignment which benefits from a relief from import duty in accordance with Article 23(1) or Article 25(1) of Regulation (EC) No 1186/2009" },
    { name: "I1", description: "I1: Import simplified declaration with regular use (DE 1/2 codes C&F)" },
    { name: "I2", description: "I2: Import simplified declaration on an occasional basis (DE 1/2 codes B&E)" }
]