export const cdsImportProcedureOptions = [
    "0002",
    "0012",
    "0014",
    "0015",
    "0016",
    "0017",
    "0018",
    "0019",
    "0100",
    "0101",
    "0107",
    "0110",
    "0111",
    "0121",
    "0122",
    "0123",
    "0131",
    "0140",
    "0142",
    "0143",
    "0144",
    "0145",
    "0146",
    "0148",
    "0151",
    "0153",
    "0154",
    "0161",
    "0163",
    "0168",
    "0171",
    "0177",
    "0178",
    "0195",
    "0196",
    "0700",
    "0701",
    "0707",
    "0710",
    "0711",
    "0721",
    "0722",
    "0723",
    "0731",
    "0740",
    "0742",
    "0743",
    "0744",
    "0745",
    "0746",
    "0748",
    "0751",
    "0753",
    "0754",
    "0761",
    "0763",
    "0768",
    "0771",
    "0777",
    "0778",
    "0795",
    "0796",
    "1000",
    "1001",
    "1007",
    "1010",
    "1011",
    "1021",
    "1022",
    "1023",
    "1031",
    "1040",
    "1042",
    "1043",
    "1044",
    "1045",
    "1046",
    "1048",
    "1051",
    "1053",
    "1054",
    "1061",
    "1063",
    "1068",
    "1071",
    "1077",
    "1078",
    "1095",
    "1096",
    "1100",
    "1101",
    "1107",
    "1110",
    "1111",
    "1121",
    "1122",
    "1123",
    "1131",
    "1140",
    "1142",
    "1143",
    "1144",
    "1145",
    "1146",
    "1148",
    "1151",
    "1153",
    "1154",
    "1161",
    "1163",
    "1168",
    "1171",
    "1177",
    "1178",
    "1195",
    "1196",
    "2100",
    "2101",
    "2107",
    "2110",
    "2111",
    "2121",
    "2122",
    "2123",
    "2131",
    "2140",
    "2142",
    "2143",
    "2144",
    "2145",
    "2146",
    "2148",
    "2151",
    "2153",
    "2154",
    "2161",
    "2163",
    "2168",
    "2171",
    "2177",
    "2178",
    "2195",
    "2196",
    "2200",
    "2201",
    "2207",
    "2210",
    "2211",
    "2221",
    "2222",
    "2223",
    "2231",
    "2240",
    "2242",
    "2243",
    "2244",
    "2245",
    "2246",
    "2248",
    "2251",
    "2253",
    "2254",
    "2261",
    "2263",
    "2268",
    "2271",
    "2277",
    "2278",
    "2295",
    "2296",
    "2300",
    "2301",
    "2307",
    "2310",
    "2311",
    "2321",
    "2322",
    "2323",
    "2331",
    "2340",
    "2342",
    "2343",
    "2344",
    "2345",
    "2346",
    "2348",
    "2351",
    "2353",
    "2354",
    "2361",
    "2363",
    "2368",
    "2371",
    "2377",
    "2378",
    "2395",
    "2396",
    "3100",
    "3101",
    "3107",
    "3110",
    "3111",
    "3121",
    "3122",
    "3123",
    "3131",
    "3140",
    "3142",
    "3143",
    "3144",
    "3145",
    "3146",
    "3148",
    "3151",
    "3153",
    "3154",
    "3161",
    "3163",
    "3168",
    "3171",
    "3177",
    "3178",
    "3195",
    "3196",
    "4000",
    "4001",
    "4007",
    "4010",
    "4011",
    "4021",
    "4022",
    "4023",
    "4031",
    "4040",
    "4042",
    "4043",
    "4044",
    "4045",
    "4046",
    "4048",
    "4051",
    "4053",
    "4054",
    "4061",
    "4063",
    "4068",
    "4071",
    "4077",
    "4078",
    "4095",
    "4096",
    "4200",
    "4201",
    "4207",
    "4210",
    "4211",
    "4221",
    "4222",
    "4223",
    "4231",
    "4240",
    "4242",
    "4243",
    "4244",
    "4245",
    "4246",
    "4248",
    "4251",
    "4253",
    "4254",
    "4261",
    "4263",
    "4268",
    "4271",
    "4277",
    "4278",
    "4295",
    "4296",
    "4300",
    "4301",
    "4307",
    "4310",
    "4311",
    "4321",
    "4322",
    "4323",
    "4331",
    "4340",
    "4342",
    "4343",
    "4344",
    "4345",
    "4346",
    "4348",
    "4351",
    "4353",
    "4354",
    "4361",
    "4363",
    "4368",
    "4371",
    "4377",
    "4378",
    "4395",
    "4396",
    "4400",
    "4401",
    "4407",
    "4410",
    "4411",
    "4421",
    "4422",
    "4423",
    "4431",
    "4440",
    "4442",
    "4443",
    "4444",
    "4445",
    "4446",
    "4448",
    "4451",
    "4453",
    "4454",
    "4461",
    "4463",
    "4468",
    "4471",
    "4477",
    "4478",
    "4495",
    "4496",
    "4500",
    "4501",
    "4507",
    "4510",
    "4511",
    "4521",
    "4522",
    "4523",
    "4531",
    "4540",
    "4542",
    "4543",
    "4544",
    "4545",
    "4546",
    "4548",
    "4551",
    "4553",
    "4554",
    "4561",
    "4563",
    "4568",
    "4571",
    "4577",
    "4578",
    "4595",
    "4596",
    "4600",
    "4601",
    "4607",
    "4610",
    "4611",
    "4621",
    "4622",
    "4623",
    "4631",
    "4640",
    "4642",
    "4643",
    "4644",
    "4645",
    "4646",
    "4648",
    "4651",
    "4653",
    "4654",
    "4661",
    "4663",
    "4668",
    "4671",
    "4677",
    "4678",
    "4695",
    "4696",
    "4800",
    "4801",
    "4807",
    "4810",
    "4811",
    "4821",
    "4822",
    "4823",
    "4831",
    "4840",
    "4842",
    "4843",
    "4844",
    "4845",
    "4846",
    "4848",
    "4851",
    "4853",
    "4854",
    "4861",
    "4863",
    "4868",
    "4871",
    "4877",
    "4878",
    "4895",
    "4896",
    "5100",
    "5101",
    "5107",
    "5110",
    "5111",
    "5121",
    "5122",
    "5123",
    "5131",
    "5140",
    "5142",
    "5143",
    "5144",
    "5145",
    "5146",
    "5148",
    "5151",
    "5153",
    "5154",
    "5161",
    "5163",
    "5168",
    "5171",
    "5177",
    "5178",
    "5195",
    "5196",
    "5300",
    "5301",
    "5307",
    "5310",
    "5311",
    "5321",
    "5322",
    "5323",
    "5331",
    "5340",
    "5342",
    "5343",
    "5344",
    "5345",
    "5346",
    "5348",
    "5351",
    "5353",
    "5354",
    "5361",
    "5363",
    "5368",
    "5371",
    "5377",
    "5378",
    "5395",
    "5396",
    "5400",
    "5401",
    "5407",
    "5410",
    "5411",
    "5421",
    "5422",
    "5423",
    "5431",
    "5440",
    "5442",
    "5443",
    "5444",
    "5445",
    "5446",
    "5448",
    "5451",
    "5453",
    "5454",
    "5461",
    "5463",
    "5468",
    "5471",
    "5477",
    "5478",
    "5495",
    "5496",
    "6100",
    "6101",
    "6107",
    "6110",
    "6111",
    "6121",
    "6122",
    "6123",
    "6131",
    "6140",
    "6142",
    "6143",
    "6144",
    "6145",
    "6146",
    "6148",
    "6151",
    "6153",
    "6154",
    "6161",
    "6163",
    "6168",
    "6171",
    "6177",
    "6178",
    "6195",
    "6196",
    "6300",
    "6301",
    "6307",
    "6310",
    "6311",
    "6321",
    "6322",
    "6323",
    "6331",
    "6340",
    "6342",
    "6343",
    "6344",
    "6345",
    "6346",
    "6348",
    "6351",
    "6353",
    "6354",
    "6361",
    "6363",
    "6368",
    "6371",
    "6377",
    "6378",
    "6395",
    "6396",
    "6800",
    "6801",
    "6807",
    "6810",
    "6811",
    "6821",
    "6822",
    "6823",
    "6831",
    "6840",
    "6842",
    "6843",
    "6844",
    "6845",
    "6846",
    "6848",
    "6851",
    "6853",
    "6854",
    "6861",
    "6863",
    "6868",
    "6871",
    "6877",
    "6878",
    "6895",
    "6896",
    "7100",
    "7101",
    "7107",
    "7110",
    "7111",
    "7121",
    "7122",
    "7123",
    "7131",
    "7140",
    "7142",
    "7143",
    "7144",
    "7145",
    "7146",
    "7148",
    "7151",
    "7153",
    "7154",
    "7161",
    "7163",
    "7168",
    "7171",
    "7177",
    "7178",
    "7195",
    "7196",
    "7700",
    "7701",
    "7707",
    "7710",
    "7711",
    "7721",
    "7722",
    "7723",
    "7731",
    "7740",
    "7742",
    "7743",
    "7744",
    "7745",
    "7746",
    "7748",
    "7751",
    "7753",
    "7754",
    "7761",
    "7763",
    "7768",
    "7771",
    "7777",
    "7778",
    "7795",
    "7796",
    "7800",
    "7801",
    "7807",
    "7810",
    "7811",
    "7821",
    "7822",
    "7823",
    "7831",
    "7840",
    "7842",
    "7843",
    "7844",
    "7845",
    "7846",
    "7848",
    "7851",
    "7853",
    "7854",
    "7861",
    "7863",
    "7868",
    "7871",
    "7877",
    "7878",
    "7895",
    "7896",
    "9500",
    "9501",
    "9507",
    "9510",
    "9511",
    "9521",
    "9522",
    "9523",
    "9531",
    "9540",
    "9542",
    "9543",
    "9544",
    "9545",
    "9546",
    "9548",
    "9551",
    "9553",
    "9554",
    "9561",
    "9563",
    "9568",
    "9571",
    "9577",
    "9578",
    "9595",
    "9596",
    "9600",
    "9601",
    "9607",
    "9610",
    "9611",
    "9621",
    "9622",
    "9623",
    "9631",
    "9640",
    "9642",
    "9643",
    "9644",
    "9645",
    "9646",
    "9648",
    "9651",
    "9653",
    "9654",
    "9661",
    "9663",
    "9668",
    "9671",
    "9677",
    "9678",
    "9695",
    "9696"
]