export const methodOfPaymentOptions = [
    { code: "A", description: "Payment in cash" },
    { code: "B", description: "Payment by credit card" },
    { code: "C", description: "Payment by cheque" },
    { code: "D", description: "Other (e. g. direct debit to agent's cash account)" },
    { code: "E", description: "Deferred or postponed payment" },
    { code: "G", description: "Postponed payment — VAT system (Article 211 of Directive 2006/112/EC)" },
    { code: "H", description: "Electronic credit transfer" },
    { code: "J", description: "Payment through post office administration (postal consignments) or other public sector or government department" },
    { code: "K", description: "Excise credit or rebate" },
    { code: "O", description: "Guarantee lodged with Intervention Agency" },
    { code: "P", description: "From agent's cash account" },
    { code: "R", description: "Guarantee of the amount payable" },
    { code: "S", description: "Individual guarantee account" },
    { code: "T", description: "From agent's guarantee account" },
    { code: "U", description: "From agent's guarantee — standing authority" },
    { code: "V", description: "From agent's guarantee — individual authority" }
]