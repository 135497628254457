import { PersonaAddress, CustomerAddressFormValues, CustomerbillingAddressFormValues } from "./personaAddress"
import { Tag } from "./tag"
import { DefaultTaxRate } from "./taxRate"

export interface CustomerBase{
    code: string
    name: string
    eori: string
    contactPhoneNumber: string
    contactMobileNumber: string
    contactName: string
    contactEmail: string
    contactWeb: string
    currencyIsoCode: string
    taxIdentifierLabel1: string
    taxIdentifierLabel2: string
    taxNumber1: string
    taxNumber2: string
    address: PersonaAddress
    billingAddress: PersonaAddress
    personaTags?: Tag[]
}

export interface Customer extends CustomerBase{
    id: string
    accountId: string
    organisationId: string
    currencyCode: string
    countryIsoCode: string
    status: string
    defaultTaxRate: DefaultTaxRate | undefined
    operatorType: string
}

export interface CustomerUpdate extends CustomerBase{
    id: string
    defaultTaxRate: string
}

export interface SupplierUpdate extends CustomerUpdate{
    operatorType: string
}

export interface CustomerAdd extends CustomerBase{
    organisationId: string
}

export interface SupplierAdd extends CustomerAdd{
    operatorType: string
}

export class Customer implements Customer {
    constructor(init: CustomerFormValues) {
        Object.assign(this, init)
    }
}

export class CustomerFormValues {
    organisationId?: string = ""
    id?: string = undefined
    name: string = ""
    code?: string = ""
    address: CustomerAddressFormValues = new CustomerAddressFormValues()
    billingAddress: CustomerbillingAddressFormValues = new CustomerbillingAddressFormValues()
    status: string = "Active"
    currencyIsoCode: string = "EUR"
    taxIdentifierLabel1: string = ""
    taxIdentifierLabel2: string = ""
    taxNumber1: string = ""
    taxNumber2: string = ""
    eori?: string = ""
    defaultTaxRate: DefaultTaxRate | undefined
    personaTags?: Tag[] = []
    operatorType: string = ""

    constructor(customer?: CustomerFormValues | Customer | undefined) {
        if (customer) {
            this.organisationId = customer.organisationId
            this.id = customer.id
            this.code = customer.code
            this.name = customer.name
            this.address = customer.address
            this.billingAddress = customer.billingAddress
            this.status = customer.status
            this.taxIdentifierLabel1 = customer.taxIdentifierLabel1
            this.taxIdentifierLabel2 = customer.taxIdentifierLabel2
            this.taxNumber1 = customer.taxNumber1
            this.taxNumber2 = customer.taxNumber2
            this.currencyIsoCode = customer.currencyIsoCode
            this.eori = customer.eori
            this.defaultTaxRate = customer.defaultTaxRate
            this.personaTags = customer.personaTags
            this.operatorType = customer.operatorType
        }
    }
}

export const CustomerStatus = [
    { value: -1, description: 'Inactive' },
    { value: 1, description: 'Active' },
]