import { useAuth0 } from "@auth0/auth0-react"
import { LoadPanel } from 'devextreme-react'
import { observer } from "mobx-react-lite"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useStore } from "../stores/store"

export default observer(function RequireAuth() {
  const location = useLocation()
  const { isAuthenticated } = useAuth0()
  const { userStore } = useStore()


  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />
  }

  if (!userStore.user) {
    return <LoadPanel visible={true} />
  }

  if (userStore.user && !userStore.user?.emailVerified && location.pathname !== "/pending-email-validation") {
    return <Navigate to="pending-email-validation" />
  }

  if (userStore.user && userStore.user?.emailVerified && location.pathname === "/pending-email-validation") {
    return <Navigate to="/" state={{ from: location }} />
  }

  return <Outlet />
})
