import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import CallbackPage from '../../features/callback/CallbackPage'
import HmrcCallbackPage from '../../features/callback/HmrcCallbackPage'
import DashboardPage from '../../features/dashboard/DashboardPage'
import NotFound from '../../features/errors/NotFound'
import ServerError from '../../features/errors/ServerError'
import RegisterUser from '../../features/registerUser/RegisterUser'
import DisplayTaskStatus from '../../features/displayTaskStatus/DisplayTaskStatus'
import App from '../layout/App'
import RequireAuth from './RequireAuth'
import HomePage from '../../features/homePage/HomePage'
import PendingEmailValidation from '../../features/pendingEmailValidation/PendingEmailValidation'
import ValidateEmail from '../../features/validateEmail/ValidateEmail'
import Organisations from '../../features/organisations/Organisations'
import ProfilePage from '../../features/profilePage/ProfilePage'
import AccountPage from '../../features/account/AccountPage'
import DeclarationsMain from '../../features/declaration/DeclarationsMain'
import PortClearanceList from '../../features/portClearance/PortClearanceList'
import PortClearanceForm from '../../features/portClearance/PortClearanceForm'
import Customers from '../../features/customer/Customers'
import CustomerForm from '../../features/customer/CustomerForm'
import DeclarationEdit from '../../features/declaration/DeclarationEdit'
import SafetyAndSecurityDeclaration from '../../features/declaration/SafetyAndSecurity/SafetyAndSecurityDeclaration'
import Suppliers from '../../features/supplier/Suppliers'
import SupplierForm from '../../features/supplier/SupplierForm'
import Addresses from '../../features/address/Addresses'
import AddressForm from '../../features/address/AddressForm'
import DeclarationTemplateList from '../../features/declarationTemplate/DeclarationTemplateList'
import DeclarationTemplateForm from '../../features/declarationTemplate/DeclarationTemplateForm'
import ExpressTemplateSelectorForm from '../../features/declarationTemplate/ExpressTemplateSelectorForm'
import DeclarationPackageList from '../../features/declarationPackage/DeclarationPackageList'
import DeclarationPackageForm from '../../features/declarationPackage/DeclarationPackageForm'
import RollOnRollOff from '../../features/declaration/RollOnRollOff'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          { path: '/dashboard', element: <DashboardPage /> },
          { path: '/pending-email-validation', element: <PendingEmailValidation /> },
          { path: '/', element: <DashboardPage /> },
          { path: '/organisation', element: <Organisations /> },
          { path: '/hmrc-auth-redirect', element: <HmrcCallbackPage /> },
          { path: '/profile', element: <ProfilePage /> },
          { path: '/account-page', element: <AccountPage /> },
          { path: '/declarations-main', element: <DeclarationsMain /> },
          { path: '/declarations/:authoritySystem/:isImport/:isExs', element: <DeclarationEdit /> },
          { path: '/declarations/:id', element: <DeclarationEdit /> },
          { path: '/declarations/ens/authority/:authoritySystem', element: <SafetyAndSecurityDeclaration /> },
          { path: '/declarations/ens/:id', element: <SafetyAndSecurityDeclaration /> },
          { path: '/declaration-packages', element: <DeclarationPackageList /> },
          { path: '/declaration-packages/:id', element: <DeclarationPackageForm /> },
          { path: '/declaration-templates', element: <DeclarationTemplateList /> },
          { path: '/declaration-templates/:templateId', element: <DeclarationTemplateForm /> },
          { path: '/declarations-roro', element: <RollOnRollOff /> },
          { path: '/port-clearances', element: <PortClearanceList /> },
          { path: '/port-clearance', element: <PortClearanceForm /> },
          { path: '/port-clearance/:id', element: <PortClearanceForm /> },
          { path: '/customers', element: <Customers /> },
          { path: '/customer/:id', element: <CustomerForm /> },
          { path: '/customer', element: <CustomerForm /> },
          { path: '/suppliers', element: <Suppliers /> },
          { path: '/supplier/:id', element: <SupplierForm /> },
          { path: '/supplier', element: <SupplierForm /> },
          { path: '/addresses', element: <Addresses /> },
          { path: '/address/:id', element: <AddressForm /> },
          { path: '/address', element: <AddressForm /> },
          { path: '/express-template/:id', element: <ExpressTemplateSelectorForm /> }
        ],
      },
      { path: '/home-page', element: <HomePage /> },
      { path: '/not-found', element: <NotFound /> },
      { path: '/server-error', element: <ServerError /> },
      { path: '/task-status/:encodedId', element: <DisplayTaskStatus /> },
      { path: '/register-user', element: <RegisterUser /> },
      { path: '/validate-email/:tokenStr', element: <ValidateEmail /> },
      { path: '*', element: <Navigate replace to='/not-found' /> },
    ],
  },

  { path: '/callback', element: <CallbackPage /> }
]

export const router = createBrowserRouter(routes)
