import { AppState, Auth0Provider } from "@auth0/auth0-react"
import React, { PropsWithChildren } from "react"
import { router } from '../router/Routes'

export const AuthProvider = ({ children }: PropsWithChildren<any>): JSX.Element | null => {

    const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID
    const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE
    const redirectUri: string | undefined = `${window.location.origin}/callback`
    const onRedirectCallback = (appState?: AppState) => {
        router.navigate(appState?.returnTo || window.location.pathname)
    }

    if (!(domain && clientId)) {
        return null
    }
    
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: audience
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    )
}