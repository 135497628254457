import { useEffect, useRef, useState } from "react"
import { methodOfPaymentOptions } from "../../../app/common/options/cdsImportDeclaration/methodOfPaymentOptions"
import { DeclarationValuationInformationTaxes, FieldError } from "../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationValuationInformationTaxes | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityValuationInformationTaxes({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        methodOfPaymentRule: boolean
    } = {
        methodOfPaymentRule: isFieldValid("methodOfPayment", fieldErrors)
    };
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Valuation Information Taxes'} visible={isFieldVisible("methodOfPayment")} >
            <Item dataField="methodOfPayment" label={{text: "Method Of Payment"}} editorType="dxSelectBox"
                    editorOptions={{
                      items: methodOfPaymentOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                        <CustomRule message={getErrorMessage("methodOfPayment", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.methodOfPaymentRule}} />
                    </Item>
        </GroupItem>
    </Form>
}