import { DataGrid } from 'devextreme-react'
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { Column, CustomRule, Editing, Lookup } from 'devextreme-react/data-grid'
import { DecalarationData } from '../../app/models/declarationCreate'
import { natureOfTransactionOptions } from '../../app/common/options/cdsImportDeclaration/natureOfTransactionOptions'
import { guaranteeTypeOptions } from '../../app/common/options/cdsImportDeclaration/guaranteeTypeOptions'
import { currencyOptions } from '../../app/common/options/currencyOptions'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function OtherDataElements({formData, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        natureOfTransactionRule: boolean
    } = {
        natureOfTransactionRule: isFieldValid("natureOfTransaction", formData.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, formData.fieldErrors);
      }

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Other data elements'} visible={isFieldVisible("section-otherdataelements")}>
        <GroupItem cssClass={'cds-group-item'} colCount={1}>
            <Item dataField="otherDataElements.natureOfTransaction" label={{text: "Nature of transaction"}} editorType="dxSelectBox"
                    visible={isFieldVisible("otherDataElements.natureOfTransaction")}
                    editorOptions={{
                      items: natureOfTransactionOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code"
                    }}>
                        <CustomRule message={handleGetErrorMessage("natureOfTransaction")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.natureOfTransactionRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Guarantee type'}>
            <GroupItem colCount={1}>
                <DataGrid dataSource={formData.otherDataElements.guaranteeType} visible={isFieldVisible("otherDataElements.guaranteeType")}>
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                    <Column dataField="type"><Lookup dataSource={guaranteeTypeOptions} displayExpr={'description'} valueExpr={'code'} /></Column>
                </DataGrid>
            </GroupItem>
            <GroupItem>
                <DataGrid dataSource={formData.otherDataElements.guaranteeList} visible={isFieldVisible("otherDataElements.guaranteeList")}>
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                    <Column dataField="reference" visible={isFieldVisible("otherDataElements.guaranteeList.reference")}></Column>
                    <Column dataField="amount" dataType="number" visible={isFieldVisible("otherDataElements.guaranteeList.amount")}></Column>
                    <Column dataField="office" visible={isFieldVisible("otherDataElements.guaranteeList.office")}></Column>
                    <Column dataField="accessCode" dataType="number" visible={isFieldVisible("otherDataElements.guaranteeList.accessCode")}></Column>
                    <Column dataField="currency" visible={isFieldVisible("otherDataElements.guaranteeList.currency")}><Lookup dataSource={currencyOptions} valueExpr={'code'} displayExpr={'name'} /></Column>
                </DataGrid>
            </GroupItem>
        </GroupItem>
    </GroupItem>
    </Form>
}