import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Customer, CustomerAdd, CustomerUpdate } from "../models/customer/customer";

export default class SupplierStore {
    supplier: Customer | null = null;
    suppliers: Customer[] | null = null;
    loading: Boolean = false;
    isSuccess: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    getSupplier = async (supplierId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Suppliers.getSupplier(supplierId)
            runInAction(() => {
                this.isSuccess = true;
                this.supplier = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }

    }

    getSuppliers = async (ogranisationId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Suppliers.getSuppliers(ogranisationId)
            runInAction(() => {
                if(response.isSuccess && response.data)
                {
                    this.isSuccess = true
                    this.suppliers = response.data.data
                }
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    updateSupplier = async (acc: CustomerUpdate) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Suppliers.updateSupplier(acc)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    createSupplier = async (acc: CustomerAdd) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Suppliers.createSupplier(acc)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    deleteSupplier = async (customerId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Suppliers.deleteSupplier(customerId)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }
}