import { DataGrid, Popup } from "devextreme-react"
import { Column, Editing, Position, ToolbarItem } from "devextreme-react/data-grid"
import { DeclarationGoods } from "../../../app/models/declarationCreate";

interface Props{
    formData: DeclarationGoods[];
    setAdding: (item: boolean) => void;
    setUpdate: (id: number) => void;
    onRowDeleted: () => void;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityGoodsView({formData, setAdding, setUpdate, onRowDeleted, isFieldVisible}: Props){
    return <>
                <DataGrid dataSource={formData} keyExpr='itemDetail.messageInformation.goodsItemNumber' onRowRemoved={() => onRowDeleted()} onInitNewRow={() => setAdding(true)} onEditingStart={(rows) => setUpdate(rows.key)} visible={isFieldVisible("references")}>
                    <Editing mode="row" allowAdding={true} allowDeleting={true} allowUpdating={true} >
                    </Editing>
                    <Column dataField='itemDetail.messageInformation.goodsItemNumber' caption={'Item #'} defaultSortIndex={0} defaultSortOrder="asc"></Column>
                    <Column dataField='itemDetail.references.referencesEns.commercialReferenceNumber' caption={'Commericial #'}></Column>
                    <Column dataField='itemDetail.references.referencesEns.additionalInformation' caption={'Additional Info'}></Column>
                    <Column dataField='itemDetail.references.referencesEns.transportDocumentType' caption={'Transport Doc Type'}></Column>
                    <Column dataField='itemDetail.references.referencesEns.transportDocumentNumber' caption={'Transport Doc #'}></Column>
                    <Column dataField='itemDetail.references.referencesEns.localReferenceNumber' caption={'Local Ref #'}></Column>
                </DataGrid>
                <Popup             
        hideOnOutsideClick={true}
      >
        <Position
          at="center"
          my="center"
          collision="fit"
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: 'Close'          
          }}
        />
      </Popup>
           </>
}