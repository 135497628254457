import React from 'react'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import Button from 'devextreme-react/button'
import UserPanel from './UserPanel'
import { Template } from 'devextreme-react'
import { ClickEvent } from 'devextreme/ui/button'

export interface HeaderProps {
    menuToggleEnabled: boolean
    toggleMenu: (e: ClickEvent) => void
}

export default function Header({ menuToggleEnabled, toggleMenu }: HeaderProps) {
    return (
        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'}>
                <Item

                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'} >
                    <Button icon="menu" visible={menuToggleEnabled} stylingMode="text" onClick={toggleMenu} />
                </Item>
                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    text={'mycustomsclouds'}
                    visible={true}
                />
                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'} >
                    <Button
                        visible={menuToggleEnabled}
                        className={'user-button authorization'}
                        height={'100%'}
                        stylingMode={'text'}>
                        <UserPanel menuMode={'context'} />
                    </Button>
                </Item>
                <Template name={'userPanelTemplate'}>
                    <UserPanel menuMode={'list'} />
                </Template>
            </Toolbar>
        </header>
    )
}