export const guaranteeTypeOptions = [
    { code: "0", description: "For guarantee waiver (Article 95(2) of the Code)" },
    { code: "1", description: "For comprehensive guarantee (Article 89(5) of the Code)" },
    { code: "2", description: "For individual guarantee in the form of an undertaking by a guarantor (Article 92(1)(b) of the Code)" },
    { code: "3", description: "For individual guarantee in cash or other means of payment recognised by the customs authorities as being equivalent to a cash deposit, made in euro or in the currency of the Member State in which the guarantee is required (Article 92(1)(a) of the Code)" },
    { code: "4", description: "For individual guarantee in the form of vouchers (Article 92(1)(b) of the Code and Article 160)" },
    { code: "5", description: "For guarantee waiver where the amount of import or export duty to be secured does not exceed the statistical value threshold for declarations laid down in accordance with Article 3(4) of Regulation (EC) No 471/2009 of the European Parliament and of the Council (Article 89(9) of the Code) Regulation (EC) No 471/2009 of the European Parliament and of the Council of 6 May 2009 on Community statistics relating to external trade with non-member countries and repealing Council Regulation (EC) No 1172/95 (OJ L 152, 16.6.2009, p. 23)." },
    { code: "8", description: "For guarantee not required for certain public bodies (Article 89(7) of the Code)" },
    { code: "B", description: "For guarantee furnished for goods dispatched under TIR procedure" },
    { code: "C", description: "For guarantee not required for goods carried by fix transport installations (Article 89(8)(b) of the Code)" },
    { code: "D", description: "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(a) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)" },
    { code: "E", description: "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(b) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)" },
    { code: "F", description: "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article81(c) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)" },
    { code: "G", description: "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(d) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)" },
    { code: "H", description: "For guarantee not required for goods placed under the Union transit procedure in accordance with Article 89(8)(d) of the Code" },
    { code: "I", description: "For individual guarantee in another form which provides equivalent assurance that the amount of import or export duty corresponding to the customs debt and other charges will be paid (Article 92(1)(c) of the Code)" },
    { code: "J", description: "Guarantee not required for the journey between customs office of departure and customs office of transit - Article 10(2)(b) of the Convention of 20 May 1987 on a common transit procedure" },
    { code: "R", description: "For guarantee not required for goods carried on the Rhine, the Rhine waterways, the Danube or the Danube waterways (Article 89(8)(a) of the Code)" }
]