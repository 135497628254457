import Form, { CustomRule, GroupItem, Item } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { transportMeansIdTypeOptions } from '../../../app/common/options/cdsImportDeclaration/transportMeansIdentificationTypes'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsTransportInformation({formData, fieldErrors, isFieldVisible}: Props){
  const [isFormReady, setIsFormReady] = useState(false)
  const formRef = useRef<Form>(null)

  const currentValidateForm = () => {
    if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
      formRef.current.instance.validate()
    }
  }

  useEffect(() => {
    if(isFormReady)
      currentValidateForm()
  });

  const validationRules: {
    containerRule: boolean
    containerIdRule: boolean
    identityMeansOfTransportOnArrivalRule: boolean
    identityOfMeansTransportOnArrivalRule: boolean
  } = {
    containerRule: isFieldValidWithSequence("container", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    containerIdRule: isFieldValidWithSequence("containerId", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    identityMeansOfTransportOnArrivalRule: isFieldValidWithSequence("identityMeansOfTransportOnArrival", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    identityOfMeansTransportOnArrivalRule: isFieldValidWithSequence("identityOfMeansTransportOnArrival", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
  };

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
      <GroupItem cssClass="cds-group-item" colCount={2} caption={'Transport Information'} visible={isFieldVisible("goods.transportInformation")}>
            <Item dataField="itemDetail.transportInformation.container" editorType={'dxCheckBox'} label={{text: "Container"}} visible={isFieldVisible("goods.transportInformation.container")}>
              <CustomRule message={getErrorMessageWithSequence("container", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.containerRule}} />
            </Item>
            <Item dataField="itemDetail.transportInformation.containerId" label={{text: "Container identification number"}} visible={isFieldVisible("goods.transportInformation.containerId")}>
              <CustomRule message={getErrorMessageWithSequence("containerId", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.containerIdRule}} />
            </Item>
            <Item dataField="transportInformation.identityMeansOfTransportOnArrival" label={{text: "Means of transport on arrival"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.transportInformation.identityMeansOfTransportOnArrival")}
                    editorOptions={{
                      items: transportMeansIdTypeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code"
                    }}>
                <CustomRule message={getErrorMessageWithSequence("identityMeansOfTransportOnArrival", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityMeansOfTransportOnArrivalRule}} />
                    </Item>
            <Item dataField="transportInformation.identityOfMeansTransportOnArrival" label={{text: "Identity of means of transport on arrival"}} visible={isFieldVisible("goods.transportInformation.identityOfMeansTransportOnArrival")}>
              <CustomRule message={getErrorMessageWithSequence("identityOfMeansTransportOnArrival", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityOfMeansTransportOnArrivalRule}} />
            </Item>
            <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
        </GroupItem>
        </Form>
}