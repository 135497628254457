import { UpdateAccount } from '../../app/models/account'
import { useStore } from '../../app/stores/store'
import Form, { ButtonItem, GroupItem, Item } from "devextreme-react/form";
import { LoadPanel } from 'devextreme-react';
import { observer } from 'mobx-react-lite';
import { notifyMessage } from '../../app/common/utils/notify';


const AccountInfo = () => {
    const { accountStore } = useStore()
    const updateProps = ["name", "logo", "notes"]
    let accountUpdated: UpdateAccount | null = null

    const onInitialized = () => {
        var elements = document.getElementsByName("name")
        elements[0]?.focus()
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        updateAccount()
    }

    function updateAccount() {
        const hasAccUpdates = updateProps.some(prop => accountStore.account?.[prop] !== accountUpdated?.[prop])
        if (hasAccUpdates && accountUpdated) {
            accountStore.updateAccount(accountUpdated)
                .then(() => notifyMessage("success", "Account Updated"))
                .catch(() => notifyMessage("error", "Account could not be Updated"))

        } else {
            if (process.env.NODE_ENV === "development") {
                console.log("it is the same Account")
            }
        }
    }

    if (!accountStore.account) {
        return <LoadPanel visible={true} />
    }

    accountUpdated = {
        "name": accountStore.account.name,
        "logo": accountStore.account.logo,
        "notes": accountStore.account.notes
    }

    return (
        <div className='html-blank-profile'>
            <div className='account-info-container'>
                <div className='add-template-form generic-box-shadows'>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Form
                            className="add-template-form"
                            formData={accountUpdated}
                            labelMode='floating'
                            onContentReady={onInitialized}
                        >
                            <Item >
                                <img className='form-avatar' src={"https://s.gravatar.com/avatar/4e5d5d6e8ca47af7786eb65bfe41853d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fse.png"} alt="Account" />
                            </Item>
                            <GroupItem colCount={1}>
                                <Item
                                    dataField="name"
                                />
                                <Item
                                    dataField="notes"
                                    editorType="dxTextArea"
                                    editorOptions={{
                                        minHeight: 100,
                                    }}
                                >
                                </Item>
                                <ButtonItem horizontalAlignment={"left"}
                                    buttonOptions={{
                                        text: 'Save',
                                        type: 'default',
                                        useSubmitBehavior: true,
                                    }}
                                />
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default observer(AccountInfo)