import Barcode from "react-barcode";
import { TaskStatus } from "../../app/models/clearances";



/// Detailed list the errors in the Status
interface ErrorProps {
  errors: string[];
}

const ErrorDetail = ({ errors }: ErrorProps) => {
  if (!errors || !errors.length)
        return (
            <span className="get-task-status-details-items-results">N/A</span>
        );
  return (
    <ul className="get-task-status-details-items-errors-li-container">
      {errors.map((error, index) => {
        return (
                    <li
                        className="get-task-status-details-items-errors-li"
                        key={index}
                    >
            {error}
          </li>
        );
      })}
    </ul>
  );
};


//// Format the data for a better display
const formatKey = (msg: string) => {
  let newMsg = "";
  if (msg === "errors") {
    return "Awaiting Action";
  }
  newMsg = msg.replace(/([A-Z])/g, " $1");
  newMsg = newMsg.charAt(0).toUpperCase() + newMsg.slice(1);
  return newMsg;
};

const formatValue = (value: string | string[]) => {
  if (!value || value === "None") return "N/A";
  let newMsg = "";
  if (typeof value === "string") {
    newMsg = value.replace(/(_)/g, " ");
  }
  return newMsg;
};

//// Return the cards for wach status properly formated

interface Props {
  status: TaskStatus;
}

export default function TaskStatusCards({ status }: Props) {
  const keySequence = [
    "portName",
    "portCountryCode",
    "authorityType",
    "portClearanceId",
    "status",
    "channelStatus",
    "channelAction",
    "errors",
  ];

  
  let cards = (
    <div
      className={"get-task-status-details-container"}
      key={status.portClearanceId}
    >
      {keySequence.map((key) => {
        return (
          <div key={key} className={"get-task-status-details-blocks"}>
            <span className={"get-task-status-details-items"}>
              {formatKey(key)}:
            </span>
            <span className="get-task-status-details-items-results">
              {key === "errors" ? (
                <ErrorDetail errors={status[key]} />
              ) : (
                    <>
                        {formatValue(
                            status[key as keyof TaskStatus]
                        )}
                    </>
              )}
            </span>
          </div>
        );
      })}
      {status.portClearanceId != "N/A" && (
        <div className={"get-task-status-details-blocks-barcode"}>
        <Barcode value={status.portClearanceId} margin={0}/>
      </div>
      )}
    </div>
  );

  return <>{cards}</>;
}
