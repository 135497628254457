import { DataGrid } from 'devextreme-react'
import Form, { GroupItem, Item } from 'devextreme-react/form'
import { Column, CustomRule, Editing, GroupingTexts, Lookup } from 'devextreme-react/data-grid'
import { DecalarationData } from '../../app/models/declarationCreate'
import { documentCategoryOptions } from '../../app/common/options/cdsImportDeclaration/documentCategoryOptions'
import { deferredPaymentOptions } from '../../app/common/options/cdsImportDeclaration/deferredPaymentOptions'
import { additionalInformationCodeOptions } from '../../app/common/options/cdsImportDeclaration/additionalInformationCodeOptions'
import { authorisationHolderCategoryCodeOptions } from '../../app/common/options/cdsImportDeclaration/authorisationHolderCategoryCodeOptions'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    isTemplate: boolean;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function ReferencesCard({formData, isTemplate, isFieldVisible}: Props){
      const lrnOptions = {maxLength: 22};
      const [isFormReady, setIsFormReady] = useState(false)
      const formRef = useRef<Form>(null)
      const lrnText = (isTemplate) ? "Template Name" : "LRN";

const currentValidateForm = () => {
    if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
        formRef.current.instance.validate()
    }
}

useEffect(() => {
    if(isFormReady){
        currentValidateForm()
    }
})

const validationRules: {
    lrnRule: boolean,
    totalPackagesRule: boolean,
    deferredPayment1Id: boolean,
    deferredPayment1Category: boolean,
    deferredPayment1Type: boolean,
    deferredPayment2Id: boolean,
    deferredPayment2Category: boolean,
    deferredPayment2Type: boolean,
    identificationOfWarehouse: boolean,
    referenceNumberUCR: boolean
  } = {
    lrnRule: isFieldValid("localReferenceNumber", formData.fieldErrors),
    totalPackagesRule: isFieldValid("totalPackagesRef", formData.fieldErrors),
    deferredPayment1Id: isFieldValid("deferredPayment1.identifier", formData.fieldErrors),
    deferredPayment1Category: isFieldValid("deferredPayment1.category", formData.fieldErrors),
    deferredPayment1Type: isFieldValid("deferredPayment1.type", formData.fieldErrors),
    deferredPayment2Id: isFieldValid("deferredPayment2.identifier", formData.fieldErrors),
    deferredPayment2Category: isFieldValid("deferredPayment2.category", formData.fieldErrors),
    deferredPayment2Type: isFieldValid("deferredPayment2.type", formData.fieldErrors),
    identificationOfWarehouse: isFieldValid("identificationOfWarehouse", formData.fieldErrors),
    referenceNumberUCR: isFieldValid("referenceNumberUCR", formData.fieldErrors)
  };

  const handleGetErrorMessage = (fieldId: string) => {
    return getErrorMessage(fieldId, formData.fieldErrors);
  }

    return <Form ref={formRef} formData={formData} onContentReady={() => setIsFormReady(true)} >
        <GroupItem cssClass="cds-group-item" colCount={1} caption={'References'} >
                <GroupItem cssClass="cds-group-item" colCount={1} caption={'Simplified declaration / Previous documents'} visible={"section-references"}>
                    <GroupItem>
                        <DataGrid dataSource={formData.references} >
                            <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                                <Column dataField="category" visible={isFieldVisible("references.category")} >
                                    <Lookup dataSource={documentCategoryOptions} valueExpr='name' displayExpr='description'/>
                                </Column>
                                <Column dataField="type" visible={isFieldVisible("references.type")} />
                                <Column dataField="reference" caption='Reference' visible={isFieldVisible("references.reference")}/>
                                <Column dataField="itemID" visible={isFieldVisible("references.itemId")} />
                                <Column dataField="lpcoExemptionCode" visible={isFieldVisible("references.lpco")}/>
                        </DataGrid>
                    </GroupItem>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2}>
                    <Item dataField="totalPackagesRef" editorType="dxNumberBox" label={{text: "Total Packages"}} visible={isFieldVisible("totalPackagesRef")}>
                        <CustomRule message={handleGetErrorMessage("totalPackagesRef")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.totalPackagesRule}} />
                    </Item>
                    <Item dataField="lrn" label={{text: lrnText}} editorOptions={lrnOptions}>
                        <CustomRule message={handleGetErrorMessage("localReferenceNumber")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.lrnRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={1} caption={'Additional Information'}  visible={isFieldVisible("section-additionalinformation")}>
                    <DataGrid dataSource={formData.additionalInfo} visible={isFieldVisible("additionalInfo")} >
                        <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                        <Column dataField="code" visible={isFieldVisible("additionalInformation.code")} >
                            <Lookup dataSource={additionalInformationCodeOptions} valueExpr='name' displayExpr='description'/>
                        </Column>
                        <Column dataField="description" visible={isFieldVisible("additionalInformation.description")}></Column>
                    </DataGrid>
                </GroupItem>
                {formData.authoritySystem != "AES" && 
                    <GroupItem cssClass="cds-group-item" colCount={3} caption={'Deferred payment'} visible={isFieldVisible("section-deferredpayment")}>
                        <Item dataField="deferredPayment1.identifier" label={{text: "ID"}} visible={isFieldVisible("deferredPayment1.identifier")} >
                            <CustomRule message={handleGetErrorMessage("deferredPayment1.identifier")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment1Id}} />
                        </Item>
                        <Item dataField="deferredPayment1.category" label={{text: "Category"}} visible={isFieldVisible("deferredPayment1.category")}>
                            <CustomRule message={handleGetErrorMessage("deferredPayment1.category")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment1Category}} />
                        </Item>
                        <Item dataField="deferredPayment1.type" label={{text: "Type"}} editorType="dxSelectBox"
                                visible={isFieldVisible("deferredPayment1.type")}
                                editorOptions={{
                                items: deferredPaymentOptions
                                }}>
                                    <CustomRule message={handleGetErrorMessage("deferredPayment1.type")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment1Type}} />
                                </Item>
                        <Item dataField="deferredPayment2.identifier" label={{text: "ID"}} visible={isFieldVisible("deferredPayment2.identifier")}>
                            <CustomRule message={handleGetErrorMessage("deferredPayment2.identifier")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment2Id}} />
                        </Item>
                        <Item dataField="deferredPayment2.category" label={{text: "Category"}} visible={isFieldVisible("deferredPayment2.category")}>
                            <CustomRule message={handleGetErrorMessage("deferredPayment2.category")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment2Category}} />
                        </Item>
                        <Item dataField="deferredPayment2.type" label={{text: "Type"}} editorType="dxSelectBox"
                                visible={isFieldVisible("deferredPayment2.type")}
                                editorOptions={{
                                items: deferredPaymentOptions
                                }}>
                                    <CustomRule message={handleGetErrorMessage("deferredPayment2.type")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment2Type}} />
                                </Item>
                                <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                    </GroupItem>
                }
                {formData.authoritySystem == "AES" && 
                    <GroupItem cssClass="cds-group-item" caption={'Deferred payment'} visible={isFieldVisible("section-deferredpayment")}>
                        <Item dataField="deferredPayment1.identifier" label={{text: "Account ID"}} visible={isFieldVisible("deferredPayment1.identifier")}>
                            <CustomRule message={handleGetErrorMessage("deferredPayment1.identifier")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.deferredPayment1Id}} />
                        </Item>
                    </GroupItem>
                }
                <GroupItem cssClass="cds-group-item" visible={isFieldVisible("identificationOfWarehouse")}>
                    <Item dataField="identificationOfWarehouse" label={{text: "ID of Warehouse"}}>
                        <CustomRule message={handleGetErrorMessage("identificationOfWarehouse")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identificationOfWarehouse}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" caption={'Authorisation Holders'} visible={isFieldVisible("section-authorisationHolder")}>
                <DataGrid dataSource={formData.authorisationHolder} >
                        <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                        <Column dataField="id" visible={isFieldVisible("authorisationHolder.id")} ></Column>
                        <Column dataField="categoryCode" visible={isFieldVisible("authorisationHolder.categoryCode")} >
                            <Lookup dataSource={authorisationHolderCategoryCodeOptions} valueExpr='code' displayExpr='description'/>
                        </Column>
                    </DataGrid>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" visible={isFieldVisible("referenceNumberUCR")}>
                    <Item dataField="referenceNumberUCR" label={{text: "Ref#/UCR"}}>
                        <CustomRule message={handleGetErrorMessage("referenceNumberUCR")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.referenceNumberUCR}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
                <GroupItem>
                    {/*I have a blank GroupItem to keep last field line from overlapping the border*/}
                </GroupItem>
            </GroupItem>
            </Form>
  }