import { useEffect, useRef, useState } from "react"
import { DeclarationPlaces, FieldError } from "../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationPlaces | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityDatesAndTimes({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        arrivalAtFirstPlaceRule: boolean
    } = {
        arrivalAtFirstPlaceRule: isFieldValid("", fieldErrors)
    };
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Dates And Times'} >
            <Item dataField="arrivalAtFirstPlace" label={{text: "Arrival At First Place"}} editorType={'dxDateBox'} visible={isFieldVisible("arrivalAtFirstPlace")} 
                editorOptions={{
                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                    displayFormat: "dd/MM/yyyy HH:mm",
                    type: "datetime"
                }}>
                    <CustomRule message={getErrorMessage("arrivalAtFirstPlace", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivalAtFirstPlaceRule}} />
            </Item>
        </GroupItem>
    </Form>
}