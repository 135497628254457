import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { supplyChainRoleOptions } from '../../../app/common/options/cdsImportDeclaration/supplyChainRoleOptions'
import { CheckBox } from 'devextreme-react';
import ActorEdit from '../ActorEdit';
import { useEffect, useRef, useState } from 'react';
import ActorEditSupplyChain from '../ActorEditSupplyChain';
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions';
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods;
    decType: string;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsParties({formData, decType, fieldErrors, isFieldVisible } : Props){
    const [actorEditMode, setActorEditMode] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        exporterNameRule: boolean
        exporterEoriRule: boolean
        importerNameRule: boolean
        importerEoriRule: boolean
        buyerNameRule: boolean
        buyerEoriRule: boolean
        sellerNameRule: boolean
        sellerEoriRule: boolean
        representativeNameRule: boolean
        representativeEoriRule: boolean
        representativeStatusRule: boolean
        declarantNameRule: boolean
        declarantEoriRule: boolean
        additionalSupplyChainActorIdRule: boolean
        additionalSupplyChainActorRoleRule: boolean
    } = {
        exporterNameRule: isFieldValidWithSequence("exporterName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        exporterEoriRule: isFieldValidWithSequence("exporterEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        importerNameRule: isFieldValidWithSequence("importerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        importerEoriRule: isFieldValidWithSequence("importerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        buyerNameRule: isFieldValidWithSequence("buyerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        buyerEoriRule: isFieldValidWithSequence("buyerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        sellerNameRule: isFieldValidWithSequence("sellerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        sellerEoriRule: isFieldValidWithSequence("sellerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        representativeNameRule: isFieldValidWithSequence("representativeName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        representativeEoriRule: isFieldValidWithSequence("representativeEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        representativeStatusRule: isFieldValidWithSequence("representativeStatus", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        declarantNameRule: isFieldValidWithSequence("declarantName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        declarantEoriRule: isFieldValidWithSequence("declarantEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        additionalSupplyChainActorIdRule: isFieldValidWithSequence("additionalSupplyChainActorId", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        additionalSupplyChainActorRoleRule: isFieldValidWithSequence("additionalSupplyChainActorRole", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
    };

    if(actorEditMode)
    {
        return <Form formData={formData}>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'}>
                    <CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} />
                    {isFieldVisible("goods.parties.exporter") && <ActorEdit formData={formData.itemDetail.parties.exporter} actorCaption='Exporter' isRepresentative={false} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.importer") && <ActorEdit formData={formData.itemDetail.parties.importer} actorCaption='Importer' isRepresentative={false} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.seller") && <ActorEdit formData={formData.itemDetail.parties.seller} actorCaption='Seller' isRepresentative={false} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.buyer") && <ActorEdit formData={formData.itemDetail.parties.buyer} actorCaption='Buyer' isRepresentative={false} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.representative") && <ActorEdit formData={formData.itemDetail.parties.representative} actorCaption='Representative' isRepresentative={true} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.declarant") && <ActorEdit formData={formData.itemDetail.parties.declarant} actorCaption='Declarant' isRepresentative={false} fieldErrors={fieldErrors} />}
                    {isFieldVisible("goods.parties.supplyChain") && <ActorEditSupplyChain formData={formData.itemDetail.parties} actorCaption='Supply Chain' fieldErrors={fieldErrors} />}
                </GroupItem>
                </Form>
    }

    return <div><Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'} visible={isFieldVisible("goods.parties")}>
                        <GroupItem colSpan={2}><CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} /></GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Exporter"}>
                            <Item dataField="itemDetail.parties.exporter.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.exporter.name")}>
                                <CustomRule message={getErrorMessageWithSequence("exporterName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.exporterNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.exporter.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.exporter.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("exporterEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.exporterEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Importer"}>
                            <Item dataField="itemDetail.parties.importer.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.importer.name")}>
                                <CustomRule message={getErrorMessageWithSequence("importerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.importer.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.importer.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("importerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Seller"}>
                            <Item dataField="itemDetail.parties.seller.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.seller.name")}>
                                <CustomRule message={getErrorMessageWithSequence("sellerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.seller.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.seller.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("sellerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Buyer"}>
                            <Item dataField="itemDetail.parties.buyer.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.buyer.name")}>
                                <CustomRule message={getErrorMessageWithSequence("buyerName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.buyer.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.buyer.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("buyerEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={2} colSpan={2} caption={"Reprensentative"}>
                            <Item dataField="itemDetail.parties.representative.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.representative.name")}>
                                <CustomRule message={getErrorMessageWithSequence("representativeName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.representative.status" label={{text: "Status"}} visible={isFieldVisible("goods.parties.representative.status")}>
                                <CustomRule message={getErrorMessageWithSequence("representativeStatus", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeStatusRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.representative.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.representative.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("representativeEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Declarant"}>
                            <Item dataField="itemDetail.parties.declarant.name" label={{text: "Name"}} visible={isFieldVisible("goods.parties.declarant.name")}>
                                <CustomRule message={getErrorMessageWithSequence("declarantName", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarantNameRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.declarant.eori" label={{text: "EORI"}} visible={isFieldVisible("goods.parties.declarant.eori")}>
                                <CustomRule message={getErrorMessageWithSequence("declarantEori", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarantEoriRule}} />
                            </Item>
                            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="cds-group-item" colCount={1} caption={"Supply Chain"}>
                            <Item dataField="itemDetail.parties.additionalSupplyChainActorId" label={{text: "Additional Supply Chain Actor ID"}} visible={isFieldVisible("goods.parties.additionalSupplyChainActorId")}>
                                <CustomRule message={getErrorMessageWithSequence("additionalSupplyChainActorId", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorIdRule}} />
                            </Item>
                            <Item dataField="itemDetail.parties.additionalSupplyChainActorRole" label={{text: "Role in the Supply Chain"}} editorType="dxSelectBox"
                                visible={isFieldVisible("goods.parties.additionalSupplyChainActorRole")}
                                editorOptions={{
                                    items: supplyChainRoleOptions,
                                    searchEnabled: true,
                                    displayExpr: "party",
                                    valueExpr: "role"
                                }}>
                                    <CustomRule message={getErrorMessageWithSequence("additionalSupplyChainActorRole", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorRoleRule}} />
                                </Item>
                                <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                        </GroupItem>
                </GroupItem>
            </Form>
            </div>
}