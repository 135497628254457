import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'
import { countryOptions } from '../../app/common/options/countryOptions'
import { locationTypeOptions } from '../../app/common/options/cdsImportDeclaration/locationTypeOptions'
import { unCodeOptions } from '../../app/common/options/cdsImportDeclaration/unCodeOptions'
import { isVisible } from '../../app/common/options/declarationTemplates/declarationTemplate';
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    onOriginCountryChanged: (countryCode: string | null) => void;
    onDestinationCountryChanged: (countryCode: string | null) => void;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function PlacesCard({formData, onOriginCountryChanged, onDestinationCountryChanged, isFieldVisible}: Props){
  const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current&& ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

  function handleOriginCountryChanged(){
    onOriginCountryChanged(formData.places.countryOfDispatchCode)
  }

  function handleDestinationCountryChanged(){
    onDestinationCountryChanged(formData.places.countryOfDestinationCode)
  }

  const validationRules: {
    countryOfDestinationCodeRule: boolean,
    countryOfDispatchCodeRule: boolean,
    additionalIdentifierRule: boolean,
    identificationOfLocationRule: boolean,
    typeOfLocationRule: boolean,
    countryRule: boolean,
    qualifierOfTheIdentificationRule: boolean,
    placeOfLoadingRule: boolean,
    placeOfUnloadingRule: boolean,
    arrivalAtFirstPlaceRule: boolean,
    arrivalPortRule: boolean
} = {
  countryOfDestinationCodeRule: isFieldValid("countryOfDestinationCode", formData.fieldErrors),
  countryOfDispatchCodeRule: isFieldValid("countryOfDispatchCode", formData.fieldErrors),
  additionalIdentifierRule: isFieldValid("additionalIdentifier", formData.fieldErrors),
  identificationOfLocationRule: isFieldValid("identificationOfLocation", formData.fieldErrors),
  typeOfLocationRule: isFieldValid("typeOfLocation", formData.fieldErrors),
  countryRule: isFieldValid("country", formData.fieldErrors),
  qualifierOfTheIdentificationRule: isFieldValid("qualifierOfTheIdentification", formData.fieldErrors),
  placeOfLoadingRule: isFieldValid("placeOfLoading", formData.fieldErrors),
  placeOfUnloadingRule: isFieldValid("placeOfUnloading", formData.fieldErrors),
  arrivalAtFirstPlaceRule: isFieldValid("arrivalAtFirstPlace", formData.fieldErrors),
  arrivalPortRule: isFieldValid("arrivalPortRule", formData.fieldErrors),
};

const handleGetErrorMessage = (fieldId: string) => {
  return getErrorMessage(fieldId, formData.fieldErrors);
}

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
      <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Places-Countries-Regions'} visible={isFieldVisible("section-places")}>
        <GroupItem cssClass={'cds-group-item'} colCount={2}>
            <Item dataField="places.countryOfDestinationCode" label={{text: "Destination Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.countryOfDestinationCode")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                      onValueChanged: handleDestinationCountryChanged
                    }}>
                      <CustomRule message={handleGetErrorMessage("countryOfDestinationCode")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDestinationCodeRule}} />
                    </Item>
            <Item dataField="places.countryOfDispatchCode" label={{text: "Dispatch Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.countryOfDispatchCode")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                      onValueChanged: handleOriginCountryChanged
                    }}>
                      <CustomRule message={handleGetErrorMessage("countryOfDispatchCode")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDispatchCodeRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={'Location of goods'} visible={isFieldVisible("section-places.locationOfGoods")}>
            <Item dataField="places.locationOfGoods.additionalIdentifier" label={{text: "Additional Identifier"}} visible={isFieldVisible("places.locationOfGoods.additionalIdentifier")} >
              <CustomRule message={handleGetErrorMessage("additionalIdentifier")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalIdentifierRule}} />
            </Item>
            <Item dataField="places.locationOfGoods.identificationOfLocation" label={{text: "Location ID"}} visible={isFieldVisible("places.locationOfGoods.identificationOfLocation")}>
              <CustomRule message={handleGetErrorMessage("identificationOfLocation")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identificationOfLocationRule}} />
            </Item>
            <Item dataField="places.locationOfGoods.typeOfLocation" label={{text: "Location Type"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.locationOfGoods.typeOfLocation")}
                    editorOptions={{
                      items: locationTypeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                      <CustomRule message={handleGetErrorMessage("typeOfLocation")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.typeOfLocationRule}} />
                    </Item>
            <Item dataField="places.locationOfGoods.country" label={{text: "Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.locationOfGoods.country")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={handleGetErrorMessage("country")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryRule}} />
                    </Item>
            <Item dataField="places.locationOfGoods.qualifierOfTheIdentification" label={{text: "Qualifier of the Identification"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.locationOfGoods.qualifierOfTheIdentification")}
                    editorOptions={{
                      items: unCodeOptions,
                      displayExpr: "description",
                      valueExpr: "name"
                    }}>
                      <CustomRule message={handleGetErrorMessage("qualifierOfTheIdentification")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.qualifierOfTheIdentificationRule}} />
                    </Item>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={2}>
            <Item dataField="places.arrivalAtFirstPlace" label={{text: "Arrival at first place"}} visible={isFieldVisible("places.arrivalAtFirstPlace")} colSpan={2} editorType="dxDateBox"
                  editorOptions={{
                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                    displayFormat: "dd/MM/yyyy HH:mm",
                    type: "datetime"
                }}>
              <CustomRule message={handleGetErrorMessage("arrivalAtFirstPlace")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivalAtFirstPlaceRule}} />
            </Item>
            <Item dataField="places.placeOfLoading" label={{text: "Place of Loading"}} visible={isFieldVisible("places.placeOfLoading")}>
              <CustomRule message={handleGetErrorMessage("placeOfLoading")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfLoadingRule}} />
            </Item>
            <Item dataField="places.placeOfUnloading" label={{text: "Place of Unloading"}} visible={isFieldVisible("places.placeOfUnloading")}>
              <CustomRule message={handleGetErrorMessage("placeOfUnloading")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfUnloadingRule}} />
            </Item>
            <Item dataField="places.arrivalPort" label={{text: "Arrival Port"}} visible={isFieldVisible("places.arrivalPort")}>
              <CustomRule message={handleGetErrorMessage("arrivalPort")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivalPortRule}} />
            </Item>
            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem>
            {/*I have a blank GroupItem to keep last field line from overlapping the border*/}
        </GroupItem>
    </GroupItem>
    </Form>
}