import { DataGrid, Popup } from 'devextreme-react'
import { DecalarationData } from '../../app/models/declarationCreate'
import { Column, Editing, Position, ToolbarItem } from 'devextreme-react/data-grid'

interface Props{
    formData: DecalarationData;
    setAdding: (item: boolean) => void;
    setUpdate: (id: number) => void;
    onRowDeleted: () => void;
}

export default function Goods(props: Props){
    return <>
                <DataGrid dataSource={props.formData.goods} keyExpr='itemDetail.messageInformation.goodsItemNumber' onRowRemoved={() => props.onRowDeleted()} onInitNewRow={() => props.setAdding(true)} onEditingStart={(rows) => props.setUpdate(rows.key)} >
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} >
                    </Editing>
                    <Column dataField='itemDetail.messageInformation.goodsItemNumber' caption={'Ref #'} defaultSortIndex={0} defaultSortOrder="asc"></Column>
                    <Column dataField='itemDetail.references.additionalReference.code' caption={'Additional Ref Code'}></Column>
                    <Column dataField='itemDetail.references.additionalReference.description' caption={'Description'}></Column>
                    <Column dataField='itemDetail.goodsIdentification.commodityCode.combinedNomenclatureCode' caption={'Commodity Code'}></Column>
                    <Column dataField='itemDetail.goodsIdentification.commodityCode.description' caption={'Description'}></Column>
                    <Column dataField='itemDetail.goodsIdentification.netMass' caption={'Net Weight'}></Column>
                    <Column dataField='itemDetail.goodsIdentification.commodityCode.cusCode' caption={'CUS Code'}></Column>
                    <Column dataField='itemDetail.transportInformation.containerId' caption={'Container'}></Column>
                </DataGrid>
                <Popup             
        hideOnOutsideClick={true}
      >
        <Position
          at="center"
          my="center"
          collision="fit"
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: 'Close'          
          }}
        />
      </Popup>
           </>
}

