export const modeOfBorderTransportOptions = [
    { code: "1", description: "Maritime" },
    { code: "2", description: "Rail" },
    { code: "3", description: "Road" },
    { code: "4", description: "Air" },
    { code: "5", description: "Mail" },
    { code: "7", description: "Fixed Transport Installations" },
    { code: "8", description: "Inland Waterway" },
    { code: "9", description: "Other" },
    { code: "10", description: "Roll-on Roll-off (accompanied)" },
    { code: "11", description: "Roll-on Roll-off (unaccompanied)" }
]