import { Button, DataGrid } from "devextreme-react"
import { Column, Editing } from "devextreme-react/data-grid"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useStore } from "../../app/stores/store"
import { GeneralId } from "../../app/models/organisation"
import AddCertificates from "./AddCertificates"
import { onToolbarPreparing } from "../../app/common/utils/toolbar"
import { notifyMessage } from "../../app/common/utils/notify"
import { confirm } from "devextreme/ui/dialog"

interface Props {
  organisationId: string
}

export default observer(function CertificatesDisplay({ organisationId, }: Props) {
  const { organisationStore } = useStore()
  const [adding, setAdding] = useState(false)
  const [change, setChange] = useState(false)

  useEffect(() => {
    organisationStore.getCertificates(organisationId)
  }, [organisationStore, organisationId, change])

  const handleDefault = (cellData: any) => {
    confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
      if (cellData.data.id && result) {
        let data: GeneralId = { id: cellData.data.id }
        organisationStore
          .setDefaultCertificate(data)
          .then(() => setChange(!change))
      }
    })
  }

  const handleDelete = (cellData: any) => {
    confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
      if (cellData.data.id && result) {
        organisationStore
          .deleteCertificate(cellData.data.id)
          .then(() => setChange(!change))
          .catch((error) => {
            notifyMessage("success", `${error}`)
          })
      }
    })
  }

  const renderButton = (cellData: any) => {
    return (
      <>
        <Button
          icon="arrowleft"
          text="Default"
          width={120}
          type="normal"
          stylingMode="contained"
          onClick={() => handleDefault(cellData)}
        />
        <Button
          icon="trash"
          width={40}
          type="normal"
          stylingMode="contained"
          onClick={() => handleDelete(cellData)}
        />
      </>
    )
  }

  return (
    <>
      {organisationStore.certificates && !adding ? (
        <DataGrid
          dataSource={organisationStore.certificates}
          onToolbarPreparing={(e) => onToolbarPreparing(e, adding, setAdding)}
        >
          <Column type="adaptive" width={10} />
          <Column dataField="name" />
          <Column dataField="type" />
          <Column dataField="expiry" dataType="date" />
          <Column dataField="isDefault" />
          <Editing allowAdding={true} />
          <Column cellRender={(e) => renderButton(e)} />
        </DataGrid>
      ) : organisationStore.certificates && adding ? (
        <AddCertificates
          organisationId={organisationId}
          closeFunction={() => setAdding(false)}
        />
      ) : (
        <></>
      )}
    </>
  )
})
