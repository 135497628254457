import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { CreateUser, emailValidation, Token, UpdateProfile, User } from "../models/user"
import { store } from "./store"

export default class UserStore {
    user: User | null = null;
    isValidate: boolean | null = null;
    emailSent: boolean = false;
    alreadyValidated: boolean = false;
    userCreated: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user
    }

    logout = () => {
        store.commonStore.setToken(null)
        this.user = null
    };

    setUser = (user: User) => {
        this.user = user
    }

    getUser = async () => {
        try {
            const response = await agent.Users.current()
            runInAction(() => {
                this.user = response.data
                if (process.env.NODE_ENV === "development")
                    console.log("Response: ", JSON.stringify(response))
            })
        } catch (error) {
            console.log(error)
        }
    };

    createUser = async (user: CreateUser) => {
        try {
            const response = await agent.Users.create(user)
            if (process.env.NODE_ENV === "development")
                console.log("Response: ", JSON.stringify(response))

            if (response.isSuccess) {
                runInAction(() => {
                    this.userCreated = true
                })
            }
        } catch (error) {
            runInAction(() => {
                this.userCreated = false
            })
            console.log(error)
        }
    };

    resendEmailValidation = async (data: emailValidation) => {
        try {
            const response = await agent.Users.resendEmailValidation(data)
            runInAction(() => {
                this.emailSent = response.isSuccess
            })
        } catch (error) {
            console.log(error)
        }
    };

    validateEmail = async (token: Token) => {
        try {
            const response = await agent.Users.emailValidation(token)
            runInAction(() => {
                if (response.isSuccess) {
                    this.isValidate = response.isSuccess
                } else {
                }
            })
        } catch (error) {
            runInAction(() => {
                this.isValidate = false
            })
            if (error?.toString() === "Token already used") {
                this.alreadyValidated = true
            }
        }
    };


    updateProfile = async (user: UpdateProfile) => {
        try {
            const response = await agent.Users.updateProfile(user)
            if (process.env.NODE_ENV === "development") {
                console.log(response.isSuccess)
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    }


}
