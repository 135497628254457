import { Button } from "devextreme-react"
import Form, { ButtonItem, EmailRule, GroupItem, Item, PatternRule } from "devextreme-react/form"
import { countryOptions } from "../../app/common/options/countryOptions"
import { currencyOptions } from "../../app/common/options/currencyOptions"
import { timeZoneOptions } from "../../app/common/options/timeZoneOptions"
import { notifyMessage } from "../../app/common/utils/notify"
import { useStore } from "../../app/stores/store"
import FormContainer from "../../app/layout/components/FormContainer"
import useRefresh from "../../app/hooks/useRefresh"

interface Props {
    closeFunction: () => void
}

export default function AddOrganisation({ closeFunction }: Props) {
    const { organisationStore } = useStore()
    const { refreshToken } = useRefresh()


    const newOrg = {
        name: "",
        logo: "",
        operationalTimeZone: "(GMT+01:00) Dublin",
        taxLabel1: "",
        taxLabel2: "",
        defaultCurrencyIsoCode: "EUR",
        addressCode: "",
        address1: "",
        address2: "",
        postcode: "",
        countryIsoCode: "IE",
        contactName: "",
        phoneNumber: "+353 ",
        email: "",
        web: "",
        isDefault: "",
        bucketCounterPrefix: "BN",
        bucketCounterNumber: "01",
        clearenceCounterPrefix: "CN",
        clearenceCounterNumber: "01",
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        organisationStore.createOrganisation(newOrg)
            .then(() => {
                notifyMessage("success", "Organisation Created")
                refreshToken()
                closeFunction()
            })
            .catch(() => {
                notifyMessage("error", "Error Creating Organisation")
            })
    }

    const onInitialized = () => {
        var elements = document.getElementsByName("name")
        elements[0]?.focus()
    }

    const organisationForm =
        <form onSubmit={(e) => { handleSubmit(e) }}>
            <Form
                className="add-organisation-form"
                formData={newOrg}
                onContentReady={onInitialized}
            >
                <GroupItem colCount={4} caption="Basic Info" alignItemLabels={true} cssClass={"form-group-item-text"}>
                    <Item dataField="name" isRequired={true} >
                    </Item>
                    <Item dataField="email">
                        <EmailRule message="Email is invalid" />
                    </Item>
                    <Item dataField="contactName">
                    </Item>
                    <Item dataField="phoneNumber">
                    </Item>
                    <Item dataField="logo url" colSpan={2}>
                        <PatternRule message="Please enter the logo url"
                            pattern={/^(http:\/\/)?([\da-z.-]+).([a-z.]{2,6})([\/\w .-]*)*\/?/} //eslint-disable-line
                        />
                    </Item>
                    <Item
                        dataField="web"
                        label={{ text: "Website url" }}
                        colSpan={2}
                    >
                        <PatternRule message="Please enter a valid website url"
                            pattern={/^(http:\/\/)?([\da-z.-]+).([a-z.]{2,6})([\/\w .-]*)*\/?$/} //eslint-disable-line
                        />
                    </Item>
                </GroupItem>
                <GroupItem colCount={4} caption="Address" cssClass={"form-group-item-text"}>
                    <Item
                        dataField="address1"
                        colSpan={2}
                    >
                    </Item>
                    <Item
                        dataField="address2"
                        colSpan={2}
                    >
                    </Item>
                    <Item
                        dataField="postcode"
                    >
                    </Item>
                    <Item
                        dataField="addressCode"
                    >
                    </Item>
                    <Item
                        dataField="countryIsoCode"
                        label={{ text: "Country" }}
                        editorType="dxSelectBox"
                        editorOptions={{
                            items: countryOptions,
                            searchEnabled: true,
                            displayExpr: "name",
                            valueExpr: "isoCode",
                            required: true,
                            defaultValue: "IE",
                        }}
                        isRequired={true}
                    />
                </GroupItem>
                <GroupItem colCount={4} caption="Settings" cssClass={"form-group-item-text"}>
                    <Item
                        dataField="operationalTimeZone"
                        editorType="dxSelectBox"
                        editorOptions={{
                            items: timeZoneOptions,
                            searchEnabled: true,
                            displayExpr: "value",
                            valueExpr: "display",
                            defaultValue: "(GMT+01:00) Dublin",
                        }}
                    >
                    </Item>
                    <Item dataField="defaultCurrencyIsoCode"
                        label={{ text: "Currency" }}
                        editorType="dxSelectBox"
                        editorOptions={{
                            items: currencyOptions,
                            searchEnabled: true,
                            displayExpr: "name",
                            valueExpr: "code",
                            required: true,
                            defaultValue: "EUR",
                        }}
                        isRequired={true}
                    />
                    <Item
                        dataField="taxLabel"
                    >
                    </Item>
                    <Item
                        dataField="taxLabel2"
                    >
                    </Item>
                    <Item
                        dataField="bucketCounterPrefix"
                        label={{ text: "Bucket Counter Prefix" }}
                        isRequired={true}
                        editorOptions={{
                            defaultValue: "BN",
                        }}
                    >
                    </Item>
                    <Item
                        dataField="bucketCounterNumber"
                        label={{ text: "Bucket Counter Number" }}
                        isRequired={true}
                        editorOptions={{
                            defaultValue: "0",
                        }}
                    >
                    </Item>
                    <Item
                        dataField="clearenceCounterPrefix"
                        label={{ text: "Clearence Counter Prefix" }}
                        isRequired={true}
                        editorOptions={{
                            defaultValue: "CN",
                        }}
                    >
                    </Item>
                    <Item
                        dataField="clearenceCounterNumber"
                        label={{ text: "Clearence Counter Number" }}
                        isRequired={true}
                        editorOptions={{
                            defaultValue: "0",
                        }}
                    >
                    </Item>
                </GroupItem >
                <Item itemType={"empty"}></Item>
                <ButtonItem
                    cssClass={"add-organisation-save-btn"}
                    horizontalAlignment="left"
                    buttonOptions={{
                        text: 'Save',
                        type: 'default',
                        useSubmitBehavior: true,
                        width: 85,
                    }}
                />
                <Item cssClass={"add-organisation-cancel-btn"} >
                    <Button
                        onClick={closeFunction}
                        type="normal"
                        className="dx-widget dx-button dx-button-mode-contained dx-button-has-text  ">
                        <div className="dx-button-text">
                            Cancel
                        </div>
                    </Button>
                </Item>
            </Form >
        </form >

    return (
        <FormContainer
            children={organisationForm}
            title={"Add organisation"}
            closeFunction={closeFunction}
        />
    )
}