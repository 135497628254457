import { ApiClientUpdate } from '../../app/models/account'
import Form, { ButtonItem, CustomRule, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form';
import { CreatingIpRows, IsValidIp, deepClone } from '../../app/common/utils/accountFunctions';
import { useStore } from '../../app/stores/store';
import { notifyMessage } from '../../app/common/utils/notify';
import { Fragment, useRef, useState } from 'react';
import FormContainer from '../../app/layout/components/FormContainer';
import { Button } from 'devextreme-react';

interface Props {
    formData: ApiClientUpdate
    closeFunction: () => void
}

const EditingApiClient = ({ formData, closeFunction }: Props) => {
    const { accountStore } = useStore()
    const cloneFormRef = useRef<ApiClientUpdate>(deepClone(formData));
    const [ipOptions, setIpOptions] = useState<any[]>([...cloneFormRef.current.validIps]);

    const handleSubmit = (e: any) => {
        e.preventDefault()
        formData.validIps = cloneFormRef.current.validIps
        accountStore.updateApiclient(formData).then(() => notifyMessage("success", "Api client updated")).finally(() => closeFunction())
    }

    const editForm =
        <form
            onSubmit={handleSubmit}>
            <Form
                className="add-template-form"
                formData={cloneFormRef.current}
            >
                <GroupItem
                    name="ip">
                    {ipOptions.map((ip, index) =>
                        <Item
                            key={`validIps${index}`}
                            dataField={`validIps[${index}]`}
                            editorOptions={ip}>
                            <Label text={`IP ${index + 1}`} />
                            <RequiredRule message="the valid IPs is required" />
                            <CustomRule message={'That is not a valid IP'} validationCallback={(e: any) => IsValidIp(e)} />
                        </Item>)}
                </GroupItem>
                <ButtonItem
                    horizontalAlignment={"left"}
                    buttonOptions={{
                        icon: "plus",
                        type: 'success',
                        onClick: () => {
                            cloneFormRef.current.validIps.push('');
                            CreatingIpRows(cloneFormRef.current, ipOptions, setIpOptions);
                        },
                    }}
                />
                <ButtonItem
                    cssClass={"add-general-save-btn"}
                    horizontalAlignment={"left"}
                    buttonOptions={{
                        width: "90",
                        text: 'Save',
                        type: 'default',
                        stylingMode: "contained",
                        useSubmitBehavior: true,
                    }}
                />
                <Item cssClass={"add-general-cancel-btn"} >
                    <Button
                        onClick={
                            () => closeFunction()
                        }
                        type="normal"
                        className="dx-widget dx-button dx-button-mode-contained dx-button-has-text">
                        <div className="dx-button-text">
                            Cancel
                        </div>
                    </Button>
                </Item>
            </Form>
        </form>

    return (
       
    <Fragment>
           <FormContainer
            title={'Edit API Client'}
            closeFunction={closeFunction}
            children={editForm}
            />
    </Fragment>
    );
};

export default EditingApiClient