import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Account, ApiClient, ApiClientForm, ApiClientUpdate, UpdateAccount } from "../models/account"

export default class AccountStore {

    apiclient: ApiClient[] | null = null;
    account: Account | null = null;
    apiclientID: string | null = null;

    constructor() {
        makeAutoObservable(this)
    }


    getAccount = async () => {
        try {
            const response = await agent.Accounts.currentAccount()
            runInAction(() => {
                this.account = response.data
            })
        } catch (error) {
            console.log(error)
        }

    }

    updateAccount = async (acc: UpdateAccount) => {
        try {
            const response = await agent.Accounts.updateAccount(acc)
            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    getApiClient = async () => {
        try {
            const response = await agent.Accounts.getApiClient()
            runInAction(() => {
                this.apiclient = response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    createApiclient = async (api: ApiClientForm) => {
        try {
            const response = await agent.Accounts.createApiclient(api)
            runInAction(() => {
                this.apiclientID = response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    updateApiclient = async (api: ApiClientUpdate) => {
        try {
            const response = await agent.Accounts.updateApiClient(api)
            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

}