import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Certificate, DefaultOrganisation, GeneralId, NewCertificate, NewCredential, Nominal, Organisation, TaxRate, UpdateCertificate } from "../models/organisation"
import { store } from "./store"


export default class OrganisationStore {
    loading = false;
    organisations: Organisation[] | null = null;
    certificates: Certificate[] | null = null;
    credentials: Credential[] | null = null;
    nominals: Nominal[] | null = null;
    taxRates: TaxRate[] | null = null;
    defaultOrganisation: DefaultOrganisation | null = null;
    hmrc: string = "";

    constructor() {
        makeAutoObservable(this)
    }

    getOrganisationMe = async () => {
        this.loading = true
        try {
            const response = await agent.Organisations.getOrganisationMe()
            runInAction(() => {
                if (response.data && response.data.organisations) {
                    this.organisations = response.data.organisations
                }
                this.loading = false
            })
        } catch (error) {
            console.log(error)
            this.loading = false
            throw error
        }
    };

    createOrganisation = async (newOrganisation: Omit<Organisation, "subscriptionId" | "id">) => {
        this.loading = true
        const subscriptionId = store.userStore.user?.accountSubscriptionId
        try {
            if (subscriptionId) {
                const newOrg: Omit<Organisation, "id"> = { ...newOrganisation, subscriptionId }
                const response = await agent.Organisations.create(newOrg)
                if (response) {
                    runInAction(() => {
                        this.loading = false
                    })
                }
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    };

    updateOrganisation = async (organisation: Omit<Organisation, "subscriptionId">) => {
        this.loading = true
        const subscriptionId = store.userStore.user?.accountSubscriptionId
        try {
            if (subscriptionId) {
                const updatedOrg: Organisation = { ...organisation, subscriptionId }
                const response = await agent.Organisations.update(updatedOrg)
                if (response) {
                    runInAction(() => {
                        this.loading = false
                    })
                }
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    };

    getCertificates = async (organisationId: string) => {
        this.loading = true
        try {
            const response = await agent.Organisations.getCertificates(organisationId)
            runInAction(() => {
                if (response.data && response.data.certificates) {
                    this.certificates = response.data.certificates
                    this.loading = false
                }
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
        }
    }

    createCertificate = async (newCertificate: NewCertificate) => {
        this.loading = true
        try {
            const response = await agent.Organisations.createCertificate(newCertificate)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    updateCertificate = async (updateCert: UpdateCertificate) => {
        this.loading = true
        try {
            const response = await agent.Organisations.updateCertificate(updateCert)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    getCertificatesId = async (certId: string) => {
        try {
            const response = await agent.Organisations.getCertificateId(certId)
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    deleteCertificate = async (certificateId: string) => {
        try {
            const response = await agent.Organisations.deleteCertificate(certificateId)
            runInAction(() => {
                if (response.data) {
                    console.log(response.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    setDefaultCertificate = async (certificateId: GeneralId) => {
        try {
            const response = await agent.Organisations.defaultCertificate(certificateId)
            runInAction(() => {
                if (response.data) {
                    console.log(response.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    getCredentials = async (organisationId: string) => {
        this.loading = true
        try {
            const response = await agent.Organisations.getCredentials(organisationId)
            runInAction(() => {
                if (response.data && response.data.credentials) {
                    this.credentials = response.data.credentials
                    this.loading = false
                }
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
        }
    }

    createCredential = async (newCredential: NewCredential) => {
        this.loading = true
        try {
            const response = await agent.Organisations.createCredential(newCredential)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    updateCredential = async (updateCredential: Credential) => {
        this.loading = true
        try {
            const response = await agent.Organisations.updateCredential(updateCredential)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    setDefaultCredential = async (credentialId: GeneralId) => {
        try {
            const response = await agent.Organisations.defaultCredential(credentialId)
            runInAction(() => {
                if (response.data) {
                    console.log(response.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    deleteCredential = async (credentialId: string) => {
        try {
            const response = await agent.Organisations.deleteCredential(credentialId)
            runInAction(() => {
                if (response.data) {
                    console.log(response.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    getNominals = async (organisationId: string) => {
        this.loading = true
        try {
            const response = await agent.Organisations.getNominals(organisationId)
            runInAction(() => {
                if (response.data) {
                    this.nominals = response.data.nominals
                    this.loading = false
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    createNominal = async (newNominal: Omit<Nominal, "id">) => {
        this.loading = true
        try {
            const response = await agent.Organisations.createNominal(newNominal)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    editNominal = async (newNominal: Nominal) => {
        this.loading = true
        try {
            const response = await agent.Organisations.updateNominal(newNominal)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    getTaxRates = async (organisationId: string) => {
        this.loading = true
        try {
            const response = await agent.Organisations.getTaxRates(organisationId)
            runInAction(() => {
                if (response.data) {
                    this.taxRates = response.data.taxRates
                    this.loading = false
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    createTaxRate = async (newTaxRate: Omit<TaxRate, "id">) => {
        this.loading = true
        try {
            const response = await agent.Organisations.createTaxRate(newTaxRate)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    editTaxRate = async (newTaxRate: TaxRate) => {
        this.loading = true
        try {
            const response = await agent.Organisations.updateTaxRate(newTaxRate)
            if (response.data) {
                runInAction(() => {
                    this.loading = false
                })
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
            })
            throw error
        }
    }

    getHmrc = async () => {
        try {
            const response = await agent.Management.getHmrcUrl()
            if (response.data) {
                runInAction(() => {
                    this.hmrc = response.data
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}
