import { makeAutoObservable, runInAction } from "mobx";
import { Address, BaseAddress, CreateAddress, UpdateAddress } from "../models/address";
import agent from "../api/agent";

export default class AddressStore {
    address: Address | null = null;
    addresses: Address[] | null = null;
    loading: Boolean = false;
    isSuccess: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    getAll = async (organisationId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Addresses.getAll(organisationId)
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                if(response.isSuccess && response.data)
                    this.addresses = response.data.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    };

    get = async (addressId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Addresses.get(addressId)
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                if(response.isSuccess && response.data)
                    this.address = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    };

    create = async (address: CreateAddress) => {
        this.isSuccess = false;
        this.loading = true;
        try {
            const response = await agent.Addresses.create(address);
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                console.log(response.data);
            });		
        } catch (error) {
            runInAction(() => {
                this.isSuccess = false;
            });
            console.error("Failed to create address", error);		
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    update = async (updateAddress: UpdateAddress) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Addresses.update(updateAddress)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    delete = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Addresses.delete(id)
            runInAction(() => {this.isSuccess = response.isSuccess});
        } catch (error) {
            runInAction(() => {this.isSuccess = false});
            console.error(error)
        }
        finally {
            runInAction(() => {this.loading = false});
        }
    }
}