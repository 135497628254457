import { LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store'
import Form, { GroupItem, Label, RequiredRule, Item, ButtonItem, } from 'devextreme-react/form'
import { useEffect } from 'react'
import { languageOptions } from '../../app/common/options/languageOptions'
import { UpdateProfile } from '../../app/models/user'
import { datePatternOptions } from '../../app/common/options/datePatternOptions'
import { timePatternOptions } from '../../app/common/options/timePatternOptions'
import { timeZoneOptions } from '../../app/common/options/timeZoneOptions'
import { notifyMessage } from '../../app/common/utils/notify'


const ProfilePage = () => {

  ///////////////  Variables   ////////////////
  const { userStore, accountStore } = useStore()
  let update: any = null
  let userUpdated: UpdateProfile | null = null
  const updateProfileProps = ["nickName", "firstName", "lastName", "picture", "phoneNumber", "locale"]
  const onInitialized = () => {
    var elements = document.getElementsByName("firstName")
    elements[0]?.focus()
  }


  /// React Hooks ////////

  useEffect(() => {
    accountStore.getAccount()
  }, [accountStore])

  ////// Functions /////////

  function handleSubmit(e: any) {
    e.preventDefault()
    updateUser()
  }

  function updateUser() {
    const hasUpdates = updateProfileProps.some(prop => userStore.user?.[prop] !== update?.[prop])

    if (hasUpdates) {
      userUpdated = {
        "nickName": update.nickName,
        "firstName": update.firstName,
        "lastName": update.lastName,
        "picture": update.picture,
        "phoneNumber": update.phoneNumber,
        "locale": update.locale
      }

      userStore.updateProfile(userUpdated)
        .then(() => notifyMessage("success", "Profile Updated"))
        .catch(() => notifyMessage("error", "Profile could not be Updated"))
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log("it is the same object")
      }
    }
  }

  if (!userStore.user) {
    return <LoadPanel visible={true} />
  }

  const edditedRole = userStore.user.role.split(/(?=[A-Z])/).join(' ')

  update = {
    "role": edditedRole,
    "email": userStore.user.email,
    "nickName": userStore.user?.nickName,
    "firstName": userStore.user?.firstName,
    "lastName": userStore.user?.lastName,
    "picture": userStore.user?.picture,
    "phoneNumber": userStore.user?.phoneNumber,
    "locale": userStore.user?.locale,
  }


  /////////////////////    Return section of the Component /////////////////////

  return (

    <div className='html-blank-profile'>
      <div className='profile-container form-with-shadows-profile'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Form
            formData={update}
            labelMode='floating'
            onContentReady={onInitialized}
            validationGroup="customerData"
          >
            <GroupItem cssClass="first-group" colCount={1}>
              <Item><img className='form-avatar' src={"https://s.gravatar.com/avatar/4e5d5d6e8ca47af7786eb65bfe41853d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fse.png"} alt="Profile" /></Item>
              <GroupItem colCount={2}>
                <GroupItem>
                  <Item
                    dataField={edditedRole}
                    editorOptions={{ readOnly: true, }}
                  />

                  <Item
                    dataField="email"
                    editorOptions={{ readOnly: true, }}
                  />

                  <Item
                    dataField="firstName"
                  >
                    <RequiredRule />
                  </Item>

                  <Item
                    dataField="lastName"
                  >
                    <RequiredRule />
                  </Item>

                  <Item
                    dataField="nickName"
                  >
                    <RequiredRule />
                  </Item>
                </GroupItem>

                <GroupItem>
                  <Item
                    dataField="phoneNumber"
                  >
                    <RequiredRule />
                  </Item>
                  <Item
                    dataField="locale.language"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: languageOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "code",
                    }}
                  />
                  <Label text="Language" />
                  <Item
                    dataField="locale.datePattern"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: datePatternOptions,
                      searchEnabled: true,
                      displayExpr: "pattern",
                      valueExpr: "pattern",
                    }}
                  />
                  <Item
                    dataField="locale.timePattern"
                    editorType="dxSelectBox"

                    editorOptions={{
                      items: timePatternOptions,
                      searchEnabled: true,
                      displayExpr: "pattern",
                      valueExpr: "pattern",
                    }}
                  />
                  <Item
                    dataField="locale.timeZone"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: timeZoneOptions,
                      searchEnabled: true,
                      displayExpr: "display",
                      valueExpr: "value",
                      placeholder: "Select a time zone..."
                    }}
                  />
                </GroupItem>
              </GroupItem>
            </GroupItem>
            <ButtonItem horizontalAlignment={"left"}
              buttonOptions={{
                text: 'Save',
                type: 'default',
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div >
    </div >

  )
}

export default observer(ProfilePage)