import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { countryOptions } from '../../../app/common/options/countryOptions'
import { countryRegionSubTypesOptions } from '../../../app/common/options/cdsImportDeclaration/countryRegionSubTypesOptions'
import { useEffect, useRef, useState } from 'react';
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions';
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods;
    decType: string;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsPlaces({formData, decType, fieldErrors, isFieldVisible} : Props){
  const [isFormReady, setIsFormReady] = useState(false)
  const formRef = useRef<Form>(null)

  const currentValidateForm = () => {
    if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
      formRef.current.instance.validate()
    }
  }

  useEffect(() => {
    if(isFormReady)
      currentValidateForm()
  });

  const validationRules: {
    countryDestinationRule: boolean
    countryDispatchRule: boolean
    countryOfOriginRule: boolean
    countryOfPreferentialOriginRule: boolean
    countryOfOriginTypeRule: boolean
    countryOfPreferentialOriginTypeRule: boolean
  } = {
    countryDestinationRule: isFieldValidWithSequence("countryDestination", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    countryDispatchRule: isFieldValidWithSequence("countryDispatch", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    countryOfOriginRule: isFieldValidWithSequence("countryOfOrigin", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    countryOfPreferentialOriginRule: isFieldValidWithSequence("countryOfPreferentialOrigin", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    countryOfOriginTypeRule: isFieldValidWithSequence("countryOfOriginType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
    countryOfPreferentialOriginTypeRule: isFieldValidWithSequence("countryOfPreferentialOriginType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
  };

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
      <GroupItem cssClass="cds-group-item" colCount={1} caption={'Places'} visible={isFieldVisible("goods.places")}>
            <GroupItem cssClass="cds-group-item" colSpan={2} colCount={2}>
                <Item dataField="itemDetail.places.countryDestination" label={{text: "Destination Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryDestination")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryDestination", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryDestinationRule}} />
                    </Item>
                <Item dataField="itemDetail.places.countryDispatch" label={{text: "Dispatch Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryDispatch")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryDispatch", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryDispatchRule}} />
                      </Item>
                      <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
            </GroupItem>
            <GroupItem cssClass="cds-group-item" colCount={2}>
                <Item dataField="itemDetail.places.countryOfOrigin" label={{text: "Country of Origin"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryOfOrigin")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryOfOrigin", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfOriginRule}} />
                    </Item>
                <Item dataField="itemDetail.places.countryOfPreferentialOrigin" label={{text: "Country of Preferential Origin"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryOfPreferentialOrigin")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryOfPreferentialOrigin", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfPreferentialOriginRule}} />
                      </Item>
                <Item dataField="itemDetail.places.countryOfOriginType" label={{text: "Country of Origin Type"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryOfOriginType")}
                    editorOptions={{
                      items: countryRegionSubTypesOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryOfOriginType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfOriginTypeRule}} />
                    </Item>
                <Item dataField="itemDetail.places.countryOfPreferentialOriginType" label={{text: "Country of preferential origin type"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.places.countryOfPreferentialOriginType")}
                    editorOptions={{
                      items: countryRegionSubTypesOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                      <CustomRule message={getErrorMessageWithSequence("countryOfPreferentialOriginType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfPreferentialOriginTypeRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
            </GroupItem>
            <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
        </GroupItem>
        </Form>
}