import { useState, useCallback, useEffect } from 'react'
import { useIsMounted } from './useIsMounted'

export const useScreenSize = () => {
    const isMounted = useIsMounted()
    const [screenSize, setScreenSize] = useState(getScreenSize())

    const onSizeChanged = useCallback(() => {
        if (isMounted.current) setScreenSize(getScreenSize())
    }, [isMounted])

    useEffect(() => {
        if (isMounted.current) subscribe(onSizeChanged)

        return () => {
            unsubscribe(onSizeChanged)
        }
    }, [isMounted, onSizeChanged])

    return screenSize
}


export const useScreenSizeClass = () => {
    const screenSize = useScreenSize()

    if (screenSize.isLarge) {
        return 'screen-large'
    }

    if (screenSize.isMedium) {
        return 'screen-medium'
    }

    if (screenSize.isSmall) {
        return 'screen-small'
    }

    return 'screen-x-small'
}

let handlers: any = []
const xSmallMedia = window.matchMedia('(max-width: 599.99px)')
const smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)')
const mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)')
const largeMedia = window.matchMedia('(min-width: 1280px)');

[xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach(media => {
    media.addListener((e) => {
        e.matches && handlers.forEach((handler: any) => handler())
    })
})

const subscribe = (handler: any) => handlers.push(handler)

const unsubscribe = (handler: any) => {
    handlers = handlers.filter((item: any) => item !== handler)
}

function getScreenSize() {
    return {
        isXSmall: xSmallMedia.matches,
        isSmall: smallMedia.matches,
        isMedium: mediumMedia.matches,
        isLarge: largeMedia.matches
    }
}

export function useMenuPatch() {
    const { isSmall, isMedium } = useScreenSize()
    const [enabled, setEnabled] = useState(isSmall || isMedium)
    const onMenuReady = useCallback(() => {
        if (!enabled) {
            return
        }

        setTimeout(() => setEnabled(false))
    }, [enabled])

    return [enabled ? 'pre-init-blink-fix' : '', onMenuReady] as [string, () => void]
}
