import { useEffect, useRef, useState } from "react"
import { specificCircumstanceIndicatorOptions } from "../../../app/common/options/safetyAndSecurity/specificCircumstanceIndicatorOptions"
import { SafetyAndSecurityMessageInformation } from "../../../app/models/declarationSafetyAndSecurity"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import { FieldError } from "../../../app/models/declarationCreate"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: SafetyAndSecurityMessageInformation | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityMessageInformationRB({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        messageSenderRule: boolean
        messageRecipientRule: boolean
        preparationDateRule: boolean
        preparationTimeRule: boolean
        priorityRule: boolean
        messageIdentificationRule: boolean
        specificCircumstanceIndicatorRule: boolean
    } = {
        messageSenderRule: isFieldValid("messageSender", fieldErrors),
        messageRecipientRule: isFieldValid("messageRecipient", fieldErrors),
        preparationDateRule: isFieldValid("preparationDate", fieldErrors),
        preparationTimeRule: isFieldValid("preparationTime", fieldErrors),
        priorityRule: isFieldValid("priority", fieldErrors),
        messageIdentificationRule: isFieldValid("messageIdentification", fieldErrors),
        specificCircumstanceIndicatorRule: isFieldValid("specificCircumstanceIndicator", fieldErrors)
    };

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Message Information'} >
            <Item dataField="messageSender" label={{text: "Message Sender"}} visible={isFieldVisible("messageSender")}>
                <CustomRule message={getErrorMessage("messageSender", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.messageSenderRule}} />
            </Item>
            <Item dataField="messageRecipient" label={{text: "Message Recipient"}} visible={isFieldVisible("messageRecipient")}>
                <CustomRule message={getErrorMessage("messageRecipient", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.messageRecipientRule}} />
            </Item>
            <Item dataField="preparationDate" label={{text: "Preparation Date"}} editorType="dxDateBox" editorOptions={{ readOnly: true}} visible={isFieldVisible("preparationDate")} >
                <CustomRule message={getErrorMessage("preparationDate", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.preparationDateRule}} />
            </Item>
            <Item dataField="preparationTime" label={{text: "Preparation Time"}} editorOptions={{ readOnly: true}} visible={isFieldVisible("preparationTime")}>
                <CustomRule message={getErrorMessage("preparationTime", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.preparationTimeRule}} />
            </Item>
            <Item dataField="priority" label={{text: "Priority"}} editorType={'dxNumberBox'} visible={isFieldVisible("priority")}>
                <CustomRule message={getErrorMessage("priority", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.priorityRule}} />
            </Item>
            <Item dataField="messageIdentification" label={{text: "Message Identification"}} visible={isFieldVisible("messageIdentification")}>
                <CustomRule message={getErrorMessage("messageIdentification", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.messageIdentificationRule}} />
            </Item>
            <Item dataField="specificCircumstanceIndicator" label={{text: "Specific Circumstance Indicator"}} editorType="dxSelectBox"
                    visible={isFieldVisible("specificCircumstanceIndicator")}
                    editorOptions={{
                      items: specificCircumstanceIndicatorOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                        <CustomRule message={getErrorMessage("specificCircumstanceIndicator", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.specificCircumstanceIndicatorRule}} />
                    </Item>
        </GroupItem>
    </Form>
}