import { SpeedDialAction, TabPanel } from "devextreme-react"
import { Item } from "devextreme-react/form"
import { observer } from "mobx-react-lite"
import { Organisation } from "../../app/models/organisation"
import { useEffect, useState } from "react"
import EditOrganisation from "./EditOrganisation"
import { router } from "../../app/router/Routes"
import { useSearchParams } from "react-router-dom"
import FormContainer from "../../app/layout/components/FormContainer"
import CertificatesDisplay from "../certificate/CertificatesDisplay"
import CredentialsDisplay from "../credential/CredentialsDisplay"

interface Props {
  organisation: Organisation
}

export default observer(function DisplayOrganisation({ organisation }: Props) {
  const [editMode, setEditMode] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [searchParams] = useSearchParams()
  const organisationId: string | null = searchParams.get("o")
  const selectedTab: string | null = searchParams.get("t")
  const hmrcCode: string | null = searchParams.get("c")

  let org = { ...organisation }

  const editFunction = () => {
    setEditMode(!editMode)
  }

  useEffect(() => {
    if (selectedTab) {
      const tabIndex = parseInt(selectedTab)
      setSelectedTabIndex(tabIndex)
    }
  }, [selectedTab])

  // childrens to be show in editmode
  const handleSelection = (index: number) => {
    const url = organisationId
      ? `/organisation?o=${organisationId}&t=${index}`
      : `/organisation?t=${index}`
    router.navigate(url)
  }

  const tabs = (
    <>
      <TabPanel
        onSelectedIndexChange={(e) => handleSelection(e)}
        selectedIndex={selectedTabIndex}
      >
        <Item title="Information">
          <EditOrganisation
            org={org}
            editMode={editMode}
            editFunction={editFunction}
          />
        </Item>
        <Item title="Certificates">
          <CertificatesDisplay organisationId={organisation.id} />
        </Item>
        <Item title="Credentials">
          <CredentialsDisplay
            organisationId={organisation.id}
            hmrcCode={hmrcCode ? hmrcCode : undefined}
          />
        </Item>
      </TabPanel>
    </>
  )

  return editMode ? (
    <div className="html-blank-organisation">
      <div className="organisation-container">
        <div className="add-organisation generic-box-shadows">
          <FormContainer
            children={
              <EditOrganisation
                key={org.id}
                org={org}
                editMode={editMode}
                editFunction={editFunction}
              />
            }
            title={org.name}
            closeFunction={editFunction}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      {tabs}
      <SpeedDialAction //floating button
        icon="edit"
        label="Edit"
        onClick={editFunction}
        visible={selectedTabIndex === 0}
      />
    </>
  )
})
