import { observer } from 'mobx-react-lite'
import { PortClearanceDataForm } from '../../app/models/clearances'
import Form, {
    Item, Label
} from "devextreme-react/form"
import { useState } from 'react'
import { ValueChangedEvent } from 'devextreme/ui/check_box'

interface Props {
    dataForm: PortClearanceDataForm | undefined
}

export default observer(function PortClearanceResourceForm({ dataForm }: Props) {
    const [vehicleUnaccompanied, setVehicleUnaccompanied] = useState<boolean>(dataForm?.isVehicleUnaccompanied ?? false)

    function handleVehicleUnaccompaniedChange(e: ValueChangedEvent){
        setVehicleUnaccompanied(e.value)
    }

    return (
        <Form formData={dataForm}>
            <Item dataField="vehicleRegistration" label={{ text: 'Vehicle Registration' }} isRequired={!vehicleUnaccompanied} />
            <Item dataField="trailerRegistration" label={{ text: 'Trailer Registration' }} isRequired={vehicleUnaccompanied} />
            <Item dataField="containerRegistration" label={{ text: 'Container Registration' }} />
            <Item dataField="contactName" label={{ text: 'Contact Name' }} />
            <Item
                dataField="emptyVehicle"
                editorType="dxCheckBox"
                editorOptions={{
                    text: 'Empty Vehicle',
                    value: dataForm?.emptyVehicle
                }}
            >
                <Label visible={false} />
            </Item>
            <Item
                dataField="isOwnVehicle"
                editorType="dxCheckBox"
                editorOptions={{
                    text: 'Is Own Vehicle',
                    value: dataForm?.isOwnVehicle
                }}
            >
                <Label visible={false} />
            </Item>
            <Item
                dataField="isVehicleUnaccompanied"
                editorType="dxCheckBox"
                editorOptions={{
                    text: 'Vehicle Unaccompanied',
                    value: dataForm?.isVehicleUnaccompanied,
                    onValueChanged: handleVehicleUnaccompaniedChange
                }}
            >
                <Label visible={false} />
            </Item>

        </Form>
    )
})