export interface User {
    [x: string]: any
    id: string
    email: string
    emailVerified: boolean
    nickName: string
    firstName?: string
    lastName?: string
    fullName: string
    picture?: string
    phoneNumber?: string | null
    locale: UserLocale
    role: string
    status: string
    loggedAccountId?: string
    accountSubscriptionId: string;
    accounts?: UserAccount[]
    organisations?: UserOrganisation[]
    teams?: UserTeam[]
}

export interface UserLocale {
    language: string
    datePattern: string
    timePattern: string
    timeZone: string
}

export enum UserRole {
    MasterUser = "MasterUser",
    AccountOwner = "AccountOwner",
    ProductAdmin = "ProductAdmin",
    OrganisationAdmin = "OrganisationAdmin",
    User = "User",
}

export enum UserStatus {
    Inactive = "Inactive",
    Invited = "Invited",
    Unverified = "Unverified",
    Verified = "Verified",
    Active = "Active",
}

export interface UserAccount {
    id: string
    name: string
}

export interface UserOrganisation {
    id: string
    name: string
    isDefault: boolean
}

export interface UserTeam {
    id: string
    name: string
}

export interface CreateUser {
    email: string
    password: string
    subscriptionPlan: string
    redirectUrl: string
}

export interface emailValidation {
    email: string
    redirectUrl: string
}

export interface Token {
    token: string
}

export interface UpdateProfile {
    nickName?: string | null
    firstName?: string | null
    lastName?: string | null
    picture?: string | null
    phoneNumber?: string | null
    locale?: UserLocale | null
}
