export const expressTemplateFields = [
    // section level, these errors do not map to a specific field so will need a Label to display the error at the top of the section
    { name: "section-messageinformation", display: "Message Information section" },
    { name: "section-additionalinformation", display: "Additional Information section" },
	{ name: "section-references", display: "References section"},
    { name: "section-authorisationholder", display: "Authorisation Holder section" },
    { name: "section-places.locationOfGoods", display: "Places - Location of Goods section" },
	{ name: "section-deferredpayment", display: "Deferred Payment section" },
	{ name: "section-parties", display: "Parties section" }, 
	{ name: "section-valuationinformation", display: "Valuation Information section" },
	{ name: "section-places", display: "Places section" },
	{ name: "section-customsoffices", display: "Customs Offices section" },
	{ name: "section-goodsidentification", display: "Goods Identification section" },
	{ name: "section-transportinformation", display: "Transport Information section" },
	{ name: "section-otherdataelements", display: "Other Data Elements section" },
	// goods sections 
	{ name: "section-goods", display: "Goods section" },
	{ name: "section-goods.additionalinformation", display: "Goods Additional Information section" },
	{ name: "section-goods.valuationInformationTaxes", display: "Goods Valuation Information section" },
	{ name: "section-goods.goodsIdentification", display: "Goods - Goods Information section" },
	{ name: "section-goods.goodsIdentification.packages", display: "Goods - Goods Information - Packages" },
	{ name: "section-goods.messageInformation", display: "Goods - Message Information section" },
	{ name: "section-goods.otherDataElements", display: "Goods - Other Data Elements section" },

	// field level 
    { name: "declarationType", display: "Message Information - Delcaration Type" },
    { name: "additionalDeclarationType", display: "Message Information - Additional Declaration Type" },
	{ name: "totalNumberPackages", display: "Message Information - Total Number Packages" },
	{ name: "references.category", display: "References - Category" },
	{ name: "references.type", display: "References - Type" },
	{ name: "references.reference", display: "References - Reference" },
	{ name: "references.itemId", display: "References - Item ID" },
	{ name: "references.lpco", display: "References - LPCO Exemption Code" },
	{ name: "totalPackagesRef", display: "References - Total Packages" },
	{ name: "additionalInformation.code", display: "References - Additional Information - Code" },
	{ name: "additionalInformation.description", display: "References - Additional Information - Description" },
	{ name: "deferredPayment1.identifier", display: "References - Deferred Payment 1 - ID" },
	{ name: "deferredPayment1.category", display: "References - Deferred Payment 1 - Category" },
	{ name: "deferredPayment1.type", display: "References - Deferred Payment 1 - Type" },
	{ name: "deferredPayment2.identifier", display: "References - Deferred Payment 2 - ID" },
	{ name: "deferredPayment2.category", display: "References - Deferred Payment 2 - Category" },
	{ name: "deferredPayment2.type", display: "References - Deferred Payment 2 - Type" },
	{ name: "identificationOfWarehouse", display: "References - ID of Warehouse" },
	{ name: "authorisationHolder.id", display: "References - Authorisation Holder - ID" },
	{ name: "authorisationHolder.categoryCode", display: "References - Authorisation Holder - Category Code" },
	{ name: "referenceNumberUCR", display: "References - UCR" },
	{ name: "exporterName", display: "Parties - Exporter Name" },
	{ name: "exporterEori", display: "Parties - Exporter EORI" },
	{ name: "exporterStreet", display: "Parties - Exporter Street" },
	{ name: "exporterCity", display: "Parties - Exporter City" },
	{ name: "exporterCountry", display: "Parties - Exporter Country" },
	{ name: "exporterPostcode", display: "Parties - Exporter Postcode" },
	{ name: "exporterPhone", display: "Parties - Exporter Phone" },
	{ name: "exporterStatus", display: "Parties - Exporter Status" },
	{ name: "importerName", display: "Parties - Importer Name" },
	{ name: "importerEori", display: "Parties - Importer EORI" },
	{ name: "importerStreet", display: "Parties - Importer Street" },
	{ name: "importerCity", display: "Parties - Importer City" },
	{ name: "importerCountry", display: "Parties - Importer Country" },
	{ name: "importerPostcode", display: "Parties - Importer Postcode" },
	{ name: "importerPhone", display: "Parties - Importer Phone" },
	{ name: "importerStatus", display: "Parties - Importer Status" },
	{ name: "representativeName", display: "Parties - Representative Name" },
	{ name: "representativeEori", display: "Parties - Representative EORI" },
	{ name: "representativeStreet", display: "Parties - Representative Street" },
	{ name: "representativeCity", display: "Parties - Representative City" },
	{ name: "representativeCountry", display: "Parties - Representative Country" },
	{ name: "representativePostcode", display: "Parties - Representative Postcode" },
	{ name: "representativePhone", display: "Parties - Representative Phone" },
	{ name: "representativeStatus", display: "Parties - Representative Status" },
	{ name: "buyerName", display: "Parties - Buyer Name" },
	{ name: "buyerEori", display: "Parties - Buyer EORI" },
	{ name: "declarantName", display: "Parties - Declarant Name" },
	{ name: "declarantEori", display: "Parties - Declarant EORI" },
	{ name: "declarantStreet", display: "Parties - Declarant Street" },
	{ name: "declarantCity", display: "Parties - Declarant City" },
	{ name: "declarantCountry", display: "Parties - Declarant Country" },
	{ name: "declarantPostcode", display: "Parties - Declarant Postcode" },
	{ name: "declarantPhone", display: "Parties - Declarant Phone" },
	{ name: "declarantStatus", display: "Parties - Declarant Status" },
	{ name: "additionalSupplyChainActorId", display: "Parties - Additional Supply Chain Actor ID" },
	{ name: "additionalSupplyChainActorRole", display: "Parties - Additional Supply Chain Actor Role" },
	{ name: "customsOffices.presentationOffice", display: "Customs Offices - Presentation Office" },
	{ name: "customsOffices.supervisingOffice", display: "Customs Offices - Supervising Office" },
	{ name: "incotermcode", display: "Valuation Information Taxes - Delivery Terms - INCOTERM Code" },
	{ name: "countryCodeLocationName", display: "Valuation Information Taxes - Delivery Terms - Location" },
	{ name: "unCode", display: "Valuation Information Taxes - Delivery Terms - Location Type" },
	{ name: "exchangeRate", display: "Valuation Information Taxes - Delivery Terms - Exchange Rate" },
	{ name: "additionsAndDeductions.value", display: "Valuation Information Taxes - Additions and Deductions - Value" },
	{ name: "additionsAndDeductions.currency", display: "Valuation Information Taxes - Additions and Deductions - Currency" },
	{ name: "additionsAndDeductions.type", display: "Valuation Information Taxes - Additions and Deductions - Type" },
	{ name: "totalAmountInvoiced", display: "Valuation Information Taxes - Total Amount Invoiced" },
	{ name: "personPayingCustomsDuty", display: "Valuation Information Taxes - Person Paying Customs Duty" },
	{ name: "personProvidingGuarantee", display: "Valuation Information Taxes - Person Providing Guarantee" },
	{ name: "internalCurrency", display: "Valuation Information Taxes - Internal Currency" },
	{ name: "countryOfDestinationCode", display: "Places - Destination Country" },
	{ name: "countryOfDispatchCode", display: "Places - Dispatch Country" },
	{ name: "locationofgoods.additionalIdentifier", display: "Places - Location of Goods - Additional Identifier" },
	{ name: "locationofgoods.identificationOfLocation", display: "Places - Location of Goods - Location ID" },
	{ name: "locationofgoods.typeOfLocation", display: "Places - Location of Goods - Location Type" },
	{ name: "locationofgoods.country", display: "Places - Location of Goods - Country" },
	{ name: "locationofgoods.qualifierOfTheIdentification", display: "Places - Location of Goods - Qualifier of the Identification" },
	{ name: "placeOfLoading", display: "Places - Place of Loading" },
	{ name: "presentationOffice", display: "Customs Offices - Presentation Office" },
	{ name: "supervisingOffice", display: "Customs Offices - Supervising Office" },
	{ name: "grossMass", display: "Goods Identification - Gross Mass" },
	{ name: "modeOfTransportAtBorder", display: "Transport Information - Mode of transport at the border" },
	{ name: "container", display: "Transport Information - Container" },
	{ name: "containerList", display: "Transport Information - Containers" },
	{ name: "modeOfTransportInland", display: "Transport Information - Inland mode of transport" },
	{ name: "arrivaltransportmeansid", display: "Transport Information - Means of transport on arrival" },
	{ name: "identityOfMeansTransportOnArrival", display: "Transport Information - Identity of transport on arrival" },
	{ name: "nationalityOfTransportWhenCrossingBorder", display: "Transport Information - Nationality of transport crossing border" },
	{ name: "identityOfBorderTransport", display: "Transport Information - Identity of transport crossing border" },
	{ name: "seals", display: "Transport Information - Seals" },
	{ name: "natureOfTransaction", display: "Other Data Elements - Nature of Transaction" },
	{ name: "guaranteeType", display: "Other Data Elements - Guarantee Type" },
	{ name: "otherDataElements.guaranteeList", display: "Other Data Elements - Guarantee List" },
	{ name: "otherDataElements.guaranteeList.reference", display: "Other Data Elements - Guarantee List - Reference" },
	{ name: "otherDataElements.guaranteeList.amount", display: "Other Data Elements - Guarantee List - Amount" },
	{ name: "otherDataElements.guaranteeList.office", display: "Other Data Elements - Guarantee List - Office" },
	{ name: "otherDataElements.guaranteeList.accessCode", display: "Other Data Elements - Guarantee List - Access Code" },
	{ name: "otherDataElements.guaranteeList.currency", display: "Other Data Elements - Guarantee List - Currency" },
	{ name: "valuationInformationTaxes.incotermCode", display: "Valuation Information - INCO Terms Code" },
	{ name: "valuationInformationTaxes.countryCodeLocationName", display: "Valuation Information - Country Code Location Name" },
	{ name: "valuationInformationTaxes.unCode", display: "Valuation Information - UN Code" },
	{ name: "valuationInformationTaxes.exchangeRate", display: "Valuation Information - Exchange Rate" },
	{ name: "valuationInformationTaxes.additionsAndReductions", display: "Valuation Information - Additions and Reductions" },
	{ name: "valuationInformationTaxes.additionsAndReductions.value", display: "Valuation Information - Additions and Reductions - value" },
	{ name: "valuationInformationTaxes.additionsAndReductions.currency", display: "Valuation Information - Additions and Reductions - currency" },
	{ name: "valuationInformationTaxes.additionsAndReductions.type", display: "Valuation Information - Additions and Reductions - type" },
	{ name: "valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced", display: "Valuation Information - Calculation of Taxes - Total Amount Invoiced" },
	{ name: "valuationInformationTaxes.personPayingCustomsDuty", display: "Valuation Information - Person Paying Customs Duty" },
	{ name: "valuationInformationTaxes.personProvidingGuarantee", display: "Valuation Information - Person Providing Guarantee" },
	{ name: "valuationInformationTaxes.internalCurrency", display: "Valuation Information - Internal Currency" },
	{ name: "transportInformation.modeOfTransportAtBorder", display: "Transport Information - Mode of Transport At Border" },
	{ name: "transportInformation.container", display: "Transport Information - Container" },
	{ name: "transportInformation.containerList", display: "Transport Information - Container list" },
	{ name: "transportInformation.modeOfTransportInland", display: "Transport Information - Mode of Transport Inland" },
	{ name: "transportInformation.identityMeansOfTransportOnArrival", display: "Transport Information - Identity means of transport on arrival" },
	{ name: "transportInformation.identityOfMeansTransportOnArrival", display: "Transport Information - Identity of means transport on arrival"},
	{ name: "transportInformation.sealNumber", display: "Transport Information - Seal Number"},
	{ name: "transportInformation.seal", display: "Transport Information - Seal Number - Seal"},
	{ name: "transportInformation.containerId", display: "Transport Information - Seal Number - Container Id"},

	// goods level, replace goods with goods item number
	// replace all other # with index position 
	{ name: "goods.goodsItemNumber", display: "Goods - Message Information - Goods Item Number" },
	{ name: "goods.procedures", display: "Goods - Message Information - Procedures" },
	{ name: "goods.additionalProcedure", display: "Goods - Message Information - Additional Procedure" },
	{ name: "goods.references.category", display: "Goods - References - Category" },
	{ name: "goods.references.type", display: "Goods - References - Type" },
	{ name: "goods.references.reference", display: "Goods - References - Reference" },
	{ name: "goods.references.itemId", display: "Goods - References - Item ID" },
	{ name: "goods.references.lpco", display: "Goods - References - LPCO Exemption Code" },
	{ name: "goods.additionalReference.code", display: "Goods - References - Additional Information - Code" },
	{ name: "goods.additionalReference.description", display: "Goods - References - Additional Information - Description" },
	{ name: "goods.ucrNumber", display: "Goods - References - UCR Number" },
	{ name: "goods.valuationMethod", display: "Goods - Valuation Information - Valuation Method" },
	{ name: "goods.preference", display: "Goods - Valuation Information - Preference" },
	{ name: "goods.taxBase.amount", display: "Goods - Valuation Information - Tax Base - Amount" },
	{ name: "goods.taxBase.unit", display: "Goods - Valuation Information - Tax Base - Unit" },
	{ name: "goods.taxBase.rate", display: "Goods - Valuation Information - Tax Base - Rate" },
	{ name: "goods.calculationOfTaxes.payableTaxAmount", display: "Goods - Valuation Information - Calculation of Taxes - Amount" },
	{ name: "goods.calculationOfTaxes.currency", display: "Goods - Valuation Information - Calculation of Taxes - Currency" },
	{ name: "goods.calculationOfTaxes.methodOfPayment", display: "Goods - Valuation Information - Calculation of Taxes - Payment Method" },
	{ name: "goods.calculationOfTaxes.taxType", display: "Goods - Valuation Information - Calculation of Taxes - Tax Type" },
	{ name: "goods.totalTaxCalculated", display: "Goods - Valuation Information - Total Tax Calculated" },
	{ name: "goods.currency", display: "Goods - Valuation Information - Currency" },
	{ name: "goods.methodOfPayment", display: "Goods - Valuation Information - Method of Payment" },
	{ name: "goods.warehouseType", display: "Goods - Valuation Information - Warehouse Type" },
	{ name: "goods.itemAmount", display: "Goods - Valuation Information - Item Amount" },
	{ name: "goods.valuationIndicators", display: "Goods - Valuation Information - Valuation Indicators" },
	{ name: "goods.additionsAndDeductions.value", display: "Goods - Valuation Information - Additions and Deductions - Value" },
	{ name: "goods.additionsAndDeductions.currency", display: "Goods - Valuation Information - Additions and Deductions - Currency" },
	{ name: "goods.additionsAndDeductions.type", display: "Goods - Valuation Information - Additions and Deductions - Type" },
	{ name: "goods.totalAmountInvoiced", display: "Goods - Valuation Information - Total Amount Invoiced" },
	{ name: "goods.countryDestination", display: "Goods - Places - Destination Country" },
	{ name: "goods.countryOfDispatch", display: "Goods - Places - Dispatch Country" },
	{ name: "goods.countryOfOrigin", display: "Goods - Places - Country of Origin" },
	{ name: "goods.countryOfPreferentialOrigin", display: "Goods - Places - Country of Preferential Origin" },
	{ name: "goods.countryOfOriginType", display: "Goods - Places - Country of Origin Type" },
	{ name: "goods.countryOfPreferentialOriginType", display: "Goods - Places - Country of Prerential Origin Type" },
	{ name: "goods.packaging.type", display: "Goods - Goods Identification - Packaging - Type" },
	{ name: "goods.packaging.numberOfPackages", display: "Goods - Goods Identification - Packaging - Number Packages" },
	{ name: "goods.packaging.shippingMarks", display: "Goods - Goods Identification - Packaging - Shipping Marks" },
	{ name: "goods.commodityCodeDescription", display: "Goods - Goods Identification - Commodity - Description of Goods" },
	{ name: "goods.combinedNomenclatureCode", display: "Goods - Goods Identification - Commodity - Combined Nomenclature Code" },
	{ name: "goods.taricCode", display: "Goods - Goods Identification - Commodity - TARIC Code" },
	{ name: "goods.cusCode", display: "Goods - Goods Identification - Commodity - CUS Code" },
	{ name: "goods.cusType", display: "Goods - Goods Identification - Commodity - CUS Type" },
	{ name: "goods.taricAdditionalCode", display: "Goods - Goods Identification - Commodity - TARIC Additional Code" },
	{ name: "goods.nationalAdditionalCode", display: "Goods - Goods Identification - Commodity - National Additional Code" },
	{ name: "goods.supplementaryUnits", display: "Goods - Goods Identification - Supplementary Id Number" },
	{ name: "goods.netMass", display: "Goods - Goods Identification - Net Mass" },
	{ name: "goods.grossMass", display: "Goods - Goods Identification - Gross Mass" },
	{ name: "goods.container", display: "Goods - Transport Information - Container" },
	{ name: "goods.containerId", display: "Goods - Transport Information - Container Identification Number" },
	{ name: "goods.identityMeansOfTransportOnArrival", display: "Goods - Transport Information - Means of transport on arrival" },
	{ name: "goods.identityOfMeansTransportOnArrival", display: "Goods - Transport Information - Identity of means of transport on arrival" },
	{ name: "goods.natureOfTransaction", display: "Goods - Other Data Elements - Nature of transaction" },
	{ name: "goods.quotaOrderNumber", display: "Goods - Other Data Elements - Quota Order Number" },
	{ name: "goods.statisticalValue", display: "Goods - Other Data Elements - Statistical Value" },
	{ name: "goods.valuationInformationTaxes.valuationMethod", display: "Goods - Valuation Information - Valuation method" },
	{ name: "goods.valuationInformationTaxes.preference", display: "Goods - Valuation Information - Preference" },
	{ name: "goods.valuationInformationTaxes.taxBaseAmount", display: "Goods - Valuation Information - Tax Base Amount" },
	{ name: "goods.valuationInformationTaxes.taxBaseAmount.amount", display: "Goods - Valuation Information - Tax Base Amount - amount" },
	{ name: "goods.valuationInformationTaxes.taxBaseAmount.unit", display: "Goods - Valuation Information - Tax Base Amount - unit" },
	{ name: "goods.valuationInformationTaxes.taxBaseAmount.rate", display: "Goods - Valuation Information - Tax Base Amount - rate" },
	{ name: "goods.valuationInformationTaxes.payableTaxAmount", display: "Goods - Valuation Information - Payable Tax Amount" },
	{ name: "goods.valuationInformationTaxes.payableTaxAmount.amount", display: "Goods - Valuation Information - Payable Tax Amount - amount" },
	{ name: "goods.valuationInformationTaxes.payableTaxAmount.currency", display: "Goods - Valuation Information - Payable Tax Amount - currency" },
	{ name: "goods.valuationInformationTaxes.payableTaxAmount.methodOfPayment", display: "Goods - Valuation Information - Payable Tax Amount - method of payment" },
	{ name: "goods.valuationInformationTaxes.payableTaxAmount.taxType", display: "Goods - Valuation Information - Payable Tax Amount - Tax Type" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.totalTaxCalculated", display: "Goods - Valuation Information - Calculation of Taxes - Total Tax Calculated" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.currency", display: "Goods - Valuation Information - Calculation of Taxes - Currency" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.methodOfPayment", display: "Goods - Valuation Information - Calculation of Taxes - Method of Payment" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.warehouseType", display: "Goods - Valuation Information - Calculation of Taxes - Warehouse Type" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.itemPriceAmount", display: "Goods - Valuation Information - Calculation of Taxes - Item Price Amount" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.valuationIndicators", display: "Goods - Valuation Information - Calculation of Taxes - Valuation Indicators" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions", display: "Goods - Valuation Information - Calculation of Taxes - Additions and Reductions" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.value", display: "Goods - Valuation Information - Calculation of Taxes - Additions and Reductions - value" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.currency", display: "Goods - Valuation Information - Calculation of Taxes - Additions and Reductions - currency" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.type", display: "Goods - Valuation Information - Calculation of Taxes - Additions and Reductions - type" },
	{ name: "goods.valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced", display: "Goods - Valuation Information - Calculation of Taxes - Total Amount Invoiced" },
	{ name: "goods.transportInformation", display: "Goods - Transport Information" },
	{ name: "goods.transportInformation.container", display: "Goods - Transport Information - container" },
	{ name: "goods.transportInformation.containerId", display: "Goods - Transport Information - container Id" },
	{ name: "goods.transportInformation.identityMeansOfTransportOnArrival", display: "Goods - Transport Information - identity means of transport on arrival" },
	{ name: "goods.transportInformation.identityOfMeansTransportOnArrival", display: "Goods - Transport Information - identity of means of transport on arrival" },
	{ name: "goods.places", display: "Goods - Places" },
	{ name: "goods.places.countryDestination", display: "Goods - Places - Country of Destination" },
	{ name: "goods.places.countryDispatch", display: "Goods - Places - Country of Dispatch" },
	{ name: "goods.places.countryOfOrigin", display: "Goods - Places - Country of Origin" },
	{ name: "goods.places.countryOfPreferentialOrigin", display: "Goods - Places - Country of Preferential Origin" },
	{ name: "goods.places.countryOfOriginType", display: "Goods - Places - Country of Origin type" },
	{ name: "goods.places.countryOfPreferentialOriginType", display: "Goods - Places - Country of Preferential Origin type" },
	{ name: "goods.parties", display: "Goods - Parties" },
	{ name: "goods.parties.exporter.name", display: "Goods - Parties - Exporter - Name" },
	{ name: "goods.parties.exporter.eori", display: "Goods - Parties - Exporter - EORI" },
	{ name: "goods.parties.importer.name", display: "Goods - Parties - Importer - Name" },
	{ name: "goods.parties.importer.eori", display: "Goods - Parties - Importer - EORI" },
	{ name: "goods.parties.seller.name", display: "Goods - Parties - Seller - Name" },
	{ name: "goods.parties.seller.eori", display: "Goods - Parties - Seller - EORI" },
	{ name: "goods.parties.buyer.name", display: "Goods - Parties - Buyer - Name" },
	{ name: "goods.parties.buyer.eori", display: "Goods - Parties - Buyer - EORI" },
	{ name: "goods.parties.representative.name", display: "Goods - Parties - Representative - Name" },
	{ name: "goods.parties.representative.eori", display: "Goods - Parties - Representative - EORI" },
	{ name: "goods.parties.representative.status", display: "Goods - Parties - Representative - Status" },
	{ name: "goods.parties.declarant.name", display: "Goods - Parties - Declarant - Name" },
	{ name: "goods.parties.declarant.eori", display: "Goods - Parties - Declarant - EORI" },
	{ name: "goods.parties.additionalSupplyChainActorId", display: "Goods - Parties - Additional Supply Chain Actor Id" },
	{ name: "goods.parties.additionalSupplyChainActorRole", display: "Goods - Parties - Additional Supply Chain Actor Role" },
	{ name: "goods.otherDataElements.natureOfTransaction", display: "Goods - Other Data Elements - Nature of Transaction" },
	{ name: "goods.otherDataElements.quotaOrderNumber", display: "Goods - Other Data Elements - Quota Order Number" },
	{ name: "goods.otherDataElements.statisticalValue", display: "Goods - Other Data Elements - Statistical Value" },
	{ name: "goods.messageInformation.goodsItemNumber", display: "Goods - Message Information - Goods Item Number" },
	{ name: "goods.messageInformation.procedures", display: "Goods - Message Information - Procedures" },
	{ name: "goods.messageInformation.additionalProcedure", display: "Goods - Message Information - Additional Procedures" },
	{ name: "goods.goodsIdentification.commodityCode.description", display: "Goods - Goods Information - Commodity Code - description" },
	{ name: "goods.goodsIdentification.commodityCode.combinedNomenclatureCode", display: "Goods - Goods Information - Commodity Code - Combined Nomenclature Code " },
	{ name: "goods.goodsIdentification.commodityCode.taricCode", display: "Goods - Goods Information - Commodity Code - TARIC Code" },
	{ name: "goods.goodsIdentification.commodityCode.cusCode", display: "Goods - Goods Information - Commodity Code - CUS Code" },
	{ name: "goods.goodsIdentification.commodityCode.cusType", display: "Goods - Goods Information - Commodity Code - CUS Type" },
	{ name: "goods.goodsIdentification.commodityCode.taricAdditionalCode", display: "Goods - Goods Information - Commodity Code - TARIC Additional Code" },
	{ name: "goods.goodsIdentification.commodityCode.nationalAdditionalCode", display: "Goods - Goods Information - Commodity Code - National Additional Code" },
	{ name: "goods.goodsIdentification.supplementaryUnits", display: "Goods - Goods Information - Supplementary Units" },
	{ name: "goods.goodsIdentification.netMass", display: "Goods - Goods Information - Net Mass" },
	{ name: "goods.goodsIdentification.grossMass", display: "Goods - Goods Information - Gross Mass" }
]