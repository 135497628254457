import { Buffer } from "buffer"

export default function convertStringBase64(str: string) {
    try {
        const base64 = Buffer.from(str).toString('base64');
        return base64;
    } catch (error) {
        console.error('Failed to convert string to base64:', error);
    }
    return "";
}