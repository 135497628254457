import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData
    isFieldVisible: (fieldName: string) => boolean
}

export default function CustomsOffice({formData, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current&& ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        presentationOfficeRule: boolean,
        supervisingOfficeRule: boolean
    } = {
        presentationOfficeRule: isFieldValid("presentationOffice", formData.fieldErrors),
        supervisingOfficeRule: isFieldValid("supervisingOffice", formData.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, formData.fieldErrors);
      }
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={'Customs offices'} visible={isFieldVisible("section-customsoffices")}>
            <Item dataField="customsOffices.presentationOffice" label={{text: "Presentation Office"}} visible={isFieldVisible("customsOffices.presentationOffice")}>
                <CustomRule message={handleGetErrorMessage("presentationOffice")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.presentationOfficeRule}} />
            </Item>
            <Item dataField="customsOffices.supervisingOffice" label={{text: "Supervising Office"}} visible={isFieldVisible("customsOffices.supervisingOffice")}>
                <CustomRule message={handleGetErrorMessage("supervisingOffice")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.supervisingOfficeRule}} />
            </Item>
            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
    </Form>
}