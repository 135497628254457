import { userRoles } from '../common/options/userRoles'

interface Menu {
    text: string
    path: string
    icon: string
    requiredRoles: string[]
    items?: MenuItem[]
}

interface MenuItem {
    text: string
    path: string
    requiredRoles: string[]
}


export function getNavigation() {
    let navigation: Menu[] = []

    //**************** Dashboard */
    navigation.push({
        text: 'Dashboard',
        path: '/dashboard',
        icon: 'home',
        requiredRoles: userRoles.all
    } as Menu)

    //**************** Buckets */
    // navigation.push({
    //     text: 'Buckets',
    //     path: '/buckets',
    //     icon: 'product',
    //     requiredRoles: userRoles.all
    // } as Menu)



    //**************** Admin */
    let adminItems: MenuItem[] = []
    adminItems.push(
        {
            text: 'Profile',
            path: '/profile',
            requiredRoles: userRoles.all
        },
        {
            text: 'Organisation',
            path: '/organisation',
            requiredRoles: userRoles.admins
        },
        {
            text: 'Account',
            path: '/account-page',
            requiredRoles: userRoles.owners,
        },
        /*  {
            text: 'Users',
            path: '/users',
            requiredRoles: userRoles.admins
        }        
         */
    )
    navigation.push({
        text: 'Admin',
        icon: 'preferences',
        requiredRoles: userRoles.all,
        items: adminItems
    } as Menu)

    if (process.env.REACT_APP_ENV == "DEV")
    {
        let declarationItems: MenuItem[] = []
        declarationItems.push(
            {
                text: 'Declarations',
                path: '/declarations-main',
                requiredRoles: userRoles.all
            },
            {
                text: 'Packages',
                path: '/declaration-packages',
                requiredRoles: userRoles.admins
            },
            {
                text: 'Templates',
                path: '/declaration-templates',
                requiredRoles: userRoles.admins
            }
        )

        navigation.push({
            text: 'Declaration',
            icon: 'doc',
            requiredRoles: userRoles.all,
            items: declarationItems
        } as Menu)

        adminItems.push({
            text: 'Customers',
            path: '/customers',
            requiredRoles: userRoles.all
        },
        {
            text: 'Suppliers',
            path: '/suppliers',
            requiredRoles: userRoles.all
        },
        {
            text: 'Addresses',
            path: '/addresses',
            requiredRoles: userRoles.all
        })
    }

    navigation.push({
        text: 'Port Clearance',
        icon: 'product',
        path: '/port-clearances',
        requiredRoles: userRoles.all
    } as Menu)

    return navigation
}
