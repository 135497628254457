import { useCallback } from 'react'
import { router } from '../../app/router/Routes'

export default function NotFound() {

    const backToHome = useCallback(() => {
        router.navigate('/')
    }, [])


    return (
        <div className='content-block'>
            <div className='not-found'>
                <h1>Oops!</h1>
                <h2>We've looked everywhere and could not find this.</h2>
                <p >
                    Go <button className='not-found-button' onClick={backToHome}>back</button> to the home page.
                </p>
            </div>
        </div>
    )
}