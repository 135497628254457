import { LoadIndicator } from "devextreme-react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import TaskStatusCards from "./TaskStatusCards";

export default observer(function DisplayGetTaskStatus() {
    const params = useParams();
    const { encodedId } = params;
    const { clearanceStore } = useStore();
    const { taskStatus, getTaskStatus, loading } = clearanceStore;

    useEffect(() => {
        if (encodedId) {
            getTaskStatus(encodedId);
        }
    }, [encodedId, getTaskStatus]);

    return (
        <div className="get-task-status">
            <div className="get-task-status-header">
                <p className="get-task-status-header-text">
                    Port Clearance Status
                </p>
                <hr className="get-task-status-header-hr" />
            </div>
            {loading ? (
                <LoadIndicator className={"get-task-status-loading"} />
            ) : (
                <div className="get-task-status-cards-container">
                    {taskStatus?.map((status, idx) => (
                        <TaskStatusCards status={status} key={idx} />
                    ))}
                </div>
            )}
        </div>
    );
});
