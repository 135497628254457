import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { cdsImportProcedureOptions } from '../../../app/common/options/cdsImportDeclaration/cdsImportProcedureOptions'
import { additionalProcedureCodeOptions } from '../../../app/common/options/cdsImportDeclaration/additionalProcedureCodeOptions'
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions'
import { useEffect, useRef, useState } from 'react'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsMessageInformation({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        goodsItemNumberRule: boolean
        proceduresRule: boolean
        additionalProcedureRule: boolean
    } = {
        goodsItemNumberRule: isFieldValidWithSequence("goodsItemNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        proceduresRule: isFieldValidWithSequence("procedures", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        additionalProcedureRule: isFieldValidWithSequence("additionalProcedure", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors)
    };

    return (<Form ref={formRef} formData={formData} onContentReady={() => setIsFormReady(true)}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Message Information'} visible={isFieldVisible("section-goods.messageInformation")} >
            <GroupItem  colCount={1}>
                <Item dataField="itemDetail.messageInformation.goodsItemNumber" label={{text: "Goods Item Number"}} editorOptions={{ readOnly: true}} visible={isFieldVisible("goods.messageInformation.goodsItemNumber")}>
                    <CustomRule message={getErrorMessageWithSequence("goodsItemNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.goodsItemNumberRule}} />
                </Item>
            </GroupItem>
            <GroupItem  colCount={1}>
                <Item dataField="itemDetail.messageInformation.procedures" label={{text: "Procedures"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.messageInformation.procedures")}
                    editorOptions={{
                      items: cdsImportProcedureOptions,
                      searchEnabled: true
                    }}>
                        <CustomRule message={getErrorMessageWithSequence("procedures", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.proceduresRule}} />
                    </Item>
                <Item dataField="itemDetail.messageInformation.additionalProcedure" label={{text: "Additional Procedure"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.messageInformation.additionalProcedure")}
                    editorOptions={{
                      items: additionalProcedureCodeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code"
                    }}>
                        <CustomRule message={getErrorMessageWithSequence("additionalProcedure", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalProcedureRule}} />
                    </Item>
            </GroupItem>
            <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
    </GroupItem></Form>)
};