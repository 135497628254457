import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Customer, CustomerAdd, CustomerUpdate } from "../models/customer/customer";

export default class CustomerStore {
    customer: Customer | null = null;
    customers: Customer[] | null = null;
    loading: Boolean = false;
    isSuccess: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    getCustomer = async (customerId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Customers.getCustomer(customerId)
            runInAction(() => {
                this.isSuccess = true;
                this.customer = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }

    }

    getCustomers = async (ogranisationId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Customers.getCustomers(ogranisationId)
            runInAction(() => {
                if(response.isSuccess && response.data)
                {
                    this.isSuccess = true
                    this.customers = response.data.data
                }
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    updateCustomer = async (acc: CustomerUpdate) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Customers.updateCustomer(acc)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    createCustomer = async (acc: CustomerAdd) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Customers.createCustomer(acc)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }

    deleteCustomer = async (customerId: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;

            const response = await agent.Customers.deleteCustomer(customerId)
            runInAction(() => {this.isSuccess = response.isSuccess})

            if (process.env.NODE_ENV === "development") {
                console.log(response)
            }
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    }
}