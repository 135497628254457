import { useAuth0 } from '@auth0/auth0-react'
import { Button, LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { emailValidation } from '../../app/models/user'
import { useStore } from '../../app/stores/store'

export default observer(function PendingEmailValidation() {

    const RESEND_DELAY_TIMER = 30 //timer to inactivate the re-send button
    const [inactive, setInactive] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [counter, setCounter] = useState(RESEND_DELAY_TIMER)
    const { userStore } = useStore()
    const { logout } = useAuth0()

    //logout
    const logUserOut = useCallback(() => {
        logout({ logoutParams: { returnTo: window.location.origin } })
        userStore.logout()
    }, [logout, userStore])

    const handleRefresh = useCallback(() => {
        setRefreshing(true)
        userStore.getUser().finally(() => setRefreshing(false))
    }, [setRefreshing, userStore])


    // controls timer
    useEffect(() => {
        if (counter > 0 && inactive) {
            setTimeout(() => setCounter(counter - 1), 1000)
        }
        if (counter === 0) {
            setInactive(false)
            setCounter(RESEND_DELAY_TIMER)
        }

    }, [setCounter, inactive, setInactive, counter])


    if (!userStore.user) {
        return <LoadPanel visible={true} />
    }

    const data: emailValidation = {
        email: userStore.user.email,
        redirectUrl: `${window.location.origin}/validate-email`
    }


    const handleEmailValidation = () => {
        userStore.resendEmailValidation(data)
        setInactive(true)
    }

    if (userStore.user && userStore.user.emailVerified)
        <Navigate replace to="/dashboard" />

    return (
        <div className='content-block'>
            <div className='pending-email-container'>
                <div className='pending-email-title'>Welcome to mycustomsclouds {`, ` && userStore.user?.firstName}</div>
                <h3>Before we get started, please confirm your email address by </h3>
                <h3>clicking in the link sent to: <b>{userStore.user?.email}</b></h3>
                {refreshing && <LoadPanel visible={true} />}
                <h3>If you have already confirmed, please try to refresh this page</h3>
                <h3>or resend the verification email.</h3>
                <h3 className="pending-email-logout">
                    <Button
                        stylingMode="text"
                        className='pending-email-logout-button'
                        text='Logout'
                        type={'default'}
                        onClick={logUserOut} />
                </h3>
                <div className='pending-email-button-container'>
                    <Button
                        className='pending-email-button'
                        text="Refresh"
                        disabled={refreshing}
                        onClick={() => handleRefresh()}
                    />
                    <Button
                        className='pending-email-button resend-email'
                        text={inactive ? "Resend in " + counter : "Resend Email"}
                        disabled={inactive}
                        onClick={() => handleEmailValidation()}
                    />
                </div>
                <div className='pending-email-button-container'>{(inactive && userStore.emailSent) &&
                    <h4>Email sent to:  {userStore.user.email}</h4>}</div>
            </div>
        </div >
    )
})