import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { ServerError } from '../../app/models/serverError'
import { router } from '../../app/router/Routes'
import { useStore } from '../../app/stores/store'


export default function CallbackPage() {
    const { isAuthenticated } = useAuth0()
    const { commonStore } = useStore()
    const { error } = useAuth0()

    useEffect(() => {
        if (isAuthenticated && !commonStore.token) {
            commonStore.getLocalToken()
            router.navigate('/dashboard')
        }
    }, [commonStore, isAuthenticated])

    if (error) {
        const serverError: ServerError = {
            Succeeded: false,
            Message: error.message,
            Errors: []
        }
        commonStore.setServerError(serverError)
        router.navigate('/server-error')
    }

    return <div></div>
}