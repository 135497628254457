export const h1Template = [
    { fieldName: "goods.parties.seller", isVisible: true},
    { fieldName: "goods.parties.buyer", isVisible: true },
    { fieldName: "header.valuationInformationTaxes.deliveryterms", isVisible: true},
    { fieldName: "header.valuationInformationTaxes.additionsanddeductions", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.valuationmethod", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.preference", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.methodofpayment", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.taxtype", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.taxbase", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.payabletaxamount", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.additionsanddeductions", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.valuationindicators", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.itemamount", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.total", isVisible: true},
    { fieldName: "header.places.placeofloading", isVisible: true},
    { fieldName: "goods.places.countryofdispatch", isVisible: true},
    { fieldName: "goods.places.countryofpreferentialorigin", isVisible: true},
    { fieldName: "header.transportinformation.arrivaltransportmeansid", isVisible: true},
    { fieldName: "goods.other.quotaordernumber", isVisible: true},
    { fieldName: "goods.parties.consignee", isVisible: false},
    { fieldName: "header.parties.declarant", isVisible: false},
    { fieldName: "header.valuationInformationTaxes.transportcharges.methodofpayment", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.transportcharges.methodofpayment", isVisible: false},
    { fieldName: "header.places.countriesofrouting", isVisible: false},
    { fieldName: "header.customsoffices.exit", isVisible: false},
    { fieldName: "goods.goodsidentification.undangerousgoodscode", isVisible: false},
    { fieldName: "header.transportinformation.idmeanstransportcrossingborder", isVisible: false},
    { fieldName: "header.transportinformation.idtransportdepature", isVisible: false},
    { fieldName: "header.transportinformation.sealnumber", isVisible: false}
]
