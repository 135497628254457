export const additionsAndDeductionsCodeOptions = [
    {code: "AB", description: "Commissions and brokerage, except buying commissions"},
    {code: "AD", description: "Containers and packing"},
    {code: "AE", description: "Materials, components, parts and similar items incorporated in the imported goods"},
    {code: "AF", description: "Tools, dies, moulds and similar items used in the production of the imported goods"},
    {code: "AG", description: "Materials consumed in the production of the imported goods"},
    {code: "AH", description: "Engineering, development, artwork, design work and plans and sketches undertaken elsewhere than in the European Union and necessary for the production of the imported goods"},
    {code: "AI", description: "Royalties and license fees"},
    {code: "AJ", description: "Proceeds of any subsequent resale, disposal or use accruing to the seller"},
    {code: "AK", description: "Transport costs, loading and handling charges and insurance costs up to the place of introduction in the European Union"},
    {code: "AL", description: "Indirect payments and other payments (Article 70 of the code)"},
    {code: "AN", description: "Additions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446. Deductions (As defined under Articles 72 of the Code)"},
    {code: "BA", description: "Costs of transport after arrival at the place of introduction"},
    {code: "BB", description: "Charges for construction, erection, assembly, maintenance or technical assistance undertaken after importation"},
    {code: "BC", description: "Import duties or other charges payable in the Union for reason of the import or sale of goods"},
    {code: "BD", description: "Interest charges"},
    {code: "BE", description: "Charges for the right to reproduce the imported goods in the European Union"},
    {code: "BF", description: "Buying commissions"},
    {code: "BG", description: "Deductions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446"}
]