import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { PortClearance, PortClearanceSummary, TaskStatus } from "../models/clearances"
import { Buffer } from "buffer"
import { router } from "../router/Routes"
import { CustomsRoute } from "../models/customsRoutes"
import { CustomsCarrier } from "../models/customsCarriers"
import { CustomsPort } from "../models/customsPorts"
import { AuthorityCustomsRoute } from "../models/authorityCustomsRoute"
import { AuthorityCustomsCarrier } from "../models/authorityCustomsCarrier"
import { AuthorityCustomsPort } from "../models/authorityCustomsPort"
import { convertUtcToLocalTime } from "../common/utils/formatDate"

export default class ClearanceStore {
    taskStatus: TaskStatus[] | null = null;
    errorMsg: string | null = "null";
    loading: Boolean = true;
    portClearances: PortClearanceSummary[] | null = null;
    portClearance: PortClearance | null = null;
    customsRoutes: CustomsRoute[] | null = null;
    authorityCustomsRoutes: AuthorityCustomsRoute[] | null = null;
    customsRoute: CustomsRoute | null = null;
    customsCarriers: CustomsCarrier[] | null = null;
    authorityCustomsCarriers: AuthorityCustomsCarrier[] | null = null;
    customsCarrier: CustomsCarrier | null = null;
    customsPorts: CustomsPort[] | null = null;
    authorityCustomsPorts: AuthorityCustomsPort[] | null = null;
    customsPort: CustomsPort | null = null;
    error: any = null;

    constructor() {
        makeAutoObservable(this)
    }

    getTaskStatus = async (encodedId: string) => {
        try {
            this.loading = true
            const buffer = Buffer.from(encodedId, "base64")
            const costumerId = buffer.toString("ascii")
            const response = await agent.PortClearances.getStatus(costumerId)
            runInAction(() => {
                if (response.isSuccess) {
                    let exitTask : TaskStatus = {
                        authorityType: response.data?.exitAuthorityType ?? 'N/A',
                        portClearanceId: response.data?.exitAuthorityNumber ?? 'N/A',
                        status: response.data?.exitStatus ?? 'N/A',
                        channelStatus: response.data?.channelStatus ?? 'N/A',
                        channelAction: response.data?.channelAction ?? 'N/A',
                        portName: response.data?.exitPortName ?? 'N/A',
                        portCountryCode: response.data?.exitPortCountryCode ?? 'N/A',
                        errors: []
                    }
                    let entryTask : TaskStatus = {
                        authorityType: response.data?.entryAuthorityType ?? 'N/A',
                        portClearanceId: response.data?.entryAuthorityNumber ?? 'N/A',
                        status: response.data?.entryStatus ?? 'N/A',
                        channelStatus: response.data?.channelStatus ?? 'N/A',
                        channelAction: response.data?.channelAction ?? 'N/A',
                        portName: response.data?.entryPortName ?? 'N/A',
                        portCountryCode: response.data?.entryPortCountryCode ?? 'N/A',
                        errors: [] 
                    }

                    if(response.data?.exitAuthorityMessage)
                        exitTask.errors = [response.data?.exitAuthorityMessage]
                    if(response.data?.entryAuthorityMessage)
                        entryTask.errors = [response.data?.entryAuthorityMessage]

                    this.taskStatus = [exitTask, entryTask]
                    this.loading = false
                } else {
                    this.errorMsg = response.error
                    this.loading = false
                }
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.loading = false
                router.navigate("/not-found")
            })
        }
    };

    setPortClearance = (portClearance: PortClearance | null) => {
        if(portClearance)
        {
            portClearance.exitTask.actionDate = convertUtcToLocalTime(portClearance.exitTask.actionDate)
            portClearance.entryTask.actionDate = convertUtcToLocalTime(portClearance.entryTask.actionDate)
        }

        this.portClearance = portClearance
    }

    setPortClearances = (portClearances: PortClearanceSummary[] | null) => {
        if(portClearances)
        {
            var clearancesWithLocalTime = portClearances.map(pc => {
                return {
                    ...pc,
                    requestedDate: convertUtcToLocalTime(pc.requestedDate)
                }
            })

            portClearances = clearancesWithLocalTime;
        }

        this.portClearances = portClearances
    }

    setCustomsRoute = (customsRoute: CustomsRoute | null) => {
        this.customsRoute = customsRoute
    }

    setCustomsRoutes = (customsRoutes: CustomsRoute[] | null) => {
        this.customsRoutes = customsRoutes
    }

    setAuthorityCustomsRoutes = (customsRoutes: AuthorityCustomsRoute[] | null) => {
        this.authorityCustomsRoutes = customsRoutes
    }

    setCustomsCarrier = (customsCarrier: CustomsCarrier | null) => {
        this.customsCarrier = customsCarrier
    }

    setCustomsCarriers = (customsCarriers: CustomsCarrier[] | null) => {
        this.customsCarriers = customsCarriers
    }

    setAuthorityCustomsCarriers = (customsCarriers: AuthorityCustomsCarrier[] | null) => {
        this.authorityCustomsCarriers = customsCarriers
    }

    setCustomsPort = (customsPort: CustomsPort | null) => {
        this.customsPort = customsPort
    }

    setCustomsPorts = (customsPorts: CustomsPort[] | null) => {
        this.customsPorts = customsPorts
    }

    setAuthorityCustomsPorts = (customsPorts: AuthorityCustomsPort[] | null) => {
        this.authorityCustomsPorts = customsPorts
    }

    setError(error: any) {
        this.error = error
    }
}
