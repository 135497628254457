export const keySize = (value: any) => {
    if (value.value.length >= 20) {
        return true
    }
    return false
}

export const IsValidIp = (value: any) => {
    const check = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/
    const input = value.value.trim()
    if (input === '*') {
        return true
    }
    if (check.test(input)) {
        return true
    }
    return false
}

export const CreatingIpRows = (formData: any, options: any, set: any) => {

    const geIpOptions = () => {
        const options = [];
        for (let i = 0; i < formData.validIps.length; i += 1) {
            options.push(generateNewIpsOptions(i));
        }
        return options;
    }

    const generateNewIpsOptions = (index: number) => {
        return {
            buttons: [{
                name: 'trash',
                location: 'after',
                options: {
                    stylingMode: 'text',
                    icon: 'trash',
                    onClick: () => {
                        formData.validIps.splice(index, 1);
                        updateIps();
                    },
                },
            }],
        };
    };

    const updateIps = () => {
        set(geIpOptions());
    }

    updateIps()

}

export function deepClone(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(deepClone);
    } else if (typeof obj === 'object' && obj !== null) {
        const clonedObj: any = {};
        for (const key in obj) {
            clonedObj[key] = deepClone(obj[key]);
        }
        return clonedObj;
    } else {
        return obj;
    }
}
