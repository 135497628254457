import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { router } from '../../app/router/Routes'

export default function HmrcCallbackPage() {

    const [searchParams] = useSearchParams()
    const organisationId: string | null = searchParams.get('org')
    const code: string | null = searchParams.get('code')

    useEffect(() => {
        if (!code) router.navigate('/server-error')
        if (code) {
            // Do any magic here and then redirect
            router.navigate(`/organisation?o=${organisationId}&t=2&c=${code}`)
        }
    }, [code])

    if (code) return <div><h3>HMRC Access Code: {code}</h3></div>
    return <div></div>
}