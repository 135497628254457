import { DataGrid } from 'devextreme-react'
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { Column, CustomRule, Editing } from 'devextreme-react/data-grid'
import { DecalarationData } from '../../app/models/declarationCreate'
import { modeOfBorderTransportOptions } from '../../app/common/options/cdsImportDeclaration/modeOfBorderTransportOptions'
import { countryOptions } from '../../app/common/options/countryOptions'
import { transportMeansIdTypeOptions } from '../../app/common/options/cdsImportDeclaration/transportMeansIdentificationTypes'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function TransportInformation({formData, isFieldVisible}: Props){
  const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
      modeOfTransportAtBorderRule: boolean
      containerRule: boolean
      modeOfTransportInlandRule: boolean
      arrivaltransportmeansidRule: boolean
      identityOfMeansTransportOnArrivalRule: boolean
      nationalityOfTransportWhenCrossingBorderRule: boolean
      identityOfBorderTransportRule: boolean

    } = {
      modeOfTransportAtBorderRule: isFieldValid("modeOfTransportAtBorder", formData.fieldErrors),
      containerRule: isFieldValid("container", formData.fieldErrors),
      modeOfTransportInlandRule: isFieldValid("modeOfTransportInland", formData.fieldErrors),
      arrivaltransportmeansidRule: isFieldValid("arrivaltransportmeansid", formData.fieldErrors),
      identityOfMeansTransportOnArrivalRule: isFieldValid("identityOfMeansTransportOnArrival", formData.fieldErrors),
      nationalityOfTransportWhenCrossingBorderRule: isFieldValid("nationalityOfTransportWhenCrossingBorder", formData.fieldErrors),
      identityOfBorderTransportRule: isFieldValid("identityOfBorderTransport", formData.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
      return getErrorMessage(fieldId, formData.fieldErrors);
    }
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
      <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Transport Information'} visible={isFieldVisible("section-transportinformation")}>
        <GroupItem cssClass={'cds-group-item'} colCount={2}>
            <Item dataField="transportInformation.modeOfTransportAtBorder" label={{text: "Mode of transport at the border"}} editorType="dxSelectBox"
                    visible={isFieldVisible("transportInformation.modeOfTransportAtBorder")}
                    editorOptions={{
                      items: modeOfBorderTransportOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "description",
                    }}>
                      <CustomRule message={handleGetErrorMessage("modeOfTransportAtBorder")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.modeOfTransportAtBorderRule}} />
                    </Item>
            <Item dataField="transportInformation.container" editorType={'dxCheckBox'} label={{text: "Container"}} visible={isFieldVisible("transportInformation.container")}>
              <CustomRule message={handleGetErrorMessage("container")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.containerRule}} />
            </Item>
            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Containers'} visible={isFieldVisible("transportInformation.containerList")}>
            <DataGrid dataSource={formData.transportInformation.containerList}>
                <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                <Column dataField="id"></Column>
            </DataGrid>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={2}>
            <Item colSpan={2} dataField="transportInformation.modeOfTransportInland" label={{text: "Inland mode of transport"}} editorType="dxSelectBox"
                    visible={isFieldVisible("transportInformation.modeOfTransportInland")}
                    editorOptions={{
                      items: modeOfBorderTransportOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "description",
                    }}>
                      <CustomRule message={handleGetErrorMessage("modeOfTransportInland")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.modeOfTransportInlandRule}} />
                    </Item>
                  <Item dataField="transportInformation.identityMeansOfTransportOnArrival" label={{text: "Means of transport on arrival"}} editorType="dxSelectBox"
                    visible={isFieldVisible("transportInformation.identityMeansOfTransportOnArrival")}
                    editorOptions={{
                      items: transportMeansIdTypeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code"
                    }}>
                      <CustomRule message={handleGetErrorMessage("arrivaltransportmeansid")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivaltransportmeansidRule}} />
                    </Item>
            <Item dataField="transportInformation.identityOfMeansTransportOnArrival" label={{text: "Identity of transport on arrival"}} visible={isFieldVisible("transportInformation.identityOfMeansTransportOnArrival")}>
              <CustomRule message={handleGetErrorMessage("identityOfMeansTransportOnArrival")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityOfMeansTransportOnArrivalRule}} />
            </Item>
            <Item dataField="transportInformation.nationalityOfTransportWhenCrossingBorder" label={{text: "Nationality of transport crossing border"}} editorType="dxSelectBox"
                    visible={isFieldVisible("transportInformation.nationalityOfTransportWhenCrossingBorder")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                      <CustomRule message={handleGetErrorMessage("nationalityOfTransportWhenCrossingBorder")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.nationalityOfTransportWhenCrossingBorderRule}} />
                    </Item>
            <Item dataField="transportInformation.identityOfBorderTransport" label={{text: "Identity of transport crossing border"}} visible={isFieldVisible("transportInformation.identityOfBorderTransport")}>
              <CustomRule message={handleGetErrorMessage("identityOfBorderTransport")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityOfBorderTransportRule}} />
            </Item>
            <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem colSpan={2} cssClass={'cds-group-item'} caption={'Seals'} visible={isFieldVisible("transportInformation.sealNumber")} >
            <DataGrid dataSource={formData?.transportInformation.sealNumber} >
                <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                <Column dataField="seal" visible={isFieldVisible("transportInformation.seal")}></Column>
                <Column dataField="containerId" visible={isFieldVisible("transportInformation.containerId")}></Column>
            </DataGrid>
          </GroupItem>
    </GroupItem>
    </Form>
}