import { useQuery } from '@tanstack/react-query'
import { store } from '../stores/store'
import agent from '../api/agent'
import { CustomsRoute } from '../models/customsRoutes'
import { Result } from '../models/result'
import { CustomsCarrier } from '../models/customsCarriers'
import { CustomsPort } from '../models/customsPorts'
import { ReferenceData } from '../models/referenceData'
import { AuthorityCustomsRoute } from '../models/authorityCustomsRoute'
import { AuthorityCustomsCarrier } from '../models/authorityCustomsCarrier'
import { AuthorityCustomsPort } from '../models/authorityCustomsPort'

export function useCustomsRoutes() {
    return useQuery(['customs-routes'], () => agent.Management.getCustomsRoutes(), {
        onSuccess: (data: Result<CustomsRoute[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setCustomsRoutes(data.data)
            } else {
                store.clearanceStore.setCustomsRoutes(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving customs routes.', error)
            store.clearanceStore.setCustomsRoutes(null)
        },
    })
}

export function useAuthorityCustomsRoutes() {
    return useQuery(['authority-customs-routes'], () => agent.Management.getAuthorityCustomsRoutes(), {
        onSuccess: (data: Result<AuthorityCustomsRoute[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setAuthorityCustomsRoutes(data.data)
            } else {
                store.clearanceStore.setAuthorityCustomsRoutes(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving authority customs routes.', error)
            store.clearanceStore.setAuthorityCustomsRoutes(null)
        },
    })
}

export function useCustomsCarriers() {
    return useQuery(['customs-carriers'], () => agent.Management.getCustomsCarriers(), {
        onSuccess: (data: Result<CustomsCarrier[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setCustomsCarriers(data.data)
            } else {
                store.clearanceStore.setCustomsCarriers(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving customs carriers.', error)
            store.clearanceStore.setCustomsCarriers(null)
        },
    })
}

export function useAuthorityCustomsCarriers() {
    return useQuery(['authority-customs-carriers'], () => agent.Management.getAuthorityCustomsCarriers(), {
        onSuccess: (data: Result<AuthorityCustomsCarrier[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setAuthorityCustomsCarriers(data.data)
            } else {
                store.clearanceStore.setAuthorityCustomsCarriers(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving authority customs carriers.', error)
            store.clearanceStore.setAuthorityCustomsCarriers(null)
        },
    })
}

export function useCustomsPorts() {
    return useQuery(['customs-ports'], () => agent.Management.getCustomsPorts(), {
        onSuccess: (data: Result<CustomsPort[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setCustomsPorts(data.data)
            } else {
                store.clearanceStore.setCustomsPorts(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving customs ports.', error)
            store.clearanceStore.setCustomsPorts(null)
        },
    })
}

export function useAuthorityCustomsPorts() {
    return useQuery(['authority-customs-ports'], () => agent.Management.getAuthorityCustomsPorts(), {
        onSuccess: (data: Result<AuthorityCustomsPort[]>) => {
            if (data.isSuccess && data.data) {
                store.clearanceStore.setAuthorityCustomsPorts(data.data)
            } else {
                store.clearanceStore.setAuthorityCustomsPorts(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving authority customs ports.', error)
            store.clearanceStore.setAuthorityCustomsPorts(null)
        },
    })
}

export function useReferenceData() {
    return useQuery(['reference-data'], () => agent.Management.getReferenceData(), {
        onSuccess: (data: Result<ReferenceData>) => {
            if (data.isSuccess && data.data) {
                store.commonStore.setReferenceData(data.data)
            } else {
                store.commonStore.setReferenceData(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving reference data.', error)
            store.commonStore.setReferenceData(null)
        },
    })
}
