import { h1Template } from "./h1Template";
import { b1Template } from "./b1template";

export const isVisible = (decType: string, fieldName: string): boolean =>
{
    if(decType == "H1")
    {
        let matchedField = h1Template.find(x => x.fieldName == fieldName);
        if(matchedField == undefined || !matchedField.isVisible)
        {
            return false;
        }
        return true;
    }
    else if(decType == "B1")
    {
        let matchedField = b1Template.find(x => x.fieldName == fieldName);
        if(matchedField == undefined || !matchedField.isVisible)
        {
            return false;
        }
        return true;
    }

    return false;
}
