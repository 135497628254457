import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'
import { supplyChainRoleOptions } from '../../app/common/options/cdsImportDeclaration/supplyChainRoleOptions'
import { isVisible } from '../../app/common/options/declarationTemplates/declarationTemplate';
import ActorEdit from './ActorEdit';
import { useEffect, useRef, useState } from 'react';
import { CheckBox } from 'devextreme-react';
import ActorEditSupplyChain from './ActorEditSupplyChain';
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions';
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function PartiesCard({formData, isFieldVisible}: Props){
    const [actorEditMode, setActorEditMode] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current&& ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        exporterNameRule: boolean,
        exporterEoriRule: boolean,
        importerNameRule: boolean,
        importerEoriRule: boolean,
        sellerNameRule: boolean,
        sellerEoriRule: boolean,
        buyerNameRule: boolean,
        buyerEoriRule: boolean,
        representativeNameRule: boolean,
        representativeEoriRule: boolean,
        representativeStatusRule: boolean,
        declarantNameRule: boolean,
        declarantEoriRule: boolean,
        additionalSupplyChainActorIdRule: boolean,
        additionalSupplyChainActorRoleRule: boolean,
        carrierNameRule: boolean,
        carrierEoriRule: boolean
    } = {
        exporterNameRule: isFieldValid("exporterName", formData.fieldErrors),
        exporterEoriRule: isFieldValid("exporterEori", formData.fieldErrors),
        importerNameRule: isFieldValid("importerName", formData.fieldErrors),
        importerEoriRule: isFieldValid("importerEori", formData.fieldErrors),
        sellerNameRule: isFieldValid("sellerName", formData.fieldErrors),
        sellerEoriRule: isFieldValid("sellerEori", formData.fieldErrors),
        buyerNameRule: isFieldValid("buyerName", formData.fieldErrors),
        buyerEoriRule: isFieldValid("buyerEori", formData.fieldErrors),
        representativeNameRule: isFieldValid("representativeName", formData.fieldErrors),
        representativeEoriRule: isFieldValid("representativeEori", formData.fieldErrors),
        representativeStatusRule: isFieldValid("representativeStatus", formData.fieldErrors),
        declarantNameRule: isFieldValid("declarantName", formData.fieldErrors),
        declarantEoriRule: isFieldValid("declarantEori", formData.fieldErrors),
        additionalSupplyChainActorIdRule: isFieldValid("additionalSupplyChainActorId", formData.fieldErrors),
        additionalSupplyChainActorRoleRule: isFieldValid("additionalSupplyChainActorRole", formData.fieldErrors),
        carrierNameRule: isFieldValid("carrierName", formData.fieldErrors),
        carrierEoriRule: isFieldValid("carrierEori", formData.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, formData.fieldErrors);
      }

    if(actorEditMode)
    {
        return <Form formData={formData}>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'} visible={isFieldVisible("section-parties")}>
                    <CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} />
                    {isFieldVisible("header.parties.exporter") && <ActorEdit formData={formData.parties.exporter} actorCaption='Exporter' isRepresentative={false} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.importer") && <ActorEdit formData={formData.parties.importer} actorCaption='Importer' isRepresentative={false} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.seller") && <ActorEdit formData={formData.parties.seller} actorCaption='Seller' isRepresentative={false} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.buyer") && <ActorEdit formData={formData.parties.buyer} actorCaption='Buyer' isRepresentative={false} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.representative") && <ActorEdit formData={formData.parties.representative} actorCaption='Representative' isRepresentative={true} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.declarant") && <ActorEdit formData={formData.parties.declarant} actorCaption='Declarant' isRepresentative={false} fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.supplyChain") && <ActorEditSupplyChain formData={formData.parties} actorCaption='Supply Chain' fieldErrors={formData.fieldErrors} />}
                    {isFieldVisible("header.parties.carrier") && <ActorEdit formData={formData.parties.carrier} actorCaption='Carrier' isRepresentative={false}  fieldErrors={formData.fieldErrors} />}
                </GroupItem>
                </Form>
    }

    return <div><Form ref={formRef} formData={formData} onContentReady={() => setIsFormReady(true)}>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'} visible={isFieldVisible("section-parties")}>
                        <GroupItem colSpan={2}><CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} /></GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Exporter'}>
                            <Item dataField="parties.exporter.name" label={{text: "Name"}} visible={isFieldVisible("exporterName")}>
                                <CustomRule message={handleGetErrorMessage("exporterName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.exporterNameRule}} />
                            </Item>
                            <Item dataField="parties.exporter.eori" label={{text: "EORI"}} visible={isFieldVisible("exporterEori")}>
                                <CustomRule message={handleGetErrorMessage("exporterEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.exporterEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Importer'}>
                            <Item dataField="parties.importer.name" label={{text: "Name"}} visible={isFieldVisible("importerName")}>
                                <CustomRule message={handleGetErrorMessage("importerName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerNameRule}} />
                            </Item>
                            <Item dataField="parties.importer.eori" label={{text: "EORI"}} visible={isFieldVisible("importerEori")}>
                                <CustomRule message={handleGetErrorMessage("importerEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerEoriRule}} />
                            </Item>
                        </GroupItem>
                        {isVisible(formData.declarationType, "header.parties.seller") && <GroupItem cssClass='cds-group-item' colCount={1} caption={'Seller'}>
                            <Item dataField="parties.seller.name" label={{text: "Name"}} visible={isFieldVisible("sellerName")}>
                                <CustomRule message={handleGetErrorMessage("sellerName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerNameRule}} />
                            </Item>
                            <Item dataField="parties.seller.eori" label={{text: "EORI"}} visible={isFieldVisible("sellerEori")}>
                                <CustomRule message={handleGetErrorMessage("sellerEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerEoriRule}} />
                            </Item>
                        </GroupItem>}
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Buyer'}>
                            <Item dataField="parties.buyer.name" label={{text: "Name"}} visible={isFieldVisible("buyerName")}>
                                <CustomRule message={handleGetErrorMessage("buyerName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerNameRule}} />
                            </Item>
                            <Item dataField="parties.buyer.eori" label={{text: "EORI"}} visible={isFieldVisible("buyerEori")}>
                                <CustomRule message={handleGetErrorMessage("buyerEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={2} colSpan={2} caption={'Reprensentative'}>
                            <Item dataField="parties.representative.name" label={{text: "Name"}} visible={isFieldVisible("representativeName")}>
                                <CustomRule message={handleGetErrorMessage("representativeName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeNameRule}} />
                            </Item>
                            <Item dataField="parties.representative.status" label={{text: "Status"}} visible={isFieldVisible("representativeStatus")}>
                                <CustomRule message={handleGetErrorMessage("representativeStatus")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeStatusRule}} />
                            </Item>
                            <Item dataField="parties.representative.eori" label={{text: "EORI"}} visible={isFieldVisible("representativeEori")}>
                                <CustomRule message={handleGetErrorMessage("representativeEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Declarant'}>
                            <Item dataField="parties.declarant.name" label={{text: "Name"}} visible={isFieldVisible("declarantName")}>
                                <CustomRule message={handleGetErrorMessage("declarantName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarantNameRule}} />
                            </Item>
                            <Item dataField="parties.declarant.eori" label={{text: "EORI"}} visible={isFieldVisible("declarantEori")}>
                                <CustomRule message={handleGetErrorMessage("declarantEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarantEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Supply Chain'}>
                            <Item dataField="parties.additionalSupplyChainActorId" label={{text: "Additional Supply Chain Actor ID"}} visible={isFieldVisible("supplyChainActorId")}>
                                <CustomRule message={handleGetErrorMessage("additionalSupplyChainActorId")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorIdRule}} />
                            </Item>
                            <Item dataField="parties.additionalSupplyChainActorRole" label={{text: "Role in the Supply Chain"}} visible={isFieldVisible("supplyChainRole")} editorType="dxSelectBox"
                                editorOptions={{
                                    items: supplyChainRoleOptions,
                                    searchEnabled: true,
                                    displayExpr: "party",
                                    valueExpr: "role"
                                }}>
                                    <CustomRule message={handleGetErrorMessage("additionalSupplyChainActorRole")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorRoleRule}} />
                                </Item>
                        </GroupItem>
                        <GroupItem cssClass='cds-group-item' colCount={1} caption={'Carrier'}>
                            <Item dataField="parties.carrier.name" label={{text: "Name"}} visible={isFieldVisible("carrierName")}>
                                <CustomRule message={handleGetErrorMessage("carrierName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.carrierNameRule}} />
                            </Item>
                            <Item dataField="parties.carrier.eori" label={{text: "EORI"}} visible={isFieldVisible("carrierEori")}>
                                <CustomRule message={handleGetErrorMessage("carrierEori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.carrierEoriRule}} />
                            </Item>
                        </GroupItem>
                        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
            </Form>
            </div>
    }