import React, { useState, createContext, useContext, useEffect } from 'react'

export type NavigationContextType = {
    setNavigationData?: ({ currentPath }: NavigationData) => void
    navigationData: NavigationData
}

export type ValidationType = {
    value: string
}

const NavContext = createContext<NavigationContextType>({} as NavigationContextType)
const useNavigation = () => useContext(NavContext)

interface NavigationData {
    currentPath: string
}

function NavigationProvider(props: React.PropsWithChildren<unknown>) {
    const [navigationData, setNavigationData] = useState({ currentPath: '' })

    return (
        <NavContext.Provider
            value={{ navigationData, setNavigationData }}
            {...props}
        />
    )
}

function withNavigationWatcher(Component: React.ElementType, path: string) {
    const WrappedComponent = function (props: Record<string, unknown>) {
        const { setNavigationData } = useNavigation()

        useEffect(() => {
            setNavigationData!({ currentPath: path })
        }, [setNavigationData])

        return <Component {...props} />
    }
    return <WrappedComponent />
}

export {
    NavigationProvider,
    useNavigation,
    withNavigationWatcher
}
