import Form, { CustomRule, GroupItem, Item } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { natureOfTransactionOptions } from '../../../app/common/options/cdsImportDeclaration/natureOfTransactionOptions'
import { useEffect, useRef, useState } from 'react';
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions';
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods;
    decType: string;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsOtherDataElements({formData, decType, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        natureOfTransactionRule: boolean
        quotaordernumberRule: boolean
        statisticalValueRule: boolean
    } = {
        natureOfTransactionRule: isFieldValidWithSequence("natureOfTransaction", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        quotaordernumberRule: isFieldValidWithSequence("quotaordernumber", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        statisticalValueRule: isFieldValidWithSequence("statisticalValue", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
    };

    return <Form ref={formRef} formData={formData} onFormDataChange={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={1} caption={'Other data elements'} visible={isFieldVisible("section-goods.otherDataElements")} >
                <GroupItem cssClass="cds-group-item" colCount={2} visible={isFieldVisible("goods.otherDataElements.natureOfTransaction")} >
                    <Item dataField="itemDetail.otherDataElements.natureOfTransaction" label={{text: "Nature of transaction"}} editorType="dxSelectBox"
                        editorOptions={{
                            items: natureOfTransactionOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code"
                        }}>
                        <CustomRule message={getErrorMessageWithSequence("natureOfTransaction", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.natureOfTransactionRule}} />
                    </Item>
                    <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
                    <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} >
                    <Item dataField="itemDetail.otherDataElements.quotaOrderNumber" label={{text: "Quota order number"}} visible={isFieldVisible("goods.otherDataElements.quotaOrderNumber")}>
                            <CustomRule message={getErrorMessageWithSequence("quotaordernumber", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.quotaordernumberRule}} />
                        </Item>
                    <Item dataField="itemDetail.otherDataElements.statisticalValue" label={{text: "Statistical value"}} visible={isFieldVisible("goods.otherDataElements.statisticalValue")}>
                        <CustomRule message={getErrorMessageWithSequence("statisticalValue", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.statisticalValueRule}} />
                    </Item>
                    <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
                </GroupItem>
                <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
        </GroupItem></Form>
}