
export default function Footer() {
    return (
        <footer className={'footer'}>
            Copyright © 2011-{new Date().getFullYear()} mycustomclouds.com
            {/* <br />
            All trademarks or registered trademarks are property of their
            respective owners. */}
        </footer>
    )
}