import DataGrid, { Column, CustomRule, Editing } from "devextreme-react/data-grid"
import { modeOfBorderTransportOptions } from "../../../app/common/options/cdsImportDeclaration/modeOfBorderTransportOptions"
import { countryOptions } from "../../../app/common/options/countryOptions"
import { DeclarationTransportInformation, FieldError } from "../../../app/models/declarationCreate"
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { useEffect, useRef, useState } from "react"
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationTransportInformation | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityTransportInformation({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        identityCrossBorderTransportRule: boolean
        nationalityCrossBorderTransportRule: boolean
        modeTransportBorderRule: boolean
        containerNumberRule: boolean
    } = {
        identityCrossBorderTransportRule: isFieldValid("identityCrossBorderTransport", fieldErrors),
        nationalityCrossBorderTransportRule: isFieldValid("nationalityCrossBorderTransport", fieldErrors),
        modeTransportBorderRule: isFieldValid("modeTransportBorder", fieldErrors),
        containerNumberRule: isFieldValid("containerNumber", fieldErrors)
    };
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Transport Information'} >
            <Item dataField="identityCrossBorderTransport" label={{text: "Identity Cross Border"}} visible={isFieldVisible("identityCrossBorderTransport")}>
                <CustomRule message={getErrorMessage("identityCrossBorderTransport", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityCrossBorderTransportRule}} />
            </Item>
            <Item dataField="nationalityCrossBorderTransport" label={{text: "Nationalty Cross Border"}} editorType="dxSelectBox"
                    visible={isFieldVisible("nationalityCrossBorderTransport")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                    }}>
                        <CustomRule message={getErrorMessage("nationalityCrossBorderTransport", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.nationalityCrossBorderTransportRule}} />
                    </Item>
            <Item dataField="modeTransportBorder" label={{text: "Mode Transport At Border"}} editorType="dxSelectBox" 
                visible={isFieldVisible("modeTransportBorder")}
                editorOptions={{
                    items: modeOfBorderTransportOptions,
                    searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                }}
            >
                <CustomRule message={getErrorMessage("modeTransportBorder", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.modeTransportBorderRule}} />
            </Item>
            <Item dataField="containerNumber" label={{text: "Container Number"}} visible={isFieldVisible("containerNumber")}>
                <CustomRule message={getErrorMessage("containerNumber", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.containerNumberRule}} />
            </Item>
            <GroupItem colSpan={2}><DataGrid dataSource={formData?.sealNumber} visible={isFieldVisible("sealNumber")} >
                <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                <Column dataField="seal"></Column>
                <Column dataField="containerId"></Column>
            </DataGrid></GroupItem>
        </GroupItem>
    </Form>
}