import { Button, CheckBox } from "devextreme-react";
import { RequiredRule } from "devextreme-react/data-grid";
import { FileUploadForm } from "../fileUploader/FileUploadForm";
import Form, {
  ButtonItem,
  GroupItem,
  Item,
  SimpleItem,
} from "devextreme-react/form";
import { createRef, useCallback, useState } from "react";
import { convertFileToBase64 } from "../../app/common/utils/convertFileBase64";
import { notifyMessage } from "../../app/common/utils/notify";
import FormContainer from "../../app/layout/components/FormContainer";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { certificateTypeOptions } from "../../app/common/options/certificateTypeOptions";

interface Props {
  organisationId: string;
  closeFunction: () => void;
}

function AddCertificates({ organisationId, closeFunction }: Props) {
  const { organisationStore } = useStore();
  const [fileUpload, setFileUpload] = useState<File>();
  const allowedFileExtensions = [".crt", ".cer", ".ca-bundle", ".p7b", ".p7c", ".p7s", ".pem", ".bac",];
  const [isDefault, setDefault] = useState(false);
  const formElement = createRef<HTMLFormElement>();
  const [fileName, setFileName] = useState<string | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>({
    id: "",
    name: "",
    type: "",
    password: "",
  });

  const handleFileUploaderValueChanged = useCallback((e: any) => {
    if (e.value) {
      setFileUpload(e.value[0]);
      setFileName(e.value[0].name);
    } else {
      setFileUpload(undefined);
      setFileName(null);
    }
  }, []);

  const handleCancel = () => {
    setSelectedRowData(null);
    setFileName(null);
    setDefault(false);
    closeFunction();
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (fileUpload) {
      convertFileToBase64(fileUpload).then((base64File: string) => {
        const postedCert = {
          organisationId: organisationId,
          name: selectedRowData.name,
          type: selectedRowData.type,
          certificateEncoded: base64File,
          password: selectedRowData.password,
          isDefault: isDefault,
        };
        organisationStore
          .createCertificate(postedCert)
          .then(() => {
            notifyMessage("success", "Certificate added successfully");
            organisationStore.getCertificates(organisationId);
            handleCancel();
          })
          .catch((error) => {
            console.log(error);
            notifyMessage("error", "Error adding certificate");
            handleCancel();
          })
          .finally(() => closeFunction());
      });
    } else {
      notifyMessage("warning", "Please select a file first");
    }
  };

  const form = (
    <form
      onSubmit={handleSave}
      ref={formElement}
      encType="multipart/form-data"
      id="form"
    >
      <Form className="add-organisation-form" formData={selectedRowData || {}}>
        <GroupItem colCount={2} colSpan={2}>
          <SimpleItem dataField="name">
            <RequiredRule message={"Certificate Name is required"} />
          </SimpleItem>
          <SimpleItem
            dataField="type"
            editorType="dxSelectBox"
            editorOptions={{ items: certificateTypeOptions }}
          >
            <RequiredRule message={"Certificate Type is required"} />
          </SimpleItem>
          <SimpleItem>
            <RequiredRule message={"Certificate is required"} />
            <FileUploadForm
              buttonText="Select a file"
              maxFileSizeInBytes={4000000}
              onValueChanged={handleFileUploaderValueChanged}
              labelText="or drop the certificate file here"
              allowedFileExtensions={allowedFileExtensions}
            />
            {fileName && (
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "20px",
                  fontSize: ".9rem",
                }}
              >
                {fileName}
              </span>
            )}
          </SimpleItem>
          <SimpleItem
            dataField="password"
            editorOptions={{ mode: "password" }}
            label={{ text: "Certificate Password" }}
          ></SimpleItem>
          <SimpleItem>
            <CheckBox
              id="check"
              defaultValue={isDefault}
              onValueChanged={() => setDefault(!isDefault)}
              text="Default"
            />
          </SimpleItem>
        </GroupItem>
        <Item itemType={"empty"}></Item>
        <ButtonItem
          cssClass={"add-organisation-save-btn"}
          horizontalAlignment="left"
          buttonOptions={{
            text: "Save",
            type: "default",
            useSubmitBehavior: true,
            width: 85,
          }}
        />
        <Item cssClass={"add-organisation-cancel-btn"}>
          <Button
            onClick={handleCancel}
            type="normal"
            className="dx-widget dx-button dx-button-mode-contained dx-button-has-text"
          >
            <div className="dx-button-text">Cancel</div>
          </Button>
        </Item>
      </Form>
    </form>
  );

  return organisationStore.certificates ? (

    <FormContainer
      children={form}
      title={"Add Certificate"}
      closeFunction={handleCancel}
    />

  ) : (
    <></>
  );
}

export default observer(AddCertificates);
