import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "devextreme-react"
import Form, { Label, Item, ButtonItem, GroupItem } from "devextreme-react/form"
import { notifyMessage } from "../../app/common/utils/notify"
import { Organisation } from "../../app/models/organisation"
import { useStore } from "../../app/stores/store"

interface Props {
    org: Organisation
    editMode: boolean
    editFunction: any
}

export default function EditOrganisation({ org, editMode, editFunction }: Props) {
    const { organisationStore, commonStore } = useStore()
    const { getAccessTokenSilently } = useAuth0()


    const readRequired = { readOnly: !editMode, require: true }
    const readonly = { readOnly: !editMode }

    const newOrg: Omit<Organisation, "subscriptionId"> = {
        id: org.id,
        name: org.name,
        logo: org.logo,
        operationalTimeZone: org.operationalTimeZone,
        taxLabel1: org.taxLabel1,
        taxLabel2: org.taxLabel2,
        defaultCurrencyIsoCode: org.defaultCurrencyIsoCode,
        addressCode: org.addressCode,
        address1: org.address1,
        address2: org.address2,
        postcode: org.postcode,
        countryIsoCode: org.countryIsoCode,
        contactName: org.contactName,
        phoneNumber: org.phoneNumber,
        email: org.email,
        web: org.web,
        isDefault: org.isDefault,
        bucketCounterPrefix: org.bucketCounterPrefix,
        bucketCounterNumber: org.bucketCounterNumber,
        clearenceCounterPrefix: org.clearenceCounterPrefix,
        clearenceCounterNumber: org.clearenceCounterNumber,
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        organisationStore.updateOrganisation(newOrg)
            .then(() => {
                notifyMessage("success", "Organisation Updated")
                handleRefreshToken()
                editFunction()
            })
            .catch(() => {
                notifyMessage("error", "Error Updating Organisation")
            })
    }

    const handleRefreshToken = () => {
        getAccessTokenSilently(
            { cacheMode: 'off' }
        ).then((token) => {
            commonStore.setToken(token)
            if (process.env.NODE_ENV === "development") {
                console.log("Token refreshed successfully:", token)
            }
        }).catch((error) => {
            console.log("Failed to refresh token:", error)
        }).finally(() => window.location.reload())
    }

    const onInitialized = () => {
        if (editMode) {
            var elements = document.getElementsByName("name")
            elements[0]?.focus()
        }
    }

    return (
        <form onSubmit={(e) => { handleSubmit(e) }} >
            <br />
            <Form formData={editMode ? newOrg : org} colCount={3} onContentReady={onInitialized}>
                <Item dataField="name" editorOptions={readRequired} isRequired={true} />
                <Item dataField="email" editorOptions={readonly} />
                <Item dataField="contactName" editorOptions={readonly} />
                <Item dataField="phoneNumber" editorOptions={readonly} />
                <Item dataField="web" editorOptions={readonly}>
                    <Label text={"Website"} />
                </Item>
                <Item dataField="address1" editorOptions={readonly} />
                <Item dataField="address2" editorOptions={readonly} />
                <Item dataField="postcode" editorOptions={readonly} />
                <Item dataField="addressCode" editorOptions={readonly} />
                <Item dataField="countryIsoCode" editorOptions={readonly} />
                <Item dataField="operationalTimeZone" editorOptions={readonly} />
                <Item dataField="defaultCurrencyIsoCode" editorOptions={readonly} />
                <Item dataField="bucketCounterPrefix" editorOptions={{ readOnly: true }} />
                <Item dataField="bucketCounterNumber" editorOptions={{ readOnly: true }} />
                <Item dataField="clearenceCounterPrefix" editorOptions={{ readOnly: true }} />
                <Item dataField="clearenceCounterNumber" editorOptions={{ readOnly: true }} />
                <Item dataField="isDefault" editorOptions={{ readOnly: true }} />
                <Item itemType={"empty"}></Item>

                {/* Edit mode */}

                {
                    editMode && <GroupItem colCount={1} >
                        <Item itemType={"empty"}></Item>
                        <ButtonItem
                            cssClass={"add-organisation-save-btn"}
                            horizontalAlignment="left"
                            buttonOptions={{
                                text: 'Save',
                                type: 'default',
                                useSubmitBehavior: true,
                                width: 85,
                            }}
                        />
                        <Item cssClass={"add-organisation-cancel-btn display-organisation"} horizontalAlignment="left" >
                            <Button
                                onClick={editFunction}
                                type="normal"
                                className="dx-widget dx-button dx-button-mode-contained dx-button-has-text  ">
                                <div className="dx-button-text">
                                    Cancel
                                </div>
                            </Button>
                        </Item>
                    </GroupItem >
                }
            </Form>
        </form >
    )

}
