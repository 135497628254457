import { useEffect, useRef, useState } from "react"
import { countryOptions } from "../../app/common/options/countryOptions"
import { DeclarationActor, FieldError } from "../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../app/common/utils/declarationErrorFunctions"

interface Props {
    formData: DeclarationActor | null | undefined
    fieldErrors: FieldError []
    actorCaption: string
    isRepresentative: boolean
}

export default function ActorEdit(props: Props) {
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const fieldName = props.actorCaption.toLowerCase;

    const validationRules: {
        nameRule: boolean,
        eoriRule: boolean,
        streetRule: boolean,
        cityRule: boolean,
        countryRule: boolean,
        postcodeRule: boolean,
        phoneRule: boolean,
        statusRule: boolean
    } = {
        nameRule: isFieldValid(fieldName + "Name", props.fieldErrors),
        eoriRule: isFieldValid(fieldName + "Eori", props.fieldErrors),
        streetRule: isFieldValid(fieldName + "Street", props.fieldErrors),
        cityRule: isFieldValid(fieldName + "City", props.fieldErrors),
        countryRule: isFieldValid(fieldName + "Country", props.fieldErrors),
        postcodeRule: isFieldValid(fieldName + "Postcode", props.fieldErrors),
        phoneRule: isFieldValid(fieldName + "Phone", props.fieldErrors),
        statusRule: isFieldValid(fieldName + "Status", props.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, props.fieldErrors);
      }


    return <Form ref={formRef} formData={props.formData} onContentReady={() => setIsFormReady(true)}>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={props.actorCaption}>
            <Item dataField="name" label={{ text: "Name" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Name")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.nameRule}} />
            </Item>
            <Item dataField="eori" label={{ text: "EORI" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Eori")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.eoriRule}} />
            </Item>
            <Item dataField="street" label={{ text: "Address" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Street")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.streetRule}} />
            </Item>
            <Item dataField="city" label={{ text: "City" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "City")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.cityRule}} />
            </Item>
            <Item dataField="country" label={{ text: "Country" }} editorType="dxSelectBox"
                editorOptions={{
                    items: countryOptions,
                    searchEnabled: true,
                    displayExpr: "name",
                    valueExpr: "isoCode"
                }}>
                    <CustomRule message={handleGetErrorMessage(fieldName + "Country")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryRule}} />
                </Item>
            <Item dataField="postcode" label={{ text: "Postcode" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Postcode")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.postcodeRule}} />
            </Item>
            <Item dataField="phone" label={{ text: "Phone" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Phone")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.phoneRule}} /> 
            </Item>
            {props.isRepresentative && <Item dataField="status" label={{ text: "Status" }}>
                <CustomRule message={handleGetErrorMessage(fieldName + "Status")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.statusRule}} /> 
            </Item>}
        </GroupItem>
    </Form>
}