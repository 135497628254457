import { useAuth0 } from "@auth0/auth0-react"
import { useStore } from "../stores/store"

const useRefresh = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { commonStore } = useStore()

    const refreshToken = () => {
        getAccessTokenSilently(
            { cacheMode: 'off' }
        ).then((token) => {
            commonStore.setToken(token)
            if (process.env.NODE_ENV === "development") {
                console.log("Token refreshed successfully:", token)
            }
        }).catch((error: any) => {
            console.log("Failed to refresh token:", error)
        }).finally(() => window.location.reload())
    }
    return { refreshToken }
}

export default useRefresh;