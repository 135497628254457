export const authorisationHolderCategoryCodeOptions = [
    { code: "ACE", description: "Authorised consignee for Union transit" },
    { code: "ACP", description: "Authorised issuer to establish the proof of the customs status of Union goods" },
    { code: "ACR", description: "Authorised consignor for Union transit" },
    { code: "ACT", description: "Authorised consignee for TIR procedure" },
    { code: "AEOC", description: "Authorised Economic Operator - Customs simplifications" },
    { code: "AEOF", description: "Authorised Economic Operator - Customs somplifications/Security and safety" },
    { code: "AEOS", description: "Authorised Economic Operator - Security and safety" },
    { code: "APEX", description: "Approved Exporter" },
    { code: "AOR", description: "Advance Origin Ruling" },
    { code: "ATR", description: "Advance Tariff Ruling" },
    { code: "AVR", description: "Advance Valuation Ruling" },
    { code: "AWB", description: "Authorised Weigher of Bananas" },
    { code: "BOI", description: "Binding Origin Information" },
    { code: "BRD", description: "Bulk Import Reduced Data Set Authorisation" },
    { code: "BTI", description: "Binding Tariff Information" },
    { code: "CCL", description: "Authorisation for centralised clearance" },
    { code: "CGU", description: "Customs comprehensive guarantee" },
    { code: "CSDR", description: "Community System of Duty Reliefs" },
    { code: "CSE", description: "Customs Supervised Exports" },
    { code: "CVA", description: "Simplification of the determination of amounts being part of the customs value of goods" },
    { code: "CW1", description: "Operation of storage facilities for the customs warehousing of goods in a public customs warehouse type I" },
    { code: "CW2", description: "Operation of storage facilities for the customs warehousing of goods in a public customs warehouse type II" },
    { code: "CWP", description: "Operation of storage facilities for the customs warehousing of goods in a private customs warehouse" },
    { code: "DEP", description: "Designated Export Place" },
    { code: "DPO", description: "Deferment of payment authorisation" },
    { code: "EIR", description: "Authorisation for the use of customs declaration through an entry of data in the declarant's records (EIDR)" },
    { code: "EORI", description: "Economic Operator Registration and Identification" },
    { code: "EXRR", description: "RORO Exporter" },
    { code: "EPSS", description: "Excise Payment Security System" },
    { code: "ETD", description: "Electronic Transport Document (authorised for use as a customs declaration)" },
    { code: "EUS", description: "End Use authorisation" },
    { code: "EXEE", description: "Registered Consignee (Excise)" },
    { code: "EXOR", description: "Registered Consignor (Excise)" },
    { code: "EXW", description: "Excise Warehouse" },
    { code: "EXWH", description: "Excise Warehouse - Hydrocarbon Oils" },
    { code: "FAS", description: "Flexible Accounting System" },
    { code: "FP", description: "Freeport Authorisation" },
    { code: "FTI", description: "Fixed Transport Installation" },
    { code: "FZ", description: "Free Zone (only for the use of the Isle of Man)" },
    { code: "GGA", description: "General Guarantee Account" },
    { code: "GVS", description: "General Valuation Statement" },
    { code: "IPO", description: "Inward Processing authorisation" },
    { code: "MOU", description: "Memorandum of Understanding (approved exporters only)" },
    { code: "OPO", description: "Outward Processing authorisation" },
    { code: "REM", description: "Remission of the amounts of import or export duty" },
    { code: "REP", description: "Repayment of the amounts of import or export duty" },
    { code: "REX", description: "Registered Exporter" },
    { code: "RSS", description: "Regular Shipping Services authorisation" },
    { code: "SAS", description: "Authorisation for self-assessment" },
    { code: "SASP", description: "Single Authorisation for Simplified Procedures" },
    { code: "SDE", description: "Simplified Declaration Procedure authorisation (SDP)" },
    { code: "SIVA", description: "Simplified Import VAT Accounting" },
    { code: "SSE", description: "Authorisation for the use of seals of a special type" },
    { code: "TEA", description: "Temporary Admission authorisation" },
    { code: "TEAH", description: "Temporary Admission authorisation - Auction Houses (no guarantee for VAT required)" },
    { code: "TRD", description: "Authorisation to use transit declaration with a reduced dataset" },
    { code: "TST", description: "Authorisation to operate storage facilities for the temporary storage of goods" },
    { code: "UKCS", description: "UK Continental Shelf" },
    { code: "UKTS", description: "UK Trader Scheme Authorisation. Note: UKTS can only be declared on supplementary declarations submitted for goods that moved into NI on or before the 29th of September 2023" }
]