import { Drawer, ScrollView, Template } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useCallback, useRef, useState } from 'react'
import { useMenuPatch, useScreenSize } from '../../common/utils/mediaQuery'
import { useNavigate } from 'react-router'
import { ClickEvent } from 'devextreme/ui/button'
import { ItemClickEvent } from 'devextreme/ui/tree_view'
import SideNavigationMenu from './SideNavigationMenu'
import Header from './Header'
import React from 'react'
import Footer from './Footer'
import { useStore } from '../../stores/store'


export default observer(function SideNavOuterToolbar({ children }: React.PropsWithChildren) {
    const scrollViewRef = useRef<ScrollView>(null)
    const navigate = useNavigate()
    const { isXSmall, isLarge } = useScreenSize()
    const [patchCssClass, onMenuReady] = useMenuPatch()
    const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed)
    const { userStore } = useStore()

    const toggleMenu = useCallback(({ event }: ClickEvent) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        )
        event?.stopPropagation()
    }, [])

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        )
    }, [])

    const onOutsideClick = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
                ? MenuStatus.Closed
                : prevMenuStatus
        )
        return menuStatus === MenuStatus.Closed ? true : false
    }, [isLarge, menuStatus])

    const onNavigationChanged = useCallback(({ itemData, event, node }: ItemClickEvent) => {
        if (menuStatus === MenuStatus.Closed || !itemData?.path || node?.selected) {
            event?.preventDefault()
            return
        }

        navigate(itemData.path)
        scrollViewRef.current?.instance.scrollTo(0)

        if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed)
            event?.stopPropagation()
        }
    }, [navigate, menuStatus, isLarge])

    const showMenu = (userStore.user && userStore.user.emailVerified) ? true : false

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                menuToggleEnabled={showMenu}
                toggleMenu={toggleMenu}
            />
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={!showMenu ? 0 : isXSmall ? 0 : 45}
                maxSize={250}
                visible={true}
                shading={isLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={'menu'}
            >
                <div className={'real-content'}>
                    {React.Children.map(children, (item: any) => {
                        return item.type !== Footer && item
                    })}
                </div>
                {/* <div className={'content-block'}>
                    {React.Children.map(children, (item: any) => {
                        return item.type === Footer && item
                    })}
                </div> */}
                <Template name={'menu'}>
                    <SideNavigationMenu
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                    >
                    </SideNavigationMenu>
                </Template>
            </Drawer>
            {menuStatus !== MenuStatus.Closed && < Footer />}
        </div>
    )
})

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3
}