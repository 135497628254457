import { makeAutoObservable, reaction } from 'mobx'
import { ServerError } from '../models/serverError'
import { ReferenceDataType, ReferenceData } from '../models/referenceData'

const getToken = () => {
    const json: string | null = localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email offline_access`)
    if (json) {
        return (JSON.parse(json) as Auth0Token).body.access_token
    }
    return null
}

const deleteToken = () => {
    localStorage.removeItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::@@user@@`)
    localStorage.removeItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email offline_access`)
}

interface Auth0Token {
    body: Auth0Body
}

interface Auth0Body {
    access_token: string
}

export default class CommonStore {
    error: ServerError | null = null
    token: string | null = getToken()
    referenceData: ReferenceData | null = null
    appLoaded: boolean = false;
    drawerOpen = false
    notifyDriverTypes: ReferenceDataType[] | null = null
    portDeclarationTypes: ReferenceDataType[] | null = null

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.token,
            token => {
                if (process.env.NODE_ENV === 'development') console.log('token: ', token)
            }
        )
    }

    setReferenceData = (referenceData: ReferenceData | null) => {
        this.referenceData = referenceData

        // reset all type 
        this.notifyDriverTypes = null

        if (referenceData) {
            referenceData.enums.forEach(enumItem => {
                if (enumItem.name === 'NotifyDriverType')
                    this.notifyDriverTypes = enumItem.values
                if (enumItem.name === 'PortDeclarationType')
                    this.portDeclarationTypes = enumItem.values
            })
        }
    }



    setServerError = (error: ServerError) => {
        this.error = error
    }

    setToken = (token: string | null) => {
        this.token = token
    }

    setDrawerOpen = (value: boolean) => {
        this.drawerOpen = value
    }

    getLocalToken = () => {
        this.token = getToken()
    }

    setAppLoaded = () => {
        this.appLoaded = true
    }

    deleteToken = () => {
        deleteToken()
        this.token = null
    }
}
