import { observer } from "mobx-react-lite";
import DataGrid, {
    Column,
    FilterRow,
    Selection
  } from 'devextreme-react/data-grid';
import { useRef, useState } from "react";
import { Button, LoadIndicator } from "devextreme-react";
import { expressTemplateFields } from "../../app/common/options/declarationTemplates/expressTemplateFields";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { useDeclarationTemplate, useUpdateDeclarationTemplate } from "../../app/hooks/declarationQueries";
import { router } from "../../app/router/Routes";
import { ExpressField } from "../../app/models/declarationCreate";
import Form, { GroupItem } from "devextreme-react/form";
import { UpdateDeclarationRequest } from "../../app/models/declarationUpdate";
import notify from "devextreme/ui/notify";
import { deepClone } from "../../app/common/utils/accountFunctions";
import { notifyMessage } from "../../app/common/utils/notify";

export default observer(function ExpressTemplateSelectorForm() {
    const dataGridRef = useRef<DataGrid>(null)
    const { id } = useParams<{ id: string }>()
    const { declarationStore } = useStore()
    const { isLoading } = useDeclarationTemplate(id)
    const updateDeclaration = useUpdateDeclarationTemplate()
    const [submitting, setSubmitting] = useState<boolean>(false)

    function getRowsClicked(){
        const selectedRowKeys = dataGridRef.current?.instance.getSelectedRowKeys();
        
        if(selectedRowKeys){
            const dataReturn = selectedRowKeys.map((field) => new ExpressField(field))
            return dataReturn;
        }
        
        return [];
    }

    function setRowsClicked(){
        if(declarationStore?.declarationTemplate?.expressFields != null)
        {
            const listFieldNames = declarationStore.declarationTemplate?.expressFields.map(li => {
                return li.name
            });

            if(listFieldNames)
                dataGridRef.current?.instance.selectRows(listFieldNames, false);
        }
    }

    function handleValidation(){
        if(declarationStore.declarationTemplate?.lrn.length === 0)
        {
            notifyMessage("warning", "Template Name is required");
            return false;
        }

        return true;
    }    

    function handleFormClose(){
        router.navigate(`/declaration-templates/${id}`)
    }

    function handleFormCloseError(){
        router.navigate(`/declaration-templates`)
    }

    const handleUpdateDeclaration = async() => {
        setSubmitting(v => !v)
        if(id && declarationStore.declarationTemplate)
        {
            if(!handleValidation())
                return;

            let updateRequest = deepClone(declarationStore.declarationTemplate);
            if(updateRequest) updateRequest.expressFields = getRowsClicked();

            let updateDec: UpdateDeclarationRequest = {declarationId: id, request: updateRequest};

            const response = await updateDeclaration.mutateAsync(updateDec)
            if(response && response.isSuccess)
            {
                notify('Express Fields set', 'success')
                handleFormClose()
            }
            else 
            {
                notify(response.error, 'error')
            }
        }
        setSubmitting(v => !v)
    }

    function handleSubmit(e: any) {
        e.preventDefault()
      }

    if(!id) handleFormCloseError()
    if (isLoading || !declarationStore.declarationTemplate || submitting) return <LoadIndicator className="organisations-load-indicator" />
    
    setRowsClicked();
    return (<div className="content-block">
            <form onSubmit={(e) => handleSubmit(e)}>
                <Form>
                <GroupItem colCount={2}> 
                    <GroupItem>
                    <span className="tab-container-title">
                        Express Template Selection
                    </span>
                        <DataGrid ref={dataGridRef} id="templateFields" dataSource={expressTemplateFields} showBorders={true} rowAlternationEnabled={true} keyExpr={"name"}
                            defaultSelectedRowKeys={declarationStore.declarationTemplate.expressFields} >
                        <FilterRow visible={true} />
                            <Selection allowSelectAll={true}
                                mode="multiple"
                                selectAllMode={'allPages'}
                                showCheckBoxesMode={'always'}
                            />
                            <Column dataField="display" caption="Field" />
                        </DataGrid>
                    </GroupItem>
                    <GroupItem colCount={2}>
                        <Button onClick={handleUpdateDeclaration} text='Save' icon="save" stylingMode="contained" type="success" disabled={submitting} />
                        <Button onClick={handleFormClose} text='Cancel' icon="close" stylingMode="contained" type="danger" disabled={submitting} />
                    </GroupItem>
                </GroupItem>
                </Form>
            </form></div>)
})