import { Button, ContextMenu, DataGrid, LoadIndicator } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useDeclarationTemplates, useDeleteDeclarationTemplate } from '../../app/hooks/declarationQueries'
import { useStore } from '../../app/stores/store'
import { useEffect, useState } from 'react'
import { Column, FilterRow } from 'devextreme-react/data-grid'
import { confirm } from "devextreme/ui/dialog"
import { onToolbarPreparing } from '../../app/common/utils/toolbar'
import notify from 'devextreme/ui/notify'
import { notifyMessage } from '../../app/common/utils/notify'
import { router } from '../../app/router/Routes'
import { DeclarationTemplateHeader } from '../../app/models/declarationTemplateHeader'

export default observer(function DeclarationTemplateList() {
    const { declarationStore, organisationStore } = useStore()
    const [selectedDeclarationId, setSelectedDeclarationId] = useState('');

    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : '';

    const { isLoading } = useDeclarationTemplates(organisationId)
    const [adding, setAdding] = useState(false)
    const deleteDeclarationTemplate = useDeleteDeclarationTemplate();
    const rightClickItems = [{text: 'Create Declaration'}];

    if (isLoading) return <LoadIndicator className="organisations-load-indicator" />

    function handleSubmit(e: any) {
        e.preventDefault()
      }

    const handleDelete = (cellData: any) => {
        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {
                deleteDeclarationTemplate.mutateAsync(cellData.data.id)   
            }
        })
    }

    const handleEdit = (cellData: any) => {
        declarationStore.setDeclarationTemplate(null)
        if (cellData.data.id) {
            router.navigate(`/declaration-templates/${cellData.data.id}`)
        }
    }

    const handleAddNew = () => {
        notify("Feature not implemented")
        /*
        declarationStore.setDeclarationTemplate(null)
        setAdding(true)
        router.navigate(`/port-clearance`)*/
    }

    function handleSendToDeclarationsClick(cellData: any){
        if(cellData.data.id)
            declarationStore.createDeclarationFromTemplate(cellData.data.id).then(() => notifyMessage("success", "Declaration created from template")).finally(() => {})
        else
            notifyMessage("warning", "Template not selected")
      }


    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}  
                />
                <Button
                    icon="upload"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleSendToDeclarationsClick(cellData)}
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

    return (
        <div className="content-block">
            <form onSubmit={(e) => handleSubmit(e)}>
            {isLoading && <LoadIndicator className="organisations-load-indicator" />}
            <span className="tab-container-title">
                Declaration Templates
            </span>
            <DataGrid
                id='templates'
                dataSource={declarationStore.declarationTemplates}
                rowAlternationEnabled={true}
                keyExpr="id"
                onContextMenuPreparing={(rows) => setSelectedDeclarationId(rows.row?.key)}
            >
                <FilterRow visible={true} />
                <Column type="adaptive" width={10} />
                <Column dataField="templateReference" caption="Name" />
                <Column dataField="authoritySystem" caption="Authority" />
                <Column dataField="countryOfDestination" caption="Destination" />
                <Column dataField="countryOfDispatch" caption="Origin" />
                <Column dataField="type" />
                <Column dataField="additionalType" />
                <Column dataField="createdDate" />
                <Column dataField="createdBy" />
                <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
            </DataGrid>
            </form>
        </div>
    )
})