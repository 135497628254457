import { Button, LoadIndicator } from "devextreme-react"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useSearchParams } from 'react-router-dom'
import { Organisation } from "../../app/models/organisation"
import { useStore } from "../../app/stores/store"
import AddOrganisation from "./AddOrganisation"
import DisplayOrganisation from "./DisplayOrganisation"

export default observer(function Organisations() {
  const [showAddOrganisation, setShowAddOrganisation] = useState(false)
  const { organisationStore } = useStore()
  const { organisations, loading, getOrganisationMe } = organisationStore
  const [selectedOrg, setSelectedOrg] = useState<string | null>()
  const [searchParams] = useSearchParams()
  const organisationId: string | null = searchParams.get('o')


  useEffect(() => {
    if (organisations) {
      const defaultOrg = organisationId ? organisations.find(org => org.id === organisationId) : organisations.find(org => org.isDefault)
      setSelectedOrg(defaultOrg?.name)
    } else {
      getOrganisationMe()
    }
  }, [getOrganisationMe, organisations, organisationId])

  const handleAddOrganisation = () => {
    setShowAddOrganisation(!showAddOrganisation)
  }



  return (
    <div className="content-block">

      {loading && <LoadIndicator className="organisations-load-indicator" />}

      {/* Show organisation list and Add button */}
      <div className="organisation-container-head">
        <span className="organisation-container-title">
          Organisations
        </span>
        {organisations &&
          organisations.map((org: Organisation) => (
            <span key={org.id}>
              <div
                onClick={() => setSelectedOrg(org.name)}
                className="organisation-header-list"
                style={{ textDecoration: org?.name === selectedOrg ? "underline" : "none", color: org?.name === selectedOrg ? "#4ea1c5" : "#20363f" }}
              >
                {org.name.toUpperCase()}
              </div>
            </span>
          ))}

        <Button
          onClick={handleAddOrganisation}
          type="success"
          icon="add"
          className="add-organisation-button"
        />
      </div>

      {/* Add organisation form */}
      {!loading && showAddOrganisation && (
        <div className="html-blank-organisation">
          <div className="organisation-container">
            <div className="add-organisation generic-box-shadows">
              <AddOrganisation closeFunction={handleAddOrganisation} />
            </div>
          </div>
        </div>
      )}

      {/* Display or Edit organisation in the body */}
      {organisations &&
        !showAddOrganisation &&
        organisations
          .filter((org) => org.name === selectedOrg)
          .map((org, index) => (

            <DisplayOrganisation
              key={index}
              organisation={org}
            />
          ))}
    </div>
  )

})
