import { LoadPanel, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import ApiClientDisplay from "../apiClient/ApiClientDisplay";
import AccountInfo from "./AccountInfo";
import { router } from "../../app/router/Routes";
import { useSearchParams } from "react-router-dom";

const AccountPage = () => {
    const { accountStore } = useStore()
    const [searchParams] = useSearchParams()
    const selectedTab: string | null = searchParams.get('t')
    const [selectedTabIndex,
        setSelectedTabIndex] = useState<number | undefined>(undefined)

    /// React Hooks ////////

    useEffect(() => {
        accountStore.getApiClient()
        accountStore.getAccount()
    }, [accountStore])


    useEffect(() => {
        if (selectedTab) {
            const tabIndex = parseInt(selectedTab)
            setSelectedTabIndex(tabIndex)
        }
    }, [selectedTab])

    const handleSelection = (index: number) => {
        const url = `/account-page?t=${index}`
        router.navigate(url)
    }

    if (!accountStore.account || !accountStore.apiclient) {
        return <LoadPanel visible={true} />
    }

    const tabs = (
        <TabPanel
            onSelectedIndexChange={(e) => handleSelection(e)}
            selectedIndex={selectedTabIndex}>
            <Item title="Info">
                <AccountInfo />
            </Item>
            <Item title="API Client">
                <ApiClientDisplay />
            </Item>
        </TabPanel>

    )

    return (
        <Fragment>
            <div className='html-blank '>
                <div className="account-tab-container">
                    <span className="tab-container-title">
                        Account
                    </span>
                    {tabs}
                </div>
            </div>
        </Fragment>
    )
}

export default observer(AccountPage)