import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useCallback } from "react";
import { router } from "../../app/router/Routes";
import { Button } from "devextreme-react";

export default observer(function ServerError() {
  const { commonStore } = useStore();

  const backToHome = useCallback(() => {
    router.navigate("/");
  }, []);

  if (process.env.NODE_ENV === "development") {
    console.log(`Server Error: ${JSON.stringify(commonStore?.error)}`);
  }

  return (
    <div className="html-blank-server-error">
      <div className="content-block server-error">
        <div className="server-error-container">
          <h1>Whoops, something went wrong.</h1>
          <h2>
            We’re sorry, but it looks like there was an error. Our team is
            already working on fixing it.
          </h2>
          <h2>
            In the meantime, please feel free to contact our customer support
            for assistance. We’re here to help.
          </h2>
          <p className="server-error-paragraph">
            <Button icon="message"></Button> support@stratum.ie
          </p>
          <p className="server-error-paragraph">
            <Button icon="tel"></Button> +353 (0)1 405 0526
          </p>
          <p className="server-error-paragraph">
            Go
            <button className="server-error-return-button" onClick={backToHome}>
              back
            </button>
            to the home page.
          </p>
        </div>

        {process.env.NODE_ENV === "development" &&
          commonStore?.error?.Errors && (
            <div className="server-error-container">
              <div>
                <h5 className="server-error message">
                  commonStore?.error?.Message
                </h5>
                <h4 className="server-error stack-trace">Stack trace</h4>
                <code className="server-error details">
                  {commonStore.error.Errors}
                </code>
              </div>
            </div>
          )}
      </div>
    </div>
  );
});
