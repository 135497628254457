import FileUploader from 'devextreme-react/file-uploader'

import { ValueChangedEvent } from 'devextreme/ui/file_uploader'

interface Props {
    onValueChanged: (e: ValueChangedEvent) => void
    acceptMultiple?: boolean
    labelText?: string
    buttonText?: string
    allowedFileExtensions?: string[]
    maxFileSizeInBytes?: number
}

export const FileUploadForm = ({
    onValueChanged,
    acceptMultiple = false,
    labelText = 'Drag & Drop the desired file',
    buttonText = 'Select a file',
    allowedFileExtensions = ["*"],
    maxFileSizeInBytes = 4000000 // 4MB
}: Props) => {
    return (
        <div className="widget-container flex-box">
            <div>
                <FileUploader
                    multiple={acceptMultiple}
                    className='fileuploader-container'
                    labelText={labelText}
                    selectButtonText={buttonText}
                    onValueChanged={onValueChanged}
                    allowedFileExtensions={allowedFileExtensions}
                    maxFileSize={maxFileSizeInBytes}
                    uploadMode="useForm" >
                </FileUploader>
            </div>
        </div>
    )
}

