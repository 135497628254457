import DataGrid, { Column, CustomRule, Editing, Lookup } from "devextreme-react/data-grid"
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { countryOptions } from "../../../app/common/options/countryOptions"
import { DeclarationPlaces, FieldError } from "../../../app/models/declarationCreate"
import { locationTypeOptions } from "../../../app/common/options/cdsImportDeclaration/locationTypeOptions"
import { useEffect, useRef, useState } from "react"
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationPlaces;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityPlaces({formData, fieldErrors, isFieldVisible}: Props){
  const [isFormReady, setIsFormReady] = useState(false)
  const formRef = useRef<Form>(null)

  const currentValidateForm = () => {
    if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
      formRef.current.instance.validate()
    }
  }

  useEffect(() => {
    if(isFormReady)
      currentValidateForm()
  });

  const validationRules: {
    countryOfDispatchCodeRule: boolean
    countryOfDestinationCodeRule: boolean
    placeOfLoadingRule: boolean
    placeOfUnloadingRule: boolean
    identificationOfLocationRule: boolean
    typeOfLocationRule: boolean
    arrivalPortRule: boolean
  } = {
    countryOfDispatchCodeRule: isFieldValid("countryOfDispatchCode", fieldErrors),
    countryOfDestinationCodeRule: isFieldValid("countryOfDestinationCode", fieldErrors),
    placeOfLoadingRule: isFieldValid("placeOfLoading", fieldErrors),
    placeOfUnloadingRule: isFieldValid("placeOfUnloading", fieldErrors),
    identificationOfLocationRule: isFieldValid("identificationOfLocation", fieldErrors),
    typeOfLocationRule: isFieldValid("typeOfLocation", fieldErrors),
    arrivalPortRule: isFieldValid("arrivalPort", fieldErrors),
  };

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Places'} >
            <GroupItem colSpan={2}>
                <DataGrid dataSource={formData.countriesOfRouting} visible={isFieldVisible("countriesOfRouting")}>
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                        <Column dataField="country"><Lookup dataSource={countryOptions} displayExpr={'name'} valueExpr={'isoCode'} /></Column>
                </DataGrid>
            </GroupItem>
            <Item dataField="countryOfDispatchCode" label={{text: "Dispatch Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("countryOfDispatchCode")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode"
                    }}>
                      <CustomRule message={getErrorMessage("countryOfDispatchCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDispatchCodeRule}} />
                    </Item>
            <Item dataField="countryOfDestinationCode" label={{text: "Destination Country"}} editorType="dxSelectBox"
                    visible={isFieldVisible("countryOfDestinationCode")}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode"
                    }}>
                      <CustomRule message={getErrorMessage("countryOfDestinationCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDestinationCodeRule}} />
                    </Item>
            <Item dataField="placeOfLoading" label={{text: "Place of Loading"}} visible={isFieldVisible("placeOfLoading")}>
              <CustomRule message={getErrorMessage("placeOfLoading", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfLoadingRule}} />
            </Item>
            <Item dataField="placeOfUnloading" label={{text: "Place of Unloading"}} visible={isFieldVisible("placeOfUnloading")}>
              <CustomRule message={getErrorMessage("placeOfUnloading", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfUnloadingRule}} />
            </Item>
            <Item dataField="locationOfGoods.identificationOfLocation" label={{text: "Location ID"}} visible={isFieldVisible("locationOfGoods.identificationOfLocation")}>
              <CustomRule message={getErrorMessage("identificationOfLocation", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identificationOfLocationRule}} />
            </Item>
            <Item dataField="locationOfGoods.typeOfLocation" label={{text: "Location Type"}} editorType="dxSelectBox"
                    visible={isFieldVisible("locationOfGoods.typeOfLocation")}
                    editorOptions={{
                      items: locationTypeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                      <CustomRule message={getErrorMessage("typeOfLocation", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.typeOfLocationRule}} />
              </Item>
              <Item dataField="arrivalPort" label={{text: "Arrival Port"}} visible={isFieldVisible("arrivalPort")}>
              <CustomRule message={getErrorMessage("arrivalPort", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivalPortRule}} />
            </Item>
        </GroupItem>
    </Form>
}