import { Button, LoadIndicator, LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store'
import Form, { GroupItem, Item } from 'devextreme-react/form'
import { DeclarationGoods, DeclarationRequest } from '../../app/models/declarationCreate'
import { useEffect, useState } from 'react'
import { deepClone } from '../../app/common/utils/accountFunctions'
import { UpdateDeclarationRequest } from '../../app/models/declarationUpdate'
import { safetyAndSecurityDeclarationTypeOptions } from '../../app/common/options/cdsImportDeclaration/safetyAndSecurityDeclarationTypeOptions'
import { DefaultDeclarationObject } from '../../app/common/options/declarationTemplates/defaultDeclarationObject'
import { useParams } from 'react-router-dom'
import { router } from '../../app/router/Routes'
import { useDeclarationTemplate, useUpdateDeclarationTemplate } from '../../app/hooks/declarationQueries'
import { ServerError } from '../../app/models/serverError'
import notify from 'devextreme/ui/notify'
import AddGoods from '../declaration/AddGoods/AddGoods'
import SafetyAndSecurityMessageInformation from '../declaration/SafetyAndSecurity/SafetyAndSecurityMessageInformation'
import SafetyAndSecurityReferences from '../declaration/SafetyAndSecurity/SafetyAndSecurityReferences'
import SafetyAndSecurityParties from '../declaration/SafetyAndSecurity/SafetyAndSecurityParties'
import SafetyAndSecurityValuationInformationTaxes from '../declaration/SafetyAndSecurity/SafetyAndSecurityValuationInformationTaxes'
import SafetyAndSecurityDatesAndTimes from '../declaration/SafetyAndSecurity/SafetyAndSecurityDatesAndTimes'
import SafetyAndSecurityPlaces from '../declaration/SafetyAndSecurity/SafetyAndSecurityPlaces'
import SafetyAndSecurityCustomsOffices from '../declaration/SafetyAndSecurity/SafetyAndSecurityCustomsOffices'
import SafetyAndSecurityTransportInformation from '../declaration/SafetyAndSecurity/SafetyAndSecurityTransportInformation'
import CustomsSummary from '../declaration/CustomsSummary'
import SafetyAndSecurityGoodsView from '../declaration/SafetyAndSecurity/SafetyAndSecurityGoodsView'
import MessageInformation from '../declaration/MessgeInformaton'
import References from '../declaration/References'
import Parties from '../declaration/Parties'
import ValuationInformationTaxes from '../declaration/ValuationInformationTaxes'
import Places from '../declaration/Places'
import CustomsOffice from '../declaration/CustomsOffice'
import GoodsIdentification from '../declaration/GoodsIdentificaton'
import TransportInformation from '../declaration/TransportInformation'
import OtherDataElements from '../declaration/OtherDataElements'
import Goods from '../declaration/Goods'
import SafetyAndSecurityConsignment from '../declaration/SafetyAndSecurity/SafetyAndSecurityConsignment'
import { notifyMessage } from '../../app/common/utils/notify'

export default observer(function DeclarationTemplateForm() {
    const { declarationStore, organisationStore, userStore, commonStore } = useStore()
    const [addingGoods, setAddingGoods] = useState(false);
    const [selectedGoodsItem, setSelectedGoodsItem] = useState(-1);
    const { templateId } = useParams<{ templateId: string; }>()
    const { isLoading, isError, error } = useDeclarationTemplate(templateId)
    const updateDeclaration = useUpdateDeclarationTemplate()
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (isError) {
            let errorMsg = error instanceof Error ? error.message : error.toString()
            const serverError: ServerError = {
                Succeeded: false,
                Message: "Error retrieving declaration template",
                Errors: [errorMsg]
            }
            commonStore.setServerError(serverError)
            router.navigate('/server-error')
        }
    }, [isError, error, commonStore])

    useEffect(() => {
        organisationStore.getOrganisationMe()
    }, [declarationStore, organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    let initialIsImportBool = true;
    const [declarationObj, setDeclarationObj] = useState<DeclarationRequest>(DefaultDeclarationObject(organisationId, initialIsImportBool));
    
    if(templateId && declarationStore.declarationTemplate)
    {
        declarationObj.request.importDeclarationData = declarationStore.declarationTemplate;
    }

    const [formData, setFormData] = useState<DeclarationRequest>(declarationObj)
    const [selectedDecType, setSelectedDecType] = useState(formData.request.importDeclarationData.declarationType);
    const [selectedSecurity, setSelectedSecurity] = useState(formData.request.importDeclarationData.messageInformationEns?.security);
    const [selectedOrigin, setSelectedOrigin] = useState(formData.request.importDeclarationData.places.countryOfDispatchCode);
    const [selectedDestination, setSelectedDestination] = useState(formData.request.importDeclarationData.places.countryOfDispatchCode);
    const [expressFields, setExpressFields] = useState<string[]>();

    if(isLoading)
        return <LoadIndicator className="organisations-load-indicator" />

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      function findGoodsItemById() {
        if(selectedGoodsItem < 0)
            return null;

        let matchedItem = formData.request.importDeclarationData.goods.find((element) => {
          return element.itemDetail.messageInformation.goodsItemNumber === selectedGoodsItem;
        })

        if(matchedItem !== undefined)
            return matchedItem;

        return null;
      }

      function handleOpenAddGoods(){
        return <div className='html-blank-profile'>
                <div className='profile-container form-with-shadows-profile'>
                    <AddGoods goodsItemId={formData.request.importDeclarationData.goods.length+1} closeFunction={handleCloseAddGoods} formDataToUpdate={findGoodsItemById()} decType={formData.request.importDeclarationData.declarationType} summarySection={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                </div>
                </div>
    }

    function handleCloseAddGoods(goodsItemAdded: DeclarationGoods | null){
        if(goodsItemAdded !== null)
        {
            if(selectedGoodsItem > 0)
            {
                formData.request.importDeclarationData.goods = formData.request.importDeclarationData.goods.filter(a => a.itemDetail.messageInformation.goodsItemNumber !== selectedGoodsItem)
            }

            formData.request.importDeclarationData.goods.push(goodsItemAdded);
        }
        calculateTotalPackages();
        setSelectedGoodsItem(-1)
        setAddingGoods(false);
        
        setFormData(prevState => ({
                    organisationId: prevState.organisationId,
                    bucketId: prevState.bucketId,
                    authority: prevState.authority,
                    request: {
                    ...prevState.request,
                    importDeclarationData: {
                        ...prevState.request.importDeclarationData,
                        totalNumberPackages: formData.request.importDeclarationData.totalNumberPackages
                    }
                }
            }));
    }

    function handleRowRemoved(){
        handleCloseAddGoods(null);
    }

    function calculateTotalPackages(){
        let sumTotal: number = 0;
        formData.request.importDeclarationData.goods.forEach(element => {
            element.itemDetail.goodsIdentification.packages.forEach(a => sumTotal += (a.numberPackages === null ? 0 : a.numberPackages))
        });

        formData.request.importDeclarationData.totalNumberPackages = sumTotal;
    }

    function handleEditGoodsItem(goodsItemId: number)
    {
        setSelectedGoodsItem(goodsItemId)
        setAddingGoods(true)
    }

    if (!userStore.user) {
        return <LoadPanel visible={true} />
    }

    if (addingGoods){           
        return handleOpenAddGoods()
    }

function backToMain(){
    router.navigate(`/declaration-templates`)
}

    function cancelButtonClicked(){
        declarationStore.declarationTemplate = null;
        backToMain();
    }

    function handleValidation(){
        if(formData.request.importDeclarationData.lrn.length === 0)
        {
            notifyMessage("warning", "Template name is required");
            return false;
        }
        else if(formData.request.importDeclarationData.declarationType.length === 0)
        {
            notifyMessage("warning", "Declaration Type is required");
            return false;
        }
        else if(formData.request.importDeclarationData.additionalDeclarationType.length === 0)
        {
            notifyMessage("warning", "Additional Declaration Type is required");
            return false;
        }

        return true;
    }

    function handleExpressTemplate(){
        // express template will be an update to the template
        // so if validation does not pass the express fields will not be allowed to save 
        // therefore make sure template passes validation first 
        if(!handleValidation())
            return;

        router.navigate(`/express-template/${templateId}`)
    }

    function saveGoodsItem(){
        if(!handleValidation())
            return;
        
        setSubmitting(v => !v)
        handleUpdateDeclaration()
        setSubmitting(v => !v)
    }

    const handleUpdateDeclaration = async() => {
        console.log('hit', templateId)
        console.log('hit', formData)
        if(templateId && formData)
        {
            let updateDec: UpdateDeclarationRequest = {declarationId: templateId, request: deepClone(formData.request.importDeclarationData)};
            const response = await updateDeclaration.mutateAsync(updateDec)
            if(response && response.isSuccess)
            {
                backToMain()
            }
            else 
            {
                notify(response.error, 'error')
            }
        }
    }

    function isFieldVisible(){
        return true;
    }

    if(declarationObj.request.importDeclarationData.declarationType === "F10")
        return (<div className='html-blank-profile'>
        <div className='profile-container form-with-shadows-profile'>
        {submitting && <LoadIndicator className="organisations-load-indicator" />}
        {!submitting &&
            <form onSubmit={(e) => handleSubmit(e)}>
                <Form formData={formData}>
                    <GroupItem colCount={2} caption={'Entry Summary'} >
                        <GroupItem>
                            <Item
                                dataField="declarationType"
                                editorType="dxSelectBox" 
                                editorOptions={{
                                items: safetyAndSecurityDeclarationTypeOptions,
                                searchEnabled: true,
                                displayExpr: "description",
                                valueExpr: "name"
                                }}
                            />
                            <SafetyAndSecurityMessageInformation formData={formData.request.importDeclarationData.messageInformationEns} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityReferences formData={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isTemplate={true} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityParties formData={formData.request.importDeclarationData.parties} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityValuationInformationTaxes formData={formData.request.importDeclarationData.valuationInformationTaxes} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityDatesAndTimes formData={formData.request.importDeclarationData.places} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityPlaces formData={formData.request.importDeclarationData.places} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityCustomsOffices formData={formData.request.importDeclarationData.customsOffices} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            <SafetyAndSecurityTransportInformation formData={formData.request.importDeclarationData.transportInformation} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                        </GroupItem>
                        <GroupItem>
                            <CustomsSummary formData={formData.request.importDeclarationData} />
                            <Button onClick={saveGoodsItem} text='Update' disabled={submitting} />
                            <Button onClick={cancelButtonClicked} text='Cancel' disabled={submitting} />
                        </GroupItem>
                    </GroupItem>
                    <GroupItem colCount={1}>
                        <SafetyAndSecurityGoodsView formData={formData.request.importDeclarationData.goods} setAdding={setAddingGoods} setUpdate={handleEditGoodsItem} onRowDeleted={calculateTotalPackages} isFieldVisible={isFieldVisible} />
                    </GroupItem>
                </Form>
            </form>
        }
        </div >
    </div >)

    return (<div className='html-blank-profile'>
    <div className='profile-container form-with-shadows-profile'>
    {submitting && <LoadIndicator className="organisations-load-indicator" />}
    {!submitting &&
        <form onSubmit={(e) => handleSubmit(e)}>
            <Form formData={formData} >
                <GroupItem colCount={2} caption='Import'> 
                    <GroupItem>
                        <MessageInformation formData={formData.request.importDeclarationData} onDeclarationTypeChanged={setSelectedDecType} onSecurityChanged={setSelectedSecurity} isFieldVisible={isFieldVisible} />
                        <References formData={formData.request.importDeclarationData} isTemplate={true} isFieldVisible={isFieldVisible} />
                        <Parties formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <ValuationInformationTaxes formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <Places formData={formData.request.importDeclarationData} onOriginCountryChanged={setSelectedOrigin} onDestinationCountryChanged={setSelectedDestination} isFieldVisible={isFieldVisible} />
                        <CustomsOffice formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <GoodsIdentification formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <TransportInformation formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        {selectedDecType === "B1" && selectedSecurity != 0 && <SafetyAndSecurityConsignment formData={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />}
                        <OtherDataElements formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                    </GroupItem>
                    <GroupItem>
                        <CustomsSummary formData={formData.request.importDeclarationData} />
                        <Button onClick={saveGoodsItem} text='Update' disabled={submitting} />
                        <Button onClick={cancelButtonClicked} text='Cancel' disabled={submitting} />
                        <Button onClick={handleExpressTemplate} text='Express' />
                    </GroupItem>
                </GroupItem>
                <GroupItem colCount={1}>
                    <Goods formData={formData.request.importDeclarationData} setAdding={setAddingGoods} setUpdate={handleEditGoodsItem} onRowDeleted={handleRowRemoved} />
                </GroupItem>
            </Form>
        </form>
    }
    </div >
</div >)
})