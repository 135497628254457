import { ApiClient } from "../../app/models/account";
import { Position } from "devextreme-react/data-grid";
import { ToolbarItem, Popup } from "devextreme-react/popup";
import { confirm } from "devextreme/ui/dialog";
import { useStore } from "../../app/stores/store";
import { useState } from "react";

interface Props {
  ApiData: ApiClient;
  ShowInfo: boolean;
  HideScreen: () => void;
}

const ApiInfoPopup = ({ ApiData: formData, HideScreen, ShowInfo }: Props) => {
  const { accountStore } = useStore();
  const [pdfDownloaded, setpdfDownloaded] = useState(false);

  function UserReminder() {
    if (!pdfDownloaded) {
      let result = confirm(
        "<i>Are you sure you want to leave before saving the data?</i>",
        "PDF file"
      );
      console.log("Here");
      result.then((dialogResult) => {
        if (dialogResult) HideScreen();
      });
    } else HideScreen();
  }

  function SaveTextFile() {
    var data = `Account ID: ${accountStore.account?.id}
    \nOrganization ID: ${accountStore.account?.organisations[0]?.id ?? ""}
    \nClient ID: ${accountStore.apiclientID}
    \nClient Key: ${formData.clientKey}
    \nAudience: ${formData.audience}
    \nAPI's: ${formData.validIps.join(", ")}`;

    const blob = new Blob([data], { type: "text/plain" });

    const link = document.createElement("a");

    link.download = `${formData.clientKey}.txt`;

    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    setpdfDownloaded((prev) => !prev);
    HideScreen();
  }

  return (
    <div className="api-client-container">
      <Popup
        visible={ShowInfo}
        showTitle={false}
        container=".dx-viewport"
        minWidth={"300px"}
        minHeight={"300px"}
        width="auto"
        height="auto"
      >
        <Position at="center" my="center" collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "Close",
            onClick: () => UserReminder(),
          }}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "Save",
            onClick: () => SaveTextFile(),
          }}
        />
        <>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <strong>Account ID:</strong> {accountStore.account?.id}
            </p>
            <p>
              <strong>Organization ID:</strong>
              {accountStore.account?.organisations[0]?.id ?? ""}
            </p>
            <p>
              <strong>Client ID:</strong>
              {accountStore.apiclientID}
            </p>
            <p>
              <strong>Client Key:</strong> {formData?.clientKey}
            </p>
            <p>
              <strong>Audience:</strong> {formData?.audience}
            </p>
            {formData.validIps.map((ips: any, index: any) => (
              <p key={index}>
                <strong>API`s`:</strong> {ips}
              </p>
            ))}
          </div>
        </>
      </Popup>
    </div>
  );
};

export default ApiInfoPopup;
