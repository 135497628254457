import { Button, LoadIndicator, LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../app/stores/store'
import Form, { GroupItem } from 'devextreme-react/form'
import { useEffect, useState } from 'react'
import SafetyAndSecurityMessageInformation from './SafetyAndSecurityMessageInformation'
import SafetyAndSecurityReferences from './SafetyAndSecurityReferences'
import SafetyAndSecurityParties from './SafetyAndSecurityParties'
import SafetyAndSecurityValuationInformationTaxes from './SafetyAndSecurityValuationInformationTaxes'
import SafetyAndSecurityDatesAndTimes from './SafetyAndSecurityDatesAndTimes'
import SafetyAndSecurityPlaces from './SafetyAndSecurityPlaces'
import SafetyAndSecurityCustomsOffices from './SafetyAndSecurityCustomsOffices'
import SafetyAndSecurityTransportInformation from './SafetyAndSecurityTransportInformation'
import SafetyAndSecurityGoodsView from './SafetyAndSecurityGoodsView'
import SafetyAndSecurityGoodsEdit from './Goods/SafetyAndSecurityGoodsEdit'
import { notifyMessage } from '../../../app/common/utils/notify'
import { DeclarationSendToAuthority } from '../../../app/models/declarationSendToAuthority'
import CustomsSummary from '../CustomsSummary'
import { DeclarationGoods, DeclarationRequest } from '../../../app/models/declarationCreate'
import { deepClone } from '../../../app/common/utils/accountFunctions'
import { UpdateDeclarationRequest } from '../../../app/models/declarationUpdate'
import { DelcarationIsLrnUniqueRequest } from '../../../app/models/declarationIsLrnUniqueRequest'
import { DefaultDeclarationObject } from '../../../app/common/options/declarationTemplates/defaultDeclarationObject'
import { router } from '../../../app/router/Routes'
import { useParams } from 'react-router-dom'
import { useAddDeclaration, useDeclaration, useUpdateDeclaration } from '../../../app/hooks/declarationQueries'
import { ServerError } from '../../../app/models/serverError'
import notify from 'devextreme/ui/notify'

export default observer(function SafetyAndSecurityDeclaration() {
    const { declarationStore, userStore, organisationStore, commonStore } = useStore()
    const [addingGoods, setAddingGoods] = useState(false);
    const [selectedGoodsItem, setSelectedGoodsItem] = useState(-1);
    const { authoritySystem, id } = useParams<{ authoritySystem: string; id: string; }>()
    const { isLoading, isError, error } = useDeclaration(id)
    const addDeclaration = useAddDeclaration()
    const updateDeclaration = useUpdateDeclaration()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [expressView, setExpressView] = useState<boolean>(false)

    useEffect(() => {
        if (isError) {
            let errorMsg = error instanceof Error ? error.message : error.toString()
            const serverError: ServerError = {
                Succeeded: false,
                Message: "Error retrieving port clearance",
                Errors: [errorMsg]
            }
            commonStore.setServerError(serverError)
            router.navigate('/server-error')
        }
    }, [isError, error, commonStore])

    useEffect(() => {
        organisationStore.getOrganisationMe()
    }, [declarationStore, organisationStore, id]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    if(organisationId == null)
        organisationId = "";

    const [declarationObj, setDeclarationObj] = useState<DeclarationRequest>(DefaultDeclarationObject(organisationId, true));
    
      const addBtnText = (id && declarationStore.declaration) ? "Update" : "Save";

      if(authoritySystem)
        declarationObj.request.importDeclarationData.authoritySystem = authoritySystem

      if(id && declarationStore.declaration)
      {
        declarationObj.request.importDeclarationData = declarationStore.declaration;
      }

    const [formData, setFormData] = useState<DeclarationRequest>(declarationObj)

    if(isLoading)
        return <LoadIndicator className="organisations-load-indicator" />
        
    function handleSubmit(e: any) {
        e.preventDefault()
      }

      function findGoodsItemById() {
        if(selectedGoodsItem < 0)
            return null;

        let matchedItem = formData.request.importDeclarationData.goods?.find((element) => {
          return element.itemDetail.messageInformation.goodsItemNumber == selectedGoodsItem;
        })

        if(matchedItem !== undefined)
            return matchedItem;

        return null;
      }

    if (!userStore.user) {
        return <LoadPanel visible={true} />
    }

    if (addingGoods){           
        return handleOpenAddGoodsView()
    }

    function cancelButtonClicked(){
        declarationStore.declaration = null;
        backToMain();
    }

    function saveGoodsItem(){
        if(formData.request.importDeclarationData.lrn.length === 0)
        {
            notifyMessage("warning", "Local Reference Number is required")
        }
        else
        {
            setSubmitting(v => !v)
            if(id && declarationStore.declaration)
            {
                handleUpdateDeclaration();
            }
            else
            {
                handleCheckLrnUniqueness();
            }
            setSubmitting(v => !v)
        }
    }

    async function createDeclaration(){
        if(declarationStore.isLrnUniqueResult)
        {
            handleCreateDeclaration();
        }
        else
        {
            notifyMessage("warning", "Local Reference Number is not unique for previous 48 hours")
        }
    }

    const handleCreateDeclaration = async () => {		
		if(formData)
        {
            const response = await addDeclaration.mutateAsync(formData)
            if(response && response.isSuccess)
            {
                backToMain()
            }
            else if(response && response.isError)
            {
                notify(response.error, 'error')
            }
        }
	};

    const handleCheckLrnUniqueness = async () => {		
		let isLrnUniqueRequest : DelcarationIsLrnUniqueRequest = {organisationId: organisationId, localReferenceNumber: formData.request.importDeclarationData.lrn}
        await declarationStore.isLrnUnique(isLrnUniqueRequest)
		if (declarationStore.isSuccess) {
            createDeclaration()
		} 
        else {
            notifyMessage("warning", "Failed to check LRN uniqueness");
		}
	};

    function backToMain(){
        router.navigate(`/declarations-main`)
    }

    const handleUpdateDeclaration = async() => {
        if(id && formData)
        {
            let updateDec: UpdateDeclarationRequest = {declarationId: id, request: deepClone(formData.request.importDeclarationData)};
            const response = await updateDeclaration.mutateAsync(updateDec)
            if(response && response.isSuccess)
            {
                backToMain()
            }
            else if(response && response.isError)
            {
                notify(response.error, 'error')
            }
        }
    }

    const handleSendToAuthority = async() => {
        if(!id)
            return;

        let sendToAuthority: DeclarationSendToAuthority = {declarationId: id}
        await declarationStore.sendToAuthority(sendToAuthority)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Sent to authority");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to send");
    }

    const handleGetStatusUpdate = async() => {
        if(!id)
            return;

        await declarationStore.getStatusUpdate(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Status update requested");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to request status update");
    }

    function getNextGoodsItemId(){
        let numberValues = formData.request.importDeclarationData.goods.map(item => item.itemDetail.messageInformation.goodsItemNumber);
        let highestGoodsItemId = 0;
        numberValues.map((el) => {
            highestGoodsItemId = Math.max(highestGoodsItemId, el ? el: 0)
        })

        return highestGoodsItemId;
    }

    function handleOpenAddGoodsView(){
        return <div className='html-blank-profile'>
                <div className='profile-container form-with-shadows-profile'>
                    <SafetyAndSecurityGoodsEdit formData={findGoodsItemById()} importData={formData} goodsItemId={getNextGoodsItemId()+1} closeFunction={handleCloseAddGoods} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible}/>
                </div>
                </div>
    }

    function handleCloseAddGoods(goodsItemAdded: DeclarationGoods | null){
        if(goodsItemAdded !== null)
        {
            if(selectedGoodsItem > 0)
            {
                formData.request.importDeclarationData.goods = formData.request.importDeclarationData.goods.filter(a => a.itemDetail.messageInformation.goodsItemNumber !== selectedGoodsItem)
            }

            formData.request.importDeclarationData.goods.push(goodsItemAdded);
        }
        setSelectedGoodsItem(-1)
        setAddingGoods(false);
        setFormData(formData);
    }

    function handleEditGoodsItem(goodsItemId: number)
    {
        setSelectedGoodsItem(goodsItemId)
        setAddingGoods(true)
    }

    function calculateTotalPackages(){
        let sumTotal: number = 0;
        formData.request.importDeclarationData.goods.forEach(element => {
            element.itemDetail.goodsIdentification.packages.forEach(a => sumTotal += (a.numberPackages === null ? 0 : a.numberPackages))
        });
        formData.request.importDeclarationData.totalNumberPackages = sumTotal;
    }

    function handleExpressView(){
        setExpressView(!expressView)
    }

    const handleDeleteAuthority = async() => {
        if(!id)
            return;

        await declarationStore.deleteAuthority(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Authority link deleted");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to remove authority link");
    }

    const handleSubmitTss = async() => {
        if(!id)
            return;

        await declarationStore.submitToTss(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Submitted to TSS");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to submit to TSS");
    }

    const handleGetSfd = async() => {
        if(!id)
            return;

        await declarationStore.getSfd(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Received SFD");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to retrieve SFD");
    }

    function isFieldVisible(fieldName: string) {
        // if there is no linked template, then all fields should appear 
        if(!expressView)
            return true;
 
         const expressFields = declarationStore.declaration?.expressFields
         if(!expressFields || expressFields.length < 1)
             return true;
 
         let matchedItem3 = expressFields.find((element) => {
             return element.name === fieldName
         })
 
         if(matchedItem3 !== undefined)
             return true;
 
         return false;
    }

    if(declarationStore.loading)
        return <LoadIndicator className="organisations-load-indicator" />

    const showSubmitBtn = declarationObj.request.importDeclarationData.authoritySystem === "TSS" && declarationObj.request.importDeclarationData.sentToAuthority && !declarationObj.request.importDeclarationData.authoritySystemSubmitted;
    const showGetSfdBtn = declarationObj.request.importDeclarationData.authoritySystem === "TSS" && declarationObj.request.importDeclarationData.authoritySystemSubmitted;
    const declarationCaption = ((formData.request.importDeclarationData.authoritySystem === "TSS" && formData.request.importDeclarationData.additionalDeclarationType === "D") && 'Simplified Frontier Declaration') || 'Entry Summary';

    return (
        <div className='html-blank-profile'>
            <div className='profile-container form-with-shadows-profile'>
            {submitting && <LoadIndicator className="organisations-load-indicator" />}
            {!submitting &&
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Form formData={formData}>
                        <GroupItem colCount={2} caption={declarationCaption} >
                            <GroupItem>
                                <SafetyAndSecurityMessageInformation formData={formData.request.importDeclarationData.messageInformationEns} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityReferences formData={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isTemplate={false} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityParties formData={formData.request.importDeclarationData.parties} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityValuationInformationTaxes formData={formData.request.importDeclarationData.valuationInformationTaxes} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityDatesAndTimes formData={formData.request.importDeclarationData.places} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityPlaces formData={formData.request.importDeclarationData.places} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityCustomsOffices formData={formData.request.importDeclarationData.customsOffices} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                                <SafetyAndSecurityTransportInformation formData={formData.request.importDeclarationData.transportInformation} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                            </GroupItem>
                            <GroupItem>
                                <CustomsSummary formData={formData.request.importDeclarationData} />
                                <Button onClick={saveGoodsItem} text={addBtnText} disabled={submitting} />
                                <Button onClick={cancelButtonClicked} text='Cancel' disabled={submitting} />
                                <Button onClick={handleExpressView} text={(expressView) ? 'Express off' : 'Express on'} />
                                {id && <Button onClick={handleSendToAuthority} text='Send to Authority' disabled={submitting} visible={!declarationObj.request.importDeclarationData.sentToAuthority} />}
                                {id && <Button onClick={handleSubmitTss} text='Submit to TSS' disabled={submitting} visible={showSubmitBtn} />}
                                {id && <Button onClick={handleGetSfd} text='Get SFD' disabled={submitting} visible={showGetSfdBtn} />}
                                {id && <Button onClick={handleGetStatusUpdate} text='Get Status Update' disabled={submitting} visible={declarationObj.request.importDeclarationData.sentToAuthority} />}
                                {id && <Button onClick={handleDeleteAuthority} text='Cancel Authority' disabled={submitting} visible={declarationObj.request.importDeclarationData.sentToAuthority} />}
                            </GroupItem>
                        </GroupItem>
                        <GroupItem colCount={1}>
                            <SafetyAndSecurityGoodsView formData={formData.request.importDeclarationData.goods} setAdding={setAddingGoods} setUpdate={handleEditGoodsItem} onRowDeleted={calculateTotalPackages} isFieldVisible={isFieldVisible} />
                        </GroupItem>
                    </Form>
                </form>
            }
            </div >
        </div >
    )
})