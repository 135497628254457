import Form, { GroupItem, Item, SimpleItem, } from 'devextreme-react/form'
import { declarationTypeImportOptions } from '../../app/common/options/cdsImportDeclaration/declarationTypeOptions'
import { additionalDeclarationTypeOptions } from '../../app/common/options/cdsImportDeclaration/additionalDeclarationTypeOptions'
import { DecalarationData } from '../../app/models/declarationCreate'
import { declarationTypeExportOptions } from '../../app/common/options/cdsImportDeclaration/declarationTypeExportOptions'
import { useEffect, useRef, useState } from 'react'
import { declarationTypeExsOptions } from '../../app/common/options/cdsImportDeclaration/declarationTypeExsOptions'
import { additionalDeclarationTypeExsOptions } from '../../app/common/options/cdsImportDeclaration/additionalDeclarationTypeExsOptions'
import { declarationSecurityOptions } from '../../app/common/options/safetyAndSecurity/declarationSecurityOptions'
import { CustomRule } from 'devextreme-react/data-grid'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    onDeclarationTypeChanged: (decType: string) => void;
    onSecurityChanged: (security: number) => void;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function MessageInformation({formData, onDeclarationTypeChanged, onSecurityChanged, isFieldVisible}: Props){
    const ref = useRef<number>(formData.totalNumberPackages);
    const [isMessageInfoReady, setIsMessageInfoReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       ref.current = formData.totalNumberPackages;
    }, [formData]);


    useEffect(() => {
        if(isMessageInfoReady){
            currentValidateForm()
        }
    })

    const validationRules: {
        declarationTypeRule: boolean,
        additionalDeclarationTypeRule: boolean,
        totalNumberPackagesRule: boolean,
        securityRule: boolean
      } = {
        declarationTypeRule: isFieldValid("declarationType", formData.fieldErrors),
        additionalDeclarationTypeRule: isFieldValid("additionalDeclarationType", formData.fieldErrors),
        totalNumberPackagesRule: isFieldValid("totalNumberPackages", formData.fieldErrors),
        securityRule: isFieldValid("security", formData.fieldErrors)
        };

    function handleDeclarationTypeChanged(){
        onDeclarationTypeChanged(formData.declarationType)
    }

    function handleSecurityChanged(){
        if(formData.messageInformationEns?.security != null)
            onSecurityChanged(formData.messageInformationEns.security)
    }

    return <Form ref={formRef} formData={formData} onContentReady={() => setIsMessageInfoReady(true)}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Message Information'} visible={isFieldVisible("section-messageinformation")}>
                <SimpleItem 
                    dataField="declarationType"
                    editorType="dxSelectBox" 
                    visible={isFieldVisible("declarationType")}
                    editorOptions={{
                      items: (formData.additionalDeclarationType == 'J') && declarationTypeExsOptions || ((formData.isImport) && declarationTypeImportOptions || declarationTypeExportOptions),
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "name",
                      onValueChanged: handleDeclarationTypeChanged
                    }}
                >
                        <CustomRule message={getErrorMessage("declarationType", formData.fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarationTypeRule}} />
                    </SimpleItem>
                {formData.authoritySystem !== "TSS" && <Item
                    dataField="additionalDeclarationType"
                    editorType="dxSelectBox"
                    visible={isFieldVisible("additionalDeclarationType")}
                    editorOptions={{
                      items: (formData.additionalDeclarationType == 'J') && additionalDeclarationTypeExsOptions || (additionalDeclarationTypeOptions),
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "name",
                    }}
                >
                    <CustomRule message={getErrorMessage("additionalDeclarationType", formData.fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalDeclarationTypeRule}} />
                </Item>}
                <Item
                    dataField="totalNumberPackages"   
                    visible={isFieldVisible("totalNumberPackages")}
                    editorOptions={{ readOnly: true, ref: ref}}
                >
                    <CustomRule message={getErrorMessage("totalNumberPackages", formData.fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.totalNumberPackagesRule}} />
                </Item>
                {formData.declarationType == "B1" && <SimpleItem dataField="messageInformationEns.security" label={{text: "Security"}} editorType="dxSelectBox"
                    visible={isFieldVisible("messageInformationEns.security")}
                    editorOptions={{
                      items: declarationSecurityOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                      onValueChanged: handleSecurityChanged
                    }}>
                        <CustomRule message={getErrorMessage("security", formData.fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.securityRule}} />
                    </SimpleItem>
                    }
                <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        </Form>
}