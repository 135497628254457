import { UserRole } from '../../models/user'

export const userRoles = {
    owners: [String(UserRole.AccountOwner)],
    admins: [
        String(UserRole.AccountOwner),
        String(UserRole.ProductAdmin),
        String(UserRole.OrganisationAdmin)
    ],
    productAdmins: [
        String(UserRole.AccountOwner),
        String(UserRole.ProductAdmin)
    ],
    users: [String(UserRole.User)],
    all: [
        String(UserRole.AccountOwner),
        String(UserRole.ProductAdmin),
        String(UserRole.OrganisationAdmin),
        String(UserRole.User)
    ]
}


export function UserRoleToDisplay(role: UserRole) {
    switch (role) {
        case UserRole.AccountOwner:
            return 'Account Owner'
        case UserRole.ProductAdmin:
            return 'Product Admin'
        case UserRole.OrganisationAdmin:
            return 'Organisation Admin'
        case UserRole.User:
            return 'User'
        default:
            return 'User'
    }

}