import { Button, LoadPanel } from 'devextreme-react'
import { useStore } from '../../../app/stores/store'
import Form, { GroupItem } from 'devextreme-react/form'
import { DecalarationData, DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import AddGoodsMessageInformation from './AddGoodsMessageInformation'
import AddGoodsReference from './AddGoodsReference'
import AddGoodsParties from './AddGoodsParties'
import AddGoodsValuationInformationTaxes from './AddGoodsValuationInformationTaxes'
import AddGoodsPlaces from './AddGoodsPlaces'
import AddGoodsIdentification from './AddGoodsIdentification'
import AddGoodsTransportInformation from './AddGoodsTransportInformation'
import AddGoodsOtherDataElements from './AddGoodsOtherDataElements'
import CustomsSummary from '../CustomsSummary'

interface Props {
    closeFunction: (goodsItemAdded: DeclarationGoods | null) => void;
    formDataToUpdate: DeclarationGoods | null;
    goodsItemId: number;
    decType: string;
    summarySection: DecalarationData;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
  }
  
  function AddGoods({ closeFunction, formDataToUpdate, goodsItemId, decType, summarySection, fieldErrors, isFieldVisible }: Props) {
    const { commonStore, userStore } = useStore()

    if (!userStore.user) {
      return <LoadPanel visible={true} />
    }

    const addBtnText = (formDataToUpdate == null) ? "Add": "Update";

    if(formDataToUpdate == null)
      formDataToUpdate = {
        itemDetail: {
          messageInformation: {
            goodsItemNumber: goodsItemId,
            procedures: null,
            additionalProcedure: null
          },
          references: {
            references: [],
            additionalReference: {
              code: null,
              description: null
            },
            ucrNumber: null,
            referencesEns: {
              commercialReferenceNumber: '',
              additionalInformation: '',
              conveyanceReferenceNumber: '',
              transportDocumentNumber: '',
              transportDocumentType: ''
          }
          },
          referencesEns: {
            commercialReferenceNumber: '',
            additionalInformation: '',
            conveyanceReferenceNumber: '',
            transportDocumentNumber: '',
            transportDocumentType: ''
        },
          parties: {
            exporter: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            importer :{
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            seller: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            buyer: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            representative: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null,
              status: null
            },
            declarant: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            carrier: {
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            notifyParty:{
              name: null,
              eori: null,
              street: null,
              city: null,
              country: null,
              postcode : null,
              phone: null
            },
            additionalSupplyChainActorId: null,
            additionalSupplyChainActorRole: null
          },
          valuationInformationTaxes: {
            methodOfPayment: '',
            personPayingCustomsDuty: '',
            personProvidingGuarantee: '',
            warehouseType: '',
            valuationMethod: null,
            preference: null,
            taxBaseAmount: [],
            calculationOfTaxes: {
              payableTaxAmount: [],
              totalTaxCalculated: null,
              currency: null,
              itemPriceAmount: null,
              valuationIndicators: null,
              additionsAndReductions: [],
              totalAmountInvoiced: null
            }
          },
          places:{
            countryDestination: null,
            countryDispatch: null,
            countryOfOrigin: null,
            countryOfPreferentialOrigin: null,
            countryOfOriginType: null,
            countryOfPreferentialOriginType: null,
            arrivalAtFirstPlace: null,
            countriesOfRouting: null,
            placeOfLoading: null,
            placeOfUnloading: null
          },
          goodsIdentification:{
            packages: [],
            commodityCode:{
              description: null,
              combinedNomenclatureCode: null,
              taricCode: null,
              cusCode: null,
              cusType: null,
              taricAdditionalCode: null,
              nationalAdditionalCode: null
            },
            supplementaryUnits: null,
            netMass: null,
            grossMass: null,
            numberOfPieces: null,
            unDangerous: null
          },
          transportInformation: {
            containerId: null,
            sealNumber: []
          },
          otherDataElements: {
            natureOfTransaction: null,
            quotaOrderNumber: null,
            statisticalValue: null
          },
          summary:{
             customsRequested: null,
             mrn: null,
             jobStatus: null,
             customs: null,
             haulierRequest: null,
             haulierResponse: null,
             fromTo: null,
             lastUpdate: null,
             authorityMessage: null
          }
        }
    }

  function cancelGoodsItem(){
    closeFunction(null);
  }

    function saveGoodsItem(){
      if(formDataToUpdate != null)
        closeFunction(formDataToUpdate);
    }
    
    return <Form formData={formDataToUpdate} >
            <GroupItem colCount={2}>
                <GroupItem colCount={1}>
                  <AddGoodsMessageInformation formData={formDataToUpdate} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsReference formData={formDataToUpdate?.itemDetail.references} goodsItemId={goodsItemId} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsParties formData={formDataToUpdate} decType={decType} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsValuationInformationTaxes formData={formDataToUpdate} decType={decType} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsPlaces formData={formDataToUpdate} decType={decType} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsIdentification formData={formDataToUpdate} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible} />
                  <AddGoodsTransportInformation formData={formDataToUpdate} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible}/>
                  <AddGoodsOtherDataElements formData={formDataToUpdate} decType={decType} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible}/>
                </GroupItem>
                <GroupItem colCount={1} >
                  <CustomsSummary formData={summarySection}/>
                  <Button onClick={saveGoodsItem} text={addBtnText} />
                  <Button onClick={cancelGoodsItem} text='Cancel' />
                </GroupItem>
            </GroupItem>
          </Form>
  }

  export default AddGoods;