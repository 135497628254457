import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { AuthProvider } from './app/contexts/AuthContext'
import { router } from './app/router/Routes'
import { store, StoreContext } from './app/stores/store'
import './app/layout/styles/dx.material.myc.style.css'
import './app/layout/styles/styles.css'
import reportWebVitals from './reportWebVitals'
import { NavigationProvider } from './app/contexts/NavContect'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StoreContext.Provider value={store}>
        <AuthProvider>
          <NavigationProvider>
            <RouterProvider router={router} />
            <ReactQueryDevtools />
          </NavigationProvider>
        </AuthProvider>
      </StoreContext.Provider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
