import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { notifyMessage } from "../../app/common/utils/notify";
import { router } from "../../app/router/Routes";
import { useEffect, useState } from "react";
import { Form, GroupItem, Item, Label, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import { Tab } from "devextreme-react/form";
import { AddressTypesOptions } from "../../app/common/options/addressTypeOptions";
import { countryOptions } from "../../app/common/options/countryOptions";
import { Button, DataGrid, LoadIndicator, Popup } from "devextreme-react";
import { Column, Editing, Paging, Texts } from "devextreme-react/data-grid";
import { GatesTypesOptions } from "../../app/common/options/gatesTypeOptions";
import { Address, CreateAddress, UpdateAddress } from "../../app/models/address";
import { deepClone } from "../../app/common/utils/accountFunctions";
import { personaTypeOptions } from "../../app/common/options/personaTypeOptions";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export default observer(function AddressForm() {
    const { id } = useParams<{ id: string }>()
    const { addressStore, organisationStore, customerStore, supplierStore } = useStore()
    const [accountType, setAccountType] = useState<string>("")

    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore])

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault))

    useEffect(() => {
        {orgDefault && customerStore.getCustomers(orgDefault.id);}
        {orgDefault && supplierStore.getSuppliers(orgDefault.id);}
        {id && handleGetAddress()}
    }, [addressStore, customerStore, supplierStore, id]);

    const iniAddressValue: Address = {
        id: '',
        code: '',
        name: '',
        eori: '',
        address1: '',
        address2: '',
        city: '',
        region: '',
        postcode: '',
        countryIsoCode: orgDefault?.countryIsoCode ?? "IE",
        latitude: 0,
        longitude: 0,
        notes: '',
        addressType: '',
        personaId: '',
        personaName: '',
        personaCode: '',
        areas: [],
        tags: [],
        personaAddressAreas: [],
        personaAddressTags: [],
        accountType: 'Customer'
    };
    const [address, setAddress] = useState<Address>(iniAddressValue);

    const handleGetAddress = async () => {		
      if(!id)
      {
          notifyMessage("warning", "Invalid address");
          handleFormClose();
      }
      {id && await addressStore.get(id)}
      if (!addressStore.isSuccess) {
          notifyMessage("warning", "Failed to get address");
          handleFormClose();
      }
      else if(addressStore.address)
      {
        setAddress(addressStore.address)
      }
    };

    function handleFormClose() {
        router.navigate(`/addresses`)
      }

      function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
            handleFormSubmit()
        }
    }

      function handleFormSubmit() {
        if(address?.id)
            handleUpdateAddress();
        else
            handleCreateAddress();
    }

    const handleUpdateAddress = async() => {
        const updateAddress: UpdateAddress = deepClone(address);
        updateAddress.personaAddressAreas = updateAddress.areas;
        updateAddress.personaAddressTags = updateAddress.tags;

        await addressStore.update(updateAddress);
        if(addressStore.isSuccess)
        {
            notifyMessage("success", "Updated")
            handleFormClose();
        }
        else
            notifyMessage("warning", "Failed to update")
    }

    const handleCreateAddress = async() => {
        if(!address)
            return;

        const createAddress: CreateAddress = {
            personaId: address.personaId,
            code: address.code,
            name: address.name,
            eori: address.eori,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            region: address.region,
            postcode: address.postcode,
            countryIsoCode: address.countryIsoCode,
            latitude: address.latitude,
            longitude: address.longitude,
            notes: address.notes,
            addressType: address.addressType,
            tags: [],
            areas: [],
            personaAddressAreas: address.areas,
            personaAddressTags: address.tags
        };
        
        await addressStore.create(createAddress)
        if(addressStore.isSuccess)
        {
            notifyMessage("success", "Created address")
            handleFormClose()
        }
        else
            notifyMessage("warning", "Failed to create address")
    }


    function handleAccountTypeChange(e: ValueChangedEvent){
        setAccountType(e.value)
    }


    if(addressStore.loading || customerStore.loading || supplierStore.loading)
        return <LoadIndicator className="organisations-load-indicator" />

    if(customerStore.customers == null || supplierStore.suppliers == null)
    {
        notifyMessage("warning", "Could not retrieve data")
        handleFormClose()
    }

    return(
        <div className='content-block'>
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
                </div>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>Address</h4>
            </div>

            <div className={'content-block'}>
                <Form
                    id={'form-address'}
                    formData={address}
                    labelLocation={'top'}
                    showValidationSummary={true}
                    validationGroup={'addressFormValidation'}
                >
                    <TabbedItem>
                        <TabPanelOptions deferRendering={false} tabIndex={0} />
                        <Tab title="Address" icon="map">
                        <GroupItem caption={'Address'} >
                                {!id && <Item
                                    dataField="accountType"
                                    editorType="dxSelectBox"
                                    colSpan={2}
                                    isRequired={true}
                                    editorOptions={{
                                        items: personaTypeOptions,
                                        searchEnabled: false,
                                        placeholder: address.accountType,
                                        onValueChanged: handleAccountTypeChange
                                    }}>
                                    <Label text="Account type" />
                                </Item>}
                                {!id && <Item
                                    dataField="personaId"
                                    editorType="dxSelectBox"
                                    text={address.accountType}
                                    colSpan={2}
                                    isRequired={true}
                                    editorOptions={{
                                        items: (address.accountType == "Customer") ? customerStore.customers : supplierStore.suppliers,
                                        searchEnabled: false,
                                        displayExpr: "name",
                                        valueExpr: "id",
                                        placeholder: address.accountType
                                    }}>
                                    <Label text={address.accountType} />
                                </Item>}
                                <Item
                                    dataField="addressType"
                                    editorType="dxSelectBox"
                                    text={"Type"}
                                    colSpan={2}
                                    isRequired={true}
                                    editorOptions={{
                                        items: AddressTypesOptions,
                                        searchEnabled: false,
                                        displayExpr: "description",
                                        valueExpr: "value",
                                        placeholder: "Type..."
                                    }}>
                                    <Label text="Type" />
                                </Item>
                                <Item dataField="code" caption={"Code"} isRequired={true}>
                                    <Label text="Code" />
                                </Item>
                                <Item dataField="name" caption={"Name"} isRequired={true}>
                                    <Label text="Name" />
                                </Item>
                                <Item dataField="eori" caption={"EORI"}>
                                    <Label text="EORI" />
                                </Item>
                                <Item dataField="address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                                    <Label text="Address 1" />
                                </Item>
                                <Item dataField="address2" caption={"Address 2"} colSpan={2}>
                                    <Label text="Address 2" />
                                </Item>
                                <Item dataField="city" title={"City"}>
                                    <Label text="City" />
                                </Item>
                                <Item dataField="region" title={"Region"}>
                                    <Label text="County/Region" />
                                </Item>
                                <Item dataField="postcode" text={"Postcode"}>
                                    <Label text="Postcode" />
                                </Item>
                                <Item
                                    dataField="countryIsoCode"
                                    editorType="dxSelectBox"
                                    text={"Country"}
                                    editorOptions={{
                                        items: countryOptions,
                                        searchEnabled: true,
                                        displayExpr: "name",
                                        valueExpr: "isoCode",
                                        placeholder: "Select a country..."
                                    }}>
                                    <Label text="Country" />
                                </Item>
                                <Item dataField="latitude" title={"Latitude"}>
                                    <Label text="Latitude" />
                                </Item>
                                <Item dataField="longitude" title={"Longitude"} colSpan={2} >
                                    <Label text="Longitude" />
                                </Item>
                                <Item dataField="notes" title={"Notes"} colSpan={2} >
                                    <Label text="Notes" />
                                </Item>
                                <Item caption={"Tags"} colSpan={2} >
                                    <Label text="Tags" />
                                     <DataGrid
                                className='stratum-grid'
                                dataSource={address?.tags ?? []}
                                showBorders={true}
                            >
                                <Paging enabled={false} />
                                <Editing
                                    refreshMode='reshape'
                                    mode="popup"
                                    allowUpdating={true}
                                    allowAdding={true}
                                    allowDeleting={true}
                                >
                                    <Texts
                                        saveRowChanges="ADD"
                                        cancelRowChanges="CANCEL"
                                    />
                                    <Popup title="Tags" showTitle={true} />
                                    <Form>
                                        <Item itemType="group" colCount={2} colSpan={2} >
                                            <Item dataField="description" />
                                        </Item>
                                    </Form>
                                </Editing>
                                <Column dataField="description" />
                            </DataGrid>
                                </Item>

                                <Item>
                                    <Button
                                        icon="check"
                                        type="success"
                                        stylingMode="contained"
                                        validationGroup='addressFormValidation'
                                        onClick={(e) => handleSaveButton(e)}
                                    />{' '}
                                    <Button
                                        icon="close"
                                        type="danger"
                                        stylingMode="contained"
                                        onClick={() => handleFormClose()}
                                    />
                                </Item>
                            </GroupItem>
                        </Tab>
                        <Tab title='Gates/Areas' icon="deleterow">
                        <DataGrid
                                className='stratum-grid'
                                dataSource={address?.areas ?? []}
                                keyExpr="name"
                                showBorders={true}
                            >
                                <Paging enabled={false} />
                                <Editing
                                    refreshMode='reshape'
                                    mode="popup"
                                    allowUpdating={true}
                                    allowAdding={true}
                                    allowDeleting={true}
                                >
                                    <Texts
                                        saveRowChanges="ADD"
                                        cancelRowChanges="CANCEL"
                                    />
                                    <Popup title="Gates and areas" showTitle={true} width={700} height={600} />
                                    <Form>
                                        <Item itemType="group" colCount={2} colSpan={2} >
                                            <Item dataField="type"
                                                editorType='dxSelectBox'
                                                text={"Type"}
                                                colSpan={2}
                                                isRequired={true}
                                                editorOptions={{
                                                    tabIndex: 0,
                                                    items: GatesTypesOptions,
                                                    searchEnabled: false,
                                                    displayExpr: "description",
                                                    valueExpr: "value",
                                                    placeholder: "Select a type..."
                                                }}>
                                                <Label text="Type" />
                                            </Item>
                                            <Item dataField="name" colSpan={2} isRequired={true}>
                                                <Label text="Location" />
                                            </Item>
                                            <Item dataField="notes" colSpan={2} />
                                            <Item dataField="latitude" />
                                            <Item dataField="longitude" />
                                        </Item>
                                    </Form>
                                </Editing>
                                <Column dataField="name" />
                                <Column dataField="type" lookup={{
                                    dataSource: GatesTypesOptions,
                                    valueExpr: "value",
                                    displayExpr: "description"
                                }} >
                                </Column>
                                <Column dataField="notes" />
                                <Column dataField="latitude" visible={false} />
                                <Column dataField="longitude" visible={false} />
                            </DataGrid>
                            <div className='grid-lower-buttons'>
                                <Button
                                    icon="check"
                                    type="success"
                                    stylingMode="contained"
                                    validationGroup='addressFormValidation'
                                    onClick={(e) => handleSaveButton(e)}
                                />{' '}
                                <Button
                                    icon="close"
                                    type="danger"
                                    stylingMode="contained"
                                    onClick={() => handleFormClose()}
                                />
                            </div>
                        </Tab>
                    </TabbedItem>
                </Form>
            </div>
        </div>
    )
})