import { useStore } from '../../app/stores/store'
import { observer } from 'mobx-react-lite';
import { Button, DataGrid, LoadPanel } from 'devextreme-react';
import { Button as BntDG, Column, Editing, FilterRow, Position, Scrolling } from 'devextreme-react/data-grid';
import { useState } from 'react';
import { ToolbarItem, Popup } from 'devextreme-react/popup';
import AddApiclient from './AddApiclient';
import EditingApiClient from './EditingApiClient';
import { ApiClient } from '../../app/models/account';
import { onToolbarPreparing } from '../../app/common/utils/toolbar';

const ApiClientDisplay = () => {
  const { accountStore } = useStore()
  const [showPopup, setShowPopup] = useState(false);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false)
  const [editable, setEditable] = useState<ApiClient>({
    "id": '',
    "clientKey": "",
    "audience": process.env.REACT_APP_AUTH0_AUDIENCE,
    "validIps": []
  })

  const handleButtonClick = (cellData: any) => {
    setEditable(cellData.row.data)
    setShowPopup(true)
  };

  const handeEdditClick = (cellData: any) => {
    setEditable(cellData.row.data)
    setEditing(true)
  }
  

  const renderButton = (cellData: any) => {
    return (
      <>
        <Button
          width={100}
          text="Contained"
          type="normal"
          stylingMode="contained"
          onClick={() => handleButtonClick(cellData)}
        >
          Details
        </Button>
      </>

    )
  };

  if (adding) return <AddApiclient closeFunction={() => setAdding(false)} />

  if (editing) return <EditingApiClient formData={editable} closeFunction={() => setEditing(false)} />

  return (
    <div className='api-client-container'>
      <DataGrid
        dataSource={accountStore.apiclient}
        showBorders={true}
        remoteOperations={true}
        onInitNewRow={() => setAdding(true)}
        onToolbarPreparing={(e) => onToolbarPreparing(e, adding, setAdding)}
      >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" />
        <Editing
          allowUpdating={true}
          allowAdding={true}
        />
        <Column
          dataField="clientKey"
        />
        <Column
          dataField="validIps"
        />
        <Column 
        visible={false}
        width={150}
        cellRender={e => renderButton(e)} 
        />
        <Column type="buttons">
          <BntDG name="edit" onClick={(e: any) => handeEdditClick(e)} />
        </Column>
      </DataGrid >

      <Popup
        visible={showPopup}
        showTitle = {false}
        onHiding={() => setShowPopup(false)}
        dragEnabled={true}
        container=".dx-viewport"
        minWidth={"300px"}
        minHeight={"300px"}
        width="auto"
        height="auto"
      >
        <Position
          at="center"
          my="center"
          collision="fit"
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: 'Close',
            onClick: () => setShowPopup(false),
          }}
        />
        <>
          <div style={{ marginBottom: "10px" }}>
            <p><strong>Account ID:</strong> {accountStore.account?.id}</p>
            <p><strong>Organization ID:</strong> {accountStore.account?.organisations[0]?.id ?? ""}</p>
            <p><strong>Client Key:</strong> {editable?.clientKey}</p>
            <p><strong>Audience:</strong> {editable?.audience}</p>
            {editable.validIps.map((ips: any, index: any) => (
              <p key={index}><strong>API`s`:</strong> {ips}</p>
            ))}
          </div>
        </>
    
      </Popup>
    </div >

  )

}

export default observer(ApiClientDisplay)
