import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { DataGrid } from 'devextreme-react'
import { Column, CustomRule, Editing, Lookup } from 'devextreme-react/data-grid'
import { documentCategoryOptions } from '../../../app/common/options/cdsImportDeclaration/documentCategoryOptions'
import { additionalInformationCodeOptions } from '../../../app/common/options/cdsImportDeclaration/additionalInformationCodeOptions'
import { GoodsItemDetailReferences } from '../../../app/models/declarationGoodsItemDetail'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions'
import { FieldError } from '../../../app/models/declarationCreate'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: GoodsItemDetailReferences;
    goodsItemId: number;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsReference({formData, goodsItemId, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        additionalReferenceCodeRule: boolean
        additionalReferenceDescriptionRule: boolean
        ucrNumberRule: boolean
    } = {
        additionalReferenceCodeRule: isFieldValidWithSequence("additionalReferenceCode", goodsItemId, fieldErrors),
        additionalReferenceDescriptionRule: isFieldValidWithSequence("additionalReferenceDescription", goodsItemId, fieldErrors),
        ucrNumberRule: isFieldValidWithSequence("ucrNumber", goodsItemId, fieldErrors)
    };


    return <Form formData={formData}>
        <GroupItem cssClass="cds-group-item" colCount={1} caption={'References'} >
            <GroupItem cssClass="cds-group-item" colCount={1} caption={'Simplified declaration/Previous documents'} visible={isFieldVisible("goods.references")}>
            <DataGrid dataSource={formData.references}>
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                    <Column dataField="category" visible={isFieldVisible("goods.references.category")}><Lookup dataSource={documentCategoryOptions} displayExpr='description' valueExpr='name'/></Column>
                    <Column dataField="type" visible={isFieldVisible("goods.references.type")}></Column>
                    <Column dataField="reference" visible={isFieldVisible("goods.references.reference")}></Column>
                    <Column dataField="itemID" visible={isFieldVisible("goods.references.itemId")}></Column>
                    <Column dataField="lpcoExemptionCode" visible={isFieldVisible("goods.references.lpco")}></Column>
                </DataGrid>
            </GroupItem>
            <GroupItem cssClass="cds-group-item" colCount={2} caption={'Additional information'} visible={isFieldVisible("section-goods.additionalinformation")}>
                <Item dataField="additionalReference.code" label={{text: "Code"}} editorType="dxSelectBox"
                    visible={isFieldVisible("goods.additionalReference.code")}
                    editorOptions={{
                      items: additionalInformationCodeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "name"
                    }}>
                        <CustomRule message={getErrorMessageWithSequence("additionalReferenceCode", goodsItemId, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalReferenceCodeRule}} />
                    </Item>
                <Item dataField="additionalReference.description" label={{text: "Description"}} visible={isFieldVisible("goods.additionalReference.description")}>
                    <CustomRule message={getErrorMessageWithSequence("additionalReferenceDescription", goodsItemId, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalReferenceDescriptionRule}} />
                </Item>
            </GroupItem>
            <GroupItem cssClass="cds-group-item" colCount={1}>
                <Item dataField="ucrNumber" label={{text: "Reference number/UCR"}} visible={isFieldVisible("goods.ucrNumber")}>
                    <CustomRule message={getErrorMessageWithSequence("ucrNumber", goodsItemId, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.ucrNumberRule}} />
                </Item>
                <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
            </GroupItem>
            <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
        </GroupItem>
        </Form>
}