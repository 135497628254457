import { DataGrid } from 'devextreme-react'
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { Column, CustomRule, Editing, Lookup } from 'devextreme-react/data-grid'
import { DecalarationData } from '../../app/models/declarationCreate'
import { incoTermCodeOptions } from '../../app/common/options/cdsImportDeclaration/incoTermCodeOptions'
import { unCodeOptions } from '../../app/common/options/cdsImportDeclaration/unCodeOptions'
import { currencyOptions } from '../../app/common/options/currencyOptions'
import { additionsAndDeductionsCodeOptions } from '../../app/common/options/cdsImportDeclaration/additionsAndDeductionsCodeOptions'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData
    isFieldVisible: (fieldName: string) => boolean;
}

export default function ValuationInformationTaxesCard({formData, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current&& ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        incoTermCodeRule: boolean,
        countryCodeLocationNameRule: boolean,
        unCodeRule: boolean,
        exchangeRateRule: boolean,
        totalAmountInvoicedRule: boolean
        personPayingCustomsDutyRule: boolean,
        personProvidingGuaranteeRule: boolean,
        internalCurrencyRule: boolean 
    } = {
        incoTermCodeRule: isFieldValid("incotermCode", formData.fieldErrors),
        countryCodeLocationNameRule: isFieldValid("countryCodeLocationName", formData.fieldErrors),
        unCodeRule: isFieldValid("unCode", formData.fieldErrors),
        exchangeRateRule: isFieldValid("exchangeRate", formData.fieldErrors),
        totalAmountInvoicedRule: isFieldValid("totalAmountInvoiced", formData.fieldErrors),
        personPayingCustomsDutyRule: isFieldValid("personPayingCustomsDuty", formData.fieldErrors),
        personProvidingGuaranteeRule: isFieldValid("personProvidingGuarantee", formData.fieldErrors),
        internalCurrencyRule: isFieldValid("internalCurrency", formData.fieldErrors),
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, formData.fieldErrors);
      }

    return <Form ref={formRef} formData={formData} onContentReady={() => setIsFormReady(true)}>
        <GroupItem cssClass={'cds-group-item'} colCount={1} caption={'Valuation Information-Taxes'} visible={isFieldVisible("section-valuationinformation")}>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={'Delivery terms'}>
            <Item dataField="valuationInformationTaxes.incotermCode" label={{text: "INCOTERM Code"}} editorType="dxSelectBox"
                    visible={isFieldVisible("valuationInformationTaxes.incotermCode")}
                    editorOptions={{
                      items: incoTermCodeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code",
                    }}>
                        <CustomRule message={handleGetErrorMessage("incotermCode")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.incoTermCodeRule}} />
                    </Item>
            <Item dataField="valuationInformationTaxes.countryCodeLocationName" label={{text: "Location"}} visible={isFieldVisible("valuationInformationTaxes.countryCodeLocationName")} >
                <CustomRule message={handleGetErrorMessage("countryCodeLocationName")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryCodeLocationNameRule}} />
            </Item>
            <Item dataField="valuationInformationTaxes.unCode" label={{text: "Location Type"}} editorType="dxSelectBox"
                    visible={isFieldVisible("valuationInformationTaxes.unCode")}
                    editorOptions={{
                      items: unCodeOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "name"
                    }}>
                        <CustomRule message={handleGetErrorMessage("unCode")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.unCodeRule}} />
                    </Item>
            <Item dataField="valuationInformationTaxes.exchangeRate" editorType="dxNumberBox" label={{text: "Exchange Rate"}} visible={isFieldVisible("valuationInformationTaxes.exchangeRate")}>
                <CustomRule message={handleGetErrorMessage("exchangeRate")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.exchangeRateRule}} />
            </Item>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={3} caption={'Additions and deductions'} visible={isFieldVisible("valuationInformationTaxes.additionsAndReductions")} >
            <GroupItem cssClass={'cds-group-item'} colSpan={3}>
                <DataGrid dataSource={formData.valuationInformationTaxes.additionsAndReductions} >
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                    <Column dataField="value" visible={isFieldVisible("valuationInformationTaxes.additionsAndReductions.value")}></Column>
                    <Column dataField="currency" visible={isFieldVisible("valuationInformationTaxes.additionsAndReductions.currency")}><Lookup dataSource={currencyOptions} displayExpr="name" valueExpr="code"/></Column>
                    <Column dataField="type" visible={isFieldVisible("valuationInformationTaxes.additionsAndReductions.type")}><Lookup dataSource={additionsAndDeductionsCodeOptions} displayExpr="description" valueExpr="code" /></Column>
                </DataGrid>
            </GroupItem>
        </GroupItem>
        <GroupItem cssClass={'cds-group-item'} colCount={2}>
            <Item dataField="valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced" editorType="dxNumberBox" label={{text: "Total Amount Invoiced"}} visible={isFieldVisible("valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced")}>
                <CustomRule message={handleGetErrorMessage("totalAmountInvoiced")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.totalAmountInvoicedRule}} />
            </Item>
            <Item dataField="valuationInformationTaxes.personPayingCustomsDuty" label={{text: "Person paying customs duty"}} visible={isFieldVisible("valuationInformationTaxes.personPayingCustomsDuty")}>
                <CustomRule message={handleGetErrorMessage("personPayingCustomsDuty")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.personPayingCustomsDutyRule}} />
            </Item>
            <Item dataField="valuationInformationTaxes.personProvidingGuarantee" label={{text: "Person providing guarantee"}} visible={isFieldVisible("valuationInformationTaxes.personProvidingGuarantee")}>
                <CustomRule message={handleGetErrorMessage("personProvidingGuarantee")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.personProvidingGuaranteeRule}} />
            </Item>
            <Item dataField="valuationInformationTaxes.internalCurrency" label={{text: "Internal currency"}} editorType="dxSelectBox"
                    visible={isFieldVisible("valuationInformationTaxes.internalCurrency")}
                    editorOptions={{
                      items: currencyOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "code"
                    }}>
                        <CustomRule message={handleGetErrorMessage("internalCurrency")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.internalCurrencyRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        </GroupItem>
        <GroupItem>
            {/*I have a blank GroupItem to keep last field line from overlapping the border*/}
        </GroupItem>
    </GroupItem>
    </Form>
}