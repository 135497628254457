import { Address } from "cluster";
import { GatesAndAreas } from "./gatesAndAreas";
import { Tag } from "./tag";

export interface PersonaAddress {    
    organisationId?: string,
    id?: string,
    personId?: string,
    type: number,
    eori?: string,
    code: string,
    name: string,
    address1: string,
    address2: string,
    city: string,
    region: string,
    postcode: string,
    countryIsoCode: string,
    status: number,
    notes?: string,
    latitude?: number,
    longitude?: number,
    flagGatesAndAreas?: boolean,
    areas?: GatesAndAreas[],
    lastUsed?: Date,
    tags?: Tag[]   
}

export class CustomerAddressFormValues {
    name: string = ""
    region: string = ""
    countryIsoCode: string = "IE"
    code: string = ""
    city: string = ""
    postcode: string = ""
    address1: string = ""
    address2: string = ""
    type: number = 0
    status: number = 1
    latitude?: number = 0
    longitude?: number = 0

    constructor(customerAddress?: CustomerAddressFormValues) {
        if (customerAddress) {
            this.name = customerAddress.name
            this.region = customerAddress.region
            this.countryIsoCode = customerAddress.countryIsoCode
            this.code = customerAddress.code
            this.city = customerAddress.city
            this.postcode = customerAddress.postcode
            this.address1 = customerAddress.address1
            this.address2 = customerAddress.address2
            this.type = customerAddress.type
            this.status = customerAddress.status
            this.latitude = customerAddress.latitude
            this.longitude = customerAddress.longitude
        }
    }
}

export class CustomerbillingAddressFormValues {
    name: string = ""
    region: string = ""
    countryIsoCode: string = "IE"
    code: string = ""
    city: string = ""
    postcode: string = ""
    address1: string = ""
    address2: string = ""
    type: number = 0
    status: number = 1

    constructor(customerAddress?: CustomerbillingAddressFormValues) {
        if (customerAddress) {
            this.name = customerAddress.name
            this.region = customerAddress.region
            this.countryIsoCode = customerAddress.countryIsoCode
            this.code = customerAddress.code
            this.city = customerAddress.city
            this.postcode = customerAddress.postcode
            this.address1 = customerAddress.address1
            this.address2 = customerAddress.address2
            this.type = customerAddress.type
            this.status = customerAddress.status
        }
    }

}