import { Button, ContextMenu, DataGrid, Form, LoadIndicator } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useDeclarationPackage, useDeleteDeclaration } from '../../app/hooks/declarationQueries'
import { useStore } from '../../app/stores/store'
import { Column, FilterRow } from 'devextreme-react/data-grid'
import { GroupItem, Item } from 'devextreme-react/form'
import { useParams } from 'react-router-dom'
import { router } from '../../app/router/Routes'
import { CopyDeclarationV2 } from '../../app/models/declarationCopy'
import { confirm } from "devextreme/ui/dialog"
import { useState } from 'react'
import { notifyMessage } from '../../app/common/utils/notify'

export default observer(function DeclarationPackageForm() {
    const { id } = useParams<{ id: string; }>()
    const { declarationStore } = useStore()
    const { isLoading, refetch } = useDeclarationPackage(id)
    const deleteDeclaration = useDeleteDeclaration()
    const [selectedDeclarationId, setSelectedDeclarationId] = useState('');
    
    if (isLoading || !declarationStore.declarationPackage) return <LoadIndicator className="organisations-load-indicator" />

    const rightClickItems = [{text: 'Create Import'}, {text: 'Create Export'}, {text: 'Create EXS'}, {text: 'Create ENS'}];

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      async function refreshData(){
        refetch()
      }

    const handleEdit = (cellData: any) => {
        declarationStore.setDeclaration(null)
        if (cellData.data.id) {
            router.navigate(`/declarations/${cellData.data.id}`)
        }
    }

    const handleAddImport = () => {
        if(selectedDeclarationId != null){
            const copyRequest : CopyDeclarationV2 = {declarationId: selectedDeclarationId, useNewBucket: false, targetDeclarationType: 'H1'}
            declarationStore.copyDeclarationV2(copyRequest).then(() => notifyMessage("success", "Import Declaration created")).finally(() => {refreshData()})
        }
    }

    const handleDelete = (cellData: any) => {
        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {
                deleteDeclaration.mutateAsync(cellData.data.id).then(() => notifyMessage("success", "Declaration deleted")).finally(() => {refreshData()})
            }
        })
      }

    const handleAddExport = () => {
        if (selectedDeclarationId != null) {
            const copyRequest : CopyDeclarationV2 = {declarationId: selectedDeclarationId, useNewBucket: false, targetDeclarationType: 'B1'}
            declarationStore.copyDeclarationV2(copyRequest).then(() => notifyMessage("success", "Export Declaration created")).finally(() => {refreshData()})
        }
    }

    const handleAddEns = () => {
        if (selectedDeclarationId != null) {
            const copyRequest : CopyDeclarationV2 = {declarationId: selectedDeclarationId, useNewBucket: false, targetDeclarationType: 'F10'}
            declarationStore.copyDeclarationV2(copyRequest).then(() => notifyMessage("success", "ENS Declaration created")).finally(() => {refreshData()})
        }
    }

    const handleAddExs = () => {
        if (selectedDeclarationId != null) {
            const copyRequest : CopyDeclarationV2 = {declarationId: selectedDeclarationId, useNewBucket: false, targetDeclarationType: 'A1'}
            declarationStore.copyDeclarationV2(copyRequest).then(() => notifyMessage("success", "EXS Declaration created")).finally(() => {refreshData()})
        }
    }

    function handleRightClick(e: any){
        if (e.itemData.text == 'Create Import') {
            handleAddImport();
        }
        else if (e.itemData.text == 'Create Export') {
            handleAddExport();
        }
        else if (e.itemData.text == 'Create ENS') {
            handleAddEns();
        }
        else if (e.itemData.text == 'Create EXS') {
            handleAddExs();
        }
    }

    function cancelButtonClicked(){
        declarationStore.declarationTemplate = null;
        router.navigate(`/declaration-packages`)
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}  
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}  
                />
            </>
        )
    }

    return (
        <div>
        <form onSubmit={(e) => handleSubmit(e)}>
        <Form formData={declarationStore.declarationPackage}>
        <GroupItem cssClass="cds-group-item" colCount={3} caption={'Package'}>
            <GroupItem colSpan={1}>
                <Item dataField="transactionPrefix" label={{text: "Prefix"}} editorOptions={{ readOnly: true }} />
                <Item dataField="transactionId" label={{text: "Id"}} editorOptions={{ readOnly: true }} />
                <Item dataField="customerName" label={{text: "Customer"}} editorOptions={{ readOnly: true }}  />
                <Item dataField="type" label={{text: "type"}} editorOptions={{ readOnly: true }}  />
                <Item dataField="createdDate" label={{text: "Created"}} editorOptions={{ readOnly: true }} />
                <Item dataField="createdBy" label={{text: "Created by"}} editorOptions={{ readOnly: true }}  />
            </GroupItem>
            <GroupItem colSpan={2} caption={'Declarations'}>
                <DataGrid
                        id='declarations'
                        dataSource={declarationStore.declarationPackage?.declarations}
                        rowAlternationEnabled={true}
                        keyExpr="id"
                        onContextMenuPreparing={(rows) => setSelectedDeclarationId(rows.row?.key)}
                    >
                        <FilterRow visible={true} />
                        <Column type="adaptive" width={10} />
                        <Column dataField="localReferenceNumber" caption="Ref" />
                        <Column dataField="declarationType" caption="Cat." />
                        <Column dataField="type" caption="Type" />
                        <Column dataField="additionalType" caption="A. Type" />
                        <Column dataField="movementReferenceNumber" caption="MRN" />
                        <Column dataField="authoritySystem" caption="Authority" />
                        <Column dataField="countryOfDispatch" caption="Origin" />
                        <Column dataField="countryOfDestination" caption="Dest." />
                        <Column dataField="lastAuthorityMessage" caption="Message" />
                        <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                </DataGrid>
            </GroupItem>
        <GroupItem colCount={1}></GroupItem>
        <GroupItem colCount={1}>
        <Button onClick={cancelButtonClicked} text='Close' />
        </GroupItem>
    </GroupItem>
    </Form>
    </form>
    <ContextMenu dataSource={rightClickItems} target="#declarations" onItemClick={handleRightClick} />
    </div>
    )
})