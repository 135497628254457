import { DataGrid } from "devextreme-react"
import { DecalarationData, FieldError } from "../../../app/models/declarationCreate"
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { Column, CustomRule, Editing, Lookup } from "devextreme-react/data-grid"
import { methodOfPaymentOptions } from "../../../app/common/options/cdsImportDeclaration/methodOfPaymentOptions"
import { countryOptions } from "../../../app/common/options/countryOptions"
import { useEffect, useRef, useState } from "react"
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityConsingmentRB({formData, fieldErrors, isFieldVisible}: Props){
  const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
      if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
      eoriRule: boolean
      methodOfPaymentRule: boolean
      countryOfDispatchCodeRule: boolean
      countryOfDestinationCodeRule: boolean
    } = {
      eoriRule: isFieldValid("carrierEori", fieldErrors),
      methodOfPaymentRule: isFieldValid("methodOfPayment", fieldErrors),
      countryOfDispatchCodeRule: isFieldValid("countryOfDispatchCode", fieldErrors),
      countryOfDestinationCodeRule: isFieldValid("countryOfDestinationCode", fieldErrors)
    };
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Safety and Security Consignment'} >
            <Item dataField="consignment.carrier.eori" label={{text: "Carrier Eori"}} visible={isFieldVisible("consignment.carrier.eori")}>
              <CustomRule message={getErrorMessage("carrierEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.eoriRule}} />
            </Item>
            <Item dataField="consignment.freight.methodOfPayment" label={{text: "Method of Payment"}} editorType="dxSelectBox"
                    visible={isFieldVisible("consignment.freight.methodOfPayment")}
                    editorOptions={{
                      items: methodOfPaymentOptions,
                      searchEnabled: true,
                      displayExpr: "description",
                      valueExpr: "code"
                    }}>
                      <CustomRule message={getErrorMessage("methodOfPayment", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.methodOfPaymentRule}} />
                    </Item>
            <Item dataField={"places.countryOfDispatchCode"} label={{text: "Origin"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.countryOfDispatchCode")}
                    editorOptions={{
                      readOnly: true,
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode"
                    }}>
                      <CustomRule message={getErrorMessage("countryOfDispatchCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDispatchCodeRule}} />
                    </Item>
            <Item dataField={"places.countryOfDestinationCode"} label={{text: "Destination"}} editorType="dxSelectBox"
                    visible={isFieldVisible("places.countryOfDestinationCode")}
                    editorOptions={{
                      readOnly: true,
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode"
                    }}>
                      <CustomRule message={getErrorMessage("countryOfDestinationCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.countryOfDestinationCodeRule}} />
                    </Item>
            <GroupItem colSpan={2} caption={'Countries of Routing (between Origin & Destination)'} visible={isFieldVisible("consignment.itinerary")}>
                <DataGrid dataSource={formData.consignment.itinerary} keyExpr={"sequenceNumber"} >
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} ></Editing>
                    <Column dataField="sequenceNumber" caption={'Seq #'} defaultSortIndex={0} defaultSortOrder="asc" ></Column>
                    <Column dataField="country" caption={'Country'}><Lookup dataSource={countryOptions} displayExpr={'name'} valueExpr={'isoCode'} /></Column>
                </DataGrid>
            </GroupItem>
        </GroupItem>
    </Form>
}