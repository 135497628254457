import { useEffect, useRef, useState } from "react"
import { DeclarationCustomsOffices, FieldError } from "../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationCustomsOffices | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityCustomsOffices({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        expectedArrivalRule: boolean
        declarationPlaceRule: boolean
    } = {
        expectedArrivalRule: isFieldValid("expectedArrival", fieldErrors),
        declarationPlaceRule: isFieldValid("declarationPlace", fieldErrors)
    };
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Customs Offices'} >
            <Item dataField="expectedArrival" label={{text: "Expected Arrival"}} editorType="dxDateBox" visible={isFieldVisible("expectedArrival")}>
                <CustomRule message={getErrorMessage("expectedArrival", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.expectedArrivalRule}} />
            </Item>
            <Item dataField="declarationPlace" label={{text: "Declaration Place"}} visible={isFieldVisible("declarationPlace")}>
                <CustomRule message={getErrorMessage("declarationPlace", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarationPlaceRule}} />
            </Item>
        </GroupItem>
    </Form>
}