import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import LoadIndicator from "devextreme-react/load-indicator";
import DataGrid, { Column, FilterRow, Item, Paging, Toolbar } from "devextreme-react/data-grid";
import Form, { GroupItem } from "devextreme-react/form";
import { router } from "../../app/router/Routes";
import { Button } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog"

export default observer(function Suppliers() {
    const {supplierStore, organisationStore } = useStore()
    
    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    useEffect(() => {
        {organisationId && supplierStore.getSuppliers(organisationId);}
    }, [supplierStore, organisationId]);

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      const handleEdit = (cellData: any) => {
        if (cellData.data.id) {
            router.navigate(`/supplier/${cellData.data.id}`)
        }
    }

    const handleAddNew = () => {
        supplierStore.supplier = null
        router.navigate(`/supplier`)
    }

    const handleDelete = (cellData: any) => {
        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {
                supplierStore.deleteSupplier(cellData.data.id)
            }
        })
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

    return (
        <div className='content-block'>
            {supplierStore.loading && <LoadIndicator className="organisations-load-indicator" />}
            <form onSubmit={(e) => handleSubmit(e)}>
                <Form formData={supplierStore.suppliers}>
                    <GroupItem caption={'Suppliers'} >
                        <DataGrid id='suppliers' dataSource={supplierStore.suppliers} rowAlternationEnabled={true} keyExpr='id' showBorders={true}  >
                            <Paging enabled={true} />
                            <FilterRow visible={true} />
                            <Column dataField="code" caption={'Code'}></Column>
                            <Column dataField="name" caption={'Customer'} ></Column>
                            <Column dataField="eori" caption={'EORI'} ></Column>
                            <Column dataField="contactName" caption={'Contact'}></Column>
                            <Column dataField="contactPhoneNumber" caption={'Phone'}></Column>
                            <Column dataField="contactMobileNumber" caption={'Mobile'}></Column>
                            <Column dataField="contactEmail" caption={'Email'} ></Column>
                            <Column dataField="contactWeb" caption={'Web'}></Column>
                            <Column dataField="currencyIsoCode" caption={'Cur.'}></Column>
                            <Column dataField="countryIsoCode" caption={'Country'}></Column>
                            <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                            <Toolbar>
                                <Item>
                                    <Button icon='add' hint='Add' onClick={() => handleAddNew()}></Button>
                                </Item>
                            </Toolbar>
                        </DataGrid>
                    </GroupItem>
                </Form>
            </form>
        </div >
        )
})