import { useCallback, useState } from 'react'
import { Form, Item, RequiredRule, Label, EmailRule, CustomRule, EmptyItem, ButtonItem, CompareRule } from 'devextreme-react/form'
import { Button, Switch } from 'devextreme-react'
import { useNavigate } from 'react-router-dom'
import { CreateUser } from '../../app/models/user'
import { useStore } from '../../app/stores/store'
import { notifyMessage } from '../../app/common/utils/notify'
import { Popup } from "devextreme-react/popup";


interface formData {
  email: string
  password: string
  repassword: string
  subscriptionPlan: string
  redirectUrl: string
}


const RegisterUser = () => {
  const navigate = useNavigate()
  const { userStore } = useStore()
  const { createUser } = userStore
  const [newUser] = useState<formData>({
    email: '',
    password: '',
    repassword: '',
    subscriptionPlan: 'b3e55203-18ee-4b30-8add-16f04a9ba8c9',
    redirectUrl: `${window.location.origin}/validate-email`,
  })
  const [sending, setSending] = useState(false)
  const [checkBox, setCheckBox] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)

  const showPopup = useCallback(() => {
    setPopupVisible(true);
  }, [setPopupVisible]);

  const hide = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const user: CreateUser = {
      email: newUser.email,
      password: newUser.password,
      subscriptionPlan: newUser.subscriptionPlan,
      redirectUrl: newUser.redirectUrl,
    }
    setCheckBox(true)
    setSending(true)
    createUser(user).then(() => {
      if (userStore.userCreated) {
        showPopup();
      } else {
        navigate('/server-error')
        notifyMessage("warning", "User could not be registered")
      }
    }).finally(() => {
      setSending(false)
      setCheckBox(true)
    })
  }

  const passwordStrength = ({ value }: any) => {
    var checkall = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})/
    if (value.match(checkall)) {
      return true
    }
    return false
  }

  const checkComparison = () => {
    return true;
  }
  const passwordComparison = () => {
    return newUser.password;
  }
  const handleChangePlan = () => {
    if (newUser.subscriptionPlan === 'b3e55203-18ee-4b30-8add-16f04a9ba8c9') {
      newUser.subscriptionPlan = '7eae43cf-1859-437a-8c2f-8a3cae40db26'
    } else {
      newUser.subscriptionPlan = 'b3e55203-18ee-4b30-8add-16f04a9ba8c9'
    }
  }

  return (
    <div className='html-blank'>
      <div className='register-user'>
        <div className='register-user-form'>
          <div className='register-user-form-wraper'>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Form
                formData={newUser}
                id='form'
                labelMode='floating'
                validationGroup="customerData">
                <Item
                  dataField={'email'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Email',
                    mode: 'email',
                  }}>
                  <RequiredRule message='Email is required' />
                  <EmailRule message='Email is invalid' />
                  <Label visible={false} />
                </Item>
                <Item
                  dataField={'password'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Password',
                    mode: 'password',
                  }}
                >
                  <RequiredRule message='Password is required' />
                  <Label visible={false} />
                </Item>
                <Item
                  dataField={'repassword'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Confirm Password',
                    mode: 'password',
                  }}
                >
                  <RequiredRule message='Password  comfirmation is required' />
                  <CustomRule message={'Password minimum lenght is 8 characters, and must include at least a capital and a lower case letter, a number and a special character (._!@#$%^&*~?,).'} validationCallback={passwordStrength} />
                  <CompareRule
                    message="Password and Confirm Password do not match"
                    comparisonTarget={passwordComparison}
                  />
                  <Label visible={false} />
                </Item>

                <EmptyItem />
                <EmptyItem />
                <Item
                  dataField="Accepted"
                  editorType="dxCheckBox"
                  editorOptions={{
                    text: 'By Creating an account you agree to the Terms of Service and Privacy',
                    value: checkBox,
                    onclick: () => setCheckBox(!checkBox)
                  }}
                >
                  <Label visible={false} />
                  <CompareRule message="You must agree to the Terms and Conditions"
                    comparisonTarget={checkComparison}
                  />
                </Item>

                <EmptyItem />
                <EmptyItem />

                <ButtonItem
                  buttonOptions={
                    {
                      disabled: sending,
                      text: sending ? "Loading..." : "Save",
                      type: 'default',
                      useSubmitBehavior: true,
                      width: "100%",
                      height: 'max(35px, 1.5rem)',
                      showLoadIndicator: sending
                    }}
                />
                <Item>
                  <div className='register-user-to-login-container'>Already have an account?<Button
                    stylingMode="text"
                    className='register-user-to-login-button'
                    text='Login'
                    type={'default'}
                    onClick={() => navigate("/home-page")} />
                  </div>
                </Item>


              </Form>
            </form>
          </div>
        </div>

        <div className='register-user-customer-plan'>
          <div className='register-user-customer-package'>
            <h3 className='register-user-customer-plan-header'>STARTER CUSTOMER PLAN</h3>
            <ul className={'register-user-plan-highlights'}>
              <li style={{ marginBottom: 5 }}>1 Organisation.</li>
              <li style={{ marginBottom: 5 }}>20 Users.</li>
              <li style={{ marginBottom: 5 }}>150 Port declarations</li>
              <li style={{ marginBottom: 5 }}>Email notifications.</li>
              <li style={{ marginBottom: 5 }}>1 API Connector.</li>
            </ul>
            <p className='register-page-plan-monthly-cost'>€ 190 per month</p>
            <p className='register-page-plan-annual-cost'>Paid Annualy or €200 per month</p>
          </div>
          <div className='register-user-customer-plan-add-on'>
            <div className='register-user-customer-plan-add-on dx-field'>
              <div>
                <Switch defaultValue={false} onValueChange={() => handleChangePlan()} />
              </div>
              <div>Add text notifications for €0.10 per message.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="poup-registration-div">
        <Popup
          width={360}
          height={320}
          visible={popupVisible}
          onHiding={hide}
          showTitle={false}
          className="popup-registration"
        >
          <div className="popup-registration-container">
            <img
              className="poup-letter-confirmation"
              src="/img/letterImage.png"
              alt="email"
            />
            <p className="poup-registration-message">
              To complete your registration process, please click the link that
              has been sent to your email address.
            </p>
            <Button
              stylingMode="contained"
              text="Confirm"
              type={"default"}
              onClick={() => navigate("/home-page")}
            ></Button>
          </div>
        </Popup>
      </div>
    </div >
  )
}

export default RegisterUser