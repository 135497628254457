import React, { useCallback, useMemo } from 'react'
import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { useAuth0 } from '@auth0/auth0-react'
import { useStore } from '../../stores/store'
import { router } from '../../router/Routes'
import { observer } from 'mobx-react-lite'


interface Props {
    menuMode: 'context' | 'list'
}

export default observer(function UserPanel({ menuMode }: Props) {
    const { logout } = useAuth0()
    const { userStore } = useStore()

    const logUserOut = useCallback(() => {
        logout({ logoutParams: { returnTo: window.location.origin } })
        userStore.logout()
    }, [userStore, logout])

    function navigateToProfile() {
        router.navigate("/profile")
    }

    const menuItems = useMemo(() => ([
        {
            text: 'Profile',
            icon: 'user',
            onClick: navigateToProfile
        },
        {
            text: 'Logout',
            icon: 'runner',
            onClick: logUserOut
        }
    ]), [logUserOut])

    if (userStore.user === null) return <div></div>

    return (
        <div className={'user-panel'}>
            <div className={'user-info'}>
                <div className={'image-container'}>
                    <div
                        style={{
                            background: `url(${userStore.user?.picture}) no-repeat #fff`,
                            backgroundSize: 'cover'
                        }}
                        className={'user-image'} />
                </div>
                <div className={'user-name'}>{userStore.user?.nickName}</div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                    items={menuItems}
                    target={'.user-button'}
                    showEvent={'dxclick'}
                    width={210}
                    cssClass={'user-menu'}
                >
                    <Position my={'top center'} at={'bottom center'} />
                </ContextMenu>
            )}
            {menuMode === 'list' && (
                <List className={'dx-toolbar-menu-action'} items={menuItems} />
            )}
        </div>
    )
})
