export const packageTypeOptions = [
{ code:"43", description:"Bag super bulk"},
{ code:"1A", description:"Drum steel"},
{ code:"1B", description:"Drum aluminium"},
{ code:"1D", description:"Drum plywood"},
{ code:"1G", description:"Drum fibre"},
{ code:"1W", description:"Drum wooden"},
{ code:"2C", description:"Barrel wooden"},
{ code:"3A", description:"Jerry-can steel"},
{ code:"3H", description:"Jerry-can plastic"},
{ code:"4A", description:"Box steel"},
{ code:"4B", description:"Box aluminum"},
{ code:"4C", description:"Box natural wood"},
{ code:"4D", description:"Box plywood"},
{ code:"4F", description:"Box reconstituted wood"},
{ code:"4G", description:"Box fibreboard"},
{ code:"4H", description:"Box plastic"},
{ code:"5H", description:"Bag woven plastic"},
{ code:"5L", description:"Bag textile"},
{ code:"5M", description:"Bag paper"},
{ code:"6H", description:"ComposIte packaging plastic receptable"},
{ code:"6P", description:"Composite packaging glass receptaple"},
{ code:"AA", description:"Intermediate bulk container rigid plastic"},
{ code:"AB", description:"Receptable fibre"},
{ code:"AC", description:"Receptable paper"},
{ code:"AD", description:"Receptable wooden"},
{ code:"AE", description:"Aerosol"},
{ code:"AF", description:"Pallet modular collars 80cm x 60cm"},
{ code:"AG", description:"Pallet shrink-wrapped"},
{ code:"AH", description:"Pallet 100 cm x 110cm"},
{ code:"AI", description:"Clamshell"},
{ code:"AJ", description:"Cone"},
{ code:"AM", description:"Ampoule non-protected"},
{ code:"AP", description:"Ampoule protected"},
{ code:"AT", description:"Atomiser"},
{ code:"AV", description:"Capsule"},
{ code:"BA", description:"Barrel"},
{ code:"BB", description:"Bobbin"},
{ code:"BC", description:"Bottlecrate bottlerack"},
{ code:"BD", description:"Board"},
{ code:"BE", description:"Bundle"},
{ code:"BF", description:"Balloon non-protected"},
{ code:"BG", description:"Bag"},
{ code:"BH", description:"Bunch"},
{ code:"BI", description:"Bin"},
{ code:"BJ", description:"Bucket"},
{ code:"BK", description:"Basket"},
{ code:"BL", description:"Bale compressed"},
{ code:"BM", description:"Basin"},
{ code:"BN", description:"Bale non-compressed"},
{ code:"BO", description:"Bottle non-protected cylindrical"},
{ code:"BP", description:"Balloon protected"},
{ code:"BQ", description:"Bottle protected cylindrical"},
{ code:"BR", description:"Bar"},
{ code:"BS", description:"Bottle non-protected bulbous"},
{ code:"BT", description:"Bolt"},
{ code:"BU", description:"Butt"},
{ code:"BV", description:"Bottle protected bulbous"},
{ code:"BW", description:"Box for liquids"},
{ code:"BX", description:"Box"},
{ code:"BY", description:"Board in bundle/bunch/truss"},
{ code:"BZ", description:"Bars in bundle/bunch/truss"},
{ code:"CA", description:"Can rectangular"},
{ code:"CB", description:"Beer crate"},
{ code:"CC", description:"Churn"},
{ code:"CD", description:"Can with handle and spout"},
{ code:"CE", description:"Creel"},
{ code:"CF", description:"Coffer"},
{ code:"CG", description:"Cage"},
{ code:"CH", description:"Chest"},
{ code:"CI", description:"Canister"},
{ code:"CJ", description:"Coffin"},
{ code:"CK", description:"Cask"},
{ code:"CL", description:"Coil"},
{ code:"CM", description:"Collis"},
{ code:"CN", description:"Container not otherwise specified as transport equipment"},
{ code:"CO", description:"Carboy non-protected"},
{ code:"CP", description:"Carboy protected"},
{ code:"CQ", description:"Cartdidge"},
{ code:"CR", description:"Crate"},
{ code:"CS", description:"Case"},
{ code:"CT", description:"Carton"},
{ code:"CU", description:"Cup"},
{ code:"CV", description:"Cover"},
{ code:"CW", description:"Cage roll"},
{ code:"CX", description:"Can cylindrical"},
{ code:"CY", description:"Cylinder"},
{ code:"CZ", description:"Canvas"},
{ code:"DA", description:"Crate multiple layer plastic"},
{ code:"DB", description:"Crate multiple layer wooden"},
{ code:"DC", description:"Crate multiple layer cardboard"},
{ code:"DG", description:"Cage Commonwealth Handling Equipment Pool (CHEP)"},
{ code:"DH", description:"Box Commonweakth Handling Equipment Pool (CHEP) Eurobox"},
{ code:"DI", description:"Drum iron"},
{ code:"DJ", description:"Demijohn non-protected"},
{ code:"DK", description:"Crate bulk cardboard"},
{ code:"DL", description:"Crate bulk plastic"},
{ code:"DM", description:"Crate bulk wooden"},
{ code:"DN", description:"Dispenser"},
{ code:"DP", description:"Demijohn protected"},
{ code:"DR", description:"Drum"},
{ code:"DS", description:"Tray one layer no cover plastic"},
{ code:"DT", description:"Tray one layer no cover wooden"},
{ code:"DU", description:"Tray one layer no cover polystyrene"},
{ code:"DV", description:"Tray one layer no cover cardboard"},
{ code:"DW", description:"Tray two layer no cover plastic"},
{ code:"DX", description:"Tray two layer no cover wooden"},
{ code:"DY", description:"Tray two layer no covercardboard"},
{ code:"EC", description:"Bag plastic"},
{ code:"ED", description:"Case with pallet base"},
{ code:"EE", description:"Case with pallet base wooden"},
{ code:"EF", description:"Case with pallet base cardboard"},
{ code:"EG", description:"Case with pallet base plastic"},
{ code:"EH", description:"Case with pallet base metal"},
{ code:"EI", description:"Case isothermic"},
{ code:"EN", description:"Envelope"},
{ code:"FC", description:"Fruit crate"},
{ code:"FD", description:"Framed crate"},
{ code:"FI", description:"Firkin"},
{ code:"FL", description:"Flask"},
{ code:"FO", description:"Footlocker"},
{ code:"FP", description:"Filmpack"},
{ code:"FR", description:"Frame"},
{ code:"FT", description:"Foodtainer"},
{ code:"FX", description:"Bag flexible container"},
{ code:"GB", description:"Gas bottle"},
{ code:"GI", description:"Girder"},
{ code:"GR", description:"Receptable glass"},
{ code:"GZ", description:"Girders in bundle/bunch/truss"},
{ code:"HA", description:"Basket with handle plastic"},
{ code:"HB", description:"Basket with handle wooden"},
{ code:"HC", description:"Basket with handle cardboard"},
{ code:"HG", description:"Hogshead"},
{ code:"HR", description:"Hamper"},
{ code:"IA", description:"Package display wooden"},
{ code:"IB", description:"Package display cardboard"},
{ code:"IC", description:"Package display plastic"},
{ code:"ID", description:"Package display metal"},
{ code:"IE", description:"Pachage show"},
{ code:"IF", description:"Package flow"},
{ code:"IG", description:"Package paper-wrapped"},
{ code:"IH", description:"Drum plastic"},
{ code:"IK", description:"Package cardboard with bottle grip-holes"},
{ code:"IN", description:"Ingot"},
{ code:"IZ", description:"Ingots in bundle/bunch/truss"},
{ code:"JC", description:"Jerrican rectangular"},
{ code:"JG", description:"Jug"},
{ code:"JR", description:"Jar"},
{ code:"JT", description:"Jutebag"},
{ code:"JY", description:"Jerrican cylindrical"},
{ code:"KG", description:"Keg"},
{ code:"LG", description:"Log"},
{ code:"LT", description:"Lot"},
{ code:"LV", description:"Liftvan"},
{ code:"LZ", description:"Logs in bundle/bunch/truss"},
{ code:"MB", description:"Multiply bag"},
{ code:"MC", description:"Milk crate"},
{ code:"MR", description:"Receptable metal"},
{ code:"MS", description:"Multiwall sack"},
{ code:"MT", description:"Mat"},
{ code:"MW", description:"Receptable plastic-wrapped"},
{ code:"MX", description:"Match box"},
{ code:"NA", description:"Not available"},
{ code:"NE", description:"Unpacked or unpackaged"},
{ code:"NF", description:"Unpacked or unpackaged single unit"},
{ code:"NG", description:"Unpacked or unpackaged multiple units"},
{ code:"NS", description:"Nest"},
{ code:"NT", description:"Net"},
{ code:"NU", description:"Net tube plastic"},
{ code:"NV", description:"Net tube textile"},
{ code:"PA", description:"Packet"},
{ code:"PB", description:"Pallet box"},
{ code:"PC", description:"Parcel"},
{ code:"PD", description:"Pallet modular collars 80cm x 100cm"},
{ code:"PE", description:"Pallet modular collars 80cm x 120cm"},
{ code:"PF", description:"Pen"},
{ code:"PG", description:"Plate"},
{ code:"PH", description:"Pitcher"},
{ code:"PI", description:"Pipe"},
{ code:"PJ", description:"Punnet"},
{ code:"PK", description:"Package"},
{ code:"PL", description:"Pail"},
{ code:"PN", description:"Plank"},
{ code:"PO", description:"Pouch"},
{ code:"PR", description:"Receptable plastic"},
{ code:"PT", description:"Pot"},
{ code:"PU", description:"Tray"},
{ code:"PV", description:"Pipes in bundle/bunch/truss"},
{ code:"PX", description:"Pallet"},
{ code:"PY", description:"Plates in bundle/bunch/truss"},
{ code:"PZ", description:"Pipes in bundle/bunch/truss"},
{ code:"QA", description:"Drum steel non removable head"},
{ code:"QB", description:"Drum steel removable head"},
{ code:"QC", description:"Drum aluminium non-removable head"},
{ code:"QD", description:"Drum aluminium removable head"},
{ code:"QF", description:"Drumplastic non-removable head"},
{ code:"QG", description:"Drumplastic removable head"},
{ code:"QH", description:"Barrel wooden bung type"},
{ code:"QJ", description:"Barrel wooden removable head"},
{ code:"QK", description:"Jerry-can steel non removable head"},
{ code:"QL", description:"Jerry-can steel removable head"},
{ code:"QM", description:"Jerry-can plastic non removable head"},
{ code:"QN", description:"Jerry-can plastic removable head"},
{ code:"QP", description:"Box wooden natural wood ordinary"},
{ code:"QQ", description:"Box wooden natural wood with sift proof walls"},
{ code:"QR", description:"Box plastic expanded"},
{ code:"QS", description:"Box plastic solid"},
{ code:"RD", description:"Rod"},
{ code:"RG", description:"Ring"},
{ code:"RJ", description:"Rack clothing hanger"},
{ code:"RK", description:"Rack"},
{ code:"RL", description:"Reel"},
{ code:"RO", description:"Roll"},
{ code:"RT", description:"Rednet"},
{ code:"RZ", description:"Rods in bundle/bunch/truss"},
{ code:"SA", description:"Sack"},
{ code:"SB", description:"Slab"},
{ code:"SC", description:"Shallow crate"},
{ code:"SD", description:"Spindle"},
{ code:"SE", description:"Sea-chest"},
{ code:"SH", description:"Sachet"},
{ code:"SI", description:"Skid"},
{ code:"SK", description:"Skeleton case"},
{ code:"SL", description:"Slipsheet"},
{ code:"SM", description:"Sheetmetal"},
{ code:"SO", description:"Spool"},
{ code:"SP", description:"Sheet plastic wrapping"},
{ code:"SS", description:"Case steel"},
{ code:"ST", description:"Sheet"},
{ code:"SU", description:"Suitcase"},
{ code:"SV", description:"Envelope steel"},
{ code:"SW", description:"Shrinkwrapped"},
{ code:"SX", description:"Set"},
{ code:"SY", description:"Sleeve"},
{ code:"SZ", description:"Sheets in bundle/bunch/truss"},
{ code:"TB", description:"Tub"},
{ code:"TC", description:"Tea chest"},
{ code:"TD", description:"Tube collapsible"},
{ code:"TI", description:"Tierce"},
{ code:"TK", description:"Tank rectangular"},
{ code:"TL", description:"Trailers"},
{ code:"TN", description:"Tin"},
{ code:"TO", description:"Tun"},
{ code:"TR", description:"Trunk"},
{ code:"TS", description:"Truss"},
{ code:"TU", description:"Tube"},
{ code:"TV", description:"Tube with nozzle"},
{ code:"TY", description:"Tank cylindrical"},
{ code:"TZ", description:"Tubes in bundle/bunch/truss"},
{ code:"UC", description:"Uncaged"},
{ code:"VA", description:"Vat"},
{ code:"VG", description:"Bulk gas (at 1 031 mbar and 15 ?C)"},
{ code:"VI", description:"Vial"},
{ code:"VK", description:"Vanpack"},
{ code:"VL", description:"Bulk liquid"},
{ code:"VO", description:"Bulk solid large particles ('nodules')"},
{ code:"VP", description:"Vacuum-packed"},
{ code:"VQ", description:"Bulk liquefied gas (abnormal temp/pres)"},
{ code:"VR", description:"Bulk solid granular particles (grains)"},
{ code:"VY", description:"Bulk solid fine particles ('powders')"},
{ code:"WA", description:"Intermediate bulk container"},
{ code:"WB", description:"Wickerbottle"},
{ code:"WC", description:"Intermediate bulk container steel"},
{ code:"WD", description:"Intermediate bulk container aluminium"},
{ code:"WF", description:"Intermediate bulk container metal"},
{ code:"WG", description:"Intermediate bulk container steel pressurised > 10kPa"},
{ code:"WH", description:"Intermediate bulk container aluminium pressurised > 10kPa"},
{ code:"WJ", description:"Intermediate bulk container metal pressure 10kPa"},
{ code:"WK", description:"Intermediate bulk container steel liquid"},
{ code:"WL", description:"Intermediate bulk container aluminium liquid"},
{ code:"WM", description:"Intermediate bulk container metal liquid"},
{ code:"WN", description:"Intermediate bulk container woven plastic without coat / liner"},
{ code:"WP", description:"Intermediate bulk container woven plastic coated"},
{ code:"WQ", description:"Intermediate bulk container woven plastic with liner"},
{ code:"WR", description:"Intermediate bulk container woven plastic coated and liner"},
{ code:"WS", description:"Intermediate bulk container plastic film"},
{ code:"WT", description:"Intermediate bulk container textile without coat/liner"},
{ code:"WU", description:"Intermediate bulk container natural wood with inner liner"},
{ code:"WV", description:"Intermediate bulk container textile coated"},
{ code:"WW", description:"Intermediate bulk container textile with liner"},
{ code:"WX", description:"Intermediate bulk container textile coated and liner"},
{ code:"WY", description:"Intermediate bulk container plywood with inner liner"},
{ code:"WZ", description:"Intermediate bulk container recontituted wood with inner liner"},
{ code:"XA", description:"Bag woven plastic without inner coat/liner"},
{ code:"XB", description:"Bag woven plastic sift proof"},
{ code:"XC", description:"Bag woven plastic water resistant"},
{ code:"XD", description:"Bag plastics film"},
{ code:"XF", description:"Bag textile without inner coat/liner"},
{ code:"XG", description:"Bag textile sift proof"},
{ code:"XH", description:"Bag textile water resistant"},
{ code:"XJ", description:"Bag paper multi-wall"},
{ code:"XK", description:"Bag paper multi-wall water resistant"},
{ code:"YA", description:"ComposIte packaging plastic receptable in steel drum"},
{ code:"YB", description:"ComposIte packaging plastic receptable in steel crate box"},
{ code:"YC", description:"ComposIte packaging plastic receptable in aluminium drum"},
{ code:"YD", description:"ComposIte packaging plastic receptable in aluminium crate"},
{ code:"YF", description:"ComposIte packaging plastic receptable in wooden box"},
{ code:"YG", description:"ComposIte packaging plastic receptable in plywood drum"},
{ code:"YH", description:"ComposIte packaging plastic receptable in plywood box"},
{ code:"YJ", description:"ComposIte packaging plastic receptable in fibre drum"},
{ code:"YK", description:"ComposIte packaging plastic receptable in fibreboard box"},
{ code:"YL", description:"ComposIte packaging plastic receptable in plastic drum"},
{ code:"YM", description:"ComposIte packaging plastic receptable in solid plastic box"},
{ code:"YN", description:"Composite packaging glass receptaple in steel drum"},
{ code:"YP", description:"Composite packaging glass receptaple in steel crate box"},
{ code:"YQ", description:"Composite packaging glass receptaple in aluminium drum"},
{ code:"YR", description:"Composite packaging glass receptaple in aluminium"},
{ code:"YS", description:"Composite packaging glass receptable in wooden box"},
{ code:"YT", description:"Composite packaging glass receptaple in plywood drum"},
{ code:"YV", description:"Composite packaging glass receptaple in wickerwork hammer"},
{ code:"YW", description:"Composite packaging glass receptaple in fibre drum"},
{ code:"YX", description:"Composite packaging glass receptaple in fibreboard box"},
{ code:"YY", description:"Composite packaging glass receptaple in expandable plastic pack"},
{ code:"YZ", description:"Composite packaging glass receptaple in solid plastic pack"},
{ code:"ZA", description:"Intermediate bulk container paper multi-wall"},
{ code:"ZB", description:"Bag large"},
{ code:"ZC", description:"Intermediate bulk container paper multi-wall water resistant"},
{ code:"ZD", description:"Intermediate bulk container rigid plastic freestanding with structural equipment solids"},
{ code:"ZF", description:"Intermediate bulk container rigid plastic freestanding solids"},
{ code:"ZG", description:"Intermediate bulk container rigid plastic freestanding with structural equipment pressurised"},
{ code:"ZH", description:"Intermediate bulk container rigid plastic freestanding pressurised"},
{ code:"ZJ", description:"Intermediate bulk container rigid plastic freestanding with structural equipment liquids"},
{ code:"ZK", description:"Intermediate bulk container rigid plastic freestanding liquids"},
{ code:"ZL", description:"Intermediate bulk container composite rigid plastic solids"},
{ code:"ZM", description:"Intermediate bulk container composite flexible plastic solids"},
{ code:"ZN", description:"Intermediate bulk container composite rigid plastic pressurised"},
{ code:"ZP", description:"Intermediate bulk container composite flexible plastic pressurised"},
{ code:"ZQ", description:"Intermediate bulk container composite rigid plastic liquids"},
{ code:"ZR", description:"Intermediate bulk container composite flexible plastic liquids"},
{ code:"ZS", description:"Intermediate bulk container composite"},
{ code:"ZT", description:"Intermediate bulk container fibreboard"},
{ code:"ZU", description:"Intermediate bulk container flexible"},
{ code:"ZV", description:"Intermediate bulk container metal other than steel"},
{ code:"ZW", description:"Intermediate bulk container natural wood"},
{ code:"ZX", description:"Intermediate bulk container plywood"},
{ code:"ZY", description:"Intermediate bulk container recontituted wood"},
{ code:"ZZ", description:"Mutually defined"}
]