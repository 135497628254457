export const shippingOperatorTypeOptions = [
    { name: "None", description: "None"},
    { name: "Ferry", description: "Ferry"},
    { name: "Subcontractor", description: "Subcontractor"},
    { name: "Service", description: "Service"},
    { name: "Equipment", description: "Equipment"},
    { name: "FerryAgent", description: "Ferry Agent"},
    { name: "LoLo", description: "Lift Off Lift On"},
    { name: "ContractHire", description: "Contract Hire"},
    { name: "DeepSea", description: "Deep Sea"},
    { name: "Train", description: "Train"}
]