import { Button, LoadIndicator, LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store'
import Form, { GroupItem, Item } from 'devextreme-react/form'
import { DeclarationGoods, DeclarationRequest } from '../../app/models/declarationCreate'
import MessageInformation from './MessgeInformaton'
import CustomsSummary from './CustomsSummary'
import References from './References'
import Parties from './Parties'
import ValuationInformationTaxes from './ValuationInformationTaxes'
import Places from './Places'
import OtherDataElements from './OtherDataElements'
import TransportInformation from './TransportInformation'
import CustomsOffice from './CustomsOffice'
import GoodsIdentification from './GoodsIdentificaton'
import Goods from './Goods'
import AddGoods from './AddGoods/AddGoods'
import { useEffect, useState } from 'react'
import { notifyMessage } from '../../app/common/utils/notify'
import { DeclarationSendToAuthority } from '../../app/models/declarationSendToAuthority'
import { CopyDeclaration } from '../../app/models/declarationCopy'
import { deepClone } from '../../app/common/utils/accountFunctions'
import { UpdateDeclarationRequest } from '../../app/models/declarationUpdate'
import SafetyAndSecurityConsingment from './SafetyAndSecurity/SafetyAndSecurityConsignment'
import { DefaultDeclarationObject } from '../../app/common/options/declarationTemplates/defaultDeclarationObject'
import { DelcarationIsLrnUniqueRequest } from '../../app/models/declarationIsLrnUniqueRequest'
import { useParams } from 'react-router-dom'
import { router } from '../../app/router/Routes'
import { useAddDeclaration, useDeclaration, useUpdateDeclaration } from '../../app/hooks/declarationQueries'
import { ServerError } from '../../app/models/serverError'
import notify from 'devextreme/ui/notify'

export default observer(function DeclarationEdit() {
    const { declarationStore, organisationStore, userStore, commonStore } = useStore()
    const [addingGoods, setAddingGoods] = useState(false);
    const [selectedGoodsItem, setSelectedGoodsItem] = useState(-1);
    const { authoritySystem, isImport, isExs, id } = useParams<{ authoritySystem: string; isImport: string; isExs: string; id: string; }>()
    const { isLoading, isError, error } = useDeclaration(id)
    const addDeclaration = useAddDeclaration()
    const updateDeclaration = useUpdateDeclaration()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [expressView, setExpressView] = useState<boolean>(true)

    useEffect(() => {
        if (isError) {
            let errorMsg = error instanceof Error ? error.message : error.toString()
            const serverError: ServerError = {
                Succeeded: false,
                Message: "Error retrieving port clearance",
                Errors: [errorMsg]
            }
            commonStore.setServerError(serverError)
            router.navigate('/server-error')
        }
    }, [isError, error, commonStore])

    useEffect(() => {
        organisationStore.getOrganisationMe()
    }, [declarationStore, organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    let initialIsImportBool = true;
    if(!id && isImport)
        initialIsImportBool = (isImport == 'true')

    const [declarationObj, setDeclarationObj] = useState<DeclarationRequest>(DefaultDeclarationObject(organisationId, initialIsImportBool));
    
    if(!id && authoritySystem)
        declarationObj.request.importDeclarationData.authoritySystem = authoritySystem

    if(id && declarationStore.declaration)
    {
        // some older Declarations have an issue with Valuation Information, this stops the issue 
        const defaultValuationObj = deepClone(declarationObj.request.importDeclarationData.valuationInformationTaxes)
        declarationObj.request.importDeclarationData = declarationStore.declaration;
        if(declarationObj.request.importDeclarationData.valuationInformationTaxes == null)
            declarationObj.request.importDeclarationData.valuationInformationTaxes = defaultValuationObj;
    }

    if(!id && (isExs == 'true'))
    {
        declarationObj.request.importDeclarationData.additionalDeclarationType = 'J';
        declarationObj.request.importDeclarationData.declarationType = 'A1';
    }

    const [formData, setFormData] = useState<DeclarationRequest>(declarationObj)
    const [selectedDecType, setSelectedDecType] = useState(formData.request.importDeclarationData.declarationType);
    const [selectedSecurity, setSelectedSecurity] = useState(formData.request.importDeclarationData.messageInformationEns?.security);
    const [selectedOrigin, setSelectedOrigin] = useState(formData.request.importDeclarationData.places.countryOfDispatchCode);
    const [selectedDestination, setSelectedDestination] = useState(formData.request.importDeclarationData.places.countryOfDispatchCode);

    if(!formData.request.importDeclarationData.valuationInformationTaxes)
        formData.request.importDeclarationData.valuationInformationTaxes = declarationObj.request.importDeclarationData.valuationInformationTaxes;

    if(!id && isImport)
        formData.request.importDeclarationData.isImport = initialIsImportBool;

    if(isLoading)
        return <LoadIndicator className="organisations-load-indicator" />

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      function findGoodsItemById() {
        if(selectedGoodsItem < 0)
            return null;

        let matchedItem = formData.request.importDeclarationData.goods.find((element) => {
          return element.itemDetail.messageInformation.goodsItemNumber === selectedGoodsItem;
        })

        if(matchedItem !== undefined)
            return matchedItem;

        return null;
      }

      function handleOpenAddGoods(){
        return <div className='html-blank-profile'>
                <div className='profile-container form-with-shadows-profile'>
                    <AddGoods goodsItemId={formData.request.importDeclarationData.goods.length+1} closeFunction={handleCloseAddGoods} formDataToUpdate={findGoodsItemById()} decType={formData.request.importDeclarationData.declarationType} summarySection={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />
                </div>
                </div>
    }

    function handleCloseAddGoods(goodsItemAdded: DeclarationGoods | null){
        if(goodsItemAdded !== null)
        {
            if(selectedGoodsItem > 0)
            {
                formData.request.importDeclarationData.goods = formData.request.importDeclarationData.goods.filter(a => a.itemDetail.messageInformation.goodsItemNumber !== selectedGoodsItem)
            }

            formData.request.importDeclarationData.goods.push(goodsItemAdded);
        }
        calculateTotalPackages();
        setSelectedGoodsItem(-1)
        setAddingGoods(false);
        
        setFormData(prevState => ({
                    organisationId: prevState.organisationId,
                    bucketId: prevState.bucketId,
                    authority: prevState.authority,
                    request: {
                    ...prevState.request,
                    importDeclarationData: {
                        ...prevState.request.importDeclarationData,
                        totalNumberPackages: formData.request.importDeclarationData.totalNumberPackages
                    }
                }
            }));
    }

    function handleRowRemoved(){
        handleCloseAddGoods(null);
    }

    function calculateTotalPackages(){
        let sumTotal: number = 0;
        formData.request.importDeclarationData.goods.forEach(element => {
            element.itemDetail.goodsIdentification.packages.forEach(a => sumTotal += (a.numberPackages === null ? 0 : a.numberPackages))
        });

        formData.request.importDeclarationData.totalNumberPackages = sumTotal;
    }

    function handleEditGoodsItem(goodsItemId: number)
    {
        setSelectedGoodsItem(goodsItemId)
        setAddingGoods(true)
    }

    if (!userStore.user) {
        return <LoadPanel visible={true} />
    }

    if (addingGoods){           
        return handleOpenAddGoods()
    }

    function backToMain(){
        router.navigate(`/declarations-main`)
    }

    function cancelButtonClicked(){
        declarationStore.declaration = null;
        backToMain();
    }

    function saveGoodsItem(){
        if(formData.request.importDeclarationData.lrn.length === 0)
        {
            notifyMessage("warning", "Local Reference Number is required")
        }
        else if(formData.request.importDeclarationData.declarationType.length === 0)
        {
            notifyMessage("warning", "Declaration Type is required")
        }
        else if(formData.request.importDeclarationData.additionalDeclarationType.length === 0)
        {
            notifyMessage("warning", "Additional Declaration Type is required")
        }
        else
        {
            setSubmitting(v => !v)
            if(id && declarationStore.declaration)
            {
                handleUpdateDeclaration()
            }
            else
            {
                handleCheckLrnUniqueness()
            }
            setSubmitting(v => !v)
        }
    }

    async function createDeclaration(){
        if(declarationStore.isLrnUniqueResult)
        {
            handleCreateDeclaration();
        }
        else
        {
            notifyMessage("warning", "Local Reference Number is not unique for previous 48 hours")
        }
    }

    const handleCheckLrnUniqueness = async () => {		
		let isLrnUniqueRequest : DelcarationIsLrnUniqueRequest = {organisationId: organisationId, localReferenceNumber: formData.request.importDeclarationData.lrn}
        await declarationStore.isLrnUnique(isLrnUniqueRequest)
		if (declarationStore.isSuccess) {
            createDeclaration()
		} 
        else {
            notifyMessage("warning", "Failed to check LRN uniqueness");
		}
	};

    const handleCreateDeclaration = async () => {
        if(formData)
        {
            const response = await addDeclaration.mutateAsync(formData)
            if(response && response.isSuccess)
            {
                backToMain()
            }
            else if(response && response.isError)
            {
                notify(response.error, 'error')
            }
        }
	};

    const handleUpdateDeclaration = async() => {
        if(id && formData)
        {
            let updateDec: UpdateDeclarationRequest = {declarationId: id, request: deepClone(formData.request.importDeclarationData)};
            const response = await updateDeclaration.mutateAsync(updateDec)
            if(response && response.isSuccess)
            {
                backToMain()
            }
            else if(response && response.isError)
            {
                notify(response.error, 'error')
            }
        }
    }

    const handleSendToAuthority = async() => {
        if(!id)
            return;

        let sendToAuthority: DeclarationSendToAuthority = {declarationId: id}
        await declarationStore.sendToAuthority(sendToAuthority)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Sent to authority");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to send");
    }

    const handleCopyDeclaration = async() => {
        if(!id)
            return;

        let declarationToCopy: CopyDeclaration = {declarationId: id}
        await declarationStore.copyDeclaration(declarationToCopy)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Copied declaration");
            backToMain();
        }
        else
            notifyMessage("warning", "Could not copy declaration");
    }

    const handleGetStatusUpdate = async() => {
        if(!id)
            return;

        await declarationStore.getStatusUpdate(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Status update requested");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to request status update");
    }

    const handleDeleteAuthority = async() => {
        if(!id)
            return;

        await declarationStore.deleteAuthority(id)
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "Authority link deleted");
            backToMain();
        }
        else
            notifyMessage("warning", "Failed to remove authority link");
    }

    function isFieldVisible(fieldName: string) {
        // if there is no linked template, then all fields should appear 
        if(!expressView)
           return true;

        const expressFields = declarationStore.declaration?.expressFields
        if(!expressFields || expressFields.length < 1)
            return true;

        let matchedItem3 = expressFields.find((element) => {
            return element.name === fieldName
        })

        if(matchedItem3 !== undefined)
            return true;

        return false;
    }

    function handleExpressView(){
        setExpressView(x=> !x)
    }

    const addBtnText = (id && declarationStore.declaration) ? "Update" : "Save";
    const isExpress = (declarationStore.declaration?.expressFields && declarationStore.declaration.expressFields.length > 0) ? true : false;
    let pageCaption = ((!id && isExs == 'true') || (declarationObj.request.importDeclarationData.declarationType.startsWith('A'))) && 'Exit Summary' || ((formData.request.importDeclarationData.isImport) && 'Import' || 'Export');
    
    if(authoritySystem === "TSS" || formData.request.importDeclarationData.authoritySystem === "TSS")
    {
        declarationObj.request.importDeclarationData.additionalDeclarationType = 'SUP';
        pageCaption = "Supplementary";
    }

    return (<div className='html-blank-profile'>
    <div className='profile-container form-with-shadows-profile'>
    {submitting && <LoadIndicator className="organisations-load-indicator" />}
    {!submitting &&
        <form onSubmit={(e) => handleSubmit(e)}>
            <Form formData={formData} >
                <GroupItem colCount={2} caption={pageCaption} >
                    <GroupItem>
                        <MessageInformation formData={formData.request.importDeclarationData} onDeclarationTypeChanged={setSelectedDecType} onSecurityChanged={setSelectedSecurity} isFieldVisible={isFieldVisible} />
                        <References formData={formData.request.importDeclarationData} isTemplate={false}  isFieldVisible={isFieldVisible}/>
                        <Parties formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <ValuationInformationTaxes formData={formData.request.importDeclarationData}  isFieldVisible={isFieldVisible} />
                        <Places formData={formData.request.importDeclarationData} onOriginCountryChanged={setSelectedOrigin} onDestinationCountryChanged={setSelectedDestination} isFieldVisible={isFieldVisible} />
                        <CustomsOffice formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <GoodsIdentification formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} />
                        <TransportInformation formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible}  />
                        {(!formData.request.importDeclarationData.isImport && selectedSecurity != 0) && <SafetyAndSecurityConsingment formData={formData.request.importDeclarationData} fieldErrors={formData.request.importDeclarationData.fieldErrors} isFieldVisible={isFieldVisible} />}
                        <OtherDataElements formData={formData.request.importDeclarationData} isFieldVisible={isFieldVisible} /> 
                    </GroupItem>
                    <GroupItem>
                        <CustomsSummary formData={formData.request.importDeclarationData} />
                        <Button onClick={saveGoodsItem} text={addBtnText} disabled={submitting} />
                        <Button onClick={cancelButtonClicked} text='Cancel' disabled={submitting} />
                        <Button onClick={handleExpressView} text={(expressView) ? 'Express off' : 'Express on'} visible={isExpress} />
                        {id && <Button onClick={handleSendToAuthority} text='Send To Authority' disabled={submitting} visible={!declarationObj.request.importDeclarationData.sentToAuthority} />}
                        {id && <Button onClick={handleGetStatusUpdate} text='Get Status Update' disabled={submitting} visible={declarationObj.request.importDeclarationData.sentToAuthority} />}
                        {id && <Button onClick={handleCopyDeclaration} text='Copy' disabled={submitting} />}
                        {id && <Button onClick={handleDeleteAuthority} text='Cancel Authority' disabled={submitting} visible={declarationObj.request.importDeclarationData.sentToAuthority} />}
                    </GroupItem>
                </GroupItem>
                <GroupItem colCount={1} visible={isFieldVisible("section-goods")}>
                    <Goods formData={formData.request.importDeclarationData} setAdding={setAddingGoods} setUpdate={handleEditGoodsItem} onRowDeleted={handleRowRemoved} />
                </GroupItem>
            </Form>
        </form>
    }
    </div >
</div >)
})