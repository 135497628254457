import {
  ButtonItem,
  CustomRule,
  Form,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { ApiClientForm } from "../../app/models/account";
import {
  CreatingIpRows,
  IsValidIp,
  keySize,
} from "../../app/common/utils/accountFunctions";
import { useStore } from "../../app/stores/store";
import { Fragment, useState } from "react";
import { notifyMessage } from "../../app/common/utils/notify";
import { Button } from "devextreme-react";
import FormContainer from "../../app/layout/components/FormContainer";
import ApiInfoPopup from "./ApiInfoPopup";

interface Props {
  closeFunction: () => void;
}

let formData: ApiClientForm = {
  clientKey: "",
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  validIps: [""],
};

function AddApiclient({ closeFunction }: Props) {
  const { accountStore } = useStore();
  const [ipOptions, setIpOptions] = useState<any[]>(formData.validIps);
  const [showPopup, setShowPopup] = useState(false);

  function handleSubmit(e: any) {
    e.preventDefault();
    if (formData.validIps[0] !== "") {
      accountStore
        .createApiclient(formData)
        .then(() => setShowPopup(true))
        .catch(() => notifyMessage("error", "Api client could not be created"));
    }
  }

  function CloseScreen(form: ApiClientForm) {
    formData = {
      clientKey: "",
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      validIps: [""],
    };
    setShowPopup(false);
    accountStore.getApiClient().then(() => closeFunction());
  }

  const addApliClientForm = (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Form className="add-template-form" formData={formData}>
        <GroupItem name="clientkey-container">
          <Item dataField="clientKey">
            <RequiredRule message="the client key is required" />
            <CustomRule
              message={"A minimun of 20 characters is required"}
              validationCallback={(e: any) => keySize(e)}
            />
          </Item>
        </GroupItem>
        <GroupItem name="ip-container">
          <GroupItem name="ip">
            {ipOptions.map((ip, index) => (
              <Item
                key={`validIps${index}`}
                dataField={`validIps[${index}]`}
                editorOptions={ip}
              >
                <Label text={`IP ${index + 1}`} />
                <RequiredRule message="the valid IPs is required" />
                <CustomRule
                  message={"That is not a valid IP"}
                  validationCallback={(e: any) => IsValidIp(e)}
                />
              </Item>
            ))}
          </GroupItem>
        </GroupItem>
        <ButtonItem
          horizontalAlignment={"left"}
          buttonOptions={{
            icon: "plus",
            type: "success",
            onClick: () => {
              formData.validIps.push("");
              CreatingIpRows(formData, ipOptions, setIpOptions);
            },
          }}
        />
        <ButtonItem
          cssClass={"add-general-save-btn"}
          horizontalAlignment={"left"}
          buttonOptions={{
            width: "90",
            text: "Save",
            type: "default",
            stylingMode: "contained",
            useSubmitBehavior: true,
          }}
        />
        <Item cssClass={"add-general-cancel-btn"}>
          <Button
            onClick={() => {
              formData = {
                clientKey: "",
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                validIps: [""],
              };
              closeFunction();
            }}
            type="normal"
            className="dx-widget dx-button dx-button-mode-contained dx-button-has-text"
          >
            <div className="dx-button-text">Cancel</div>
          </Button>
        </Item>
      </Form>
    </form>
  );

  return (
    <Fragment>
      <div className="html-blank-organisation">
        <div className="organisation-container">
          <FormContainer
            title={"Add API Client"}
            children={addApliClientForm}
            closeFunction={closeFunction}
          />
        </div>
      </div>
      <ApiInfoPopup
        ApiData={formData}
        ShowInfo={showPopup}
        HideScreen={() => CloseScreen(formData)}
      />
    </Fragment>
  );
}

export default AddApiclient;
