import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "devextreme-react"
import { useNavigate } from "react-router-dom"

export default function HomePage() {
    const { loginWithRedirect } = useAuth0()
    const navigate = useNavigate()
    const versionNumner = process.env.REACT_APP_VERSION
    return (
        <div className='html-blank'>
            <div className="home-page">
                <div className="home-page-buttons-holder">
                    <Button
                        className="button-default home-page-buttons"
                        text="register"
                        type="default"
                        onClick={() => navigate("/register-user")}
                    ></Button>
                    <Button
                        className="button-default home-page-buttons"
                        text="login"
                        type="default"
                        onClick={() => loginWithRedirect()}
                    ></Button>
                </div>
                <div>
                    <div className="home-page-text-holder">
                        <p className="home-page-text title">
                            Welcome to mycustomsclouds.com
                        </p>
                        <div className="home-page-subtitle-container">
                            <hr className="home-page-horizontal-line"></hr>
                            <p className="home-page-text subtitle">
                                One place for your data, commercial invoices and
                                network partners
                            </p>
                            <hr className="home-page-horizontal-line"></hr>
                            <div><small style={{ color: "#fff", paddingLeft: 10 }}>v{versionNumner}</small></div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
