import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { DataGrid } from 'devextreme-react'
import { Column, CustomRule, Editing, Lookup } from 'devextreme-react/data-grid'
import { taxTypeOptions } from '../../../app/common/options/cdsImportDeclaration/taxTypeOptions'
import { currencyOptions } from '../../../app/common/options/currencyOptions'
import { methodOfPaymentOptions } from '../../../app/common/options/cdsImportDeclaration/methodOfPaymentOptions'
import { additionsAndDeductionsCodeOptions } from '../../../app/common/options/cdsImportDeclaration/additionsAndDeductionsCodeOptions'
import { isVisible } from '../../../app/common/options/declarationTemplates/declarationTemplate';
import { warehouseTypeOptions } from '../../../app/common/options/cdsImportDeclaration/warehouseTypeOptions'
import { valuationMethodOptions } from '../../../app/common/options/cdsImportDeclaration/valuationMethodOptions'
import { getErrorMessageWithSequence, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions'
import { useEffect, useRef, useState } from 'react'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods;
    decType: string;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsValuationInformationTaxes({formData, decType, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        valuationMethodRule: boolean
        preferenceRule: boolean
        totalTaxCalculatedRule: boolean
        currencyRule: boolean
        methodOfPaymentRule: boolean
        warehouseTypeRule: boolean
        itemPriceAmountRule: boolean
        valuationIndicatorsRule: boolean
        totalAmountInvoicedRule: boolean
    } = {
        valuationMethodRule: isFieldValidWithSequence("valuationMethod", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        preferenceRule: isFieldValidWithSequence("preference", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        totalTaxCalculatedRule: isFieldValidWithSequence("totalTaxCalculated", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        currencyRule: isFieldValidWithSequence("currency", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        methodOfPaymentRule: isFieldValidWithSequence("methodOfPayment", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        warehouseTypeRule: isFieldValidWithSequence("warehouseType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        itemPriceAmountRule: isFieldValidWithSequence("itemPriceAmount", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        valuationIndicatorsRule: isFieldValidWithSequence("valuationIndicators", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        totalAmountInvoicedRule: isFieldValidWithSequence("totalAmountInvoiced", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
    };

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={1} caption={'Valuation Information-Taxes'} visible={isFieldVisible("section-goods.valuationInformationTaxes")}>
                <GroupItem cssClass="cds-group-item" colCount={2}>
                    <Item dataField="itemDetail.valuationInformationTaxes.valuationMethod" label={{text: "Valuation Method"}} editorType="dxSelectBox"
                        visible={isFieldVisible("goods.valuationInformationTaxes.valuationMethod")}
                        editorOptions={{
                            items: valuationMethodOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                        }}>
                        <CustomRule message={getErrorMessageWithSequence("valuationMethod", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.valuationMethodRule}} />
                    </Item>
                    <Item dataField="itemDetail.valuationInformationTaxes.preference" label={{text: "Preference"}} visible={isFieldVisible("goods.valuationInformationTaxes.preference")}>
                        <CustomRule message={getErrorMessageWithSequence("preference", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.preferenceRule}} />
                    </Item>
                        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
                <GroupItem colCount={1}>
                    <GroupItem cssClass="cds-group-item" colCount={2} caption={'Tax base'} visible={isFieldVisible("goods.valuationInformationTaxes.taxBaseAmount")}>
                        <DataGrid dataSource={formData.itemDetail.valuationInformationTaxes.taxBaseAmount} >
                            <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                            <Column dataField="amount" visible={isFieldVisible("goods.valuationInformationTaxes.taxBaseAmount.amount")}></Column>
                            <Column dataField="unit" visible={isFieldVisible("goods.valuationInformationTaxes.taxBaseAmount.unit")}></Column>
                            <Column dataField="rate" visible={isFieldVisible("goods.valuationInformationTaxes.taxBaseAmount.rate")}></Column>
                        </DataGrid>
                    </GroupItem>
                    <GroupItem cssClass="cds-group-item" colCount={2} caption={'Calculation of taxes'} visible={isFieldVisible("goods.valuationInformationTaxes.payableTaxAmount")}>
                        <DataGrid dataSource={formData.itemDetail.valuationInformationTaxes.calculationOfTaxes.payableTaxAmount} >
                            <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                            <Column dataField="payableTaxAmount" caption={'Amount'} visible={isFieldVisible("goods.valuationInformationTaxes.payableTaxAmount.amount")}></Column>
                            <Column dataField="currency" visible={isFieldVisible("goods.valuationInformationTaxes.payableTaxAmount.currency")}><Lookup dataSource={currencyOptions} displayExpr="name" valueExpr="code" /></Column>
                            <Column dataField="methodOfPayment" caption={'Payment Method'} visible={isFieldVisible("goods.valuationInformationTaxes.payableTaxAmount.methodOfPayment")}>
                                <Lookup dataSource={methodOfPaymentOptions} valueExpr='code' displayExpr='description' />
                            </Column>
                            <Column dataField="taxType" visible={isFieldVisible("goods.valuationInformationTaxes.payableTaxAmount.taxType")}><Lookup dataSource={taxTypeOptions} displayExpr="description" valueExpr="code" /></Column>
                        </DataGrid>
                    </GroupItem>
                    <GroupItem  cssClass="cds-group-item" colCount={2}>
                        <Item dataField="itemDetail.valuationInformationTaxes.calculationOfTaxes.totalTaxCalculated" label={{text: "Total tax calculated"}} visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.totalTaxCalculated")} >
                            <CustomRule message={getErrorMessageWithSequence("totalTaxCalculated", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.totalTaxCalculatedRule}} />
                        </Item>
                        <Item dataField="itemDetail.valuationInformationTaxes.calculationOfTaxes.currency" label={{text: "Currency"}} editorType="dxSelectBox"
                                visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.currency")}
                                editorOptions={{
                                    items: currencyOptions,
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "code",
                                }}>
                            <CustomRule message={getErrorMessageWithSequence("currency", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.currencyRule}} />
                        </Item>
                        <Item dataField="itemDetail.valuationInformationTaxes.methodOfPayment" label={{text: "Method of payment"}} editorType="dxSelectBox"
                                visible={isFieldVisible("goods.valuationInformationTaxes.methodOfPayment")}
                                editorOptions={{
                                    items: methodOfPaymentOptions,
                                    searchEnabled: true,
                                    displayExpr: "description",
                                    valueExpr: "code",
                                }}>
                            <CustomRule message={getErrorMessageWithSequence("methodOfPayment", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.methodOfPaymentRule}} />
                        </Item>
                        <Item dataField="itemDetail.valuationInformationTaxes.warehouseType" label={{text: "Warehouse type"}} editorType="dxSelectBox"
                                visible={isFieldVisible("goods.valuationInformationTaxes.warehouseType")}
                                editorOptions={{
                                    items: warehouseTypeOptions,
                                    searchEnabled: true,
                                    displayExpr: "description",
                                    valueExpr: "code"
                                }}>
                            <CustomRule message={getErrorMessageWithSequence("warehouseType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.warehouseTypeRule}} />
                        </Item>
                        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                    </GroupItem>
                    <GroupItem  cssClass="cds-group-item" colCount={2}>
                        <Item dataField="itemDetail.valuationInformationTaxes.calculationOfTaxes.itemPriceAmount" label={{text: "Item price/amount"}} visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.itemPriceAmount")}>
                            <CustomRule message={getErrorMessageWithSequence("itemPriceAmount", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.itemPriceAmountRule}} />
                        </Item>
                        <Item dataField="itemDetail.valuationInformationTaxes.calculationOfTaxes.valuationIndicators" label={{text: "Valuation indicators"}} visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.valuationIndicators")}>
                            <CustomRule message={getErrorMessageWithSequence("valuationIndicators", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.valuationIndicatorsRule}} />
                        </Item>
                        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                    </GroupItem>
                    <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={1} caption={'Additions and deductions'} visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions")}>
                    <DataGrid dataSource={formData.itemDetail.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions} >
                        <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                        <Column dataField="value" visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.value")}></Column>
                        <Column dataField="currency" visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.currency")}><Lookup dataSource={currencyOptions} displayExpr="name" valueExpr="code" /></Column>
                        <Column dataField="type" visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.additionsAndReductions.type")}><Lookup dataSource={additionsAndDeductionsCodeOptions} displayExpr="description" valueExpr="code" /></Column>
                    </DataGrid>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={1} >
                    <Item dataField="itemDetail.valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced" label={{text: "Total amount invoiced"}} visible={isFieldVisible("goods.valuationInformationTaxes.calculationOfTaxes.totalAmountInvoiced")}>
                        <CustomRule message={getErrorMessageWithSequence("totalAmountInvoiced", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.totalAmountInvoicedRule}} />
                    </Item>
                    <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
                </GroupItem>
                <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
            </GroupItem>
            </Form>
}