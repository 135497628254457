import React, { Fragment, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useStore } from '../stores/store'
import { LoadPanel } from 'devextreme-react'
import { useAuth0 } from '@auth0/auth0-react'
import HomePage from '../../features/homePage/HomePage'
import { observer } from 'mobx-react-lite'
import { useScreenSizeClass } from '../common/utils/mediaQuery'
import Footer from './components/Footer'
import SideNavOuterToolbar from './components/SideNavOuterToolbar'

function App() {
  const location = useLocation()
  const { commonStore, userStore } = useStore()
  const { isLoading, isAuthenticated } = useAuth0()
  const screenSizeClass = useScreenSizeClass()

  useEffect(() => {
    if (!commonStore.appLoaded) {
      if (isAuthenticated && commonStore.token) {
        userStore.getUser().finally(() => commonStore.setAppLoaded())
      } else if (!isLoading && !isAuthenticated) {
        commonStore.setAppLoaded()
      }
    }
  }, [isLoading, isAuthenticated, commonStore, userStore])

  if (!commonStore.appLoaded) return <LoadPanel visible={true} />

  return (
    <Fragment>
      {!isAuthenticated && location.pathname === "/" ? (
        <HomePage />
      ) : !isAuthenticated ? (
        <Outlet />
      ) : (

        <div className={`app ${screenSizeClass}`}>
          <SideNavOuterToolbar >

            <Outlet />

            <Footer />

          </SideNavOuterToolbar>
        </div>
      )}
    </Fragment>
  )
}

export default observer(App)
