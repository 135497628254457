import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import { useStore } from '../../app/stores/store'
import { Button, LoadIndicator } from 'devextreme-react'
import Form, {
    GroupItem,
    Item,
} from "devextreme-react/form"
import notify from "devextreme/ui/notify"
import { useAddPortClearance, usePortClearance, useUpdatePortClearance } from "../../app/hooks/portClearanceQueries"
import { ServerError } from "../../app/models/serverError"
import { router } from "../../app/router/Routes"
import { useAuthorityCustomsCarriers, useAuthorityCustomsPorts, useAuthorityCustomsRoutes, useCustomsCarriers, useCustomsPorts, useCustomsRoutes, useReferenceData } from "../../app/hooks/managementQueries"
import { MRN, MRNForm, PortClearanceDataForm } from '../../app/models/clearances'
import { useEffect, useMemo, useState } from "react"
import RouteForm from './PortClearanceRouteForm'
import PortClearanceTaskForm from './PortClearanceResourceForm'
import PortClearanceMrnsGrid from './PortClearanceMrnsGrid'
import { notifyMessage } from "../../app/common/utils/notify"

export default observer(function PortClearanceForm() {
    const { id } = useParams<{ id: string }>()
    const { clearanceStore, commonStore } = useStore()
    const { isLoading: isReferenceData } = useReferenceData()
    const { isLoading: isPortsLoading } = useCustomsPorts()
    const { isLoading: isRoutesLoading } = useCustomsRoutes()
    const { isLoading: isCarriersLoading } = useCustomsCarriers()
    const { isLoading: isAuthorityPortsLoading } = useAuthorityCustomsPorts()
    const { isLoading: isAuthorityRoutesLoading } = useAuthorityCustomsRoutes()
    const { isLoading: isAuthorityCarriersLoading } = useAuthorityCustomsCarriers()
    const { isLoading, isError, error } = usePortClearance(id)
    const addPortClearance = useAddPortClearance()
    const updatePortClearance = useUpdatePortClearance()
    const [portClearanceData, setPortClearanceData] = useState<PortClearanceDataForm>()
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (isError) {
            let errorMsg = error instanceof Error ? error.message : error.toString()
            const serverError: ServerError = {
                Succeeded: false,
                Message: "Error retrieving port clearance",
                Errors: [errorMsg]
            }
            commonStore.setServerError(serverError)
            router.navigate('/server-error')
        }
    }, [isError, error, commonStore])

    useEffect(() => {
        if(!isRoutesLoading && !isCarriersLoading)
        {
            setPortClearanceData(new PortClearanceDataForm(id?clearanceStore.portClearance:null))
        }
    }, [id, isRoutesLoading, isCarriersLoading, clearanceStore.portClearance])

   
    const exitPorts = useMemo(() => {
        const route = clearanceStore.customsRoutes?.find(r => r.id === portClearanceData?.customsRouteId)
        return clearanceStore.customsPorts?.filter(port => port.countryIsoCode === route?.startRegionIsoCode)
    }, [clearanceStore.customsPorts, portClearanceData?.customsRouteId, clearanceStore.customsRoutes])

    const entryPorts = useMemo(() => {
        const route = clearanceStore.customsRoutes?.find(r => r.id === portClearanceData?.customsRouteId)
        return clearanceStore.customsPorts?.filter(port => port.countryIsoCode === route?.endRegionIsoCode)
    }, [clearanceStore.customsPorts, portClearanceData?.customsRouteId, clearanceStore.customsRoutes])

    if (!portClearanceData || isLoading || isPortsLoading || isRoutesLoading || isCarriersLoading || isReferenceData || isAuthorityCarriersLoading 
        || isAuthorityPortsLoading || isAuthorityRoutesLoading ||
        (id && !clearanceStore.portClearance) || !clearanceStore.customsRoutes?.length || !clearanceStore.customsCarriers?.length) {
        return <LoadIndicator className="organisations-load-indicator" />
    }

const isRouteValid = () => {
    // only a required check if GB is involved as the route needs to be valid on the HMRC database
    const route = clearanceStore.customsRoutes?.find(r => r.id === portClearanceData?.customsRouteId)
    const isGbInvolved = (route?.endRegionIsoCode === "GB" || route?.startRegionIsoCode === "GB")
    if(!isGbInvolved)
        return true;

    const authorityCarrier = clearanceStore.authorityCustomsCarriers?.find(r => r.carrierId === portClearanceData?.customsCarrierId)
    const authorityEntryPort = clearanceStore.authorityCustomsPorts?.find(r => r.portId === portClearanceData?.entryTask.customsPortId)
    const authorityExitPort = clearanceStore.authorityCustomsPorts?.find(r => r.portId === portClearanceData?.exitTask.customsPortId)

    const matchedRoute = clearanceStore.authorityCustomsRoutes?.find(
        r => r.routeId === portClearanceData?.customsRouteId
        && r.carrierId === authorityCarrier?.id
        && r.arrivalPortId === authorityEntryPort?.id
        && r.departurePortId === authorityExitPort?.id
    )

    if(!matchedRoute)
    {
        var routeWithoutCarrier = clearanceStore.authorityCustomsRoutes?.find(
            r => r.routeId === portClearanceData?.customsRouteId
            && r.arrivalPortId === authorityEntryPort?.id
            && r.departurePortId === authorityExitPort?.id
        )
        var routeWithoutArrivalPort = clearanceStore.authorityCustomsRoutes?.find(
            r => r.routeId === portClearanceData?.customsRouteId
            && r.carrierId === authorityCarrier?.id
            && r.departurePortId === authorityExitPort?.id
        )
        var routeWithoutDeparturePort = clearanceStore.authorityCustomsRoutes?.find(
            r => r.routeId === portClearanceData?.customsRouteId
            && r.carrierId === authorityCarrier?.id
            && r.arrivalPortId === authorityEntryPort?.id
        )

        if(routeWithoutCarrier)
            notifyMessage("warning", "Carrier does not operate this route")
        else if(routeWithoutArrivalPort || routeWithoutDeparturePort)
            notifyMessage("warning", "Ports are invalid for this route")
        else
            notifyMessage("warning", "Route is invalid")

        return false;
    }

    return true;
}

    const isDataValid = () => {
        if(!portClearanceData)
            return false;
        
        const route = clearanceStore.customsRoutes?.find(r => r.id === portClearanceData?.customsRouteId)
        const isGbInvolved = (route?.endRegionIsoCode === "GB" || route?.startRegionIsoCode === "GB")
        const isFrInvolved = (route?.endRegionIsoCode === "FR" || route?.startRegionIsoCode === "FR")

        if(isGbInvolved)
        {
            if(!portClearanceData.isVehicleUnaccompanied && !portClearanceData.vehicleRegistration)
            {
                notifyMessage("warning", "Vehicle registration is required")
                return false;
            }
            else if(portClearanceData.isVehicleUnaccompanied && !portClearanceData.trailerRegistration)
            {
                notifyMessage("warning", "Trailer registration is required")
                return false;
            }
        }
        
        if(isFrInvolved)
        {
            const exportMrns = portClearanceData.mrns?.find(mrn => mrn.actionType === "Export")
            if(!exportMrns)
            {
                notifyMessage("warning", "France requires an export MRN")
                return false;
            }

            const importMrns = portClearanceData.mrns?.find(mrn => mrn.actionType === "Import")
            if(!importMrns)
            {
                notifyMessage("warning", "France requires an import MRN")
                return false;
            }
        }

        return true;
    }

    async function handleFormSubmit() {
        if(!isRouteValid() || !isDataValid())
            return null;

        setSubmitting(v => !v)
        try {
            if (id) {
                // Update...
                if (portClearanceData) {
                    const response = await updatePortClearance.mutateAsync(portClearanceData)
                    if (response && response.isSuccess) {
                        router.navigate('/port-clearances')
                    } else if (response && response.error) {
                        notify(response.error, 'error')
                    }
                }
            } else {
                // Create new...                
                if (portClearanceData) {
                    const response = await addPortClearance.mutateAsync(portClearanceData)
                    if (response && response.isSuccess) {
                        router.navigate('/port-clearances')
                    } else if (response && response.error) {
                        notify(response.error, 'error')
                    }
                }
            }
        } catch (error: any) {
            console.log("Error handling submit: ", JSON.stringify(error))
        }
        setSubmitting(v => !v)
    }

    function handleFormCancel() {
        router.navigate('/port-clearances')
    }

    function handleRouteChange(newRoute: string) {
        if (portClearanceData !== null && portClearanceData !== undefined) {

            const route = clearanceStore.customsRoutes?.find(r => r.id === newRoute)
            const exitPort = clearanceStore.customsPorts?.filter(port => port.countryIsoCode === route?.startRegionIsoCode)
            const entryPort = clearanceStore.customsPorts?.filter(port => port.countryIsoCode === route?.endRegionIsoCode)

            setPortClearanceData(currentData => ({
                ...currentData!,
                customsRouteId: newRoute,
                exitTask: {
                    ...currentData!.exitTask,
                    customsPortId: exitPort ? exitPort[0].id : "00000000-0000-0000-0000-000000000000"
                },
                entryTask: {
                    ...currentData!.entryTask,
                    customsPortId: entryPort ? entryPort[0].id : "00000000-0000-0000-0000-000000000000"
                }
            }))
        }
    }

    function handleAddMrn(mrn: MRNForm) {
        if (portClearanceData !== null && portClearanceData !== undefined &&
            portClearanceData.mrns !== null && portClearanceData.mrns !== undefined) {
            portClearanceData.mrns.push(mrn)
        } else if (portClearanceData !== null && portClearanceData !== undefined) {

            const mrnData: MRN = {
                id: mrn.id,
                mrnNumber: mrn.mrnNumber,
                sasMrnNumber: mrn.sasMrnNumber,
                isTsad: mrn.isTsad,
                declarationType: mrn.declarationType
            }
            const mrnForm: MRNForm = new MRNForm(mrnData, mrn.actionType)
            const mrnForms: MRNForm[] = []
            mrnForms.push(mrnForm)

            setPortClearanceData(currentData => ({
                ...currentData!,
                mrns: mrnForms
            }))
        }
    }

    function handleDeleteMrn(id: string) {
        if (portClearanceData !== null && portClearanceData !== undefined &&
            portClearanceData.mrns !== null && portClearanceData.mrns !== undefined) {

            setPortClearanceData(currentData => ({
                ...currentData!,
                mrns: currentData?.mrns?.filter(x => x.id !== id) ?? []
            }))
        }
    }

    function handleUpdateMrn(mrn: MRNForm){
        if (portClearanceData !== null && portClearanceData !== undefined &&
            portClearanceData.mrns !== null && portClearanceData.mrns !== undefined) 
        {
            const newMrnList = [...portClearanceData.mrns]
            const newMrnListFiltered = newMrnList.filter(x => x.id !== mrn.id)
            newMrnListFiltered.push(mrn)

            setPortClearanceData(currentData => ({
                ...currentData!,
                mrns: newMrnListFiltered 
            }))
        }
    }

    return (
        <div className="content-block">
            <span className="tab-container-title">
                Port Clearance
            </span>
            <div>
                {id ? `Updating` : "Adding new"}
                {submitting && <LoadIndicator className="organisations-load-indicator" />}
                {!submitting &&
                    <Form className="add-organisation-form" formData={portClearanceData} >
                        <GroupItem colCount={3} >
                            <GroupItem caption={'Route & Contact Information'} >
                                <RouteForm dataForm={portClearanceData} handleRouteChange={handleRouteChange} exitPorts={exitPorts} entryPorts={entryPorts} />
                            </GroupItem>
                            <GroupItem caption={'Resources Information'}>
                                <PortClearanceTaskForm dataForm={portClearanceData} />
                            </GroupItem>
                            <GroupItem>
                                <GroupItem caption={'MRNs Information'}>
                                    <PortClearanceMrnsGrid dataForm={portClearanceData.mrns} handleAddMrn={handleAddMrn} handleDeleteMrn={handleDeleteMrn} handleEditMrn={handleUpdateMrn} routeId={portClearanceData.customsRouteId} />
                                </GroupItem>
                                <GroupItem caption={'Authority Message'} visible={portClearanceData.exitTask.authorityMessage || portClearanceData.entryTask.authorityMessage}>
                                    <Item dataField={'entryTask.authorityMessage'} editorType="dxTextArea" editorOptions={{readOnly: true}} label={{text: "Import"}} visible={portClearanceData.entryTask.authorityMessage} />
                                    <Item dataField={'exitTask.authorityMessage'} editorType="dxTextArea" editorOptions={{readOnly: true}} label={{text: "Export"}} visible={portClearanceData.exitTask.authorityMessage} />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <Item itemType={"empty"}></Item>
                        <Item >
                            <Button
                                onClick={handleFormSubmit}
                                type="default"
                                disabled={submitting}
                                className="dx-widget dx-button dx-button-mode-contained dx-button-has-text myc-button-left"
                            >
                                <div className="dx-button-text">{id ? 'Update' : 'Save'}</div>
                            </Button>
                            <Button
                                onClick={handleFormCancel}
                                type="normal"
                                disabled={submitting}
                                className="dx-widget dx-button dx-button-mode-contained dx-button-has-text"
                            >
                                <div className="dx-button-text">Cancel</div>
                            </Button>
                        </Item>
                    </Form>
                }
            </div>
        </div>
    )
})