import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import ClearanceStore from "./clearanceStore";
import CommonStore from "./commonStore";
import OrganisationStore from "./organisationStore";
import UserStore from "./userStore";
import DeclarationStore from "./declarationStore";
import CustomerStore from "./customerStore";
import SupplierStore from "./supplierStore";
import AddressStore from "./addressStore";

interface Store {
    accountStore: AccountStore;
    commonStore: CommonStore;
    userStore: UserStore;
    clearanceStore: ClearanceStore;
    organisationStore: OrganisationStore;
    declarationStore: DeclarationStore;
    customerStore: CustomerStore;
    supplierStore: SupplierStore;
    addressStore: AddressStore;
}

export const store: Store = {
    accountStore: new AccountStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    clearanceStore: new ClearanceStore(),
    organisationStore: new OrganisationStore(),
    declarationStore: new DeclarationStore(),
    customerStore: new CustomerStore(),
    supplierStore: new SupplierStore(),
    addressStore: new AddressStore()
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
