export const natureOfTransactionOptions = [
    {code: 1, description: 'Transfer ownership with financial compensation'},
    {code: 11, description: 'Transfer ownership - Outright purchase/sale'},
    {code: 12, description: 'Transfer ownership - Supply for sale'},
    {code: 13, description: 'Transfer ownership - Barter trade'},
    {code: 14, description: 'Transfer ownership - Financial leasing'},
    {code: 19, description: 'Transfer ownership - Other'},
    {code: 2, description: 'Return/replacement goods free of charge'},
    {code: 21, description: 'Return/replacement - Return of goods'},
    {code: 22, description: 'Return/replacement - Replacement for returned goods'},
    {code: 23, description: 'Return/replacement - Replacement for goods not returned'},
    {code: 29, description: 'Return/replacement - Other'},
    {code: 3, description: 'Transfer ownership without compensation'},
    {code: 4, description: 'Operations with a view to processing under contract'},
    {code: 41, description: 'Operations view - Goods returning to country of export'},
    {code: 42, description: 'Operations view - Goods not returning to country of export'},
    {code: 5, description: 'Operations following processing under contract'},
    {code: 51, description: 'Operations following - Goods returning to country of export'},
    {code: 52, description: 'Operations following - Goods not returning to country of export'},
    {code: 6, description: 'Not in use'},
    {code: 7, description: 'Operations under joint defence projects/government programmes'},
    {code: 8, description: 'Supply of materials and equipment for construction/engineering contract'},
    {code: 9, description: 'Other'},
    {code: 91, description: 'Other - Hire, loan, and operational leasing longer than 24 months'},
    {code: 99, description: 'Other - Other'}
]