import notify from 'devextreme/ui/notify';

export const notifyMessage = (type: string, message: string) => {
    return (
        notify(
            {
                message: message,
                shading: true,
                type: type,
                animation: {
                    show: {
                        type: 'fade', duration: 500, from: 0, to: 1,
                    },
                    hide: { type: 'fade', duration: 20, to: 0 },
                }
            },
            {
                position: "top center",
                direction: "up-push",
            },
        )
    )
}
