export const customsOfficeCodeOptions = [
    // Republic of Ireland
    { code: "IEARK100", country: "IE", description: "Arklow, Co. Wicklow" },
    { code: "IEATH200", country: "IE", description: "Athlone Customs Office" },
    { code: "IEATY200", country: "IE", description: "Athy Customs Office" },
    { code: "IEBTP100", country: "IE", description: "Burtonport" },
    { code: "IEBYT100", country: "IE", description: "Bantry, Co. Cork" },
    { code: "IECAV100", country: "IE", description: "IECAV100 IMD Customs Hub" },
    { code: "IECAV200", country: "IE", description: "IECAV200 IMD Customs Hub" },
    { code: "IECBP100", country: "IE", description: "Castletownbere Port" },
    { code: "IEDDK100", country: "IE", description: "Dundalk Port, Co. Louth" }, 
    { code: "IEDON200", country: "IE", description: "Donegal TSF" },
    { code: "IEDRO100", country: "IE", description: "Drogheda Port, Co. Louth" },
    { code: "IEDUB100", country: "IE",  description: "Dublin Port" },
    { code: "IEDUB200", country: "IE", description: "Dublin ACP" },
    { code: "IEDUB400", country: "IE", description: "Dublin Airport" },
    { code: "IEDUB413", country: "IE", description: "RyanAir DAC, Airside Business PArk, Swords, Co. Dublin" },
    { code: "IEDUB416", country: "IE", description: "Gilmac Ltd" },
    { code: "IEDUB500", country: "IE", description: "Dublin Mail Centre" },
    { code: "IEDUB800", country: "IE", description: "Oil Wharf Dublin" },
    { code: "IEFOV100", country: "IE", description: "Foynes, Co. Limerick" },
    { code: "IEGCP100", country: "IE", description: "Greencastle Port" },
    { code: "IEGRN100", country: "IE", description: "Government Buildings, Milennium Centre, Dundalk, Co. Louth" },
    { code: "IEGWY100", country: "IE", description: "Galway" },
    { code: "IEHWP100", country: "IE", description: "Howth Port" },
    { code: "IEKBS100", country: "IE", description: "Killybegs, Co. Donegal" },
    { code: "IEKLK100", country: "IE", description: "Kilkenny" },
    { code: "IEKLK200", country: "IE", description: "Kilkenny TSF" },
    { code: "IELMK100", country: "IE", description: "Limerick Docks" },
    { code: "IELTR300", country: "IE", description: "Letterkenny, Co. Donegal" },
    { code: "IEMON300", country: "IE", description: "Monaghan, Co. Monaghan" },
    { code: "IENRS100", country: "IE", description: "New Ross, Co. Wexford" },
    { code: "IEORK400", country: "IE", description: "Cork Airport" },
    { code: "IEPOR200", country: "IE", description: "Customs TFU Fastway Couriers" },
    { code: "IERMP100", country: "IE", description: "Rathmullen Port" },
    { code: "IEROS100", country: "IE", description: "Rosslare Harbour, Co. Wexford" },
    { code: "IEROS200", country: "IE", description: "Rosslare TSF" },
    { code: "IERSP100", country: "IE", description: "Rosamhil Port" },
    { code: "IESLI100", country: "IE", description: "Sligo" },
    { code: "IESNN400", country: "IE", description: "Shannon Airport" },
    { code: "IETIV100", country: "IE", description: "Tivoi, Cork" },
    { code: "IETRA100", country: "IE", description: "Tralee" },
    { code: "IEWAT100", country: "IE", description: "Waterford Government Buildings" },
    { code: "IEWAT101", country: "IE", description: "Belview, Waterford" },
    { code: "IEWHI100", country: "IE", description: "Whitegate, Co. Cork" },
    { code: "IEWIC100", country: "IE", description: "Wicklow, Co. Wicklow" },
    { code: "IEWSE100", country: "IE", description: "Ireland West East" },
    { code: "IEWSE200", country: "IE", description: "WSE200 Ireland West East, Cargo Terminal 1, Dublin Airport" },
    // United Kingdown
    { code: "GB000001", country: "GB", description: "Liverpool, Central Community Transit Office" },
    { code: "GB000003", country: "GB", description: "Liverpool, HMRC, Derby Square" },
    { code: "GB000011", country: "GB", description: "Birmingham Airport" },
    { code: "GB000029", country: "GB", description: "Bristol International Airport" },
    { code: "GB000047", country: "GB", description: "Ramsgate" },
    { code: "GB000051", country: "GB", description: "Felixstowe" },
    { code: "GB000052", country: "GB", description: "Harwich" },
    { code: "GB000053", country: "GB", description: "Cairnryan" },
    { code: "GB000054", country: "GB", description: "Glasgow Airport" },
    { code: "GB000055", country: "GB", description: "Aberdeen + Highlands & Island Ports" },
    { code: "GB000058", country: "GB", description: "Ediburgh Airport" },
    { code: "GB000059", country: "GB", description: "Grangemouth" },
    { code: "GB000060", country: "GB", description: "Dover/Folkestone Eurotunnel Freight" },
    { code: "GB000072", country: "GB", description: "Hull" },
    { code: "GB000074", country: "GB", description: "Immingham" },
    { code: "GB000080", country: "GB", description: "Liverpool, Seaforth S2 Berth" },
    { code: "GB000081", country: "GB", description: "UK North Auth Consignor/nees" },
    { code: "GB000084", country: "GB", description: "London Heathrow Cargo" },
    { code: "GB000085", country: "GB", description: "London Gatwick Airport Cargo Dist." },
    { code: "GB000087", country: "GB", description: "Leeds Bradford" },
    { code: "GB000093", country: "GB", description: "Tilbury" },
    { code: "GB000102", country: "GB", description: "Luton Airport FCT" },
    { code: "GB000120", country: "GB", description: "London Heathrow Airport (Baggage)" },
    { code: "GB000121", country: "GB", description: "Stansted Airport FCT" },
    { code: "GB000122", country: "GB", description: "Cardiff Airport (including Barry)" },
    { code: "GB000124", country: "GB", description: "Holyhead" },
    { code: "GB000126", country: "GB", description: "Pembroke Dock" },
    { code: "GB000149", country: "GB", description: "Purfleet Thames Terminal" },
    { code: "GB000150", country: "GB", description: "Thamesport" },
    { code: "GB000170", country: "GB", description: "London Gateway Port" },
    { code: "GB000191", country: "GB", description: "Manchester" },
    { code: "GB000218", country: "GB", description: "Border Force, Port of Tyne" },
    { code: "GB000219", country: "GB", description: "Teesport" },
    { code: "GB000245", country: "GB", description: "UKBA East Midlands Airport" },
    { code: "GB000246", country: "GB", description: "UK South Auth Consignor/nees" },
    { code: "GB000290", country: "GB", description: "Southampton" },
    { code: "GB000291", country: "GB", description: "Poole Ferry Terminal" },
    { code: "GB000292", country: "GB", description: "Portsmouth" },
    { code: "GB000328", country: "GB", description: "Cardiff" },
    { code: "GB000450", country: "GB", description: "Isle of Man Customs & Excise" },
    { code: "GB000461", country: "GB", description: "Guernsey Customs & Excise" },
    { code: "GB000465", country: "GB", description: "Jersey Customs & Immigration" },
    { code: "GB003050", country: "GB", description: "Plymouth Docks" },
    { code: "GB003250", country: "GB", description: "Newhaven" },
    { code: "GB004098", country: "GB", description: "Glasgow" },
    { code: "GB005170", country: "GB", description: "Killingholme" },
    { code: "GB005200", country: "GB", description: "Fishguard" },
    { code: "GB005210", country: "GB", description: "Heysham" },
    // Northern Ireland
    { code: "XI000142", country: "NI", description: "Belfast" },
    { code: "XI005220", country: "NI", description: "Larne" },
    { code: "XI005160", country: "NI", description: "Warrenpoint" },
]