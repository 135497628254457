import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { store } from '../stores/store'
import agent from '../api/agent'
import { PaginatedResult, Result } from '../models/result'
import { DeclarationHeader } from '../models/declarationHeader'
import { DecalarationData } from '../models/declarationCreate'
import { DeclarationTemplateHeader } from '../models/declarationTemplateHeader'
import { DeclarationPackage } from '../models/declarationPackage'

export function useDeclarations(orgId?: string) {
    const query = useQuery(['declarations', orgId], () => agent.Declarations.getAll(orgId!), {
        enabled: !!orgId,
        onSuccess: (data: Result<PaginatedResult<DeclarationHeader[]>>) => {
            if (data.isSuccess && data.data?.data) {
                store.declarationStore.setDeclarations(data.data?.data)
            } else {
                store.declarationStore.setDeclarations([])
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declarations.', error)
            store.declarationStore.setDeclarations([])
        },
    })

    return{
        ...query,
        isLoading: !!orgId && query.isLoading
    }
}

export function useDeclaration(id?: string){
    const query = useQuery(['declaration', id], () => agent.Declarations.get(id!), {
        enabled: !!id,
        refetchOnWindowFocus: false,
        onSuccess: (data: Result<DecalarationData>) => {
            if (data.isSuccess && data.data) {
                store.declarationStore.setDeclaration(data.data)
            } else {
                store.declarationStore.setDeclaration(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declaration.', error)
            store.declarationStore.setDeclaration(null)
        },
    })

    return{
        ...query,
        isLoading: !!id && query.isLoading
    }
}

export function useAddDeclaration() {
    const queryClient = useQueryClient()

    return useMutation(agent.Declarations.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['declarations'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}

export function useUpdateDeclaration() {
    const queryClient = useQueryClient()

    return useMutation(agent.Declarations.update, {
        onSuccess: (_data, variables) => {
            const id = variables.declarationId
            queryClient.invalidateQueries(['declaration', id])
            queryClient.invalidateQueries(['declarations'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}

export function useDeleteDeclaration() {
    const queryClient = useQueryClient()

    return useMutation(agent.Declarations.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['declarations'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}

export function useDeclarationTemplates(orgId?: string){
    const query = useQuery(['declarationTemplates', orgId], () => agent.Declarations.getAllTemplates(orgId!), {
        enabled: !!orgId,
        onSuccess: (data: Result<PaginatedResult<DeclarationTemplateHeader[]>>) => {
            if (data.isSuccess && data.data?.data) {
                store.declarationStore.setDeclarationTemplates(data.data?.data)
            } else {
                store.declarationStore.setDeclarationTemplates([])
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declaration templates.', error)
            store.declarationStore.setDeclarationTemplates([])
        },
    })

    return{
        ...query,
        isLoading: !!orgId && query.isLoading
    }
}

export function useDeclarationTemplate(id?: string){
    const query = useQuery(['declarationTemplate', id], () => agent.Declarations.getTemplate(id!), {
        enabled: !!id,
        refetchOnWindowFocus: false,
        onSuccess: (data: Result<DecalarationData>) => {
            if (data.isSuccess && data.data) {
                store.declarationStore.setDeclarationTemplate(data.data)
            } else {
                store.declarationStore.setDeclarationTemplate(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declaration template.', error)
            store.declarationStore.setDeclarationTemplate(null)
        },
    })

    return{
        ...query,
        isLoading: !!id && query.isLoading
    }
}

export function useAddDeclarationTemplate(id?: string) {
    const queryClient = useQueryClient()

    const query = useQuery(['declarationTemplate', id], () => agent.Declarations.createTemplate(id!), {
        enabled: !!id,
        onSuccess: () => {
            queryClient.invalidateQueries(['declarationTemplates'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })

    return{
        ...query,
        isLoading: !!id && query.isLoading
    }
}

export function useAddDeclarationFromTemplate(id?: string) {
    const queryClient = useQueryClient()

    const query = useQuery(['declaration', id], () => agent.Declarations.sendTemplateToDeclaration(id!), {
        enabled: !!id,
        onSuccess: () => {
            queryClient.invalidateQueries(['declarationTemplates'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })

    return{
        ...query,
        isLoading: !!id && query.isLoading
    }
}

export function useUpdateDeclarationTemplate() {
    const queryClient = useQueryClient()

    return useMutation(agent.Declarations.updateTemplate, {
        onSuccess: (_data, variables) => {
            const id = variables.declarationId
            queryClient.invalidateQueries(['declarationTemplate', id])
            queryClient.invalidateQueries(['declarationTemplates'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}

export function useDeleteDeclarationTemplate() {
    const queryClient = useQueryClient()

    return useMutation(agent.Declarations.deleteTemplate, {
        onSuccess: () => {
            queryClient.invalidateQueries(['declarationTemplates'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}

export function useDeclarationPackages(orgId?: string){
    const query = useQuery(['declarationPackages', orgId], () => agent.Declarations.getPackages(orgId!), {
        enabled: !!orgId,
        onSuccess: (data: Result<PaginatedResult<DeclarationPackage[]>>) => {
            if (data.isSuccess && data.data?.data) {
                store.declarationStore.setDeclarationPackages(data.data?.data)
            } else {
                store.declarationStore.setDeclarationPackages([])
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declaration packages.', error)
            store.declarationStore.setDeclarationPackages([])
        },
    })

    return{
        ...query,
        isLoading: !!orgId && query.isLoading
    }
}

export function useDeclarationPackage(id?: string){
    const query = useQuery(['declarationPackage', id], () => agent.Buckets.get(id!), {
        enabled: !!id,
        onSuccess: (data: Result<DeclarationPackage>) => {
            if (data.isSuccess && data.data) {
                store.declarationStore.setDeclarationPackage(data.data)
            } else {
                store.declarationStore.setDeclarationPackage(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving declaration package.', error)
            store.declarationStore.setDeclarationPackage(null)
        },
    })

    return{
        ...query,
        isLoading: !!id && query.isLoading
    }
}

export function useDeleteDeclarationPackage() {
    const queryClient = useQueryClient()

    return useMutation(agent.Buckets.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['declarationTemplates'])
        },
        onError: (error: any) => {
            store.declarationStore.setError(error)
        },
    })
}