import { FieldError } from "../../models/declarationCreate";

export function isFieldValid(fieldName: string, fieldErrors: FieldError[]){
    if(!fieldErrors)
        return false;

    const matchedItem = fieldErrors.find((element) => {
        return element.name === fieldName
    })

    if(matchedItem !== undefined)
    {
        // if it is not undefined, must be a match therefore this field has an error
        return false;
    }

    return true;
}

export function isFieldValidWithSequence(fieldName: string, sequenceNumber: number, fieldErrors: FieldError[]){
    const newFieldName = sequenceNumber + '-' + fieldName
    return isFieldValid(newFieldName, fieldErrors)
}

export function getErrorMessage(fieldName: string, fieldErrors: FieldError[]){
    if(!fieldErrors)
        return '';
    
    const matchedItem = fieldErrors.find((element) => {
        return element.name === fieldName
    })

    if(matchedItem !== undefined)
        return matchedItem.error;

    return '';
}

export function getErrorMessageWithSequence(fieldName: string, sequenceNumber: number, fieldErrors: FieldError[]){
    const newFieldName = sequenceNumber + '-' + fieldName
    return getErrorMessage(newFieldName, fieldErrors)
}