import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'

interface Props{
    formData: DecalarationData | null,
}

export default function CustomsSummary(props: Props){
    return <Form formData={props.formData}><GroupItem cssClass="cds-group-item" colCount={2} caption={'Summary'}>
        <GroupItem colCount={1}>
            <Item dataField="summary.customsRequested" label={{text: "Customs Requested"}}/>
            <Item dataField="summary.mrn" label={{text: "MRN"}}/>
            <Item dataField="summary.jobStatus" label={{text: "Job status"}}/>
            <Item dataField="summary.customs" label={{text: "Customs"}}/>
        </GroupItem>
        <GroupItem cssClass="cds-group-item" colCount={2}>
            <Item dataField="summary.haulierRequest" label={{text: "Haulier Request"}}/>
            <Item dataField="summary.fromTo" editorOptions={{ readOnly: true}} label={{text: "From To"}}/>
            <Item dataField="summary.haulierResponse" label={{text: "Haulier Response"}}/>
            <Item dataField="summary.lastUpdate" editorOptions={{ readOnly: true}} label={{text: "Last Update"}}/>
        </GroupItem>
        <GroupItem colSpan={2}>
            <Item dataField="authoritySystem" label={{text: "Authority System"}} editorOptions={{ readOnly: true}}/>
            <Item dataField="summary.authorityMessage" editorType="dxTextArea" editorOptions={{ readOnly: true} } label={{text: "Authority Message"}}/>
        </GroupItem>
    </GroupItem>
    </Form>
}