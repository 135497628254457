import { useEffect, useRef, useState } from "react";
import { supplyChainRoleOptions } from "../../app/common/options/cdsImportDeclaration/supplyChainRoleOptions";
import { DeclarationParties, FieldError } from "../../app/models/declarationCreate";
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../app/common/utils/declarationErrorFunctions";

interface Props{
    formData: DeclarationParties | null
    fieldErrors: FieldError[]
    actorCaption: string
}

export default function ActorEditSupplyChain(props: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        additionalSupplyChainActorIdRule: boolean
        additionalSupplyChainActorRoleRule: boolean
    } = {
        additionalSupplyChainActorIdRule: isFieldValid("additionalSupplyChainActorId", props.fieldErrors),
        additionalSupplyChainActorRoleRule: isFieldValid("additionalSupplyChainActorRole", props.fieldErrors)
    };

    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, props.fieldErrors);
      }

    return <Form ref={formRef} formData={props.formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={props.actorCaption}>
        <Item dataField="additionalSupplyChainActorId" label={{text: "Additional Supply Chain Actor ID"}}>
            <CustomRule message={handleGetErrorMessage("additionalSupplyChainActorId")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorIdRule}} />
        </Item>
        <Item dataField="additionalSupplyChainActorRole" label={{text: "Role in the Supply Chain"}} editorType="dxSelectBox"
            editorOptions={{
                items: supplyChainRoleOptions,
                searchEnabled: true,
                displayExpr: "party",
                valueExpr: "role"
            }}>
                <CustomRule message={handleGetErrorMessage("additionalSupplyChainActorRole")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalSupplyChainActorRoleRule}} />
            </Item>
    </GroupItem>
    </Form>
}