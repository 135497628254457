import { Box, LoadPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store'

export default observer(function DashboardPage() {
    const { commonStore, userStore } = useStore()

    if (!userStore.user) {
        return <LoadPanel visible={true} />
    }

    return (
        <div className='content-block'>
            <h2>Your token is:</h2>
            <Box
                direction="row"
                width="100%" >
                <Item ratio={1} >
                    <p style={{ overflowWrap: 'anywhere' }}>{commonStore.token}</p>
                </Item>
            </Box>            
        </div>
    )
})