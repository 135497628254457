import dayjs from "dayjs"

export function formatDateTimeToDisplay(dateString?: string): string {
    if (!dateString) return ''
    return new Date(dateString).toString()
}

export function formatDateToDisplay(dateString?: string, format?: string): string {
    if (!dateString) return ''
    if (!format) format = 'DD/MM/YYYY'
    const formattedDate = dayjs(new Date()).format(format)
    return formattedDate
}

export function stringToDate(dateString?: string): Date {
    if (!dateString) return new Date()
    return new Date(dateString)
}

export function currentDateTimeToString(): string {
    const timestamp = new Date()
    const formattedDate = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
    return formatDateTimeToDisplay(formattedDate)
}

export function currentDateTimeFormated(): string {
    const timestamp = new Date()
    const formattedDate = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
    return formattedDate
}

export function getCurrentDateISO() {
    const now = new Date();
    return now.toISOString();
}

export function convertUtcToLocalTime(utcTime: string): string{
    var localDefaultTime = new Date();
    var offset = localDefaultTime.getTimezoneOffset();
    var reverseOffset = offset * -1;
    var utcDateTime = new Date(utcTime)
    var utcTimeConvertedToLocal = new Date(utcDateTime.getTime() + reverseOffset * 60000)
    var formattedDateTime = dayjs(utcTimeConvertedToLocal).format('YYYY-MM-DDTHH:mm:ss')
    return formattedDateTime
}