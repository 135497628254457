export const b1Template = [
    { fieldName: "header.valuationInformationTaxes.deliveryterms", isVisible: false},
    { fieldName: "header.valuationInformationTaxes.additionsanddeductions", isVisible: false},
    { fieldName: "header.places.placeofloading", isVisible: true},
    { fieldName: "header.transportinformation.arrivaltransportmeansid", isVisible: true},
    { fieldName: "header.parties.declarant", isVisible: true},
    { fieldName: "header.valuationInformationTaxes.transportcharges.methodofpayment", isVisible: false},
    { fieldName: "header.places.countriesofrouting", isVisible: true},
    { fieldName: "header.customsoffices.exit", isVisible: true},
    { fieldName: "header.transportinformation.idmeanstransportcrossingborder", isVisible: true},
    { fieldName: "header.transportinformation.idtransportdepature", isVisible: true},
    { fieldName: "header.transportinformation.sealnumber", isVisible: true},
    { fieldName: "goods.parties.seller", isVisible: false},
    { fieldName: "goods.parties.buyer", isVisible: false },
    { fieldName: "goods.valuationInformationTaxes.valuationmethod", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.preference", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.methodofpayment", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.taxtype", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.taxbase", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.payabletaxamount", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.additionsanddeductions", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.valuationindicators", isVisible: false},
    { fieldName: "goods.valuationInformationTaxes.itemamount", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.calculationoftaxes.total", isVisible: false},
    { fieldName: "goods.places.countryofdispatch", isVisible: false},
    { fieldName: "goods.places.countryofpreferentialorigin", isVisible: false},
    { fieldName: "goods.other.quotaordernumber", isVisible: true},
    { fieldName: "goods.parties.consignee", isVisible: true},
    { fieldName: "goods.valuationInformationTaxes.transportcharges.methodofpayment", isVisible: false},
    { fieldName: "goods.goodsidentification.undangerousgoodscode", isVisible: true}
]
