import { Button, DataGrid, LoadIndicator } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import {  useDeclarationPackages, useDeleteDeclarationPackage } from '../../app/hooks/declarationQueries'
import { useStore } from '../../app/stores/store'
import { useEffect } from 'react'
import { Column, FilterRow } from 'devextreme-react/data-grid'
import { router } from '../../app/router/Routes'

export default observer(function DeclarationPackageList() {
    const { declarationStore, organisationStore } = useStore()
    const deleteDeclarationPackage = useDeleteDeclarationPackage();

    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : '';

    const { isLoading } = useDeclarationPackages(organisationId)

    if (isLoading) return <LoadIndicator className="organisations-load-indicator" />

    const handleEdit = (cellData: any) => {
        declarationStore.setDeclarationPackage(null)
        if (cellData.data.id) {
            router.navigate(`/declaration-packages/${cellData.data.id}`)
        }
    }

    const handleDelete = (cellData: any) => {
        declarationStore.setDeclarationPackage(null)
        if (cellData.data.id) {
            deleteDeclarationPackage.mutateAsync(cellData.data.id)   
        }
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}  
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

    return (
        <div className="content-block">
            {isLoading && <LoadIndicator className="organisations-load-indicator" />}
            <span className="tab-container-title">
                Declaration Packages
            </span>
            <DataGrid
                id='packages'
                dataSource={declarationStore.declarationPackages}
                rowAlternationEnabled={true}
            >
                <FilterRow visible={true} />
                <Column type="adaptive" width={10} />
                <Column dataField="transactionPrefix" caption="Prefix" />
                <Column dataField="transactionId" caption="Id" />
                <Column dataField="customerName" caption="Customer" />
                <Column dataField="type" caption="Type" />
                <Column dataField="createdDate" dataType="date" format="dd/MM/yy HH:mm" />
                <Column dataField="createdBy" />
                <Column dataField="updatedDate" dataType="date" format="dd/MM/yy HH:mm" />
                <Column dataField="updatedBy" />
                <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
            </DataGrid>
        </div>
    )
})