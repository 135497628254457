export const incoTermCodeOptions = [
    { code: "CFR", description: "Cost and freight"},
    { code: "CIF", description: "Cost, insurance and freight"},
    { code: "CIP", description: "Carriage and insurance paid to"},
    { code: "CPT", description: "Carriage paid to"},
    { code: "DAP", description: "Delivered at place"},
    { code: "DPU", description: "Delivered at place unloaded"},
    { code: "DAT", description: "Delivered at terminal"},
    { code: "DDP", description: "Delivered duty paid"},
    { code: "DDU", description: "Delivered duty unpaid"},
    { code: "EXW", description: "Ex-Works"},
    { code: "FAS", description: "Free alongside ship"},
    { code: "FCA", description: "Free carrier alongside"},
    { code: "FOB", description: "Free on board"},
    { code: "XXX", description: "Other"}
]