import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import { Button, DataGrid, Form, LoadIndicator } from "devextreme-react";
import { GroupItem } from 'devextreme-react/form';
import { Column, FilterRow, Item, Toolbar } from "devextreme-react/data-grid";
import { router } from "../../app/router/Routes";
import { confirm } from "devextreme/ui/dialog"

export default observer(function Addresses() {
    const {addressStore, organisationStore } = useStore()
    
    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    useEffect(() => {
        {organisationId && addressStore.getAll(organisationId);}
    }, [addressStore, organisationId]);

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      const handleEdit = (cellData: any) => {
        if (cellData.data.id) {
            router.navigate(`/address/${cellData.data.id}`)
        }
    }

    const handleAddNew = () => {
        addressStore.address = null
        router.navigate(`/address`)
    }

    const handleDelete = (cellData: any) => {
        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {
                addressStore.delete(cellData.data.id)
            }
        })
    }

      const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

      return(
        <div className='content-block'>
            {addressStore.loading && <LoadIndicator className="organisations-load-indicator" />}
            <form onSubmit={(e) => handleSubmit(e)}>
                <Form formData={addressStore.addresses}>
                    <GroupItem caption={'Addresses'} >
                        <DataGrid id='declarations' dataSource={addressStore.addresses} rowAlternationEnabled={true} keyExpr='id' >
                            <FilterRow visible={true} />
                            <Column dataField="code" caption={'Code'} width={'150'}></Column>
                            <Column dataField="name" caption={'Name'}></Column>
                            <Column dataField="eori" caption={'EORI'} ></Column>
                            <Column dataField="address1" caption={'Line 1'}></Column>
                            <Column dataField="address2" caption={'Line 2'}></Column>
                            <Column dataField="city" caption={'City'}></Column>
                            <Column dataField="region" caption={'Region'}></Column>
                            <Column dataField="postcode" caption={'Postcode'}></Column>
                            <Column dataField="countryIsoCode" caption={'Country'}></Column>
                            <Column dataField="latitude" caption={'Latitude'}></Column>
                            <Column dataField="longitude" caption={'Longitude'}></Column>
                            <Column dataField="notes" caption={'Notes'}></Column>
                            <Column dataField="addressType" caption={'Type'}></Column>
                            <Column dataField="personaName" caption={'Person Name'}></Column>
                            <Column dataField="personaCode" caption={'Person Code'}></Column>
                            <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                            <Toolbar>
                                <Item>
                                    <Button icon='add' hint='Add' onClick={() => handleAddNew()}></Button>
                                </Item>
                            </Toolbar>
                        </DataGrid>
                    </GroupItem>
                </Form>
            </form>
        </div>
      )
})