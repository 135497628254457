import { useState } from 'react'
import { useStore } from '../../app/stores/store'
import LoadIndicator from 'devextreme-react/load-indicator'
import { observer } from 'mobx-react-lite'
import { Button, DataGrid } from 'devextreme-react'
import { Column, Editing } from 'devextreme-react/data-grid'
import { confirm } from "devextreme/ui/dialog"
import { onToolbarPreparing } from '../../app/common/utils/toolbar'
import { router } from '../../app/router/Routes'
import { usePortClearances } from '../../app/hooks/portClearanceQueries'

export default observer(function PortClearanceList() {
    const { clearanceStore } = useStore()
    const { isLoading } = usePortClearances()
    const [adding, setAdding] = useState(false)

    if (isLoading) return <LoadIndicator className="organisations-load-indicator" />

    const handleDelete = (cellData: any) => {
        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {

            }
        })
    }

    const handleEdit = (cellData: any) => {
        clearanceStore.setPortClearance(null)
        if (cellData.data.id) {
            router.navigate(`/port-clearance/${cellData.data.id}`)
        }
    }

    const handleAddNew = () => {
        clearanceStore.setPortClearance(null)
        setAdding(true)
        router.navigate(`/port-clearance`)
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}  //handleDefault(cellData)}
                />
                <Button
                    icon="trash"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

    return (
        <div className="content-block">
            {isLoading && <LoadIndicator className="organisations-load-indicator" />}
            <span className="tab-container-title">
                Port Clearances
            </span>
            <DataGrid
                dataSource={clearanceStore.portClearances}
                onToolbarPreparing={(e) => onToolbarPreparing(e, adding, handleAddNew)}
            >
                <Column type="adaptive" width={10} />
                <Column dataField="transactionPrefix" />
                <Column dataField="transactionId" />
                <Column dataField="requestedDate" dataType="datetime" format="dd/MM/yyyy HH:mm" />
                <Column dataField="customsRouteName" />
                <Column dataField="exitAuthority" />
                <Column dataField="exitAuthorityNumber" />
                <Column dataField="exitStatus" />
                <Column dataField="exitChannelStatus" />
                <Column dataField="entryAuthority" />
                <Column dataField="entryAuthorityNumber" />
                <Column dataField="entryStatus" />
                <Column dataField="entryChannelStatus" />
                <Editing allowAdding={true} />
                <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
            </DataGrid>
        </div>
    )
})
