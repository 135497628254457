import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import TreeView from 'devextreme-react/tree-view'
import * as events from 'devextreme/events'
import { useScreenSize } from '../../common/utils/mediaQuery'
import { getNavigation } from '../../router/appNavigation'
import { useNavigation } from '../../contexts/NavContect'
import dxTreeView, { ItemClickEvent } from 'devextreme/ui/tree_view'
import { EventInfo } from 'devextreme/events'

interface Props {
    selectedItemChanged: (e: ItemClickEvent) => void
    openMenu: (e: React.PointerEvent) => void
    compactMode: boolean
    onMenuReady: (e: EventInfo<dxTreeView>) => void
}
export default function SideNavigationMenu(props: React.PropsWithChildren<Props>) {
    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady
    } = props

    const { isLarge } = useScreenSize()
    function normalizePath() {
        const navigation = getNavigation()
        return navigation.map((item) => (
            { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
        ))
    }

    const items = useMemo(
        normalizePath,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const { navigationData: { currentPath } } = useNavigation()

    const treeViewRef = useRef<TreeView>(null)
    const wrapperRef = useRef<HTMLDivElement>()
    const getWrapperRef = useCallback((element: HTMLDivElement) => {
        const prevElement = wrapperRef.current
        if (prevElement) {
            events.off(prevElement, 'dxclick')
        }

        wrapperRef.current = element
        events.on(element, 'dxclick', (e: React.PointerEvent) => {
            openMenu(e)
        })
    }, [openMenu])

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance
        if (!treeView) {
            return
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath)
            treeView.expandItem(currentPath)
        }

        if (compactMode) {
            treeView.collapseAll()
        }
    }, [currentPath, compactMode])

    return (
        <div
            id={'navigation-header'}
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    )
}
