import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { Button, LoadIndicator } from "devextreme-react";
import { CustomerAdd, CustomerFormValues, CustomerStatus, CustomerUpdate } from "../../app/models/customer/customer";
import { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import { router } from "../../app/router/Routes";
import Form, { Item, GroupItem, Label, TabbedItem, Tab, RequiredRule, TabPanelOptions } from 'devextreme-react/form'
import { currencyOptions } from "../../app/common/options/currencyOptions";
import { countryOptions } from "../../app/common/options/countryOptions";
import { CustomerAddressFormValues } from "../../app/models/customer/personaAddress";
import { notifyMessage } from "../../app/common/utils/notify";

export default observer(function CustomerForm() {
    const { id } = useParams<{ id: string }>()
    const { customerStore, organisationStore } = useStore()

    const handleGetCustomer = async () => {		
      if(!id)
      {
          notifyMessage("warning", "Invalid customer");
          handleFormClose();
      }
      {id && await customerStore.getCustomer(id)}
      if (!customerStore.isSuccess) {
          notifyMessage("warning", "Failed to get customer");
          handleFormClose();
      }
      if(customerStore.customer)
          setCustomer(new CustomerFormValues(customerStore.customer))
    };

    useEffect(() => {
        organisationStore.getOrganisationMe();
        {id && handleGetCustomer()}
    }, [organisationStore, customerStore, id]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    const iniCustValue: CustomerFormValues = {
        ...new CustomerFormValues(),
        organisationId: organisationId ?? '',
        currencyIsoCode:  orgDefault?.defaultCurrencyIsoCode ?? "EUR",
        address: {
          ...new CustomerAddressFormValues(),
          countryIsoCode: orgDefault?.countryIsoCode ?? "IE"
        },
        billingAddress: {
          ...new CustomerAddressFormValues(),
          countryIsoCode: orgDefault?.countryIsoCode ?? "IE"
        }
      }
    
      const [customer, setCustomer] = useState<CustomerFormValues>(iniCustValue)

    if(customerStore.loading)
        return <LoadIndicator className="organisations-load-indicator" />

    function handleFormSubmit() {
      if(id)
      {
        handleUpdateCustomer()
      }
      else
      {
        handleAddCustomer()
      }
    }

    const handleAddCustomer = async () => {
      let customerCreate: CustomerAdd = {
        organisationId: customer?.organisationId ?? '',
        code: customer.code ?? '',
        name: customer.name ?? '',
        eori: customer.eori ?? '',
        address: customer.address,
        billingAddress: customer.billingAddress,
        contactPhoneNumber: customerStore.customer?.contactPhoneNumber ?? '',
        contactMobileNumber: customerStore.customer?.contactMobileNumber ?? '',
        contactName: customerStore.customer?.contactName ?? '',
        contactEmail: customerStore.customer?.contactEmail ?? '',
        contactWeb: customerStore.customer?.contactWeb ?? '',
        currencyIsoCode: customer.currencyIsoCode,
        taxIdentifierLabel1: customer.taxIdentifierLabel1,
        taxIdentifierLabel2: customer.taxIdentifierLabel2,
        taxNumber1: customer.taxNumber1,
        taxNumber2: customer.taxNumber2
      }
      
      await customerStore.createCustomer(customerCreate)
      if(customerStore.isSuccess)
      {
        notifyMessage("success", "Created Customer")
        handleFormClose()
      }
      else
      {
        notifyMessage("warning", "Failed to create Customer")
      }
    }

    const handleUpdateCustomer = async() => {
      let customerUpdate: CustomerUpdate = {
        id: customer.id ?? '',
        code: customer.code ?? '',
        name: customer.name ?? '',
        eori: customer.eori ?? '',
        address: customer.address,
        billingAddress: customer.billingAddress,
        contactPhoneNumber: customerStore.customer?.contactPhoneNumber ?? '',
        contactMobileNumber: customerStore.customer?.contactMobileNumber ?? '',
        contactName: customerStore.customer?.contactName ?? '',
        contactEmail: customerStore.customer?.contactEmail ?? '',
        contactWeb: customerStore.customer?.contactWeb ?? '',
        currencyIsoCode: customer.currencyIsoCode,
        taxIdentifierLabel1: customer.taxIdentifierLabel1,
        taxIdentifierLabel2: customer.taxIdentifierLabel2,
        taxNumber1: customer.taxNumber1,
        taxNumber2: customer.taxNumber2,
        defaultTaxRate: customer.defaultTaxRate?.id ?? ''
      }
      
      await customerStore.updateCustomer(customerUpdate)
      if(customerStore.isSuccess)
        {
          notifyMessage("success", "Updated Customer")
          handleFormClose()
        }
        else
        {
          notifyMessage("warning", "Failed to update Customer")
        }
    }

    function handleCopyToBillingAddress() {
        customer.billingAddress = customer.address
    
        setCustomer(new CustomerFormValues(customer))
    
        notify('Address copied to billing address', 'success')
      }

      function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
          handleFormSubmit()
        }
      }

      const onTabChange = (e: any) => {
        if (e.itemData.title === 'Customer') {
          var customerName = document.getElementsByName("name")
          customerName[0]?.focus()
        } else if (e.itemData.title === 'Address') {
          var customerAddress = document.getElementsByName("address.code")
          customerAddress[0]?.focus()
        } else if (e.itemData.title === 'Billing address') {
          var customerBilling = document.getElementsByName("billingAddress.code")
          customerBilling[0]?.focus()
        }
      }

      const onInitialized = () => {
        var elements = document.getElementsByName("name")
        elements[0]?.focus()
      }

      function handleFormClose() {
        router.navigate(`/customers`)
      }

      const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
      }
      
if(id && !customerStore.loading && !customerStore.isSuccess)
{
  notify('Failed to get Customer')
  handleFormClose()
}

if(id && !customerStore.loading && customerStore.customer && customerStore.customer.id != id)
{
  notify('Failed to get correct Customer')
  handleFormClose()
}

    return <div className='content-block'>
    <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
      <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
      </div>
      <h4 style={{ margin: '15px 5px 15px 30px' }}>{customer.name ? customer.name : "Create new customer"}</h4>
    </div>
    <div className={'content-block'}>
      <Form
        id={'form'}
        formData={customer}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={'customerFormValidation'}
        tabIndex={0}
        onContentReady={onInitialized}
      >
        <TabbedItem >
          <TabPanelOptions deferRendering={false} tabIndex={0} onTitleClick={onTabChange} />
          <Tab title="Customer" icon="card" colCount={2}  >
            <GroupItem caption="Info" colCountByScreen={colCountByScreen} >
              <Item dataField="name" caption={"Name"} isRequired={true} >
                <Label text="Name" />
                <RequiredRule message="Name is required" />
              </Item>
              <Item dataField="code" caption={"Code"}>
                <Label text="Account Code" />
              </Item>
              <Item dataField="eori" caption={"EORI"} data-test="eori" isRequired={true}>
                <Label text="EORI" />
                <RequiredRule message="EORI is required" />
              </Item>
              <Item
                dataField="currencyIsoCode"
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  items: currencyOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "code",
                  placeholder: "Select a currency..."
                }}>
                <Label text="Billing Currency" />
              </Item>
              <Item dataField="status"
                editorType="dxSelectBox"
                text={"Status"}
                editorOptions={{
                  items: CustomerStatus,
                  searchEnabled: false,
                  displayExpr: "description",
                  valueExpr: "description",
                  placeholder: "Status..."
                }}>
                <Label text="Status" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
              </Item>
            </GroupItem>
            <GroupItem caption="Tax Info" colCountByScreen={colCountByScreen}>

              <Item dataField="taxIdentifierLabel1" caption={"Tax Label 1"}>
                <Label text="Tax Label 1" />
              </Item>
              <Item dataField="taxNumber1" caption={customer?.taxIdentifierLabel1}>
                <Label text={customer?.taxIdentifierLabel1} />
              </Item>
              <Item dataField="taxIdentifierLabel2" caption={"Tax Label 2"}>
                <Label text="Tax Label 2" />
              </Item>
              <Item dataField="taxNumber2" caption={customer?.taxIdentifierLabel2}>
                <Label text={customer?.taxIdentifierLabel2} />
              </Item>
            </GroupItem>
          </Tab>


          <Tab title='Address' icon="map">
            <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
              <Item dataField="address.code" caption={"Code"} isRequired={true}>
                <Label text="Code" />
              </Item>
              <Item dataField="address.name" caption={"Name"} isRequired={true}>
                <Label text="Name" />
              </Item>
              <Item dataField="address.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                <Label text="Address 1" />
                <RequiredRule message="Customer Address is required" />
              </Item>
              <Item dataField="address.address2" caption={"Address 2"} colSpan={2}>
                <Label text="Address 2" />
              </Item>
              <Item dataField="address.city" title={"City"}>
                <Label text="City" />
              </Item>
              <Item dataField="address.region" title={"Region"}>
                <Label text="County/Region" />
              </Item>
              <Item dataField="address.postcode" text={"Postcode"}>
                <Label text="Postcode" />
              </Item>
              <Item
                dataField="address.countryIsoCode"
                editorType="dxSelectBox"
                text={"Country"}
                isRequired={true}
                editorOptions={{
                  items: countryOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "isoCode",
                  placeholder: "Select a country..."
                }}>
                <Label text="Country" />
              </Item>
              <Item dataField="address.latitude" title={"Latitude"}>
                <Label text="Latitude" />
              </Item>
              <Item dataField="address.longitude" title={"Longitude"} colSpan={2} >
                <Label text="Longitude" />
              </Item>
              <Item dataField="address.notes" title={"Notes"} colSpan={2} >
                <Label text="Notes" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
                {'   '}
                <Button
                  text="Copy to billing address"
                  type="default"
                  stylingMode="contained"
                  onClick={handleCopyToBillingAddress}
                  useSubmitBehavior={false}
                />
              </Item>
            </GroupItem>
          </Tab>


          <Tab title='Billing address' icon="money">
            <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
              <Item dataField="billingAddress.code" caption={"Code"} isRequired={true}>
                <Label text="Code" />
              </Item>
              <Item dataField="billingAddress.name" caption={"Name"} isRequired={true}>
                <Label text="Name" />
              </Item>
              <Item dataField="billingAddress.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                <Label text="Address 1" />
                <RequiredRule message="Billing Address is required" />
              </Item>
              <Item dataField="billingAddress.address2" caption={"Address 2"} colSpan={2}>
                <Label text="Address 2" />
              </Item>
              <Item dataField="billingAddress.city" title={"City"}>
                <Label text="City" />
              </Item>
              <Item dataField="billingAddress.region" title={"Region"} >
                <Label text="County/Region" />
              </Item>
              <Item dataField="billingAddress.postcode" text={"Postcode"}>
                <Label text="Postcode" />
              </Item>
              <Item
                dataField="billingAddress.countryIsoCode"
                editorType="dxSelectBox"
                text={"Country"}
                isRequired={true}
                editorOptions={{
                  items: countryOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "isoCode",
                  placeholder: "Select a country..."
                }}>
                <Label text="Country" />
              </Item>
              <Item dataField="billingAddress.latitude" title={"Latitude"}>
                <Label text="Latitude" />
              </Item>
              <Item dataField="billingAddress.longitude" title={"Longitude"} colSpan={2} >
                <Label text="Longitude" />
              </Item>
              <Item dataField="billingAddress.notes" title={"Notes"} colSpan={2} >
                <Label text="Notes" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
              </Item>
            </GroupItem>
          </Tab>
        </TabbedItem>
      </Form>
    </div>
  </div>
})