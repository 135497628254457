import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { store } from '../stores/store'
import agent from '../api/agent'
import { PortClearance, PortClearanceSummary } from '../models/clearances'
import { PaginatedResult, Result } from '../models/result'

export function usePortClearances() {
    const pagination = {
        pageNumber: 1,
        pageSize: 50
    }

    return useQuery(['port-clearances'], () => agent.PortClearances.getPagedPortClearanceSummary(pagination), {
        onSuccess: (data: Result<PaginatedResult<PortClearanceSummary[]>>) => {
            if (data.isSuccess && data.data?.data) {
                store.clearanceStore.setPortClearances(data.data?.data)
            } else {
                store.clearanceStore.setPortClearances(null)
            }
        },
        onError: (error: any) => {
            console.error('Error retrieving port clearances.', error)
            store.clearanceStore.setPortClearances(null)
        },
    })
}

export function usePortClearance(id?: string) {
    const query = useQuery(['port-clearance', id], () => agent.PortClearances.getPortClearance(id!), {
        enabled: !!id,
        refetchOnWindowFocus: false,
        onSuccess: (data: Result<PortClearance>) => {
            store.clearanceStore.setPortClearance(data.data)
        },
        onError: (error: any) => {
            store.clearanceStore.setPortClearance(null)
        },
    })

    return {
        ...query,
        isLoading: !!id && query.isLoading
    }
}


export function useAddPortClearance() {
    const queryClient = useQueryClient()

    return useMutation(agent.PortClearances.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['port-clearances'])
        },
        onError: (error: any) => {
            store.clearanceStore.setError(error)
        },
    })
}

export function useUpdatePortClearance() {
    const queryClient = useQueryClient()

    return useMutation(agent.PortClearances.update, {
        onSuccess: (_data, variables) => {
            const id = variables.id
            queryClient.invalidateQueries(['port-clearance', id])
            queryClient.invalidateQueries(['port-clearances'])
        },
        onError: (error: any) => {
            store.clearanceStore.setError(error)
        },
    })
}

export function useDeletePortClearance() {
    const queryClient = useQueryClient()

    return useMutation(agent.PortClearances.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['port-clearances'])
        },
        onError: (error: any) => {
            store.clearanceStore.setError(error)
        },
    })
}