import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import { Button, DataGrid, Form, LoadIndicator } from "devextreme-react";
import { DeclarationRoro, DeclarationRoroRequest } from "../../app/models/declarationRoro";
import { GroupItem, Item } from "devextreme-react/form";
import { notifyMessage } from "../../app/common/utils/notify";
import { router } from "../../app/router/Routes";
import { Column, Editing, FilterRow, Paging } from "devextreme-react/data-grid";
import { incoTermCodeOptions } from "../../app/common/options/cdsImportDeclaration/incoTermCodeOptions";

export default observer(function RollOnRollOff(){
    const { organisationStore, customerStore, supplierStore, declarationStore } = useStore()
    
    useEffect(() => {
        organisationStore.getOrganisationMe()
    }, [organisationStore]);


    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;
    const routeItems = ["IE-GB"];

    useEffect(() => {
        customerStore.getCustomers(organisationId ?? '')
        supplierStore.getSuppliers(organisationId ?? '')
    }, [customerStore, supplierStore, organisationStore]);

    if(!customerStore.customers || !supplierStore.suppliers)
        return <LoadIndicator className="organisations-load-indicator" />

    let declarationRoro : DeclarationRoro = {
        customer: null,
        carrier: null,
        consignee: null,
        consignor: null,
        totalAmountInvoiced: null,
        incotermsCode: null,
        route: null,
        goodsItemRoRo: []
    }

    const handleCreateRoro = async () =>{
        if(!orgDefault || !orgDefault.id)
            return;

        let roroRequest: DeclarationRoroRequest = {
            customer: declarationRoro.customer ?? null,
            consignee: declarationRoro.consignee ?? null,
            consignor: declarationRoro.consignor ?? null,
            carrier: declarationRoro.carrier ?? null,
            totalAmountInvoiced: declarationRoro.totalAmountInvoiced ?? null,
            incotermsCode: declarationRoro.incotermsCode ?? null,
            route: declarationRoro.route ?? null,
            goodsItemRoRo: declarationRoro.goodsItemRoRo ?? null,
            organisationId: orgDefault.id
        }

        await declarationStore.createRollOnRollOff(roroRequest);
        if(declarationStore.isSuccess)
        {
            notifyMessage("success", "RoRo created");
            backToMain();
        }
        else
            notifyMessage("warning", "Could not create RoRo");
    }

    function backToMain(){
        router.navigate(`/declarations-main`)
    }

    return <div>
        <span className="tab-container-title">
                Roll On Roll Off
            </span>
        <Form formData={declarationRoro} >
            <GroupItem colCount={2} >
            <GroupItem colSpan={2} cssClass="cds-group-item"  colCount={1} caption={'Customer'}>
                    <Item 
                            dataField="customer"
                            editorType="dxSelectBox" 
                            editorOptions={{
                                items: customerStore.customers,
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "id"
                            }}
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'Consignee'}>
                    <Item 
                            dataField="consignee"
                            editorType="dxSelectBox" 
                            editorOptions={{
                                items: supplierStore.suppliers,
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "id"
                            }}
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'Consignor'}>
                    <Item 
                            dataField="consignor"
                            editorType="dxSelectBox" 
                            editorOptions={{
                                items: customerStore.customers,
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "id"
                            }}
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'Carrier'}>
                    <Item 
                            dataField="carrier"
                            editorType="dxSelectBox" 
                            editorOptions={{
                                items: supplierStore.suppliers,
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "id"
                            }}
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'INCO Terms Code'}>
                    <Item 
                            dataField="incotermsCode"
                            editorType="dxSelectBox" 
                            editorOptions={{
                                items: incoTermCodeOptions,
                                displayExpr: "description",
                                valueExpr: "code"
                            }}
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'Total Amount Invoiced'}>
                    <Item 
                            dataField="totalAmountInvoiced"
                            editorType="dxNumberBox"
                    />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colCount={1} caption={'Route'}>
                    <Item 
                                dataField="route"
                                editorType="dxSelectBox" 
                                editorOptions={{
                                    items: routeItems
                                }}
                        />
                </GroupItem>
                <GroupItem cssClass="cds-group-item"  colSpan={2} caption={'Goods Items'}>
                <DataGrid
                        id='goods'
                        dataSource={declarationRoro.goodsItemRoRo}
                        rowAlternationEnabled={true}
                        keyExpr='commodityCode'
                    >
                        <FilterRow visible={true} />
                        <Paging enabled={true} />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true} />
                        <Column type="adaptive" width={10} />
                        <Column dataField="commodityCode" caption="Commodity Code" />
                        <Column dataField="totalAmountInvoiced" caption="Total" />
                </DataGrid>
                </GroupItem>
            </GroupItem>
            <GroupItem>
                <Button onClick={handleCreateRoro} text={'Add'} icon="save" stylingMode="contained" type="success" />
                <Button onClick={backToMain} text={'Cancel'} icon="close" stylingMode="contained" type="danger" />
            </GroupItem>
        </Form>
        </div>
})