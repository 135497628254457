import { observer } from 'mobx-react-lite'
import { confirm } from "devextreme/ui/dialog"
import { Button, DataGrid } from 'devextreme-react'
import { useState } from 'react'
import { Column, Editing } from 'devextreme-react/data-grid'
import { onToolbarPreparing } from '../../app/common/utils/toolbar'
import { MRNForm } from '../../app/models/clearances'
import { v4 as uuidv4 } from 'uuid'
import PortClearanceAddMrn from './PortClearanceAddMrn'

interface Props {
    dataForm: MRNForm[] | null
    handleDeleteMrn: (id: string) => void
    handleAddMrn: (mrn: MRNForm) => void
    handleEditMrn: (mrn: MRNForm) => void
    routeId: string | null
}

export default observer(function PortClearanceMrnsGrid({ dataForm, handleDeleteMrn, handleAddMrn, handleEditMrn, routeId }: Props) {
    const [adding, setAdding] = useState(false)
    const [mrn, setMrn] = useState<MRNForm | null>(null)

    const handleDelete = (cellData: any) => {

        confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
            if (cellData.data.id && result) {
                handleDeleteMrn(cellData.data.id)
            }
        })
    }

    const handleEdit = (cellData: any) => {
        setMrn(new MRNForm(
            {
                id: cellData.data.id,
                mrnNumber: cellData.data.mrnNumber,
                sasMrnNumber: cellData.data.sasMrnNumber,
                isTsad: cellData.data.isTsad,
                declarationType: cellData.data.declarationType
            },
            cellData.data.actionType
        ))
        setAdding(e => !e)
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="edit"
                    width={40}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleEdit(cellData)}
                />
                <Button
                    icon="trash"
                    width={30}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => handleDelete(cellData)}
                />
            </>
        )
    }

    function handleCancelAdd() {
        setAdding(e => !e)
    }

    function handleConfirmAdd(data: MRNForm) {
        if(!data.id)
        {
            data.id = uuidv4()
            handleAddMrn(data)
        }
        else if(data.id)
            handleEditMrn(data)

        setAdding(e => !e)
    }

    function handleAdding() {
        setMrn(new MRNForm(
            {
                id: "",
                mrnNumber: "",
                sasMrnNumber: "",
                isTsad: false,
                declarationType: "Customs"
            },
            'Export'
        ))
        setAdding(e => !e)
    }

    return (
        <div>
            {!adding ? (
                <DataGrid
                    dataSource={dataForm}
                    onToolbarPreparing={(e) => onToolbarPreparing(e, adding, handleAdding)}
                >
                    <Editing allowUpdating={true} allowAdding={true} />
                    <Column dataField="mrnNumber" caption={'MRN'} />
                    <Column dataField="actionType" />
                    <Column dataField="sasMrnNumber" />
                    <Column dataField="isTsad" />
                    <Column dataField="declarationType" caption={'Dec.'} />
                    <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                </DataGrid>
            ) : (
                <PortClearanceAddMrn dataForm={mrn} handleCancelAdd={handleCancelAdd} handleConfirmAdd={handleConfirmAdd} routeId={routeId} />
            )}
        </div>
    )
})