import { Button } from "devextreme-react";

interface Props {
    title: string,
    children: JSX.Element,
    closeFunction: any;
};

/**
 * Display styled Form with buttons
 *
 * Inputs: 
 * - form: Form to be displayed
 * - closeFunction: Reference to a function to close/cancel the form 
 * - title: The form title
*/

export default function FormContainer({ children, title, closeFunction }: Props) {

    return (
        <div className="html-blank-general">
            <div className="general-container">
                <div className="generic-form-shadows">
                    <div className="form-add-container" >
                     <span className="form-add-organisation-title">{title}</span>
                        <Button
                            className="add-organisation-close-button"
                            onClick={closeFunction}
                            icon="close"
                        />
                        {children}
                    </div >
                </div>
            </div>
        </div>
    )
}