import { LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Token } from '../../app/models/user'
import { useStore } from '../../app/stores/store'

export default observer(function ValidateEmail() {
  const {userStore: { validateEmail, isValidate, alreadyValidated }} = useStore()
  const { tokenStr } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (tokenStr) {
      const token: Token = {
        token: decodeURIComponent(tokenStr),
      }
      validateEmail(token)
    }
  }, [validateEmail, tokenStr])

  if (isValidate == null) return <LoadPanel visible={true} />

  if (alreadyValidated) {
    window.setTimeout(() => { navigate('/dashboard') }, 5000)
  } else {
    window.setTimeout(() => { navigate('/pending-email-validation') }, 5000)
  }

  return (
    <div className='email-validated-container'>
      {alreadyValidated || isValidate ? (
        <div className='email-validated-container-inner_div'>
          <img className='letter-confirmation' src='/img/letterImage.png' alt='email' />
          <h2 className='email-validated-title'>{alreadyValidated ? 'Your email has been already verified.' : 'Your email has been verified.'}</h2>
          <h3>Redirecting to mycustomscloud...</h3>
        </div>
      ) : (
        <div className='email-validated-container-inner_div'>
          <img className='letter-confirmation' src='/img/letterImage.png' alt='email' />
          <h2 className='email-validated-title'> Sorry!! Email could not be verified.</h2>
          <h4>Please resend validation email, redirecting...</h4>
        </div>
      )}
    </div>
  )
})
